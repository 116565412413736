import { ContentObserver } from "@angular/cdk/observers";
import { Injectable, Inject } from "@angular/core";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isLoggedIn = false; // ... your login logic here

    if (this.storage.get("userId") != null) {
      if (this.storage.get('AssignedBusiness') != undefined && this.storage.get('AssignedBusiness') != null) {
        let assignedBUsiness = this.storage.get('AssignedBusiness');
        // console.log(assignedBUsiness)
        let index = assignedBUsiness.findIndex(e => e.businessId == this.storage.get("selectedBusinessId"));
        // console.log(assignedBUsiness[index])
        if (index < 0) {
          this.storage.remove("userName");
          this.storage.remove("password");
          this.storage.remove("userId");
          this.storage.remove("selectedBusinessId");
          this.router.navigate(['login']);
          return false;
        } else {
          // console.log(state.url)
          if (state.url.includes("businessSelector") || state.url.includes("notifications") || state.url.includes("wallet-management") || state.url.includes("showDocumentation") || state.url.includes("profile") || state.url.includes("announcements")) {
            return true
          } else {
            let modules = this.storage.get("modules")
            let route = 0
            // for (let i = 0; i < modules.length; i++) {

            // }
            for (let i = 0; i < modules.length; i++) {
              // console.log(modules[i].subLinks)
              if (modules[i].subLinks != undefined && modules[i].subLinks != null && modules[i].subLinks.length != 0) {
                // console.log('sublink')
                for (let j = 0; j < modules[i].subLinks.length; j++) {
                  if (state.url.includes(modules[i].subLinks[j].page) || state.url.includes(modules[i].page)) {
                    // console.log('includes')
                    route = 1
                    break;
                  }
                }
              } else {
                // console.log('normal')
                if (state.url.includes(modules[i].page)) {
                  // console.log('includes')
                  route = 1
                  break;
                }
              }
            }


            // console.log(route)
            if (route == 1) {
              return true;
            } else {
              // console.log("here")
              // this.storage.remove("userName");
              // this.storage.remove("password");
              // this.storage.remove("userId");
              // this.storage.remove("selectedBusinessId");
              this.router.navigateByUrl('Dashboard/authorization-required');
              return true;
            }
          }
        }
      }
      else {
        this.storage.remove("userName");
        this.storage.remove("password");
        this.storage.remove("userId");
        this.storage.remove("selectedBusinessId");
        this.router.navigate(['login']);
        return false;
      }
    }
    else {
      // console.log("in else")
      this.storage.remove("userName");
      this.storage.remove("password");
      this.storage.remove("userId");
      this.storage.remove("selectedBusinessId");
      this.router.navigate(['login']);
      return false;
    }
    // if (this.storage.get("userId") != null) {
    //   return true;
    // }
    // this.router.navigate([""]);
    // return false;
  }
}
