import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'customcurrency',
})
export class MycurrencyPipe implements PipeTransform {
    currency: string;
    locale: string;
    constructor() {
        this.currency = localStorage.getItem('currency')
        if (this.currency != undefined && this.currency != '' && this.currency != null && this.currency != 'undefined' && this.currency != 'null') {

        } else {
            this.currency = 'INR'
        }
        // console.log(this.currency)
        this.locale = localStorage.getItem('locale')
        if (this.locale != undefined && this.locale != '' && this.locale != null && this.locale != 'undefined' && this.locale != 'null') {

        } else {
            this.locale = 'en-INR'
        }
    }
    transform(
        value: number,
        currencyCode: string = this.currency,
        display: 'symbol',
        digitsInfo: string = '1.2-2',
        locale: string = this.locale
    ): string | null {
        let formattedAmount = formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'wide'), currencyCode, digitsInfo);

        // Custom formatting for lakh and crore
        if (value >= 10000000) {
            formattedAmount = getCurrencySymbol(currencyCode, 'wide') + (value / 10000000).toFixed(2) + ' Cr';
        } else if (value >= 100000) {
            formattedAmount = getCurrencySymbol(currencyCode, 'wide') + (value / 100000).toFixed(2) + ' Lac ';
        }
        return formattedAmount;
    }
}