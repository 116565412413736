import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MustMatch } from "../_helpers/must-matchValidator";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { AppComponent } from "../../../../app.component";
import * as $ from "jquery";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
// declare let Swal : any;
import Swal from "sweetalert2";
@Component({
  selector: "m-logout",
  templateUrl: "./logout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public httpClient: HttpClient,
    private cd: ChangeDetectorRef,
    private appComp: AppComponent,
    private activateRoute: ActivatedRoute,
    private modalService: NgbModal,
    private modalRef: NgbActiveModal,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}
  ngOnInit() {
    // ("inside logout")
    localStorage.clear();
    let path = "";
    this.router.navigate([path]);
  }
  
}
