import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdvancePaymentsService {

  baseUrl = environment.base_api;
  baseUrlCrm = environment.base_api_crm;
  constructor(private http: HttpClient) {

    
  }
   

  searchCustomer(params) {
    const url = `${this.baseUrl}pos/searchUser?${params}`
    return this.http.get(url)
  }
  fetchPaymentsList(params) {
    const url = `${this.baseUrl}arc/fetchAdvanePaymentList?${params}`
    return this.http.get(url)
  }
  createPaymentsList(params) {
    const url = `${this.baseUrl}arc/addAdvancePayment?${params}`
    return this.http.get(url)
  }
}
