<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;">
  <div class="col-3">
    <img src="../../../../../assets/media/logos/ue-logonew.jpg" style="width: 12em;">
  </div>
  <div class="col-6 " style="text-align: center;">
    <span class="font-weight-bold text-dark font-size-h4 font-size-h1-lg">Welcome,&nbsp;</span>
    <span class="font-weight-bolder" style="color: #4faa5d;font-size:2.3em;">{{businessName}}</span>
  </div>
  <div class="col-3 d-flex justify-content-end">
    <div ngbDropdown class="d-inline-block dropdown mt-3" style="cursor: pointer;">
      <div class="btn btn-icon pulse pulse-info" id="dropdownBasic1" ngbDropdownToggle>
        <span>
          <i class="far fa-user text" style="font-size: 3em;"></i>
        </span>
        <span class="pulse-ring"></span>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
        background-color: #fff;
        box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
        min-height: 5em;
        margin-top: 1em;
        width: 26em;margin-left:-24em !important
      ">
        <div class="m-dropdown__inner">
          <div class="m-dropdown__header m--align-center" #mProfileDropdown style="height: 3em;">
            <div class="m-card-user m-card-user--skin-dark">
              <div class="m-card-user__details text-center">
                <span class="m-card-user__name m--font-weight-500 ml-2" style="color: black; font-size: 18px;">
                  {{ userName }}
                </span>
                <!-- <span>
                <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 ml-10">
                  <i class="fas fa-power-off"></i>&nbsp;Logout
                </a>
              </span> -->
              </div>
            </div>
          </div>
          <div class="m-dropdown__body mt-2">
            <div class="m-dropdown__content">
              <div class="row w-100 m-0">
                <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 text-center w-100 ml-2 mr-2">
                  <i class="fas fa-power-off"></i>&nbsp;Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="row justify-content-center">
  <div class="card card-custom mt-5" style="height: auto;width:35em">
    <div class="ribbon ribbon-top-left"><span *ngIf="currentStatus==0">PENDING</span>
      <span *ngIf="currentStatus==-2">REJECTED</span>
      <span *ngIf="currentStatus==1">ACCEPTED</span>
    </div>
    <div class="card-body pt-2 pb-0 mt-n3" *ngIf="currentStatus==0">
      <div class="row w-100 m-0 justify-content-center">
        <img class="circle" src="../../../../../assets/media/misc/pending.png" style="width: 17em;">
      </div>
      <div class="row w-100 m-0 justify-content-center mt-3 text-center">
        <span class="text-dark-75 font-size-h1">Thank You For Your Response.</span>
      </div>
      <div class="row w-100 m-0 justify-content-center text-center" style="margin-top: 3em !important;">
        <span class="text-dark-75">We have sent your details for approval. Our team will get back to you shortly.</span>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="text-right mb-15 mr-xxl-10">
          <span class="font-weight-bold text-muted font-size-h5">Having issues?</span>
          <a href="https://api.uengage.in/contact-uengage" class="font-weight-bolder text-info font-size-h4"
            id="kt_login_signup" target="_blank">&nbsp;Get Help</a>
        </div>
      </div>
    </div>
    <div class="card-body pt-2 pb-0 mt-n3" *ngIf="currentStatus==-2">
      <div class="row w-100 m-0 justify-content-center">
        <img class="circle" src="../../../../../assets/media/misc/pending.png" style="width: 17em;">
      </div>
      <div class="row w-100 m-0 justify-content-center mt-3 text-center">
        <span class="text-dark-75 font-size-h1">Thank You For Your Response.</span>
      </div>
      <div class="row w-100 m-0 justify-content-center text-center" style="margin-top: 3em !important;">
        <span class="text-dark-75 font-weight-bold font-size-h5">Your application has been rejected.&nbsp;
          <!-- <a (click)="viewDetails()"
            class="btn btn-link-info font-weight-bold">View Details</a> -->
        </span>
      </div>
      <div class="row justify-content-center mt-5">
        <table class="table table-bordered">
          <thead>
            <th>Reason</th>
            <th>Date</th>
          </thead>
          <tbody>
            <tr *ngFor="let data of logs">
              <td style="word-break: break-all">{{data.comments}}</td>
              <td>{{data.insertedAt | date:"dd MMM,yyyy hh:mm a"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end mt-5 d-flex">
        <a class="btn btn-sm btn-light-warning" style="cursor: pointer;" (click)="comment(commentModal)">Add Comment and
          Submit</a>&nbsp;&nbsp;
        <a class="btn btn-sm btn-light-info" (click)="viewDetails()" style="cursor: pointer;">Edit Form</a>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="text-right mb-15 mr-xxl-10">
          <span class="font-weight-bold text-muted font-size-h5">Having issues?</span>
          <a href="https://api.uengage.in/contact-uengage" class="font-weight-bolder text-info font-size-h4"
            id="kt_login_signup" target="_blank">&nbsp;Get Help</a>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</div>
<ng-template let-modal #commentModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Enter Comment</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <!-- <label>Comments:</label> -->
    <textarea class="form-control" [(ngModel)]="comments"></textarea>
  </div>
  <div class="modal-footer">
    <input type="button" class="btn btn-primary" value="Submit" (click)="submitApplication()">
  </div>
</ng-template>