<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;" *ngIf="bName!=undefined">
    <div class="col-3">
        <img src="../../../../../assets/media/logos/ue-logonew.jpg" style="width: 12em;">
    </div>
    <div class="col-6 d-none d-sm-block" style="text-align: center;">
        <span class="font-weight-bold text-dark font-size-h4 font-size-h1-lg">Welcome,&nbsp;</span>
        <span class="font-weight-bolder" style="color: #4faa5d;font-size:2.3em;">{{bName}}</span>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <div ngbDropdown class="d-inline-block dropdown mt-3" style="cursor: pointer;">
            <div class="btn btn-icon pulse pulse-info" id="dropdownBasic1" ngbDropdownToggle>
                <span>
                    <i class="far fa-user text" style="font-size: 3em;"></i>
                </span>
                <span class="pulse-ring"></span>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
          background-color: #fff;
          box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
          min-height: 5em;
          margin-top: 1em;
          width: 26em;margin-left:-24em !important
        ">
                <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center" #mProfileDropdown style="height: 3em;">
                        <div class="m-card-user m-card-user--skin-dark">
                            <div class="m-card-user__details text-center">
                                <span class="m-card-user__name m--font-weight-500 ml-2"
                                    style="color: black; font-size: 18px;">
                                    {{ userName }}
                                </span>
                                <!-- <span>
                  <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 ml-10">
                    <i class="fas fa-power-off"></i>&nbsp;Logout
                  </a>
                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="m-dropdown__body mt-2">
                        <div class="m-dropdown__content">
                            <div class="row w-100 m-0">
                                <a (click)="logout()"
                                    class="btn btn-light-danger btn-sm mt-3 text-center w-100 ml-2 mr-2">
                                    <i class="fas fa-power-off"></i>&nbsp;Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;" *ngIf="bName==undefined">
    <div class="col-6">
        <img src="../../../../../assets/media/logos/logo-sm.png" style="width: 12em;">
    </div>
    <div class="col-6 d-flex justify-content-end">
        <div ngbDropdown class="d-inline-block dropdown mt-3" style="cursor: pointer;">
            <div class="btn btn-icon pulse pulse-info" id="dropdownBasic1" ngbDropdownToggle>
                <span>
                    <i class="far fa-user text" style="font-size: 3em;"></i>
                </span>
                <span class="pulse-ring"></span>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
          background-color: #fff;
          box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
          min-height: 5em;
          margin-top: 1em;
          width: 26em;margin-left:-24em !important
        ">
                <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center" #mProfileDropdown style="height: 3em;">
                        <div class="m-card-user m-card-user--skin-dark">
                            <div class="m-card-user__details text-center">
                                <span class="m-card-user__name m--font-weight-500 ml-2"
                                    style="color: black; font-size: 18px;">
                                    {{ userName }}
                                </span>
                                <!-- <span>
                  <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 ml-10">
                    <i class="fas fa-power-off"></i>&nbsp;Logout
                  </a>
                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="m-dropdown__body mt-2">
                        <div class="m-dropdown__content">
                            <div class="row w-100 m-0">
                                <a (click)="logout()"
                                    class="btn btn-light-danger btn-sm mt-3 text-center w-100 ml-2 mr-2">
                                    <i class="fas fa-power-off"></i>&nbsp;Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
<div class="d-flex flex-column-fluid">
    <div class="container">
        <div class="card card-custom gutter-b mt-5 mb-5">
            <div class="card-body">
                <!--begin: Wizard -->
                <div #wizard class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first">
                    <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10 d-none d-sm-block"
                        style="position: relative;">
                        <img src="../../../../../assets/media/misc/UE.png" style="width: 11em;
              position: absolute;
              top: 0;">
                        <!--begin: Form Wizard Nav -->
                        <div class="wizard-steps"
                            style="background-color: #fff; z-index: 1; margin-top: 103px; position: relative;">
                            <a class="wizard-step" href="javascript:;" data-wizard-type="step"
                                [ngClass]="{'changeBg':businessDetails==1}" data-wizard-state="current">
                                <div class="wizard-wrapper">
                                    <div class="wizard-icon">
                                        <span>
                                            <i class="flaticon-squares-4" style="color: #568b17;font-size: 1em;"></i>
                                        </span>
                                    </div>
                                    <div class="wizard-label">
                                        <div class="wizard-title">Company Details</div>
                                        <div class="wizard-desc">
                                            <span [ngClass]="{'product':businessDetails==1}"></span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step"
                                [ngClass]="{'changeBg':imageUploaded==1}">
                                <div class="wizard-wrapper">
                                    <div class="wizard-icon">
                                        <span>
                                            <i class="flaticon-background" style="color: #568b17;font-size: 1em;"></i>
                                        </span>
                                    </div>
                                    <div class="wizard-label">
                                        <div class="wizard-title">Brand Logo</div>
                                        <div class="wizard-desc">
                                            <span [ngClass]="{'product':imageUploaded==1}"></span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <!-- <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step"
                            [ngClass]="{'changeBg':menu==1}">
                            <div class="wizard-wrapper">
                                <div class="wizard-icon">
                                    <span>
                                        <i class="flaticon-background" style="color: #568b17;font-size: 1em;"></i>
                                    </span>
                                </div>
                                <div class="wizard-label">
                                    <div class="wizard-title">Menu Creation</div>
                                    <div class="wizard-desc">
                                        <span [ngClass]="{'product':menu==1}"></span>
                                    </div>
                                </div>
                            </div>
                        </a> -->
                            <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step">
                                <div class="wizard-wrapper">
                                    <div class="wizard-icon">
                                        <span>
                                            <i class="flaticon-list" style="color: #568b17;font-size: 1em;"></i>
                                        </span>
                                    </div>
                                    <div class="wizard-label">
                                        <div class="wizard-title">Upload Documents</div>
                                        <div class="wizard-desc">
                                            <!-- <span class="industry"></span> -->
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="wizard-body py-8 px-8">
                        <!-- <div class="row w-100 m-0 justify-content-end">
                            <span style="font-size: 2em;">
                                <img src="{{product_icon}}" style="width: 1.5em;">&nbsp;Uengage Edge</span>
                        </div> -->
                        <!--begin: Wizard Form-->
                        <div class="row mt-10">
                            <div class="offset-xxl-2 col-xxl-8">
                                <!--begin: Form Wizard Form-->
                                <form class="form" id="kt_form">
                                    <!--begin: Form Wizard Step 1-->
                                    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                        <div class="row w-100 m-0 mb-5">
                                            <span style="font-size: 2em;">
                                                <img src="{{product_icon}}" style="width: 1.5em;">&nbsp;Uengage
                                                Edge</span>
                                        </div>
                                        <h5 class="font-weight-bold text-dark">
                                            Step 1:
                                        </h5>
                                        <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                                            Setup Your Company Details
                                        </h4>
                                        <form [formGroup]="detailForm" (ngSubmit)="saveDetails()">
                                            <div class="row w-100">
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Legal
                                                            Entity Name</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Legal Entity Name" value=""
                                                            formControlName="legalEntityName"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.legalEntityName.errors }">
                                                        <div *ngIf="submitted2 && d.legalEntityName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.legalEntityName.errors.required">Field is
                                                                required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Address</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Address" value="" formControlName="address"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.address.errors }">
                                                        <div *ngIf="submitted2 && d.address.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.address.errors.required">Field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row w-100">
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">GSTIN</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="GSTIN" value="" formControlName="gstin"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.gstin.errors }">
                                                        <div *ngIf="submitted2 && d.gstin.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.gstin.errors.required">Field is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Company
                                                            Type</label>
                                                        <select class="form-control form-control-solid form-control-lg"
                                                            formControlName="companyType"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.companyType.errors }">
                                                            <option value="">Select</option>
                                                            <option *ngFor="let data of companyData"
                                                                value="{{data.company_typ_id}}">
                                                                {{data.company_type_name}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="submitted2 && d.companyType.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.companyType.errors.required">Field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row w-100">
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Bank
                                                            Name</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Bank Name" value="" formControlName="bankName"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.bankName.errors }">
                                                        <div *ngIf="submitted2 && d.bankName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.bankName.errors.required">Field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Bank
                                                            Account Holder</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Account Holder" value=""
                                                            formControlName="accHolder"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.accHolder.errors }">
                                                        <div *ngIf="submitted2 && d.accHolder.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.accHolder.errors.required">Field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row w-100">
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">
                                                            Account Number</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Account Number" value=""
                                                            formControlName="accNumber"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.accNumber.errors }">
                                                        <div *ngIf="submitted2 && d.accNumber.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.accNumber.errors.required">Field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label
                                                            class="font-size-h6 font-weight-bold text-dark control-label">Bank
                                                            IFSC</label>
                                                        <input type="text"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="IFSC" value="" formControlName="ifsc"
                                                            [ngClass]="{ 'is-invalid': submitted2 && d.ifsc.errors }">
                                                        <div *ngIf="submitted2 && d.ifsc.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="d.ifsc.errors.required">Field is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between p-5" style="float: right;">
                                                <div>
                                                    <button type="submit"
                                                        class="btn btn-success font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3">Save
                                                        & Continue
                                                        <span class="svg-icon svg-icon-md ml-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                                height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <rect fill="#000000" opacity="0.3"
                                                                        transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                                                        x="7.5" y="7.5" width="2" height="9" rx="1">
                                                                    </rect>
                                                                    <path
                                                                        d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                        fill="#000000" fill-rule="nonzero"
                                                                        transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <!--end: Form Wizard Step 1-->

                                    <!--begin: Form Wizard Step 2-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <div class="row w-100 m-0 mb-5">
                                            <span style="font-size: 2em;">
                                                <img src="{{product_icon}}" style="width: 1.5em;">&nbsp;Uengage
                                                Edge</span>
                                        </div>
                                        <h5 class="font-weight-bold text-dark">
                                            Step 2:
                                        </h5>
                                        <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                                            Upload Your Brand Logo
                                        </h4>
                                        <form [formGroup]="logoForm" (ngSubmit)="uploadImageCropped()">
                                            <div class="row w-100">
                                                <div class="col-lg-12">
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label class="font-size-h6 font-weight-bold text-dark">Company
                                                            Logo</label>
                                                        <input type="file" (change)="fileChangeEvent($event)"
                                                            class="form-control form-control-solid form-control-lg"
                                                            placeholder="Logo" value="" formControlName="logo">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row w-100 m-0 mt-5" *ngIf="prefillImage==0">
                                                <image-cropper [imageChangedEvent]="imageChangedEvent"
                                                    [maintainAspectRatio]="false" format="png" [resizeToWidth]="400"
                                                    [resizeToHeight]="400" (imageCropped)="imageCropped($event)"
                                                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                                                    (loadImageFailed)="loadImageFailed()"></image-cropper>
                                            </div>
                                            <div class="row w-100 m-0 mt-5" *ngIf="prefillImage==1">
                                                <img src="{{brandLogo}}">
                                            </div>
                                            <div class="d-flex justify-content-between p-5" style="float: right;">
                                                <div>
                                                    <button type="submit"
                                                        class="btn btn-success font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3">Save
                                                        & Continue
                                                        <span class="svg-icon svg-icon-md ml-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                                height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <rect fill="#000000" opacity="0.3"
                                                                        transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                                                        x="7.5" y="7.5" width="2" height="9" rx="1">
                                                                    </rect>
                                                                    <path
                                                                        d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                        fill="#000000" fill-rule="nonzero"
                                                                        transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <!--begin: Form Wizard Step 2-->
                                    <!--begin: Form Wizard Step 3-->
                                    <!-- <div class="pb-5" data-wizard-type="step-content">
                                        <div class="row w-100 m-0 mb-5">
                                            <span style="font-size: 2em;">
                                                <img src="{{product_icon}}" style="width: 1.5em;">&nbsp;Uengage
                                                Edge</span>
                                        </div>
                                        <h5 class="font-weight-bold text-dark">
                                            Step 3:
                                        </h5>
                                        <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                                            From Where You Want To Create Your Menu
                                        </h4>
                                        <div class="row w-100 mt-5">
                                            <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                                <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
            align-items: center;
            justify-content: center;" (click)="saveMenuCreation(manually)"
                                                    [ngClass]="{'menuSelect': menuCreation == 'manually'}">
                                                    <span [ngClass]="{'check-selected': menuCreation == 'manually'}"
                                                        class="check-nonselected">
                                                        <span
                                                            [ngClass]="{'check-position-data': menuCreation == 'manually'}"><i
                                                                class="fas fa-check" style="color: #fff;
                  size: 68%;
                  font-size: 100%;
                  padding: 4px;"></i></span>
                                                    </span>
                                                    <label>Manually</label>
                                
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                                <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
            align-items: center;
            justify-content: center;" (click)="saveMenuCreation(petpooja)"
                                                    [ngClass]="{'menuSelect': menuCreation == 'petpooja'}">
                                                    <span [ngClass]="{'check-selected': menuCreation=='petpooja'}"
                                                        class="check-nonselected">
                                                        <span
                                                            [ngClass]="{'check-position-data': menuCreation=='petpooja'}"><i
                                                                class="fas fa-check" style="color: #fff;
                  size: 68%;
                  font-size: 100%;
                  padding: 4px;"></i></span>
                                                    </span>
                                                    <label>PetPooja</label>
                                           
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                                <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
            align-items: center;
            justify-content: center;" (click)="saveMenuCreation(posist)"
                                                    [ngClass]="{'menuSelect': menuCreation == 'posist'}">
                                                    <span [ngClass]="{'check-selected': menuCreation=='posist'}"
                                                        class="check-nonselected">
                                                        <span
                                                            [ngClass]="{'check-position-data': menuCreation=='posist'}"><i
                                                                class="fas fa-check" style="color: #fff;
                  size: 68%;
                  font-size: 100%;
                  padding: 4px;"></i></span>
                                                    </span>
                                                    <label>Posist</label>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                                <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
            align-items: center;
            justify-content: center;" (click)="saveMenuCreation(billberry)"
                                                    [ngClass]="{'menuSelect': menuCreation == 'billberry'}">
                                                    <span [ngClass]="{'check-selected': menuCreation=='billberry'}"
                                                        class="check-nonselected">
                                                        <span
                                                            [ngClass]="{'check-position-data': menuCreation=='billberry'}"><i
                                                                class="fas fa-check" style="color: #fff;
                  size: 68%;
                  font-size: 100%;
                  padding: 4px;"></i></span>
                                                    </span>
                                                    <label>Billberry</label>
                                                  
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                                <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
            align-items: center;
            justify-content: center;" (click)="saveMenuCreation(sparktech)"
                                                    [ngClass]="{'sourceSelect': menuCreation == 'sparktech'}">
                                                    <span [ngClass]="{'check-selected': menuCreation=='sparktech'}"
                                                        class="check-nonselected">
                                                        <span
                                                            [ngClass]="{'check-position-data': menuCreation=='sparktech'}"><i
                                                                class="fas fa-check" style="color: #fff;
                  size: 68%;
                  font-size: 100%;
                  padding: 4px;"></i></span>
                                                    </span>
                                                    <label>Sparktech</label>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between p-5" style="float: right;">
                                            <div>
                                                <button type="button" (click)="saveMenu()"
                                                    class="btn btn-success font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3">Save
                                                    & Continue
                                                    <span class="svg-icon svg-icon-md ml-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                            height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none"
                                                                fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                <rect fill="#000000" opacity="0.3"
                                                                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                                                    x="7.5" y="7.5" width="2" height="9" rx="1">
                                                                </rect>
                                                                <path
                                                                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                    fill="#000000" fill-rule="nonzero"
                                                                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                    </span></button>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!--end: Form Wizard Step 3-->
                                    <!--begin: Form Wizard Step 4-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <div class="row w-100 m-0 mb-5">
                                            <span style="font-size: 2em;">
                                                <img src="{{product_icon}}" style="width: 1.5em;">&nbsp;Uengage
                                                Edge</span>
                                        </div>
                                        <h5 class="font-weight-bold text-dark">
                                            Step 3:
                                        </h5>
                                        <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                                            Verify Your Company Details
                                        </h4>
                                        <div class="row w-100 mt-5" *ngFor="let data of docList">
                                            <div class="col-md-1 col-lg-1">
                                                <span [ngClass]="{'showTick':data.uploaded==1}"></span>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">{{data.name}}</label>
                                            </div>
                                            <div class="col-md-5 col-lg-5">
                                                <div class="image-input image-input-empty image-input-outline"
                                                    id="{{data.id}}" *ngIf="data.uploaded==0" (click)="change(data.id)"
                                                    style="background-image: url(../../../../../assets/media/misc/noImage.png)">
                                                    <input type="file" name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg, .pdf" (change)="onSelectFile($event)"
                                                        style="position: absolute;
                                                    width: 100%;
                                                    height: 100%;
                                                    opacity: 0;
                                                " />
                                                    <div class="image-input-wrapper"></div>
                                                    <label
                                                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="change" data-toggle="tooltip" title=""
                                                        data-original-title="Change avatar">
                                                        <i class="fa fa-pen icon-sm text-muted"></i>

                                                        <input type="hidden" name="profile_avatar_remove" />
                                                    </label>
                                                    <!-- <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel" data-toggle="tooltip"
                                                    title="Cancel avatar">
                                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                                </span> -->
                                                </div>
                                                <div class="image-input image-input-empty image-input-outline"
                                                    id="{{data.id}}"
                                                    *ngIf="data.uploaded==1 && !getExtension(data.file)"
                                                    (click)="change(data.id)"
                                                    [ngStyle]="{'background-image': 'url(https://static.uengage.in/onboarding/'+bId+'/'+data.file+')'}">
                                                    <input type="file" name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg,.pdf" (change)="onSelectFile($event)"
                                                        style="position: absolute;
                                                    width: 100%;
                                                    height: 100%;
                                                    opacity: 0;
                                                " />
                                                    <!-- <embed src="https://static.uengage.in/onboarding/'+bId+'/'+data.file+'" width="21px" height="21x" /> -->
                                                    <div class="image-input-wrapper"></div>
                                                    <label
                                                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="change" data-toggle="tooltip" title=""
                                                        data-original-title="Change avatar">
                                                        <i class="fa fa-pen icon-sm text-muted"></i>

                                                        <input type="hidden" name="profile_avatar_remove" />
                                                    </label>
                                                    <!-- <span
                                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                data-action="cancel" data-toggle="tooltip"
                                                title="Cancel avatar">
                                                <i class="ki ki-bold-close icon-xs text-muted"></i>
                                            </span> -->
                                                </div>
                                                <div class="image-input image-input-empty image-input-outline"
                                                    id="{{data.id}}" *ngIf="data.uploaded==1 && getExtension(data.file)"
                                                    (click)="change(data.id)"
                                                    style="background-image: url(../../../../../assets/media/misc/PDF-Attached.png)">
                                                    <input type="file" name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg,.pdf" (change)="onSelectFile($event)"
                                                        style="position: absolute;
                                                width: 100%;
                                                height: 100%;
                                                opacity: 0;
                                            " />
                                                    <!-- <embed src="https://static.uengage.in/onboarding/'+bId+'/'+data.file+'" width="21px" height="21x" /> -->
                                                    <div class="image-input-wrapper"></div>
                                                    <label
                                                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="change" data-toggle="tooltip" title=""
                                                        data-original-title="Change avatar">
                                                        <i class="fa fa-pen icon-sm text-muted"></i>

                                                        <input type="hidden" name="profile_avatar_remove" />
                                                    </label>
                                                    <!-- <span
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="cancel" data-toggle="tooltip"
                                            title="Cancel avatar">
                                            <i class="ki ki-bold-close icon-xs text-muted"></i>
                                        </span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end: Form Wizard Step 4-->

                                    <!--begin: Form Actions -->
                                    <div class="d-flex justify-content-between border-top mobileBox">
                                        <div class="mr-2">
                                            <button type="button"
                                                style="background-color: #c0e495 !important;color: #568b17 !important;border-color: #c0e495 !important;"
                                                class="btn btn-info font-weight-bold font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                                                data-wizard-type="action-prev">
                                                <span class="svg-icon svg-icon-md mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#568b17" opacity="0.3"
                                                                transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                                                x="14" y="7" width="2" height="10" rx="1"></rect>
                                                            <path
                                                                d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                                                fill="#000000" fill-rule="nonzero"
                                                                transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)">
                                                            </path>
                                                        </g>
                                                    </svg>
                                                </span>Previous</button>
                                            <!-- <div
                                class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                data-wizard-type="action-prev" style="background-color: #c0e495 !important;"
                              >
                                Previous
                              </div> -->
                                        </div>
                                        <div>
                                            <div class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-submit" (click)="compSetup()">
                                                Submit
                                            </div>
                                            <!-- <div style="background-color: #568b17 !important;"
                                class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                data-wizard-type="action-next"
                              >
                                Next Step
                              </div> -->
                                        </div>
                                    </div>
                                    <!--end: Form Actions -->
                                </form>
                                <!--end: Form Wizard Form-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--end: Wizard -->
            </div>
        </div>
    </div>
</div>