export const ENDPOINTS = {
    downloadCustomerData: 'crm_api/download/customers',
    dogsPoopDetails: 'addoapi/get_pet_poop_color_listing',
    advanceDashboardDetails: 'arc/getAdvancePaySplit',
    getBusinessStatusLogs: 'business_management/getBusinessStatusLogs?',
    storeDynamicRules: 'store_management/dynamic_rules?',
    storeMenuPushDetails: 'store_management/integrationMenuPush/',
    getShortUrlById :'store_management/GetShortUrlById?',
    addShortUrls :'store_management/AddShortUrls?',
    uploadCustomCampaigns :'crm_api/coldCampaign/upload/campaign/cold/data',
    deleteDraftCampaign :'crm_api/coldCampaign/delete/draft/campaign',
    downloadSampleCampaign :'cdn.uengage.io/customer_format.xlsx',
    customerMarketingDashboard:'crm_api/dashboardData',
    marketingChecklist:'crm_api/get/checklist',
    customerRating:'crm_api/get/ratings',
    nudgesList:'crm_api/list/nudges',
    // Add more endpoints as needed
};