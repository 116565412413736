<nav class="navbar navbar-expand-lg navbar-dark bg-white" style="height: 6em;">
  <div class="row w-100">
    <div class="col-6 mt-1">
      <a routerLink="/">
        <img alt="" src="../../../../../assets/media/logos/new_uengage.png" style="width: 14em;" />
      </a>
    </div>
    <div class="col-6 pr-0" style="text-align: right !important;">
      <div ngbDropdown class="d-inline-block dropdown mt-3 ml-2" style="cursor: pointer;">
        <div class="btn btn-icon btn-hover-transparent-white align-items-center btn-lg px-md-2 w-md-auto mobWidth"
          id="kt_quick_user_toggle" id="dropdownBasic1" ngbDropdownToggle>
          <span class="text-dark opacity-70 font-weight-bold font-size-base  d-md-inline mr-1">Hi,</span>
          <span class="text-dark opacity-90 font-weight-bolder font-size-base  d-md-inline mr-4">User</span><br>
          <span>
            <img src="../../../../assets/media/misc/Arrow.png" style="width: 1em;">
          </span>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
            background-color: #fff;
            box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
            width: 26em;
          ">
          <div class="m-dropdown__inner">
            <div class="m-dropdown__header m--align-center" #mProfileDropdown style="margin-bottom:10px">
              <div class="m-card-user m-card-user--skin-dark">
                <div class="m-card-user__details text-center">
                  <span class="m-card-user__name m--font-weight-500 ml-2"
                    style="color: black; font-size: 18px;word-break: break-word;">
                    {{ userName }}
                  </span>

                </div>
              </div>
            </div>
            <div class="m-dropdown__body mt-2">
              <div class="m-dropdown__content">
                <div class="row w-100 m-0">
                  <div class="col-12">
                    <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 text-center w-100">
                      <i class="fas fa-power-off"></i>&nbsp;Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="col-xl-5 col-md-5 col-12 portal" align="center"
  style="margin-top: 2em; margin-left: auto; margin-right: auto;">
  <kt-portlet>
    <kt-portlet-header>
      <ng-container ktPortletTitle>
        <div class="m-portlet__head-title row mt-3 w-100">
          <div class="col-8 text-left mt-1">
            <h3 class="m-portlet__head-text">Business Selector</h3>
          </div>
          <div class="col-4 text-right">
            <button (click)="refresh()" class="btn btn-icon" style="cursor: pointer;background-color: #E8FFC4;">
              <span class="svg-icon">
                <i class="fas fa-sync-alt" style="color: #8CC42A;"></i>
              </span>
            </button>
          </div>
        </div>
      </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
      <ng-container mPortletBody>
        <div class="row w-100 m-0">
          <div class="form-group w-100 mb-0">
            <div class="input-icon">
              <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchText"
                style="border-radius: 26px;background-color: #f5f5f5;border: 1px solid #f5f5f5;height: 45px;" />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </div>
        </div>
        <div *ngFor="let details of businessAvailable | localityCityFilter: searchText"
          class="m-widget3__header row p-3 w-100 m-0 mt-5" style="position: relative;"
          (click)="selectBusinessId(details.businessId,details.currency)"
          [ngClass]="{'corporate':details.parentId==0,'child':details.parentId!=0 }">
          <div class="m-widget3__user-img col-lg-2 col-sm-3 col-3 text-left" *ngIf="details.logo!=null" style="place-items: center;
          display: flex;">
            <img class="m-widget3__img" style="width: 5rem ;border-radius: 10px;" src="https://api.uengage.in/images/addo/logos/{{
                    details.logo
                  }}" alt="" />
          </div>
          <div class="m-widget3__user-img col-lg-2 col-sm-3 col-3 text-left" *ngIf="details.logo==null" style="place-items: center;
          display: flex;">
            <div class="squares">
              <div class="icon1" style="border-radius: 10px;">
                <p style="font-size: 3em;color: #a3a3a3;">{{getChar(details.name) | uppercase}}</p>
              </div>
            </div>
          </div>
          <div class="m-widget3__info info text-left col-lg-6 col-sm-5 col-5 pl-0 mt-2">
            <p class="m-widget3__username font-weight-bolder mb-0">
              {{ details.name }}
            </p>
            <p class="m-widget3__time mb-0" style="font-size: 0.9em;">
              {{ details.locality }}, {{ details.city }}
            </p>
            <small *ngIf="details.parentId==0" style="color: burlywood;">For managing admin level
              functionalities</small>
            <!-- <span *ngIf="details.parentId==0"
              class="label label-lg label-light-warning label-inline font-weight-bold py-4"
              style="white-space: nowrap;">Corporate Business</span>
            <span *ngIf="details.parentId!=0"
              class="label label-lg label-light-success label-inline font-weight-bold py-4"
              style="white-space: nowrap;">Child Business</span> -->
          </div>
          <div class="m-widget3__status m--font-info col-lg-4 col-sm-4 col-4 text-right" style="margin-top: 2.4em;">
            <button class="btn btn-sm" type="button" *ngIf="details.businessId!=selectedId"
              style="background-color: #8CC42A;color: white;">
              Select&nbsp;<i class="flaticon2-right-arrow" style="color: white;font-size: 0.6em;"></i>
            </button>
            <button type="button" class="btn btn-sm spinner spinner-white spinner-right"
              *ngIf="details.businessId==selectedId" style="background-color: #8CC42A;color: white;">
              Select
            </button>
          </div>
          <div class="top-right-div p-1">
            <span *ngIf="details.parentId==0" style="white-space: nowrap;font-size: 0.8em;color: #FEB019;">Corporate
              Business</span>
            <span *ngIf="details.parentId!=0" style="white-space: nowrap;font-size: 0.8em;color: #32A7A2;">Child
              Business</span>
          </div>
        </div>
      </ng-container>
    </kt-portlet-body>
  </kt-portlet>
</div>
<!-- <kt-footer></kt-footer> -->
<div class="footer bg-white p-4 d-flex flex-lg-column" style="margin-top: 55px;" id="kt_footer"
  [ngClass]="footerClasses">
  <div [ngClass]="footerContainerClasses"
    class="d-flex flex-column flex-md-row align-items-center justify-content-between mobFooter container-fluid">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span style="color: #717579;font-size: 0.9em;">uEngage services Pvt Ltd</span><br>
      <span style="color: #717579;font-size: 0.8em;">Made with ❤️ by uEngage</span>
    </div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
      <a href="https://api.uengage.in/" target="_blank" class="nav-link pr-3 pl-0">Privacy</a>

      <a href="https://api.uengage.in/contact-uengage" target="_blank" class="nav-link pl-3 pr-0">Contact</a>
    </div>
  </div>
</div>