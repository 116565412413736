import { Injectable } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Inject } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SetStorageServiceService {
  private _localItem;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {}

  set localItem(selectedModules) {
    this._localItem = selectedModules;
    this.storage.set("selectedModule", selectedModules);
  }

  get localItem() {
    return (this._localItem = this.storage.get("selectedModule"));
  }

  set notificationPermission(status) {
    this.storage.set("notificationPermission", status);
  }

  get notificationPermission() {
    return this.storage.get("notificationPermission");
  }
}
