<!-- <div
  class="d-flex flex-column flex-root"
  id="kt_login_wrapper"
  (window:resize)="onResize($event)"
>
  <div
    class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
    id="kt_login"
  > -->
<!--begin::Aside https://app.uengage.io/assets/images/wave.png-->
<!-- <div
      class="login-aside d-flex flex-column flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
      style="background-image: url('../../../../assets/media/misc/wave.png');"
      *ngIf="showImage"
    > -->
<!-- <div class="d-flex flex-row-fluid flex-column justify-content-between">
        <div
          class="flex-column-fluid d-flex flex-column justify-content-center"
        >
          <img
            src="https://static.uengage.in/uploads/1/bg-01.svg"
            style="height: 35em;"
          />
        </div>

        <div
          class="d-none flex-column-auto d-lg-flex justify-content-between mt-10"
        >
          <div class="opacity-70 font-weight-bold text-white">
            &copy; 2016 Uengage Services Private Ltd.
          </div>
          <div class="d-flex">
            <a href="https://api.uengage.in/" class="text-white">Privacy</a>
            <a
              href="https://api.uengage.in/contact-uengage"
              class="text-white ml-10"
              >Contact</a
            >
          </div>
        </div>
      </div> -->

<!-- <ngb-carousel style="width: 100%; height: 100%;">
        <ng-template ngbSlide>
          <img
            src="../../../../assets/media/misc/dcf-01.png"
            alt="Random first slide"
          /> -->
<!-- <div class="carousel-caption">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div> -->
<!-- </ng-template>
        <ng-template ngbSlide>
          <img
            src="../../../../assets/media/misc/bg_3.svg"
            alt="Random second slide"
          /> -->
<!-- <div class="carousel-caption">
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div> -->
<!-- </ng-template>
        <ng-template ngbSlide>
          <img
            src="../../../../assets/media/misc/bg_2.svg"
            alt="Random third slide"
          /> -->
<!-- <div class="carousel-caption">
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </div> -->
<!-- </ng-template>
      </ngb-carousel>

      <div
        class="d-none flex-column-auto d-lg-flex justify-content-between mt-10"
      >
        <div class="opacity-70 font-weight-bold text-white">
          &copy; 2016 Uengage Services Private Ltd.
        </div>
        <div class="d-flex"> -->
<!-- <a href="https://api.uengage.in/" class="text-white">Privacy</a> -->
<!-- <a
            href="https://api.uengage.in/contact-uengage"
            class="text-white ml-10"
            >Contact</a
          >
        </div>
      </div>
    </div>

    <div
      class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
    > -->
<!--begin::Content header-->
<!-- <div
        class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
      >
        <span class="font-weight-bold text-dark-50">
          Don't have an account yet?
        </span>
        <a
          href="https://api.uengage.in/contact-uengage"
          class="font-weight-bold ml-2"
          id="kt_login_signup"
          >{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a
        >
      </div> -->
<!--end::Content header-->

<router-outlet></router-outlet>
<!--begin::Content footer for mobile-->

<!--end::Content footer for mobile-->
<!-- </div> -->
<!--end::Content-->
<!-- </div>
</div> -->