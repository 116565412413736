import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpService {
  ipbaseUrl = environment.ipUrl
  private ipSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) { }

  getIpAddress(): Observable<string> {
    return this.http.get<{ ip: string }>(this.ipbaseUrl).pipe(
      map(response => {
        // this.ipSubject.next(response.ip);
        return response.ip;
      }),
      catchError(error => {
        console.error(error);
        return throwError('Error');
      })
    );
  }

  get ip$(): Observable<string> {
    return this.ipSubject.asObservable();
  }
}
