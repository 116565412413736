import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import Swal from 'sweetalert2';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
// import { ThrowStmt } from '@angular/compiler';
// import KTImageInput from '../../../../../assets/js/components/image-input.js'
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  @ViewChild("wizard", { static: true }) el: ElementRef;
  companyData: any;
  docDetails: any;
  userId: any;
  user: SocialUser;
  selectedProduct: any;
  productSelected: number;
  selectedIndustry: any = '';
  industrySelected: number;
  bDetails: number;
  showtextArea: number;
  industryType: string = '';
  showEmailLogin: number;
  otpValidate: number;
  otpCorrect: number;
  productList: any[];
  cityData: any;
  showNext: number = 0;
  industryData: any[];
  industryName: any;
  password: any;
  flashSignup: number = 0;
  edgeSignup: number;
  prismSignup: number;
  salesId = '';
  industryId: any;
  profileShow: any;
  userPassword: any;
  firstName: any;
  lastName: any;
  constructor(private fb: FormBuilder, @Inject(LOCAL_STORAGE) private storage: WebStorageService, public httpClient: HttpClient,
    private cd: ChangeDetectorRef, private router: Router, private authService: SocialAuthService, private toastr: ToastrService, private route: ActivatedRoute) { }
  wizard: any;
  emailId = ''
  submitted = false;
  accountForm: FormGroup
  companyDetailForm: FormGroup
  otpForm: FormGroup
  detailForm: FormGroup
  docForm: FormGroup
  submitted1 = false
  submitted2 = false
  submitted3 = false
  bName = ''
  city = ''
  state = ''
  wizardStep = 1
  bNumber = ''
  merchantNumber = ''
  ngOnInit(): void {
    //   var ReverseMd5 = require('reverse-md5')
    //   var rev = ReverseMd5({
    //     lettersUpper: false,
    //     lettersLower: true,
    //     numbers: true,
    //     special: false,
    //     whitespace: true,
    //     maxLen: 12
    // })

    // rev('0ff1bdf681c2ab7f9b99beaec2b7bd11')
    this.authService.authState.subscribe(user => {
      this.user = user;
      // (this.user)
    });
    this.route.queryParams.subscribe((params) => {
      this.salesId = params["sales_ref"];
    });
    if (this.router.url.includes("flash")) {
      this.flashSignup = 1
    } else if (this.router.url.includes("edge")) {
      this.edgeSignup = 1
    } else if (this.router.url.includes("prism")) {
      this.prismSignup = 1
    }
    this.createAccForm()
    this.createOtpForm()
    this.createCompanyForm()
    this.getProductList()
    this.getCity()
    // this.createDetailForm()
    // this.getCompanyTypes()
  }
  signInWithGoogle() {
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.idToken;
          this.httpClient.get("https://api.uengage.in/onboarding/googleLogin?" + params).subscribe((data: any) => {
            if (data.status == 1) {
              let params =
                "email=" +
                userData.email +
                "&accessToken=" + userData.idToken;
              this.httpClient.get("https://api.uengage.in/ueapi/login_google?" + params, {}).subscribe((res: any) => {
                if (typeof res !== "undefined") {
                  if (res.status == 1) {
                    this.storage.set("AssignedBusiness", res.msg);
                    this.storage.set("userId", res.msg[0].userId);
                    this.storage.set("userName", res.msg[0].userName);
                    this.storage.set("password", res.msg[0].misc);
                    let data1 = res.onboarding.bDetails
                    if (data1 != undefined) {
                      if (data1.onboard_productId == 2) {
                        this.router.navigate(["businessSelector"]);
                      } else if (data1.onboard_productId == 3) {
                        if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                          let data = res.onboarding.bDetails
                          this.storage.set("onboardBusiness", data)
                          this.storage.set("productId", data.onboard_productId);
                          this.storage.set("businessName", data.name);
                          this.router.navigate(["complete-flash-profile"]);
                        } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                          let data = res.onboarding.bDetails
                          this.storage.set("onboardBusiness", data)
                          this.router.navigate(["approval-pending"]);
                        } else {
                          this.router.navigate(["businessSelector"]);
                        }
                      } else {
                        if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                          let data = res.onboarding.bDetails
                          this.storage.set("onboardBusiness", data)
                          this.storage.set("productId", data.onboard_productId);
                          this.storage.set("businessName", data.name);
                          this.router.navigate(["complete-edge-profile"]);
                        } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                          let data = res.onboarding.bDetails
                          this.storage.set("onboardBusiness", data)
                          this.router.navigate(["approval-pending"]);
                        } else {
                          this.router.navigate(["businessSelector"]);
                        }
                      }
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  } else {
                  }
                } else {
                }
              });
            }
            else {
              this.toastr.error(data.msg, 'Error', {
                positionClass: 'toast-bottom-right'
              })
            }
          })
        }
        //  (userData);
      })
  }
  signInWithFB(): void {
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        // (userData)
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.authToken;
          this.httpClient.get("https://api.uengage.in/onboarding/facebookLogin?" + params).subscribe((data: any) => {
            let userId = data.userId
            if (data.status == 1) {
              let params =
                "userId=" +
                userId +
                "&email_id=" +
                userData.email +
                "&firstName=" + data.firstName +
                "&lastName=" + data.lastName +
                "&password=" + data.accPassword +
                "&bname=" + encodeURIComponent(this.bName) +
                "&city=" + this.city +
                "&state=" + this.state +
                "&rest_contact_no=" + this.bNumber +
                "&personal_contact_no=" + this.merchantNumber +
                "&product_id=" + this.selectedProduct +
                "&category_id=" + this.selectedIndustry +
                "&login_via=2";
              this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
                if (data.status == 1) {
                  Swal.fire({
                    icon: "success",
                    title: "Account Setup Completed!",
                    showConfirmButton: false,
                    timer: 2000
                  });
                  let params =
                    "email=" +
                    userData.email +
                    "&accessToken=" + userData.authToken;
                  this.httpClient.get("https://api.uengage.in/ueapi/login_fb?" + params, {}).subscribe((res: any) => {
                    if (typeof res !== "undefined") {
                      if (res.status == 1) {
                        this.storage.set("AssignedBusiness", res.msg);
                        this.storage.set("userId", res.msg[0].userId);
                        this.storage.set("userName", res.msg[0].userName);
                        this.storage.set("password", res.msg[0].misc);
                        let data1 = res.onboarding.bDetails
                        if (data1 != undefined) {
                          if (data1.onboard_productId == 2) {
                            this.router.navigate(["businessSelector"]);
                          } else if (data1.onboard_productId == 3) {
                            if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                              let data = res.onboarding.bDetails
                              this.storage.set("onboardBusiness", data)
                              this.storage.set("productId", data.onboard_productId);
                              this.storage.set("businessName", data.name);
                              this.router.navigate(["complete-flash-profile"]);
                            } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                              let data = res.onboarding.bDetails
                              this.storage.set("onboardBusiness", data)
                              this.router.navigate(["approval-pending"]);
                            } else {
                              this.router.navigate(["businessSelector"]);
                            }
                          } else {
                            if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                              let data = res.onboarding.bDetails
                              this.storage.set("onboardBusiness", data)
                              this.storage.set("productId", data.onboard_productId);
                              this.storage.set("businessName", data.name);
                              this.router.navigate(["complete-edge-profile"]);
                            } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                              let data = res.onboarding.bDetails
                              this.storage.set("onboardBusiness", data)
                              this.router.navigate(["approval-pending"]);
                            } else {
                              this.router.navigate(["businessSelector"]);
                            }
                          }
                        } else {
                          this.router.navigate(["businessSelector"]);
                        }
                      } else {

                      }
                    } else {
                    }
                  });
                }
                if (data.status == 0) {
                  Swal.fire('', data.msg, 'error')
                }
              })
            }
            else {
              Swal.fire('', data.msg, 'error')
            }
          })
        }
        // this.sendToRestApiMethod(userData.token);
      }
    );
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  ngAfterViewInit(): void {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });

    // Validation before going to next page
    this.wizard.on('beforeNext', (wizardObj) => {
      // (wizardObj.currentStep)
      if (wizardObj.currentStep == 1) {
        if (this.productSelected != 1) {
          //  ("inside")
          this.toastr.error('Please select your product', 'Error', {
            positionClass: 'toast-bottom-right'
          })
          wizardObj.stop()
        }
      } else if (wizardObj.currentStep == 2) {
        if (this.industrySelected != 1) {
          this.toastr.error('Please select your industry type', 'Error', {
            positionClass: 'toast-bottom-right'
          })
          wizardObj.stop()
        } else if (this.industrySelected == 1 && this.selectedIndustry == 8) {
          if (this.industryType == '') {
            this.toastr.error('Please provide your industry type', 'Error', {
              positionClass: 'toast-bottom-right'
            })
            wizardObj.stop()
          }
        }
      } else if (wizardObj.currentStep == 3) {
        if (this.bName == '' || this.city == '' || this.state == '' || this.bNumber == '' || this.merchantNumber == '') {
          this.bDetails = 0
          this.toastr.error('Please provide all details', 'Error', {
            positionClass: 'toast-bottom-right'
          })
          wizardObj.stop()
        } else {
          this.bDetails = 1
        }
        this.cd.detectChanges()
        // (this.bDetails)
      }
      // (wizardObj)
      // https://angular.io/guide/forms
      // https://angular.io/guide/form-validation

      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    this.wizard.on('change', () => {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
  reqAgain() {
    let params =
      "&email_id=" +
      this.emailId;
    this.httpClient.get("https://api.uengage.in/onboarding/createUser?" + params).subscribe((data: any) => {
      if (data.status == 1) {
        this.userId = data.userId
        // this.submitted = false;
        this.otpValidate = 1
        // this.wizardStep = 2
        // this.wizard.goNext()
      }
      else {
        Swal.fire('', data.msg, 'error')
      }
    })
  }
  check() {
    this.submitted = true
    if (this.accountForm.invalid) {
      // this.wizard.stop();
    }
    else {
      let data = this.accountForm.value
      this.emailId = data.emailId
      let params =
        "email_id=" +
        data.emailId;
      this.httpClient.get("https://api.uengage.in/onboarding/createUser?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.userId = data.userId
          this.submitted = false;
          this.otpValidate = 1
          this.cd.detectChanges()
          // this.wizardStep = 2
          // this.wizard.goNext()
        }
        else {
          Swal.fire('', data.msg, 'error')
        }
      })

      // this.accountForm.reset();
    }
  }
  getProductList() {
    this.productList = []
    this.httpClient.get("https://api.uengage.in/onboarding/getProductList?").subscribe((data: any) => {
      if (data.status == 1) {
        this.productList = data.product_list
        if (this.flashSignup == 1) {
          this.productSelected = 1
          this.selectedProduct = 3
          this.selectProduct(3)
          this.wizard.goNext()
        } else if (this.edgeSignup == 1) {
          this.productSelected = 1
          this.selectedProduct = 1
          this.selectProduct(1)
          this.wizard.goNext()
        } else if (this.prismSignup == 1) {
          this.productSelected = 1
          this.selectedProduct = 2
          this.selectProduct(2)
          this.wizard.goNext()
        }
        this.cd.detectChanges()
      }
      if (data.status == 0) {
        this.productList = []
      }
    })
  }
  checkotp() {
    this.submitted1 = true
    if (this.otpForm.invalid) {
      // this.wizard.stop();
    }
    else {
      let data = this.otpForm.value
      let params =
        "userId=" +
        this.userId +
        "&email_id=" +
        this.emailId +
        "&OTP=" + data.otp;
      this.httpClient.get("https://api.uengage.in/onboarding/validateOTP?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          Swal.fire('', 'OTP verified successfully!', 'success')
          this.submitted1 = false;
          this.profileShow = data.showProfileScreen
          if (data.password != undefined && data.password != null) {
            this.userPassword = data.password
            this.firstName = data.firstName
            this.lastName = data.lastName
          }
          this.checkBDetails()
          // this.otpCorrect = 1
          this.cd.detectChanges()
          // this.router.navigateByUrl("/login");
        }
        if (data.status == 0) {
          Swal.fire('', data.msg, 'error')
        }
      })

      // this.accountForm.reset();
    }
  }
  checkBDetails() {
    let params =
      "userId=" +
      this.userId +
      "&bname=" +
      encodeURIComponent(this.bName) +
      "&city=" + this.city +
      "&state=" + this.state;
    this.httpClient.get("https://api.uengage.in/onboarding/validateBusinessMapping?" + params).subscribe((data: any) => {
      if (data.status == 1) {
        if (this.profileShow == 1) {
          this.otpCorrect = 1
        } else {
          this.otpCorrect = 0
          this.goToLogin()
        }
        this.cd.detectChanges()
      }
      if (data.status == 0) {
        this.otpCorrect = 0
        Swal.fire({
          text: data.msg,
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          footer: `<a class="btn btn-primary" href="https://app.uengage.in/" style="background-color: #568b17;colour:white;">LOGIN</a>`
        });
        this.cd.detectChanges()
      }
    })
  }
  goToLogin() {
    let params =
      "userId=" +
      this.userId +
      "&email_id=" +
      this.emailId +
      "&firstName=" + this.firstName +
      "&lastName=" + this.lastName +
      "&password=" + this.userPassword +
      "&bname=" + encodeURIComponent(this.bName) +
      "&city=" + this.city +
      "&state=" + this.state +
      "&rest_contact_no=" + this.bNumber +
      "&personal_contact_no=" + this.merchantNumber +
      "&product_id=" + this.selectedProduct +
      "&category_id=" + this.selectedIndustry +
      "&login_via=1";
    if (this.salesId != '' && this.salesId != undefined) {
      params += "&sales_ref=" + this.salesId;
    }
    this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
      if (data.status == 1) {
        Swal.fire({
          icon: "success",
          title: "Account Setup Completed!",
          showConfirmButton: false,
          timer: 2000
        });
        let params =
          "userName=" +
          this.emailId +
          "&password=" +
          this.userPassword;
        this.httpClient.get("https://api.uengage.in/ueapi/login/addo/?" + params, {}).subscribe((res: any) => {
          if (typeof res !== "undefined") {
            if (res.status == 1) {
              this.storage.set("AssignedBusiness", res.msg);
              this.storage.set("userId", res.msg[0].userId);
              this.storage.set("userName", this.emailId);
              this.storage.set("password", this.userPassword);
              let data1 = res.onboarding.bDetails
              // this.authNoticeService.setNotice(res.displayMessage, "success");
              // (res.onboarding)
              //this.router.navigate(["businessSelector"]);
              if (data1 != undefined) {
                if (data1.onboard_productId == 2) {
                  this.router.navigate(["businessSelector"]);
                } else if (data1.onboard_productId == 3) {
                  if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                    let data = res.onboarding.bDetails
                    this.storage.set("onboardBusiness", data)
                    this.storage.set("productId", data.onboard_productId);
                    this.storage.set("businessName", data.name);
                    this.router.navigate(["complete-flash-profile"]);
                  } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                    let data = res.onboarding.bDetails
                    this.storage.set("onboardBusiness", data)
                    this.router.navigate(["approval-pending"]);
                  } else {
                    this.router.navigate(["businessSelector"]);
                  }
                } else {
                  if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                    let data = res.onboarding.bDetails
                    this.storage.set("onboardBusiness", data)
                    this.storage.set("productId", data.onboard_productId);
                    this.storage.set("businessName", data.name);
                    this.router.navigate(["complete-edge-profile"]);
                  } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                    let data = res.onboarding.bDetails
                    this.storage.set("onboardBusiness", data)
                    this.router.navigate(["approval-pending"]);
                  } else {
                    this.router.navigate(["businessSelector"]);
                  }
                }
              } else {
                // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                //   let data = res.onboarding.bDetails
                //   this.storage.set("productId", data.onboard_productId);
                //   this.storage.set("businessName", data.name);
                //   this.router.navigate(["complete-profile"]);
                // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                //   this.router.navigate(["approval-pending"]);
                // } else {
                this.router.navigate(["businessSelector"]);
                // }
              }
            } else {
              // this.authNoticeService.setNotice(res.displayMessage, "error");
            }
          } else {
            // this.authNoticeService.setNotice(
            //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
            //   "error"
            // );
          }
          // this.loading = false;
          // this.cdr.detectChanges();
        },
          (error) => {
            // this.authNoticeService.setNotice(
            //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
            //   "error"
            // );
          }
        );
      }
      if (data.status == 0) {
        Swal.fire('', data.msg, 'error')
      }
    })
  }
  saveDetails() {
    this.submitted2 = true
    if (this.detailForm.invalid) {
      this.wizard.stop();
    }
    else {
      let data = this.detailForm.value
      let index = this.companyData.findIndex(e => e.company_typ_id == data.companyType);
      this.docDetails = this.companyData[index].document_list
      //(this.docDetails)
      this.cd.detectChanges()
      this.submitted2 = false;
      this.wizardStep = 4
      this.wizard.goNext()
      // this.accountForm.reset();
    }
  }
  getCompanyTypes() {
    this.httpClient.get("https://api.uengage.in/onboarding/getCompanytypes").subscribe((data: any) => {
      if (data.status == 1) {
        this.companyData = data.list
        this.cd.detectChanges()
      }
      else {
        Swal.fire('', data.msg, 'error')
        return false;
      }
    });
  }
  getCity() {
    this.cityData = []
    this.httpClient.get("https://api.uengage.in/onboarding/getCitiesList").subscribe((data: any) => {
      if (data.status == 1) {
        this.cityData = data.cities
        this.cd.detectChanges()
      }
      else {
        Swal.fire('', data.msg, 'error')
        return false;
      }
    });
  }
  getState() {
    // (this.city)
    let index = -1
    // this.cityData.forEach(element=>{
    //    index = element.cityName.indexOf(this.city)
    // })
    for (let i = 0; i < this.cityData.length; i++) {
      if (this.cityData[i].cityName == this.city) {
        index = i
        break;
      }
    }
    // (index)
    this.state = this.cityData[index].stateName
    this.cd.detectChanges()
  }
  selectProduct(flag) {
    this.selectedProduct = flag
    this.industryData = []
    this.httpClient.get("https://api.uengage.in/onboarding/getIndustries/1").subscribe((data: any) => {
      if (data.status == 1) {
        this.industryData = data.industry_list
        this.cd.detectChanges()
      }
      else {
        Swal.fire('', data.msg, 'error')
        return false;
      }
    });
    // this.wizardStep = 1
    this.productSelected = 1
    if (this.flashSignup != 1 && this.edgeSignup != 1 && this.prismSignup != 1) {
      this.wizard.goNext()
    }
  }
  selectIndustry(flag, name, id) {
    this.industryId = id
    this.industryName = name
    this.selectedIndustry = flag
    // this.wizardStep = 2
    this.industrySelected = 1
    this.showNext = 1
    this.showtextArea = 0
    this.industryType = ''
    this.wizard.goNext()
    // if (flag == 8) {
    //   this.showtextArea = 1
    // } else {

    // }
  }
  trimValue() {
    let data = this.accountForm.value
    this.accountForm.controls.emailId.setValue(data.emailId.trim())
  }
  createAccForm() {
    this.accountForm = this.fb.group({
      // firstName: ['', [Validators.required]],
      // lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$")]],
      // password: ['', [Validators.required]],
      // mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    })
  }
  get a() { return this.accountForm.controls; }

  createCompanyForm() {
    this.companyDetailForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      accPassword: ['', [Validators.required]]
    })
  }
  get c() { return this.companyDetailForm.controls; }

  checkStep() {
    // (this.wizardStep)
  }
  createDetailForm() {
    this.detailForm = this.fb.group({
      businessName: ['', [Validators.required]],
      companyType: ['', [Validators.required]],
      legalEntityName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    })
  }
  get d() { return this.detailForm.controls; }

  createOtpForm() {
    this.otpForm = this.fb.group({
      otp: ['', Validators.required]
    })
  }
  get o() { return this.otpForm.controls; }

  previous() {
    this.wizardStep = this.wizardStep - 1
    this.wizard.goPrev()
  }
  loginPage() {
    // let path = "";
    this.router.navigateByUrl("/login");
  }
  emailLogin() {
    this.showEmailLogin = 1
  }
  sendOtp() {
    this.otpValidate = 1
  }
  anotherMethod() {
    this.showEmailLogin = 0
    this.otpValidate = 0
    this.otpCorrect = 0
    this.companyDetailForm.reset()
    this.otpForm.reset()
    this.accountForm.reset()
  }
  createAccount() {
    this.submitted3 = true
    if (this.companyDetailForm.valid) {
      let data = this.companyDetailForm.value
      this.password = data.accPassword
      let params =
        "userId=" +
        this.userId +
        "&email_id=" +
        this.emailId +
        "&firstName=" + data.firstName +
        "&lastName=" + data.lastName +
        "&password=" + data.accPassword +
        "&bname=" + encodeURIComponent(this.bName) +
        "&city=" + this.city +
        "&state=" + this.state +
        "&rest_contact_no=" + this.bNumber +
        "&personal_contact_no=" + this.merchantNumber +
        "&product_id=" + this.selectedProduct +
        "&category_id=" + this.selectedIndustry +
        "&login_via=1";
      if (this.salesId != '' && this.salesId != undefined) {
        params += "&sales_ref=" + this.salesId;
      }
      this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Account Setup Completed!",
            showConfirmButton: false,
            timer: 2000
          });
          let params =
            "userName=" +
            this.emailId +
            "&password=" +
            this.password;
          this.httpClient.get("https://api.uengage.in/ueapi/login/addo/?" + params, {}).subscribe((res: any) => {
            if (typeof res !== "undefined") {
              if (res.status == 1) {
                this.storage.set("AssignedBusiness", res.msg);
                this.storage.set("userId", res.msg[0].userId);
                this.storage.set("userName", this.emailId);
                this.storage.set("password", this.password);
                let data1 = res.onboarding.bDetails
                // this.authNoticeService.setNotice(res.displayMessage, "success");
                // (res.onboarding)
                //this.router.navigate(["businessSelector"]);
                if (data1 != undefined) {
                  if (data1.onboard_productId == 2) {
                    this.router.navigate(["businessSelector"]);
                  } else if (data1.onboard_productId == 3) {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-flash-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  } else {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-edge-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  }
                } else {
                  // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                  //   let data = res.onboarding.bDetails
                  //   this.storage.set("productId", data.onboard_productId);
                  //   this.storage.set("businessName", data.name);
                  //   this.router.navigate(["complete-profile"]);
                  // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                  //   this.router.navigate(["approval-pending"]);
                  // } else {
                  this.router.navigate(["businessSelector"]);
                  // }
                }
              } else {
                // this.authNoticeService.setNotice(res.displayMessage, "error");
              }
            } else {
              // this.authNoticeService.setNotice(
              //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
              //   "error"
              // );
            }
            // this.loading = false;
            // this.cdr.detectChanges();
          },
            (error) => {
              // this.authNoticeService.setNotice(
              //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
              //   "error"
              // );
            }
          );
        }
        if (data.status == 0) {
          Swal.fire('', data.msg, 'error')
        }
      })
    }
  }
  // goToLogin(){
  //   this.password = data.accPassword
  //   let params =
  //     "userId=" +
  //     this.userId +
  //     "&email_id=" +
  //     this.emailId +
  //     "&firstName=" + data.firstName +
  //     "&lastName=" + data.lastName +
  //     "&password=" + data.accPassword +
  //     "&bname=" + this.bName +
  //     "&city=" + this.city +
  //     "&state=" + this.state +
  //     "&rest_contact_no=" + this.bNumber +
  //     "&personal_contact_no=" + this.merchantNumber +
  //     "&product_id=" + this.selectedProduct +
  //     "&category_id=" + this.selectedIndustry +
  //     "&login_via=1";
  //   if (this.salesId != '') {
  //     params += "&sales_ref=" + this.salesId;
  //   }
  //   this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
  //     if (data.status == 1) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Account Setup Completed!",
  //         showConfirmButton: false,
  //         timer: 2000
  //       });
  //       let params =
  //         "userName=" +
  //         this.emailId +
  //         "&password=" +
  //         this.password;
  //       this.httpClient.get("https://api.uengage.in/ueapi/login/addo/?" + params, {}).subscribe((res: any) => {
  //         if (typeof res !== "undefined") {
  //           if (res.status == 1) {
  //             this.storage.set("AssignedBusiness", res.msg);
  //             this.storage.set("userId", res.msg[0].userId);
  //             this.storage.set("userName", this.emailId);
  //             this.storage.set("password", this.password);
  //             let data1 = res.onboarding.bDetails
  //             // this.authNoticeService.setNotice(res.displayMessage, "success");
  //             // (res.onboarding)
  //             //this.router.navigate(["businessSelector"]);
  //             if (data1 != undefined) {
  //               if (data1.onboard_productId == 2) {
  //                 this.router.navigate(["businessSelector"]);
  //               } else if (data1.onboard_productId == 3) {
  //                 if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
  //                   let data = res.onboarding.bDetails
  //                   this.storage.set("productId", data.onboard_productId);
  //                   this.storage.set("businessName", data.name);
  //                   this.router.navigate(["complete-flash-profile"]);
  //                 } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
  //                   this.router.navigate(["approval-pending"]);
  //                 } else {
  //                   this.router.navigate(["businessSelector"]);
  //                 }
  //               } else {
  //                 if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
  //                   let data = res.onboarding.bDetails
  //                   this.storage.set("productId", data.onboard_productId);
  //                   this.storage.set("businessName", data.name);
  //                   this.router.navigate(["complete-edge-profile"]);
  //                 } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
  //                   this.router.navigate(["approval-pending"]);
  //                 } else {
  //                   this.router.navigate(["businessSelector"]);
  //                 }
  //               }
  //             } else {
  //               // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
  //               //   let data = res.onboarding.bDetails
  //               //   this.storage.set("productId", data.onboard_productId);
  //               //   this.storage.set("businessName", data.name);
  //               //   this.router.navigate(["complete-profile"]);
  //               // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
  //               //   this.router.navigate(["approval-pending"]);
  //               // } else {
  //               this.router.navigate(["businessSelector"]);
  //               // }
  //             }
  //           } else {
  //             // this.authNoticeService.setNotice(res.displayMessage, "error");
  //           }
  //         } else {
  //           // this.authNoticeService.setNotice(
  //           //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
  //           //   "error"
  //           // );
  //         }
  //         // this.loading = false;
  //         // this.cdr.detectChanges();
  //       },
  //         (error) => {
  //           // this.authNoticeService.setNotice(
  //           //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
  //           //   "error"
  //           // );
  //         }
  //       );
  //     }
  //     if (data.status == 0) {
  //       Swal.fire('', data.msg, 'error')
  //     }
  //   })
  // }
}

