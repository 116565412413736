export class BusinessSelectorModel {
    moduleId: Number = 0;
    title: String = "";
    desc: String = "";
    icon: String = "";
    root: boolean = true;
    page: String = "";
    path: String = "/vendor";
    maxDiscount: Number = 0;
    userType: String = "";
    documentationId: String = "";
    products: any;
    subLinks = []
}