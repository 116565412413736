<div *ngIf="multiTabs!=1">
  <kt-splash-screen></kt-splash-screen>
  <audio id="playAudio" src="assets/audio/audio_new.mp3"></audio>
  <router-outlet></router-outlet>
</div>
<div *ngIf="isLoading| async">
  <kt-spinner></kt-spinner>
</div>
<div class="mydiv p-5" *ngIf="multiTabs==1">
  <div class="row w-100 m-0">
    <img src="../assets/media/logos/ue-logonew.jpg" style="width: 10em;">
  </div>
  <div class="row w-100 m-0 mt-5">
    <label style="font-weight:400;font-size: 1.2em;">uEngage Dashboard is open in another window. Use that window to
      start using the Dashboard.</label>
  </div>
  <!-- <div class="row w-100 m-0 mt-5 justify-content-end">
      <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    </div> -->
  <!-- <router-outlet></router-outlet> -->
</div>
<ng-template #warningModal let-modal>
  <div class="modal-body">
    <div class="row w-100 m-0 justify-content-center p-3">
      <img src="../../../../assets/media/logos/error.png" style="width: 8em">
    </div>
    <div class="row w-100 m-0 mt-3" style="text-align: center;">
      <span style="font-size: 1.2em;">Your subscription is over! Contact your account manager to renew your subscription
        to start using again.</span>
    </div>
    <div class="row w-100 m-0 mt-3" style="text-align: center;">
      <button class="btn btn-light-danger w-100" (click)="logout()">Logout</button>
    </div>
  </div>
</ng-template>
<button class="btn btn-info" type="button" (click)="openWarningLarge(warningModal)" style="display: none;"
  id="warningButton"></button>
<button class="btn btn-info" type="button" (click)="openWalletLarge(walletModal)" style="display: none;"
  id="walletButton"></button>
<ng-template #walletModal let-modal>
  <div class="modal-body">
    <div class="row w-100 m-0 justify-content-end">
      <i class="fas fa-times" style="cursor: pointer;" (click)="modal.close()"></i>
    </div>
    <div class="row w-100 m-0 justify-content-center p-3">
      <img src="../assets/media/misc/Total-COD-Orders.png" style="width: 8em">
    </div>
    <div class="row w-100 m-0 mt-3 justify-content-center">
      <span style="font-size: 1.5em;" class="font-weight-bold">Low Wallet Balance</span>
    </div>
    <div class="row w-100 m-0 mt-3 justify-content-center">
      <span style="font-size: 1.1em;">Recharge your wallet today to avoid inconvenience.</span>
    </div>
    <div class="row w-100 m-0 mt-3" style="text-align: center;">
      <button class="btn btn-light-danger w-100" (click)="recharge(addbalancemodal)"
        style="color: white;background-color: #8CC42A;">Recharge Wallet</button>
    </div>
  </div>
</ng-template>
<ng-template #addbalancemodal let-modal>
  <div class="modal-body" style="height: 30em;">
    <div class="row w-100 m-0">
      <div class="col-lg-6 col-9">
        <span style="font-size: 15px;font-weight:500;display: flex;">
          <span class="svg-icon svg-icon-info svg-icon-2x mr-2">
            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo2/dist/../src/media/svg/icons/Shopping/Wallet.svg--><svg
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
              viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="20.5" cy="12.5" r="1.5" />
                <rect fill="#000000" opacity="0.3"
                  transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) " x="3"
                  y="3" width="18" height="7" rx="1" />
                <path
                  d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z"
                  fill="#000000" />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>Add Wallet Money:</span>
      </div>
      <div class="col-lg-6 col-3" style="text-align: end;">
        <i class="fas fa-times" (click)="modal.close()" style="cursor: pointer;"></i>
      </div>
    </div>
    <div class="form">
      <div class="row m-0" style="margin-top: 2em !important;">
        <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text">₹</span></div>
          <input type="number" class="form-control" [(ngModel)]="Amount" (focusout)="calculateTax()"
            placeholder="Amount" min="1" [readonly]="!edit">
          <span class="form-text text-danger" *ngIf="parentBusinessId==0">Money added at parent account will not reflect
            on child accounts.</span>
          <!-- <span class="text-muted">Min. recharge amount is &#8377;1000</span> -->
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 btn-group mt-3" style="display: inline-flex;">
      <button type="button" class="btn btn-outline-light" (click)="updateMoney(1000)"
        [ngClass]="{'changeBg':value==1000}">+ {{1000 | customcurrency}}</button>
      <button type="button" class="btn btn-outline-light" (click)="updateMoney(2000)"
        [ngClass]="{'changeBg':value==2000}">+ {{2000| customcurrency}}</button>
      <button type="button" class="btn btn-outline-light" (click)="updateMoney(5000)"
        [ngClass]="{'changeBg':value==5000}">+ {{5000| customcurrency}}</button>
      <button type="button" class="btn btn-outline-light" (click)="updateMoney(10000)"
        [ngClass]="{'changeBg':value==10000}">+ {{10000| customcurrency}}</button>
      <button type="button" class="btn btn-outline-light" (click)="updateMoney(-1)"
        [ngClass]="{'changeBg':value==-1}">Other Amount</button>
    </div>
    <div class="cashsection mt-3" *ngIf="taxShow == 1">
      <div class="fl-row" style="display: none;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;">
        <div class="fl-cell fl-cell-lbl" style="padding: 8px 22px 8px 14px;">
          Sub Total:
        </div>
        <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
          {{subTotal | customcurrency}}
          <!-- <span style="cursor: pointer;" class="ml-1"><i class="fas fa-plus-circle text-info"
                          *ngIf="showBreakup!=1" (click)="showBreakdown()"></i>
                      <i class="fas fa-minus-circle text-info" *ngIf="showBreakup==1" (click)="hideBreakup()"></i>
                  </span> -->
        </div>
      </div>
      <div>
        <div class="fl-row" style="display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;">
          <div class="fl-cell fl-cell-lbl" style="padding: 8px 22px 8px 14px;">
            GST:
          </div>
          <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
            {{0 | customcurrency}}
          </div>
        </div>
        <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
          <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;">
            Payable:
          </div>
          <div class="fl-cell text-right ng-binding font-weight-bold" style="padding: 8px 22px 8px 14px;">
            {{subTotal | customcurrency}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-3">
    <div class="row w-100 m-0">
      <span class="label label-inline label-light-danger font-weight-bold py-4 w-100" style="color: green;
          background-color: #c1ffc1;">A Receipt will be available in Your Ledger after Successful Payment</span>
    </div>
    <button class="btn btn-success font-weight-bolder w-100 font-size-lg" (click)="UpdateData()" type="submit"
      [disabled]="disableBtn==1" [ngClass]="{'disableButton':disableBtn==1}">
      <span class="svg-icon svg-icon-white svg-icon-2x" style="display: inline-flex;">
        <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo2/dist/../src/media/svg/icons/General/Lock.svg--><svg
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
          viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask fill="white">
              <use xlink:href="#path-1" />
            </mask>
            <g />
            <path
              d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
              fill="#000000" />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
      <span style="vertical-align: super;font-size:1.3em">Pay Securely</span>
    </button>
    <!-- <button type="submit" class="btn btn-primary" (click)="UpdateData()">Recharge</button> -->
  </div>
</ng-template>