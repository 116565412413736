// Angular
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from "../../../core/_base/layout";
// Auth
import { AuthNoticeService } from "../../../core/auth";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";

@Component({
  selector: "kt-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  headerLogo: string;
  showImage = true;
  layoutBreakpoint = 995;
  images = [
    "https://static.uengage.in/uploads/1/bg_2.svg",
    "https://static.uengage.in/uploads/1/bg-01.svg",
  ];
  /**
   * Component constructor
   *
   * @param el
   * @param render
   * @param layoutConfigService: LayoutConfigService
   * @param authNoticeService: authNoticeService
   * @param translationService: TranslationService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute,public meta: Meta, public title1: Title,
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    public authNoticeService: AuthNoticeService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
   getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
  ngOnInit(): void {
    this.translationService.setLanguage(
      this.translationService.getSelectedLanguage()
    );
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();
    this.showImage = window.innerWidth >= this.layoutBreakpoint ? true : false;
//     this.router.events.pipe(
//       filter(event => event instanceof NavigationEnd),
//     )
//       .subscribe(() => {
//  //traverse through the ActivatedRoute tree to get to the current route.
//         var rt = this.getChild(this.activatedRoute)
//  //Once we have the ActivateRoute of the currently loaded component, we subscribe to the data property to read the Route Data.
//         rt.data.subscribe(data => {
//           // (data);
//           this.title1.setTitle(data.title)
//           if (data.ogTitle) {
//             this.meta.updateTag({ property: 'og:title', content: data.ogTitle })
//           } else {
//             this.meta.updateTag({ property: 'og:title', content: 'uEngage Dashboard' })
//           }
//           // if (data.title) {
//           //   this.meta.updateTag({ name: 'title', content: data.title })
//           // } else {
//           //   this.meta.removeTag("name='title'")
//           // }
//         })
 
//       })
  }

  onResize(event) {
    this.showImage =
      event.target.innerWidth >= this.layoutBreakpoint ? true : false;
  }

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement("link");
      styleElement.href = styleUrl;
      styleElement.type = "text/css";
      styleElement.rel = "stylesheet";
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }
}
