import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { Router } from '@angular/router';
import { ApiService } from "../../../src/app/api.service"
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private router: Router, private service: ApiService) { }
    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.service.leave != 1) {
            // ("no request created")
            if (confirm("Changes that you made may not be saved.")) {
                return true;
                // this.router.navigateByUrl('/Dashboard/request-inventory')
            } else {
                return false;
            }
        }
        else {
            return true;
        }
    }
}