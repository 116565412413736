// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  base_api_crm:'https://crm.uengage.in/',
  base_api:'https://api.uengage.in/',
  base_rider_api:'https://riderapi.uengage.in/',
  ipUrl:'https://api.ipify.org?format=json',
  authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
  base_download_aws:'https://s3.ap-south-1.amazonaws.com/',
  firebase: {
    apiKey: "AIzaSyA1KL0Hd2-pM3UzA_LMGei3-1g6Px0aC2w",
    authDomain: "uengage-156611.firebaseapp.com",
    databaseURL: "https://uengage-156611.firebaseio.com",
    projectId: "uengage-156611",
    storageBucket: "uengage-156611.appspot.com",
    messagingSenderId: "363296902958",
    appId: "1:363296902958:web:567a4a4bd273f6d94234bf"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
