<div class="row w-100 m-0 justify-content-center" style="background-color: #fff;">
    <div class="card-holder">
        <div class="card bg-purple">
           <div class="row w-100 m-0 justify-content-center">
               <span style="color: white;font-size:6em">WEL<span style="color: #568b17;">C<span style="color: #8CC428;">OME</span><span style="color: #568b17;">.</span></span></span>
           </div>
           <div class="row w-100 m-0">
               <div class="col-lg-7"></div>
               <div class="col-lg-5">
                   <img src="../../../../../assets/media/misc/Mascot.png">
               </div>
           </div>
        </div>
      </div>
</div>
