export class menuSection {
  sectionId: number = 0;
  sectionName: String = "";
}
export class itemDetails {
  id: number = 0;
  itemName: String = "";
  description: String = "";
  status: String = "";
  sp: number = 0;
  mrp: number = 0;
  actualCost: number = 0;
  variantDetails: Array<variantDetails> = [];
  unit: String = "";
  costPerUnit: String = "";
  type: number = 0;
  quantity: number = 0;
  tax: number = 0;
  parentId: number = 0;
  inventoryAmount = 0;
  exciseApplicable = 0;
  templateId = 0;
}
export class variantDetails {
  id: number = 0;
  itemName: String = "";
  description: String = "";
  status: String = "";
  sp: number = 0;
  mrp: number = 0;
  actualCost: number = 0;
  unit: String = "";
  costPerUnit: String = "";
  type: number = 0;
  quantity: number = 0;
  tax: number = 0;
  parentId: number = 0;
  exciseApplicable = 0;
  inventoryAmount = 0;
  templateId = 0;
}
export class cartItems {
  addOnCost: number = 0
  subItems = []
  itemId: number = 0;
  cost: number = 0;
  quantity: number = 0;
  itemName: String = "";
  costPerItem: number = 0;
  tax: number = 0;
  taxPer: number = 0;
  unit: string = "0";
  costPerUnit: String = "";
  specialInstruction: String = "";
  type: number = 0;
  exciseApplicable: number = 0;
  foodCategory: number = 0;
  comments: String = "";
  KOTDone: number = 0;
  complimentaryMarked = 0;
  bId: number;
  workerName: string = '';
  workerId: string;
  discount: number = 0;
  disValue: number;
  disType: string;
  item: string = ''
  productUsed: number
  productQty: number
  discRemark: string
  packingMappingId: number = 0;
  packageName: string = '';
  templateCartDetails: Array<templateCartItems> = [];
  points = 0
  membershipAmount = 0
  promoDiscount = 0
  memDis = 0
  isGSTInclusive=false
}
export class templateCartItems {
  itemId: number = 0;
  cost: number = 0;
  quantity: number = 0;
  itemName: String = "";
  costPerItem: number = 0;
  tax: number = 0;
  taxPer: number = 0;
}
export class orderDetails {
  dob: any
  anniversary: any
  appointmentId: number = 0
  discountLimit: number
  serviceList = []
  gender: string;
  orderId: number = 0;
  roundedTotalAmt: number = 0;
  roundDiff: number = 0
  invoicedAt: String = "";
  updatedAt: String = "";
  status: String = "";
  customerName: String = "";
  mobileNo: String = "";
  paymentMode: String = "";
  packingCharges: number = 0;
  itemCount: number = 0;
  subtotal: number = 0;
  totalAmt: number = 0;
  tax: number = 0;
  source: String = "";
  sourceId: number = 0;
  orderType: String = "";
  linkedOrderId: number = 0;
  discount: number = 0;
  discountedAmount: number = 0;
  discountType: number = 0;
  addressLine1: String = "";
  addressLine2: String = "";
  city: String = "";
  state: String = "";
  pincode: String = "";
  tableId: number = 0;
  tableName: String = "";
  deliveryAddressId: number = 0;
  supportStaff: String = "";
  businessId: number = 0;
  invoiceId: number = 0;
  asyncOrder: number = 0;
  serviceCharges: number = 0;
  cartDetails: Array<cartItems> = [];
  posDisType = '';
  posDisValue = 0;
  walletPoints = 0
  promoCode: String
  promoDiscount = 0
  memPerDiscount = 0
  isGstInclusive=false
  isTaxFlag : boolean=true;
  netAmt: number = 0;
  isOtpValid:string = '';
}
export class newOrderDetails {
  dob: any
  anniversary: any
  discountLimit: number
  appointmentId: number = 0;
  serviceList: any
  gender: string;
  orderId: number = 0;
  roundedTotalAmt: number = 0;
  roundDiff: number = 0
  invoicedAt: String = "";
  updatedAt: String = "";
  status: String = "";
  customerName: String = "";
  mobileNo: String = "";
  paymentMode: String = "";
  itemCount: number = 0;
  subtotal: number = 0;
  totalAmt: number = 0;
  tax: number = 0;
  source: String = "";
  sourceId: number = 0;
  linkedOrderId: number = 0;
  orderType: String = "";
  serviceCharges: number = 0;
  discount: number = 0;
  discountedAmount: number = 0;
  discountType: number = 0;
  addressLine1: String = "";
  addressLine2: String = "";
  packingCharges: number = 0;
  city: String = "";
  state: String = "";
  pincode: String = "";
  deliveryAddressId: number = 0;
  remarks: String = "";
  tableId: number = 0;
  tableName: String = "";
  businessId: number = 0;
  supportStaff: String = "";
  invoiceId: number = 0;
  asyncOrder: number = 0;
  cartDetails: Array<cartItems> = [];
  posDisType = ''
  posDisValue = 0
  walletPoints = 0
  promoCode: String
  promoDiscount = 0
  memPerDiscount = 0
  isGstInclusive=false
  isTaxFlag : boolean=true;
  netAmt: number = 0;
  isOtpValid:string = '';

}
export class templateItemDetails {
  id: number = 0;
  itemName: String = "";
  description: String = "";
  status: String = "";
  sp: number = 0;
  mrp: number = 0;
  actualCost: number = 0;
  variantDetails: Array<variantDetails> = [];
  unit: String = "";
  quantity: number = 0;
  tax: number = 0;
  type: number = 0;
  parentId: number = 0;
  inventoryAmount = 0;
  exciseApplicable: number = 0;
  parentItemId: number = 0;
  templateId = 0;
}
export class templateVariantDetails {
  id: number = 0;
  itemName: String = "";
  description: String = "";
  status: String = "";
  sp: number = 0;
  mrp: number = 0;
  actualCost: number = 0;
  unit: String = "";
  costPerUnit: String = "";
  quantity: number = 0;
  tax: number = 0;
  parentItemId: number = 0;
  parentId: number = 0;
  inventoryAmount = 0;
  exciseApplicable: number = 0;
  type: number = 0;
  templateId = 0;
}
export class deliveryAddress {
  deliveryAddressId: number = 0;
  addressLine1: String = "";
  addressLine2: String = "";
  city: String = "";
  state: String = "";
  pincode: String = "";
}
export class redeem {
  itemId: number = 0;
  itemName: String = "";
  packageId: number = 0;
  packageName: String = "";
  contactMappingId: number = 0;
  redeemValue: String = "No";
  sp: number = 0;
}

export class openItems {
  itemId = -1;
  itemName: String = "";
  sp: number;
  exciseApplicable: number = 0;
  foodCategory: number = 1;
  tax: number = 0;
}
