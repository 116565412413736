<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;" *ngIf="bName!=undefined">
    <div class="col-3">
        <img alt="Uengage Logo" class="max-h-60px" src="../../../../../assets/media/logos/uEngage-Logo.png">
    </div>
    <div class="col-6 d-none d-sm-block" style="text-align: center;">
        <span class="font-weight-bold text-dark font-size-h4 font-size-h1-lg">Welcome,&nbsp;</span>
        <span class="font-weight-bolder" style="color: #8CC42A;font-size:2.3em;">{{bName}}</span>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <div ngbDropdown class="d-inline-block dropdown mt-3 ml-2" style="cursor: pointer;">
            <div class="btn btn-icon btn-hover-transparent-white align-items-center btn-lg px-md-2 w-md-auto mobWidth"
                id="kt_quick_user_toggle" id="dropdownBasic1" ngbDropdownToggle>
                <span class="text-dark opacity-70 font-weight-bold font-size-base  d-md-inline mr-1">Hi,</span>
                <span class="text-dark opacity-90 font-weight-bolder font-size-base  d-md-inline mr-4">User</span><br>
                <span>
                    <img src="../../../../assets/media/misc/Arrow.png" style="width: 1em;">
                </span>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
                background-color: #fff;
                box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
                width: 26em;left: -19em !important;">
                <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center" #mProfileDropdown style="margin-bottom:10px">
                        <div class="m-card-user m-card-user--skin-dark">
                            <div class="m-card-user__details text-center">
                                <span class="m-card-user__name m--font-weight-500 ml-2"
                                    style="color: black; font-size: 18px;word-break: break-word;">
                                    {{ userName }}
                                </span>

                            </div>
                        </div>
                    </div>
                    <div class="m-dropdown__body mt-2">
                        <div class="m-dropdown__content">
                            <div class="row w-100 m-0">
                                <div class="col-12">
                                    <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 text-center w-100">
                                        <i class="fas fa-power-off"></i>&nbsp;Logout
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
<div class="multi-step-form">
    <div class="container" style="padding-top: 3em;">
        <div class="row">
            <div class="col-md-12 col-md-offset-3">
                <!-- progressbar -->
                <div class="progress-bar-upper">
                    <div class="card" style="border-radius: 20px;border: none;height: 11em;">
                        <div class="card-body">
                            <ul id="progressbar">
                                <!-- <li [ngClass]="step>=1 ? 'active ' : 'inactive'"> <span class="steps-content">Step 1 <br/><b>Can you tell us more about your business?</b></span></li>
                            <li [ngClass]="step>=2 ? 'active' : 'inactive'"><span class="steps-content">Step 2 <br/><b>Select your primary outlet location?</b></span></li>
                            <li [ngClass]="step==3 ? 'active' : 'inactive'"><span class="steps-content">Step 3 <br/><b>What is your registered address?</b></span></li> -->
                                <li [ngClass]="{'active': step >= 1, 'complete': step >= 2, 'inactive': step < 1}">
                                    <p class="steps-content"
                                        [ngClass]="{'activeStep': step >= 1, 'completeStep': step >= 2, 'inactiveStep': step < 1}">
                                        Step 1 <br /><b><span class="responsive-progress-bar">Fill up your company
                                                details</span></b></p>
                                </li>
                                <li [ngClass]="{'active': step >= 2, 'complete': step >= 3, 'inactive': step < 2}">
                                    <p class="steps-content"
                                        [ngClass]="{'activeStep': step >= 2, 'completeStep': step >= 3, 'inactiveStep': step < 2}">
                                        Step 2 <br /><b><span class="responsive-progress-bar">Get Your Business Location
                                                From Google Maps</span></b></p>
                                </li>
                                <li [ngClass]="{'active': step >= 3, 'inactive': step < 3}">
                                    <p class="steps-content"
                                        [ngClass]="{'activeStep': step >= 3, 'inactiveStep': step < 3}">
                                        Step 3 <br /><b><span class="responsive-progress-bar">Enter your POS
                                                details</span></b></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- fieldsets -->
                <form [formGroup]="flashForm" id="msform" *ngIf="step == 1">
                    <div class="card" style="border-radius: 20px;border: none;">
                        <div class="card-body">
                            <fieldset>
                                <div class="row mt">
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>Legal Entity Name</label>
                                            <input type="text" formControlName="legalEntityName"
                                                placeholder="Enter legal entity name"
                                                [ngClass]="{ 'is-invalid': personal_step && f.legalEntityName.errors }" />
                                            <div *ngIf="personal_step && flashForm.controls.legalEntityName.errors">
                                                <div *ngIf="f.legalEntityName.errors?.required">Field is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>Company Type</label>
                                            <select formControlName="companyType">
                                                <option disabled value="">--Select--</option>
                                                <option *ngFor="let data of companyData"
                                                    value="{{data.company_typ_id}}">{{data.company_type_name}}</option>
                                            </select>
                                            <!-- <input type="text" formControlName="legalEntityName"
                                                placeholder="Enter legal entity name"
                                                [ngClass]="{ 'is-invalid': personal_step && f.legalEntityName.errors }" /> -->
                                            <div *ngIf="personal_step && flashForm.controls.companyType.errors">
                                                <div *ngIf="f.companyType.errors?.required">Field is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>Address</label>
                                            <input type="text" formControlName="address" placeholder="Enter address"
                                                [ngClass]="{ 'is-invalid': personal_step && f.address.errors }" />
                                            <div *ngIf="personal_step && flashForm.controls.address.errors">
                                                <div *ngIf="f.address.errors?.required">Field is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>Locality</label>
                                            <input type="text" formControlName="locality" placeholder="Enter locality"
                                                [ngClass]="{ 'is-invalid': personal_step && f.locality.errors }" />
                                            <div *ngIf="personal_step && flashForm.controls.locality.errors">
                                                <div *ngIf="f.locality.errors?.required">Field is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>GSTIN (optional)</label>
                                            <input type="text" formControlName="gstin" placeholder="Enter gstin" />
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="margin-top: 1em;">
                                        <div class="form-group bussness-input-block userFormParent">
                                            <label>FSSAI (optional)</label>
                                            <input type="text" formControlName="fssai" placeholder="Enter fssai" />
                                        </div>
                                    </div>
                                </div>
                                <div class="next-btn-block">
                                    <button class="btn next-btn" type="button" (click)="next();"
                                        style="width: auto;">Save &
                                        Continue</button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
                <div class="card" style="border-radius: 20px; margin-bottom: 50px;border: none;" *ngIf="step == 2">
                    <div class="card-body">
                        <fieldset>
                            <div class="row w-100 m-0 mb-5">
                                <span style="font-size: 1.2em;font-weight: 600;">NOTE: </span>
                                <span style="font-size: 1.2em;font-weight: 500;">
                                    📍Ensure accurate entry of your location, as this information will be used as the
                                    pickup location for deliveries.
                                </span>
                            </div>
                            <div class="map-upper-class">
                                <div class="map-inner-input" style="box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <input type="text" placeholder="Search your location" ngx-google-places-autocomplete
                                        [options]="options" (onAddressChange)="AddressChange($event)"
                                        style="border: white;" class="form-control">
                                </div>
                                <div class="map-inner-locate-me"><button class="btn btn-locate-me" type="button"
                                        id="locateMeButton" (click)="getLocation()"
                                        style="box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);"> <img
                                            src="../../../../../assets/media/prism-images/locateMe.png" />Locate
                                        Me</button></div>
                                <div id="map" style="z-index: 0;margin-top: 1em;"></div>
                            </div>
                            <!-- <div id="map" style="z-index: 0;margin-top: 1em;"></div> -->
                            <!-- <div id="map" style="z-index: 0;margin-top: 1em;"></div> -->
                            <!-- <input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/>
                  <input  (click)="next()" type="button" name="next" class="next action-button" value="Next"/> -->
                            <div class="next-btn-block mt-4">
                                <button class="btn previous-btn" type="button" (click)="previous();"
                                    style="width: auto;">Previous</button>
                                <button class="btn next-btn" type="button" (click)="next()"
                                    style="width: auto;">Continue</button>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="card" style="border-radius: 20px; margin-bottom: 50px;border: none;" *ngIf="step == 3">
                    <div class="card-body">
                        <fieldset>
                            <h4 class="font-weight-bold mb-5">
                                From where you want to create your Task/Rider
                            </h4>
                            <div class="row w-100 m-0">
                                <div *ngFor="let data of taskSource" class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                    <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
                                    align-items: center;
                                    justify-content: center;" (click)="saveSource(data.id)"
                                        [ngClass]="{'sourceSelect': data.id == sourceId}">
                                        <img src="{{data.logo}}" style="width: 15em;">
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 pl-0 pr-0 mt-5 p-2">
                                    <div class="" style="background-color: white;border-radius: 5px;border: 1px solid #c4c4c4;height: 10em;cursor: pointer;display: flex;
                                    align-items: center;
                                    justify-content: center;" (click)="saveSource('others')"
                                        [ngClass]="{'sourceSelect': sourceId=='others'}">
                                        <span style="font-size: 2em;">Others</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showField==1" class="row mt-10 m-0">
                                <div class="form-group bussness-input-block userFormParent" style="width: 49em;">
                                    <label>Restaurant Id</label>
                                    <input type="text" [(ngModel)]="restId" placeholder="Enter restaurant id" />
                                </div>
                            </div>
                            <div *ngIf="showText==1" class="row mt-10 m-0">
                                <div class="form-group bussness-input-block userFormParent" style="width: 49em;">
                                    <label>POS Name</label>
                                    <input type="text" [(ngModel)]="posName" placeholder="Enter POS name" />
                                </div>
                            </div>
                            <div class="next-btn-block mt-4">
                                <button class="btn previous-btn" type="button" (click)="previous();"
                                    style="width: auto;">Previous</button>
                                <button class="btn next-btn" type="button" (click)="compFlashSetup()"
                                    style="width: auto;">Submit
                                    Details</button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>