import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localityCityFilter'
})
export class LocalityCityFilterPipe implements PipeTransform {
    transform(items: any[], searchTerm: string): any[] {
        if (!items || !searchTerm) {
            return items;
        }
        // console.log(items)
        // console.log(searchTerm)
        searchTerm = searchTerm.toLowerCase()
        return items.filter(item =>
            (item.locality && item.locality.toLowerCase().includes(searchTerm)) ||
            (item.city && item.city.toLowerCase().includes(searchTerm)) || (item.name && item.name.toLowerCase().includes(searchTerm))
        );
    }
}
