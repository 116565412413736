import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Component({
  selector: 'kt-full-screen-live-rider',
  templateUrl: './full-screen-live-rider.component.html',
  styleUrls: ['./full-screen-live-rider.component.css']
})
export class FullScreenLiveRiderComponent implements OnInit {
  businessName: any;
  category_id: any;
  parentBusinessId: any;
  noImage: number;
  avatar: string;
  locality: any;
  city: any;
  data = {
    type: 'fullscreen'
  }
  constructor(private router: Router, @Inject(LOCAL_STORAGE) private storage: WebStorageService,) { }

  ngOnInit(): void {
    console.log(this.data)
    let selectedBusiness = this.storage
      .get("AssignedBusiness")
      .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
    this.businessName = selectedBusiness[0].name;
    this.category_id = selectedBusiness[0].category_id;
    this.parentBusinessId = selectedBusiness[0]["parentId"];
    // (selectedBusiness)  
    if (selectedBusiness[0].logo != null) {
      this.noImage = 0
      this.avatar =
        "https://api.uengage.in/images/addo/logos/" + selectedBusiness[0].logo;
    } else {
      // this.char = 
      this.noImage = 1
    }

    // (this.avatar)
    // this.apiService.setBrandColor(selectedBusiness[0].brand_color)
    // this.brandColor = selectedBusiness[0].brand_color;
    this.locality = selectedBusiness[0].locality;
    this.city = selectedBusiness[0].city;
  }
  myFunction() {
    // document.getElementById("myDropdownWeb").classList.toggle("show");
    this.router.navigateByUrl('/businessSelector')
  }
  getChar(string) {
    // (string)
    return string.charAt(0)
  }
}
