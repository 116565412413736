import { Component, OnInit, Input, HostBinding, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { BusinessSelectorModel } from "./businessSelector.model";
import { MessagingService } from "../../../../messaging.service";
import { ConnectionService } from "ng-connection-service";
import { Meta, Title } from '@angular/platform-browser';
// import { Subject } from "rxjs/Subject";
//import { LayoutConfigService } from "../../../../core/services/layout-config.service";
// import * as objectPath from "object-path";
import { HtmlClassService } from "../../../theme/html-class.service";
import { SocialAuthService } from "angularx-social-login";
import Swal from "sweetalert2";
import { formatDate } from "@angular/common";
import moment from "moment";
// import { CurrencySharedService } from "src/app/currency-shared.service";

@Component({
  selector: "m-businessSelector",
  templateUrl: "./businessSelector.component.html",
  styleUrls: ["./businessselector.component.scss"],
})
export class BusinessSelectorComponent implements OnInit {
  @Input() headerLogo: any = "";
  @HostBinding("id") id = "m_header_nav";
  @Input() avatarBg: SafeStyle = "";
  userName: string = "";
  modules: BusinessSelectorModel = new BusinessSelectorModel();
  moudlesArray: Array<BusinessSelectorModel> = new Array<
    BusinessSelectorModel
  >();
  message;
  public config: any;
  public onlineOffline: boolean = navigator.onLine;
  status = "ONLINE";
  isConnected = true;
  businessAvailable = [];
  searchText = ''
  componenetPage = {
    "31": "order",
    "26": "onlineOrder",
    "17": "adminAccess",
    "13": "dashboard",
    "6": "menu-management",
    "32": "rider-management",
    "35": "userProfile",
    "19": "appointment",
    "37": "on-call-support",
    "38": "imageManagement",
    "39": "bannersManagement",
    "40": "storeManagement",
    "41": "pushNotification",
    "21": "subscription",
    "8": "promoManagement",
    "12": "customer-interaction",
    "43": "loyaltyDashboard",
    "20": "tokenManagement",
    "45": "reports",
    "46": "marketingAutomation",
    "47": "customer-support",
    "48": "payments",
    "49": "storeInfo",
    "50": "invoices",
    "51": "storeAuditAnalysis",
    "52": "leadGenerationForm",
    "53": "blog-manager",
    "54": "manage-documentation",
    "55": "safety-measure",
    "56": "bulk-inventory-update",
    "57": "feed-management",
    "58": "market-place",
    "4": "campaign-management",
    "59": "delivery-logistic",
    "60": "request-management",
    "61": "request-inventory",
    "62": "mess-flow",
    "63": "web_config",
    "64": "maps-locality",
    "65": "serviceManagement",
    "66": "staffManagement",
    "67": "nps-module",
    "34": "inventory-management",
    "11": "events",
    "25": "reservation",
    "10": "table-management",
    "68": "prebuy-management",
    "69": "membership-management",
    "70": "outlet-audit",
    "23": "expense-management",
    "71": "package-management",
    "72": "crm-customers",
    "73": "reviews-rating",
    "74": "automated-campaigns",
    "75": "crm-admin",
    "76": "sales-admin",
    "77": "menu-management-dummy",
    "79": "onboarding-approval",
    "78": 'third-party-delivery',
    "80": 'flash-dashboard',
    "81": 'flash-configuration',
    "82": "enquiry-form",
    "83": "campaigns",
    "84": "outlet-downtime",
    "85": "pending-payout",
    "86": "owner-access",
    "87": "flash-summary",
    "88": "order-support",
    "89": 'flash-admin',
    "90": "image-master",
    "91": "finance-management",
    "92": "job-posting",
    "93": "crm-sales",
    "94": "whatsapp-requests",
    "95": "business-management",
    "96": "stories",
    "97": "employee",
    "98": "ondc-dashboard",
    "99": "ondc-reconcilation",
    "100": "sales-data",
    "101": 'rewards',
    "102": 'master-menu',
    "103": 'ucollab',
    "104": 'ondc-funnel',
    "106": 'announcements',
    "107": 'memebership-cards',
    "108": 'live-rider-view',
    "109": 'incentives',
    "110": 'membership',
    "111": 'support',
    "112": 'whatsapp',
    "113": 'links',
    "114": 'ondc-admin',
    "115": 'service-reminder',
    "116": 'rewards-games',
    "117": 'advance-payments',
    "118": "flash-developer-api",
    "119": "flash-analytics-reports",
    "121": "online-store",
    "122": "web-pages",
    "123": "web-pages/header",
    "124": "web-pages/footer",
    "125": "web-pages/integration",
    "126": "web-pages/settings",
    "120": "config",
    "127": "account-success",
    "128": "fixed-package",
    "129": "customer-address",
    "130": "super-admin",
    "131":"whatsapp-ordering",
    "132":"whatsapp-agent-flow"


  };
  today: number = Date.now();
  footerClasses = "";
  footerContainerClasses = "";
  selectedId: any = '';

  constructor(
    private router: Router,
    private appComp: AppComponent,
    public httpClient: HttpClient,
    private messagingService: MessagingService,
    //private layoutConfigService: LayoutConfigService,
    private connectionService: ConnectionService,
    private sanitizer: DomSanitizer, private cd: ChangeDetectorRef,
    private uiClasses: HtmlClassService, private authService: SocialAuthService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService, public meta: Meta, public title: Title,
  ) {
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      } else {
        this.status = "OFFLINE";
      }
    });
    // this.layoutConfigService.onLayoutConfigUpdated$.subscribe((model) => {
    //   const headerLogo = objectPath.get(model, "config.header.self.logo");
    //   if (typeof headerLogo === "object") {
    //     this.headerLogo = objectPath.get(headerLogo);
    //   } else {
    //     this.headerLogo = headerLogo;
    //   }
    // });
  }

  ngOnInit(): void {
    this.businessAvailable = []
    this.title.setTitle('uEngage Dashboard');
    // this.meta.updateTag({ name: 'description', content: '' }); 
    // this.title1.setTitle('Uengage Dashboard');
    this.meta.updateTag({ name: 'description', content: '' });
    this.meta.updateTag({ property: 'og:title', content: 'uEngage Dashboard' });
    this.meta.updateTag({ property: 'og:image', content: 'https://www.uengage.io/assets/uen/img/uengage-mascot-banner.png' });
    this.meta.updateTag({ property: 'og:type', content: 'image:png' });
    //let res = this.appComp.checkDetails();
    // if (res == false) {
    //   this.router.navigate(["login"]);
    // }
    this.storage.remove("selectedModule");
    this.footerClasses = this.uiClasses.getClasses("footer", true).toString();
    this.footerContainerClasses = this.uiClasses
      .getClasses("footer_container", true)
      .toString();

    this.businessAvailable = this.storage.get("AssignedBusiness").sort((a, b) => parseInt(a.parentId) - parseInt(b.parentId));
    // (this.businessAvailable)
    // ("hey")
    window.scroll(0, 0);
    if (!this.avatarBg) {
      this.avatarBg = this.sanitizer.bypassSecurityTrustStyle(
        "url(./assets/media/misc/user_profile_bg.jpg)"
      );
    }
    this.userName = this.storage.get("userName");
  }

  getRequest(url) {
    return this.httpClient.get(url);
  }

  selectBusinessId(businessId, currency) {
    // console.log(currency)
    this.selectedId = businessId
    let selectedBusinessDetails = this.storage
      .get("AssignedBusiness")
      .filter((e) => e.businessId == this.selectedId);
    // console.log(selectedBusinessDetails)
    let validity = selectedBusinessDetails[0]["validity_date"]
    if (validity != null) {
      let valid_till = validity.split(" ")
      valid_till = valid_till[0]
      let endDate = formatDate(valid_till, 'yyyy-MM-dd', 'en')
      let startDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
      let daysLeft = moment(endDate).diff(moment(startDate), 'days');
      if (daysLeft < 0) {
        Swal.fire('', 'Your subscription is over! Contact your account manager to renew your subscription to start using again.', 'error')
        return false;
      }
    }
    this.moudlesArray = new Array<BusinessSelectorModel>();
    if (navigator.onLine) {
      this.storage.set("selectedBusinessId", businessId);

      this.storage.set("modules", "");

      this.messagingService.requestPermission(this.storage.get("userId"));
      this.message = this.messagingService.currentMessage;
      var params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        businessId +
        "&web=1";
      this.httpClient
        .get(this.appComp.base_url + "ueapi/addoPermissions?" + params, {})
        .subscribe((res: any) => {
          if (res.length == 0) {
            Swal.fire('', 'No modules available! Please contact uEngage team for the same.', 'error')
            this.selectedId = ''
            this.cd.detectChanges()
          } else {
            localStorage.setItem('currency', currency);
            // this.currencySharedService.setCurrentCurrencyCode(currency)
            if (currency == 'INR' || currency == undefined) {
              localStorage.setItem('locale', 'en-INR')
              localStorage.setItem('currency', 'INR');
            } else if (currency == 'GBP') {
              localStorage.setItem('locale', 'en-GB')
            }
            res.forEach((element) => {
              // (element.riders)
              if (element.riders == undefined || element.riders == 'undefined') {
                this.modules = new BusinessSelectorModel();
                this.modules.moduleId = element.id;
                this.modules.title = element.moduleName;
                this.modules.desc = element.quickInfo;
                this.modules.icon = "fa " + element.fa_icon;
                this.modules.maxDiscount = element.maxDiscount;
                this.modules.userType = element.userType;
                this.modules.documentationId = element.documentationId;
                this.modules.products = element.products;
                if (this.componenetPage[element.id] == "onlineOrder") {
                  this.modules.page =
                    this.componenetPage[element.id] + "/" + businessId;
                } else {
                  this.modules.page = this.componenetPage[element.id];
                }
                if (element.id == 108 || element.id == 80 || element.id == 87 || element.id == 32) {
                  this.modules.subLinks.push({
                    page: 'flash-task'
                  })
                }
                if (element.id == 46) {
                  this.modules.subLinks.push({
                    page: 'campaigns'
                  })
                }
                if (element.id == 113) {
                  this.modules.subLinks.push({
                    page: 'create-link'
                  })
                }
                this.cd.detectChanges()
                // console.log(this.modules)
                this.moudlesArray.push(this.modules);
              }
            });
            // console.log(this.moudlesArray)
            this.storage.set("modules", this.moudlesArray);
            //  (this.moudlesArray)
            if (this.moudlesArray[0].title == "Online Orders") {
              this.router.navigate([
                "Dashboard/onlineOrder" + "/" + businessId,
              ]);
            } else if (this.moudlesArray[0].title == "Online Orders") {
              this.router.navigate([
                "Dashboard/userProfile"
              ]);
            }
            else {
              if (this.moudlesArray[0].products == 2) {
                this.router.navigate(["prism/" + this.moudlesArray[0].page]);
              } else if (this.moudlesArray[0].products == 3) {
                this.router.navigate(["flash/" + this.moudlesArray[0].page]);
              } else if (this.moudlesArray[0].products == 4) {
                this.router.navigate(["admin/" + this.moudlesArray[0].page]);
              } else if (this.moudlesArray[0].products == 1) {
                this.router.navigate(["edge/" + this.moudlesArray[0].page]);
              } else if (this.moudlesArray[0].products == 5) {
                this.router.navigate(["corporate/" + this.moudlesArray[0].page]);
              } else if (this.moudlesArray[0].products == 6) {
                this.router.navigate(["wla/" + this.moudlesArray[0].page]);
              }
            }
          }
        }
        )
    }
    else {
      // this.storage.set('modules',this.moudlesArray);
      if (businessId != this.storage.get("selectedBusinessId")) {
        alert("You cannot change the business in Offline Mode");
        return false;
      }
      localStorage.setItem('currency', currency);
      // this.currencySharedService.setCurrentCurrencyCode(currency)
      if (currency == 'INR') {
        localStorage.setItem('locale', 'en-INR')
      } else if (currency == 'GBP') {
        localStorage.setItem('locale', 'en-GB')
      }
      this.moudlesArray = this.storage.get("modules");
      if (this.moudlesArray[0].page == "onlineOrder") {
        this.router.navigate([
          "Dashboard/" + this.moudlesArray[0].page + "/" + businessId,
        ]);
      } else {
        this.router.navigate(["Dashboard/" + this.moudlesArray[0].page]);
      }
    }
  }
  refresh() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password");
    let url = this.appComp.base_url + "ueapi/getMappedBusiness?" + params;
    this.getRequest(url).subscribe(
      (res: any) => {
        this.storage.set("AssignedBusiness", res.msg);
        this.businessAvailable = this.storage.get("AssignedBusiness").sort((a, b) => parseInt(a.parentId) - parseInt(b.parentId));
      },
      (err) => {
      }
    );
    // location.reload();
  }
  register() {
    this.router.navigateByUrl("/legalEntity")
  }
  public logout() {
    // this.storage.remove("userName");
    // this.storage.remove("password");
    // this.storage.remove("userId");
    // this.storage.remove("selectedBusinessId");
    this.authService.authState.subscribe((user) => {
      // (user)
      // this.user = user;
      // this.loggedIn = (user != null);
      if (user != null) {
        this.authService.signOut()
      }
    });
    let isBuzz = this.storage.get("isBuzz");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    let path = "";
    this.router.navigate([path]);
  }
  routeBack() {
    let path = "businessSelector";
    this.router.navigate([path]);
  }
  addnew() {
    this.router.navigateByUrl("/create-brand")
  }
  getChar(string) {
    return string.charAt(0)
  }
}
