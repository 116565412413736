import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IpService } from '../ip.service';
import { ENDPOINTS } from '../routes/index.routes';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class HttpMethodInterceptor implements HttpInterceptor {

  constructor(private ipService: IpService) { }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return next.handle(request);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(ENDPOINTS.downloadCustomerData) && req.method === 'POST') {
      return this.ipService.getIpAddress().pipe(
        switchMap((ip: string) => {
          let params = req.body;

          if (params instanceof HttpParams) {
            params = params.append('ip_address', ip);
          } else if (typeof params === 'object' && params !== null) {
            params = { ...params, ip_address: ip };
          }

          const modifiedReq = req.clone({ body: params });
          return next.handle(modifiedReq);
        }),
        catchError((err: HttpErrorResponse) => {
          this.handleError(err);
          return throwError(() => err);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleError(err);
        return throwError(() => err);
      })
    );
  }

  private handleError(err: HttpErrorResponse): void {
    if (err) {
      switch (err.status) {
        // case 400:
        //   alert('Bad Request');
        //   break;
        // case 401:
        //   alert('Unauthorized');
        //   break;
        // case 404:
        //   alert('Not Found');
        //   break;
        // default:
        //   alert('An error occurred');
        //   break;
      }
    }
  }
}
