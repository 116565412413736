import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import Swal from 'sweetalert2';
import KTImageInput from '../../../../../assets/js/components/image-input.js'
import { ToastrService } from 'ngx-toastr';
// import { MapsAPILoader } from '@agm/core';
import { GoogleServiceService } from 'src/app/views/google-service.service.js';
import { control } from 'leaflet';
declare var L
@Component({
  selector: 'app-brandregister',
  templateUrl: './brandregister.component.html',
  styleUrls: ['./brandregister.component.scss']
})

export class BrandregisterComponent implements OnInit {
  @ViewChild("wizard", { static: true }) el: ElementRef;
  submitted2 = false
  map: any;
  detailForm: FormGroup
  flashForm: FormGroup
  locationForm: FormGroup
  submitted3 = false
  companyData: any;
  docDetails: any;
  wizard: any;
  bName: any;
  posName = ''
  productId: any;
  step = 1;
  bDetails: any;
  userId: any;
  bId: any;
  avatar: any;
  docId: any;
  businessDetails: number;
  docList = [];
  docData: any;
  userName: any;
  prefill: number;
  latitude: any = '';
  options: { componentRestrictions: { country: string[]; }; };
  longitude: any = '';
  showMap: number;
  zoom = 15
  radiusData = ['1km', '2km', '3km', '4km', '5km', '6km', '7km', '8km', '9km', '10km', '11km', '12km', '13km', '14km', '15km', '16km', '17km', '18km', '19km', '20km']
  businessLocation: number = 0;
  wizardStep: number;
  taskSource: any;
  product_icon: any;
  sourceId: any = '';
  showField: number;
  restId: any = ''
  googleLoaded: number;
  personal_step = false
  restFlag: number;
  restNameFlag: number;
  industryData: any;
  showText: number;
  constructor(private fb: FormBuilder, @Inject(LOCAL_STORAGE) private storage: WebStorageService, public httpClient: HttpClient,
    private cd: ChangeDetectorRef, private router: Router, private toastr: ToastrService, public google: GoogleServiceService) {
    this.options = {
      componentRestrictions: {
        country: ["IN"]
      }
    }
  }

  ngOnInit(): void {
    this.userName = this.storage.get("userName");
    let bDetails = this.storage.get("AssignedBusiness");
    this.bDetails = bDetails[0]
    this.userId = this.bDetails["userId"]
    this.bId = this.bDetails["businessId"]
    this.flashForm = this.fb.group({
      gstin: [''],
      fssai: [''],
      legalEntityName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      companyType: ['', [Validators.required]],
      locality: ['', [Validators.required]]
      // posStatus: ['', [Validators.required]],
      // restId: [''],
      // posName: ['']
    })
    this.locationForm = this.fb.group({
      deliveryRadius: ['', [Validators.required]],
      locality: ['', [Validators.required]],
    })
    this.getCompanyTypes()
  }
  // get personal() { return this.personalDetails.controls; }
  getSource() {
    let params =
      "userId=" + this.userId +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId;
    this.httpClient.get("https://api.uengage.in/onboarding/getFlashTaskSource?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        this.taskSource = res.task_source
        this.cd.detectChanges()
      }
    });
  }
  getBDetails() {
    this.docDetails = []
    this.docList = []
    let params =
      "userId=" + this.userId +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId;
    this.httpClient.get("https://api.uengage.in/onboarding/getUpdatedDetails?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        let bdata = res.bDetails
        this.bName = bdata.name
        this.productId = bdata.onboard_productId
        this.product_icon = bdata.product_icon
        this.cd.detectChanges()
        if (bdata.legalEntityName != '' && bdata.legalEntityName != null) {
          this.prefill = 1
          if (this.productId == 1) {
            this.detailForm.controls.legalEntityName.setValue(bdata.legalEntityName)
            this.detailForm.controls.address.setValue(bdata.address)
            this.detailForm.controls.gstin.setValue(bdata.gstin)
            this.detailForm.controls.companyType.setValue(bdata.company_type)
            this.detailForm.controls.bankName.setValue(bdata.bankName)
            this.detailForm.controls.accHolder.setValue(bdata.bankAccountHolder)
            this.detailForm.controls.accNumber.setValue(bdata.bankAccountNumber)
            this.detailForm.controls.ifsc.setValue(bdata.ifsc)
            let index = this.companyData.findIndex(e => e.company_typ_id == bdata.company_type);
            this.docDetails = this.companyData[index].document_list
            // (this.docDetails)
            for (let i = 0; i < this.docDetails.length; i++) {
              this.docList.push({
                id: this.docDetails[i].id,
                name: this.docDetails[i].documentName,
                uploaded: 0,
                file: ''
              })
            }
            // (this.docList)
            if (res.doc_uploaded.length != 0) {
              // (res.doc_uploaded)
              for (let i = 0; i < this.docList.length; i++) {
                let index: any
                index = res.doc_uploaded.findIndex(e => e.document_id == this.docList[i].id)
                // (index)
                if (index > -1) {
                  this.docList[index].uploaded = 1
                  this.docList[index].file = res.doc_uploaded[index].document_path
                }
              }
            }
            this.docList.sort((a, b) => (a.uploaded < b.uploaded ? -1 : 1));

            //  (this.docList)
            this.businessDetails = 1
            this.cd.detectChanges()
            this.wizard.goNext()
          }
          else if (this.productId == 3) {
            // (bdata)
            this.flashForm.controls.legalEntityName.setValue(bdata.legalEntityName)
            this.flashForm.controls.address.setValue(bdata.address)
            this.flashForm.controls.gstin.setValue(bdata.gstin)
            this.flashForm.controls.fssai.setValue(bdata.fssai_no)
            this.flashForm.controls.companyType.setValue(bdata.company_type)
            this.flashForm.controls.locality.setValue(bdata.locality)
            // this.flashForm.controls.deliveryRadius.setValue(bdata.company_type)
            // this.flashForm.controls.lat.setValue(bdata.bankName)
            // this.flashForm.controls.long.setValue(bdata.bankAccountHolder)
            // this.businessDetails = 1
            this.cd.detectChanges()
            this.forMap()
            this.step++
          }
        }
      } else {
        this.prefill = 0
        this.businessDetails = 0
      }
    })
  }
  addGoogleMapScript() {
    this.removeGoogleMapScript()
  }
  removeGoogleMapScript() {
    // console.debug('removing google script...');
    let keywords = ['maps.googleapis'];

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    let scripts = document.head.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      let scriptSource = scripts[i].getAttribute('src');
      if (scriptSource != null) {
        if (keywords.filter(item => scriptSource.includes(item)).length) {
          scripts[i].remove();
          // scripts[i].parentNode.removeChild(scripts[i]);
        }
      }
    }
    const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB1dEy_J-XULzpX6zcSirVT2OQ_-WAHjUo&libraries=places';
    // let element = document.getElementById("googleScript")
    // (element)
    // if(element!=undefined && element!=null){
    this.google.loadScript(url).then(value => {
      // (value)
      if (value != undefined && value != null && value != '') {
        this.googleLoaded = 1
      }
    })
    // if(document.getElementById("googleScript")!=null){
    // this.googleLoaded=1
    // this.cd.detectChanges()
    // }
  }
  ngAfterViewInit(): void {
    this.addGoogleMapScript()
    this.getBDetails()
  }
  compFlashSetup() {
    if (this.sourceId == null || this.sourceId == undefined || this.sourceId == '') {
      this.toastr.error('Please provide source', 'Error', {
        positionClass: 'toast-bottom-right'
      })
    } else {
      if (this.sourceId == 2) {
        if (this.restId == '' || this.restId == null || this.restId == undefined) {
          this.toastr.error('Please provide restaurant id', 'Error', {
            positionClass: 'toast-bottom-right'
          })
        } else {
          // let data = this.flashForm.value
          // let radius = data.deliveryRadius.replace('km', '');
          let params =
            "userId=" + this.userId +
            "&password=" + this.storage.get("password") +
            "&businessId=" + this.bId +
            "&latitude=" + this.latitude +
            "&longitude=" + this.longitude +
            "&radius=" +
            "&source=" + this.sourceId;
          if (this.sourceId != 2) {
            "&restaurantId=" + this.restId;
          }
          if (this.sourceId == 'others') {
            "&pos_name=" + this.posName;
          }
          this.httpClient.get("https://api.uengage.in/onboarding/completeOnboarding?" + params).subscribe((res: any) => {
            if (res.status == 1) {
              Swal.fire('', res.msg, 'success')
              let params =
                "userId=" +
                this.storage.get("userId") +
                "&password=" +
                this.storage.get("password");
              let url = "https://api.uengage.in/ueapi/getMappedBusiness?" + params;
              this.httpClient.get(url).subscribe(
                (res: any) => {
                  this.storage.set("AssignedBusiness", res.msg);
                  this.router.navigateByUrl("/businessSelector");
                },
                (err) => {
                }
              );
              // this.router.navigateByUrl("/businessSelector");
              // setTimeout(function () {

              // }, 2000)
            } else {
              Swal.fire('', res.msg, 'error')
            }
          })
        }
      } else {
        let params =
          "userId=" + this.userId +
          "&password=" + this.storage.get("password") +
          "&businessId=" + this.bId +
          "&latitude=" + this.latitude +
          "&longitude=" + this.longitude +
          "&radius=" +
          "&source=" + this.sourceId;
        if (this.sourceId != 2) {
          "&restaurantId=" + this.restId;
        }
        if (this.sourceId == 'others') {
          "&pos_name=" + this.posName;
        }
        this.httpClient.get("https://api.uengage.in/onboarding/completeOnboarding?" + params).subscribe((res: any) => {
          if (res.status == 1) {
            Swal.fire('', res.msg, 'success')
            let params =
              "userId=" +
              this.storage.get("userId") +
              "&password=" +
              this.storage.get("password");
            let url = "https://api.uengage.in/ueapi/getMappedBusiness?" + params;
            this.httpClient.get(url).subscribe(
              (res: any) => {
                this.storage.set("AssignedBusiness", res.msg);
                this.router.navigateByUrl("/businessSelector");
              },
              (err) => {
              }
            );
            // setTimeout(function () {

            // }, 2000)
          } else {
            Swal.fire('', res.msg, 'error')
          }
        })
      }
    }
  }
  saveSource(id) {
    this.sourceId = id
    if (id == 2) {
      this.showField = 1
      this.showText = 0
    } else if (this.sourceId == 'others') {
      this.showText = 1
      this.showField = 0
    } else {
      this.showText = 0
      this.showField = 0
    }
    this.cd.detectChanges()
  }
  get l() { return this.locationForm.controls; }

  get f() { return this.flashForm.controls; }
  createDetailForm() {
    this.detailForm = this.fb.group({
      // businessName:['',[Validators.required]],
      gstin: ['', [Validators.required]],
      legalEntityName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      // mobile:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    })
  }
  get d() { return this.detailForm.controls; }

  getCompanyTypes() {
    this.httpClient.get("https://api.uengage.in/onboarding/getCompanytypes").subscribe((data: any) => {
      if (data.status == 1) {
        this.companyData = data.list
        this.cd.detectChanges()
        // this.getBDetails()
      }
      else {
        Swal.fire('', data.msg, 'error')
        return false;
      }
    });
  }
  saveDetails() {
    // (this.prefill)
    if (this.prefill != 1) {
      this.docDetails = []
      this.docList = []
    }
    this.submitted2 = true
    if (this.detailForm.invalid) {
      this.wizard.stop();
    }
    else {
      let data = this.detailForm.value
      if (this.prefill != 1) {
        let index = this.companyData.findIndex(e => e.company_typ_id == data.companyType);
        this.docDetails = this.companyData[index].document_list
        for (let i = 0; i < this.docDetails.length; i++) {
          this.docList.push({
            id: this.docDetails[i].id,
            name: this.docDetails[i].documentName,
            uploaded: 0,
            file: ''
          })
        }
      }
      // (this.docList)
      this.cd.detectChanges()
      let params =
        "userId=" + this.userId +
        "&password=" + this.storage.get("password") +
        "&businessId=" + this.bId +
        "&legalEntityName=" + encodeURIComponent(data.legalEntityName) +
        "&gstin=" + data.gstin +
        "&address=" + encodeURIComponent(data.address) +
        "&company_type=" + data.companyType +
        "&bank_name=" + encodeURIComponent(data.bankName) +
        "&bank_account_holder=" + data.accHolder +
        "&ifsc=" + data.ifsc +
        "&bank_account_number=" + data.accNumber +
        "&onboard_productId=" + this.productId;
      this.httpClient.get("https://api.uengage.in/onboarding/updateBusinessDetails?" + params).subscribe((res: any) => {
        if (res.status == 1) {
          this.businessDetails = 1
          this.wizard.goNext()
          this.cd.detectChanges()
        } else {
          this.businessDetails = 0
          Swal.fire('', res.msg, 'error')
          this.cd.detectChanges()
          this.wizard.stop()
        }
      })
      // (this.docDetails)
      this.cd.detectChanges()

      // this.accountForm.reset();
    }
  }
  saveFlashDetails() {
    let data = this.flashForm.value
    let params =
      "userId=" + this.userId +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId +
      "&legalEntityName=" + encodeURIComponent(data.legalEntityName) +
      "&gstin=" + data.gstin +
      "&fssai_no=" + data.fssai +
      "&address=" + encodeURIComponent(data.address) +
      "&onboard_productId=" + this.productId +
      "&company_type=" + data.companyType +
      "&locality=" + data.locality;
    this.httpClient.get("https://api.uengage.in/onboarding/updateBusinessDetails?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        this.businessDetails = 1
        this.locationForm.controls.deliveryRadius.setValue("")
        // this.wizardStep = 2
        // this.wizard.goNext()
        this.cd.detectChanges()
      } else {
        this.businessDetails = 0
        Swal.fire('', res.msg, 'error')
        this.cd.detectChanges()
        this.wizard.stop()
      }
    })
    // (this.docDetails)
    this.cd.detectChanges()
  }
  change(id) {
    this.avatar = new KTImageInput(id);
    this.docId = id
    // (this.docId)
  }
  onSelectFile(event) {
    // (event.target.files)
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("businessId", this.bId);
    formData.append("userId", this.userId);
    formData.append("password", this.storage.get("password"));
    formData.append("document_id", this.docId);
    this.httpClient
      .post("https://api.uengage.in/onboarding/uploadDocuments", formData)
      .subscribe((res: any) => {
        if (res.status == 1) {
          //  (this.docList)
          let index = this.docList.findIndex(e => e.id == this.docId);
          this.docList[index].uploaded = 1
          this.docList[index].file = res.fileName
          this.cd.detectChanges()
          this.toastr.success(res.msg, 'Success', {
            positionClass: 'toast-bottom-right'
          })
          // Swal.fire({
          //   title: "Document uploaded!",
          //   text: "",
          //   icon: "success"
          // });
        } else {
          this.toastr.error(res.msg, 'Error', {
            positionClass: 'toast-bottom-right'
          })
          // this.toastr.success(res.msg)
          // Swal.fire({
          //   title: "Failed to upload the document",
          //   text: "",
          //   icon: "error"
          // });
        }
      });
  }
  compSetup() {
    let error = 0
    for (let i = 0; i < this.docList.length; i++) {
      if (this.docList[i].uploaded == 0) {
        error = 1
        break;
      }
    }
    if (error == 1) {
      this.toastr.error('Please upload all documents', 'Error', {
        positionClass: 'toast-bottom-right'
      })
      this.wizard.stop()
    } else {
      let params =
        "userId=" + this.userId +
        "&password=" + this.storage.get("password") +
        "&businessId=" + this.bId;
      this.httpClient.get("https://api.uengage.in/onboarding/completeOnboarding?" + params).subscribe((res: any) => {
        if (res.status == 1) {
          Swal.fire('', res.msg, 'success')
          setTimeout(function () {
            window.location.href = 'http://app.uengage.in/approval-pending';
            // this.router.navigateByUrl("/login");
          }, 2000)
        } else {
          Swal.fire('', res.msg, 'error')
        }
      })
    }
  }
  logout() {
    let isBuzz = this.storage.get("isBuzz");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    let path = "";
    this.router.navigate([path]);
  }
  AddressChange(event) {
    this.latitude = event.geometry.location.lat()
    this.longitude = event.geometry.location.lng()
    this.map.remove()
    var mapOptions = {
      // center: [this.latitude, this.longitude],
      center: [this.latitude, this.longitude],
      zoom: 17,
    }
    this.map = new L.map('map', mapOptions);
    var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
    this.map.addLayer(layer);
    var storeicon = L.icon({
      iconUrl: '../../../../../assets/media/prism-images/map-marker.png',
      iconSize: [74, 74],
      iconAnchor: [37, 74]
    });

    // var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
    var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
    // this.latitude, this.longitude 30.712546585969097,76.84757766742457,
    marker.addTo(this.map);
    this.map.removeControl(this.map.zoomControl);
    control.zoom({ position: 'bottomright' }).addTo(this.map);
    this.cd.detectChanges()
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        // console.log(this.latitude, this.longitude);
        this.map.remove()
        var mapOptions = {
          // center: [this.latitude, this.longitude],
          center: [this.latitude, this.longitude],
          zoom: 17,
        }
        this.map = new L.map('map', mapOptions);
        var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        this.map.addLayer(layer);
        var storeicon = L.icon({
          iconUrl: '../../../../../assets/media/prism-images/map-marker.png',
          iconSize: [74, 74],
          iconAnchor: [37, 74]
        });

        // var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
        var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
        // this.latitude, this.longitude 30.712546585969097,76.84757766742457,
        marker.addTo(this.map);
        this.map.removeControl(this.map.zoomControl);
        control.zoom({ position: 'bottomright' }).addTo(this.map);
      })
    }
  }
  next() {
    if (this.step == 1) {
      this.personal_step = true;
      if (this.flashForm.invalid) {
        // console.log('invalid')
        return
      } else {
        this.step++
        this.forMap()
        this.saveFlashDetails()
      }
    }
    else if (this.step == 2) {
      // this.address_step = true;
      // console.log(this.latitude)
      if (this.latitude == null || this.latitude == undefined || this.latitude == '' || this.longitude == null || this.longitude == undefined || this.longitude == '') {
        this.toastr.error('Please provide your outlet location', 'Error', {
          positionClass: 'toast-bottom-right'
        })
      } else {
        this.getSource()
        this.step++;
      }
    }
  }
  previous() {
    this.step--
    if (this.step == 1) {
      // this.address_step = false;
    }
    if (this.step == 2) {
      this.forMap()
    }
  }
  forMap() {
    if (this.latitude == null || this.latitude == undefined || this.latitude == '' || this.longitude == null || this.longitude == undefined || this.longitude == '') {
      setTimeout(() => {
        var mapOptions = {
          center: [30.7333, 76.7794],
          zoom: 17
        }
        this.map = new L.map('map', mapOptions);
        var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        this.map.addLayer(layer);
        // var storeicon = L.icon({
        //   iconUrl: '../../../../../assets/media/prism-images/map-marker.png',
        //   iconSize: [74, 74],
        //   iconAnchor: [37, 74]
        // });
        // var marker = '';
        // var marker = L.marker([30.7333, 76.7794], { icon: storeicon });
        // this.latitude, this.longitude 30.7144164,76.8374883
        // marker.addTo(this.map);
        this.map.removeControl(this.map.zoomControl);
        control.zoom({ position: 'bottomright' }).addTo(this.map);
      }, 1000);
    } else {
      setTimeout(() => {
        var mapOptions = {
          center: [this.latitude, this.longitude],
          zoom: 17
        }
        this.map = new L.map('map', mapOptions);
        var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        this.map.addLayer(layer);
        var storeicon = L.icon({
          iconUrl: '../../../../../assets/media/prism-images/map-marker.png',
          iconSize: [74, 74],
          iconAnchor: [37, 74]
        });
        // var marker = '';
        var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
        // this.latitude, this.longitude 30.7144164,76.8374883
        marker.addTo(this.map);
        this.map.removeControl(this.map.zoomControl);
        control.zoom({ position: 'bottomright' }).addTo(this.map);
      }, 1000);
    }
  }
  checkPos(value) {
    if (value == 2) {
      this.restFlag = 1
      this.restNameFlag = 0
      this.flashForm.get('restId').setValidators(Validators.required);
      this.flashForm.get('posName').clearValidators();
      this.flashForm.get('posName').updateValueAndValidity();
    } else if (value == -1) {
      this.restNameFlag = 1
      this.restFlag = 0
      this.flashForm.get('posName').setValidators(Validators.required);
      this.flashForm.get('restId').clearValidators();
      this.flashForm.get('restId').updateValueAndValidity();
    } else {
      this.restFlag = 0
      this.restNameFlag = 0
      this.flashForm.get('restId').clearValidators();
      this.flashForm.get('restId').updateValueAndValidity();
      this.flashForm.get('posName').clearValidators();
      this.flashForm.get('posName').updateValueAndValidity();
    }
  }
}
