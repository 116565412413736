import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
// import {AsideLeftComponent} from "../app/views/theme/aside/aside-left.component"
// import {sign, SignOptions} from "jsonwebtoken"
// import { param } from 'jquery';
// import { selectLastCreatedUserId } from './core/auth';
// import emailAddress from 'src/assets/plugins/formvalidation/src/js/validators/emailAddress';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  // signOptions: SignOptions = {
  //   algorithm: 'RS256',
  //   expriresIn:'1hr'
  // }
  lost: number;
  new: number;
  recent: number;
  requestData: any = [];
  flag: any;
  leave = 0;
  message: any;
  title: any;
  image: any;
  os: any;
  newUserFlag: number;
  state = '';
  customerData: any;
  productFlag: any;
  allowPushFlag: any;
  brandColor: any;
  permiumFlag: any;
  campDetails: any;
  prePOPEmail: number;
  customerflag: any;
  prePOPWhatsapp: number;
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: WebStorageService) { }
  segmentName: any;
  campaignValue: any = '';
  text: any;
  type: any;
  segmentId: any;
  prePOP: any;
  total: any;
  active: any;
  indexDetails: any;
  customerReport: any = '';

  // payload: {
  //   targetAudience: 5467
  // }
  // public getData(value:any) {

  //   return this.http.get('https://crm.uengage.in/segments/dashboardData/4580');
  // }
  public getStoreDetails(selectedBId: any, userId, password, businessId, parentBusinessId, flag, startdate, enddate) {
    // let bidarray
    // if(selectedBId==''){
    //   bidarray=[]
    // } else {
    //   bidarray=JSON.stringify(selectedBId)
    // }
    // (selectedBId)
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", businessId)
      .set("parent_business_id", parentBusinessId)
      .set("selectedBId", selectedBId)
      .set("date_filter", flag)
      .set("start_date", startdate)
      .set("end_date", enddate)
    // var parameter = {  
    //   "userId": userId,
    //   "password": password,
    //   "business_id": businessId,
    //   "parent_business_id": parentBusinessId,
    //   "selectedBId": selectedBId,
    //   "date_filter": flag,
    //   "start_date": startdate,
    //   "end_date": enddate,
    // };


    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    // let url = "";

    return this.http.post("https://crm.uengage.in/crm_api/dashboardData", params, httpOptions);
  }
  public getChildAcc(userId, password, bId, parentBId) {
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", bId)
      .set("parent_business_id", parentBId)

    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };

    return this.http.post("https://crm.uengage.in/crm_api/get/child/business", params, httpOptions);
  }
  public walletFlagDetails(userId, password, bId, parentBId) {
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", bId)
      .set("parent_business_id", parentBId)

    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };

    return this.http.post("https://crm.uengage.in/crm_api/get/wallet/flag", params, httpOptions);
  }

  public getModules(userId, password, bId, parentBId) {
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", bId)
      .set("parent_business_id", parentBId)

    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };

    return this.http.post("https://crm.uengage.in/crm_api/get/dashboard/modules", params, httpOptions);
  }
  public getSegments(userId, password, businessId, parentBusinessId, selectedBId) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
      }
    })
    // var parameter = JSON.stringify({
    //   userId: userId,
    //   password: password,
    //   business_id: businessId,
    //   parent_business_id: parentBusinessId
    //   // selectedBId: selectedBId
    // });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/segments/list";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     selectedBId: selectedBId
    //   }
    // })
    // return this.http.get('https://crm.uengage.in/segments/list', { params: params });
  }
  public getSenderDetails(userId, password, businessId, parentBusinessId) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
      }
    })
    // var parameter = JSON.stringify({
    //   userId: userId,
    //   password: password,
    //   business_id: businessId,
    //   parent_business_id: parentBusinessId
    //   // selectedBId: selectedBId
    // });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/sender/id";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     selectedBId: selectedBId
    //   }
    // })
    // return this.http.get('https://crm.uengage.in/segments/list', { params: params });
  }
  public getCount(userId, password, businessId, parentBusinessId, id: any, value: any, selected: any, type: any, stateString, startDate, endDate) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        segmentId: id,
        segmentValue: value,
        countData: "1",
        selectedBId: selected,
        cType: type,
        state: stateString,
        start_date: startDate,
        end_date: endDate
      }
    });
    return this.http.get('https://crm.uengage.in/crm_api/getSegmentDataCount', { params: params });
  }
  public sendText(userId, password, businessId, parentBusinessId, type: any, id: any, value: any, text: any, target: any, senderid, uengageid, bIds, header, amount, smsCost, skipCount, perc, state, startDate, endDate, camDraftId,custom_filter,custom_filter_data) {
    let currentdate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    let detailsParams: any = {
      userId: userId,
      password: password,
      business_id: businessId,
      parent_business_id: parentBusinessId,
      campaign_type: type,
      segment_id: id,
      segment_value: value,
      sms_text: text,
      target_audience: target,
      sender_id: senderid,
      // code: newHash,
      selected_b_id: bIds,
      segment_name: header,
      scheduled_at: currentdate,
      amount: amount,
      per_sms_price: smsCost,
      skip_count: skipCount,
      percentage_selected: perc,
      state: state,
      start_date: startDate,
      end_date: endDate,
      custom_filter:custom_filter,
      custom_filter_data:custom_filter_data,
      created_by:this.storage.get('userName')
    }

    if (camDraftId) {
      detailsParams = { ...detailsParams, 'draft_campaign_id': camDraftId }
    }
    let params = new HttpParams({
      fromObject: {
        ...detailsParams
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/addCampaigns";
    return this.http.post(url, params, httpOptions)
    // return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
  }
  public sendEmail(userId, password, businessId, parentBusinessId, type: any, id: any, value: any, content, time, audience, header, bIds, emailsubject, emailfrom, open, click, emailFrom, skipCount, perc) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        campaign_type: type,
        segment_id: id,
        segment_value: value,
        html_body: content,
        target_audience: audience,
        selected_b_id: bIds,
        segment_name: header,
        scheduled_at: time,
        from: emailfrom,
        subject: emailsubject,
        track_open: open,
        track_click: click,
        name: emailFrom,
        skip_count: skipCount,
        percentage_selected: perc
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/save/email/campaign";
    return this.http.post(url, params, httpOptions)
    // return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
  }
  public sendWhatsapp(userId, password, businessId, parentBusinessId, type: any, id: any, value: any, time, audience, header, bIds, body, head, lang, contactId, name, msg, header_text, skipCount, perc, state, startDate, endDate) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        campaign_type: type,
        segment_id: id,
        segment_value: value,
        target_audience: audience,
        selected_b_id: bIds,
        segment_name: header,
        scheduled_at: time,
        header: JSON.stringify(head),
        body: JSON.stringify(body),
        language: lang,
        phone_number_id: contactId,
        sender_id: name,
        whatsapp_text: msg,
        header_text: header_text,
        skip_count: skipCount,
        percentage_selected: perc,
        state: state,
        start_date: startDate,
        end_date: endDate,

        
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/create/whatsapp/campaign";
    return this.http.post(url, params, httpOptions)
    // return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
  }
  public sendWhatsappGupshup(userId, password, businessId, parentBusinessId, id: any, value: any, time, audience, header, bIds, body, image, skipCount, perc, textbody, footer, state, startDate, endDate, camDraftId, temp_type, promoData, animatedFlag,custom_filter,custom_filter_data,carousel_cards) {
    let paramsDetails: any = {
      userId: userId,
      password: password,
      business_id: businessId,
      parent_business_id: parentBusinessId,
      segment_id: id,
      segment_value: value,
      target_audience: audience,
      selected_b_id: bIds,
      segment_name: header,
      scheduled_at: time,
      media_data: JSON.stringify(image),
      template: JSON.stringify(body),
      skip_count: skipCount,
      percentage_selected: perc,
      whatsapp_text: JSON.stringify(textbody),
      whatsapp_footer: JSON.stringify(footer),
      state: state,
      start_date: startDate,
      end_date: endDate,
      template_type: temp_type,
      promoData: promoData,
      animated_campaign: animatedFlag,
      custom_filter:custom_filter,
      custom_filter_data:custom_filter_data,
      created_by:this.storage.get('userName'),
      cards: carousel_cards
    }
    if (camDraftId) {
      paramsDetails = { ...paramsDetails, 'draft_campaign_id': camDraftId }
    }
    let params = new HttpParams({
      fromObject: {
        ...paramsDetails
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/create/whatsapp/campaign";
    return this.http.post(url, params, httpOptions)
    // return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
  }
  public submitPush(userId, password, businessId, parentBusinessId, type: any, id: any, value: any,
    title: any, message: any, url: any, os: any, target, selected: any, header, state, startDate, endDate, tg_details,custom_filter,custom_filter_data) {
    let currentdate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        campaign_type: type,
        segment_id: id,
        segment_value: value,
        title: title,
        message: message,
        image: url,
        os: os,
        selected_b_id: selected,
        target_audience: target,
        segment_name: header,
        scheduled_at: currentdate,
        state: state,
        start_date: startDate,
        end_date: endDate,
        custom_filter:custom_filter,
        custom_filter_data:custom_filter_data,
        created_by:this.storage.get('userName')
      }
    });
    if (tg_details) {
      params = params.append("data", JSON.stringify(tg_details))
    }
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let apiurl = "https://crm.uengage.in/crm_api/addCampaigns";
    return this.http.post(apiurl, params, httpOptions)
  }
  public schedulePush(userId, password, businessId, parentBusinessId, type: any, id: any, value: any, title: any,
    message: any, url: any, os: any, scheduled_time: any, scheduled_date: any, target, selected: any, header, state, startDate, endDate, tg_details,custom_filter,custom_filter_data) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        campaign_type: type,
        segment_id: id,
        segment_value: value,
        title: title,
        message: message,
        image: url,
        os: os,
        selected_b_id: selected,
        target_audience: target,
        scheduled_at: scheduled_date,
        segment_name: header,
        state: state,
        start_date: startDate,
        end_date: endDate,
        custom_filter:custom_filter,
        custom_filter_data:custom_filter_data,
        created_by:this.storage.get('userName')
      }
    });
    if (tg_details) {
      params = params.append("data", JSON.stringify(tg_details))
    }
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let apiurl = "https://crm.uengage.in/crm_api/addCampaigns";
    return this.http.post(apiurl, params, httpOptions)
  }
  public pastCamp(userId, password, businessId, parentBusinessId, index: any, value: any, dateid, startdate, enddate, type, os) {
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", businessId)
      .set("parent_business_id", parentBusinessId)
      .set("page_count", index)
      .set("selected_b_id", value)
      .set("date_filter", dateid)
      .set("start_date", startdate)
      .set("end_date", enddate)
      .set("campaign_type", type)
      .set("os", os)
    // var parameter = {  
    //   "userId": userId,
    //   "password": password,
    //   "business_id": businessId,
    //   "parent_business_id": parentBusinessId,
    //   "selectedBId": selectedBId,
    //   "date_filter": flag,
    //   "start_date": startdate,
    //   "end_date": enddate,
    // };


    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    // var parameter = JSON.stringify({
    //   userId: userId,
    //   password: password,
    //   business_id: businessId,
    //   parent_business_id: parentBusinessId,
    //   page_count: index,

    // });
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   }),
    // };
    let url = "https://crm.uengage.in/crm_api/getPastCampaigns";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     page: index,
    //     limit: "20",
    //     selectedBId: value
    //   }
    // })
    // return this.http.get('https://crm.uengage.in/segments/getPastCampaigns', { params: params });
  }
  public filter(userId, password, businessId, parentBusinessId, mobileno) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        mobile_number: mobileno
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/getTopCustomers";
    return this.http.post(url, params, httpOptions)
    // return this.http.get('https://crm.uengage.in/get/customer/detail', { params: params });
  }
  public totalUser() {
    this.total = 1;
  }
  // public setBrandColor(color){
  //   this.brandColor = color
  //   (this.brandColor)
  // }
  // public allowPush(flag) {
  //   this.allowPushFlag = flag;
  //   // (this.allowPushFlag)
  // }
  public isPremium(flag) {
    this.permiumFlag = flag;
    // (this.allowPushFlag)
  }
  public activeUser(state) {
    this.active = 1;
    this.state = state
  }
  public lostUser() {
    this.lost = 1;
  }
  public decodeRecentSms(userId, password, businessId, parentBusinessId) {
    var params = new HttpParams()
      .set("userId", userId)
      .set("password", password)
      .set("business_id", businessId)
      .set("parent_business_id", parentBusinessId)
    // var parameter = {  
    //   "userId": userId,
    //   "password": password,
    //   "business_id": businessId,
    //   "parent_business_id": parentBusinessId,
    //   "selectedBId": selectedBId,
    //   "date_filter": flag,
    //   "start_date": startdate,
    //   "end_date": enddate,
    // };


    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    // var parameter = JSON.stringify({
    //   userId: userId,
    //   password: password,
    //   business_id: businessId,
    //   parent_business_id: parentBusinessId,
    //   page_count: index,

    // });
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   }),
    // };
    let url = "https://crm.uengage.in/crm_api/get/past/sms/campaigns";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     page: index,
    //     limit: "20",
    //     selectedBId: value
    //   }
    // })
    // return this.http.get('https://crm.uengage.in/segments/getPastCampaigns', { params: params });
  }
  // public recentOrder(){
  //   this.recent=1;
  // }
  public newUser() {
    this.newUserFlag = 1;
  }
  public scheduleCamp(userId, password, businessId, parentBusinessId, type: any, id: any, value: any, text: any, dateTime: any, target, senderid, uengageid, bIds, header, amount, smsCost, skipCount, perc, state, startDate, endDate, camDraftId,custom_filter,custom_filter_data) {
    // var hash = require('object-hash');
    // // let payload={
    // //   target_audience : target
    // // }
    // // var res = sign(payload,'uengage@shoulto',this.signOptions)
    // // (res)
    // let string=target+'@uengage435';
    // let newHash=hash(string, { algorithm: 'md5', encoding: 'base64' });
    // (string);
    // (newHash);
    let paramsDetails: any = {
      userId: userId,
      password: password,
      business_id: businessId,
      parent_business_id: parentBusinessId,
      campaign_type: type,
      segment_id: id,
      segment_value: value,
      sms_text: text,
      target_audience: target,
      sender_id: senderid,
      // code: newHash,
      selected_b_id: bIds,
      segment_name: header,
      scheduled_at: dateTime,
      amount: amount,
      per_sms_price: smsCost,
      skip_count: skipCount,
      percentage_selected: perc,
      state: state,
      start_date: startDate,
      end_date: endDate,
      custom_filter:custom_filter,
      custom_filter_data:custom_filter_data,
      created_by:this.storage.get('userName')
    }
    if (camDraftId) {
      paramsDetails = { ...paramsDetails, 'draft_campaign_id': camDraftId }
    }


    let params = new HttpParams({
      fromObject: {
        ...paramsDetails
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/addCampaigns";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     campaignType: type,
    //     segmentId: id,
    //     segmentValue: value,
    //     sms_text: text,
    //     scheduled_at: dateTime,
    //     target_audience: target,
    //     senderId: senderid,
    //     uengageId: uengageid
    //   }
    // });
    // return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
  }
  public populateValue(camp: any) {
    return [
      this.segmentId = camp.segmentId,
      this.segmentName = camp.segmentName,
      this.type = camp.campaignType,
      this.text = camp.sms_text,
      this.campaignValue = camp.segmentValue,
      this.message = camp.message,
      this.title = camp.title,
      this.image = camp.image,
      this.os = camp.os,
      this.prePOP = 1
    ]
  }
  public populateWhatsAppValue(camp) {
    return [
      this.campDetails = camp,
      this.prePOPWhatsapp = 1
    ]
  }
  public populateEmailValue(camp) {
    return [
      this.campDetails = camp,
      this.prePOPEmail = 1
    ]
  }
  public getIndexDetail(data: any) {
    this.indexDetails = data;
  }
  public getCustomerData(data: any) {
    return this.customerData = data
  }
  public getReviews(userId, password, businessId, parentBusinessId, value, date, rating, mobileNo) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        page_count: value,
        month: date,
        rating: rating,
        mobile_number: mobileNo
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/rating/reviews";
    return this.http.post(url, params, httpOptions)
    // let params = new HttpParams({
    //   fromObject: {
    //     userId: userId,
    //     password: password,
    //     businessId: businessId,
    //     parentBusinessId: parentBusinessId,
    //     page: value,
    //     limit: "25"
    //   }
    // })
    // return this.http.get('https://crm.uengage.in/segments/getRatingOrders', { params: params });
  }
  public sendMsg(userId, password, businessId, email, message) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        businessId: businessId,
        email: email,
        amount: message
      }
    })
    return this.http.get('https://crm.uengage.in/segments/contactus', { params: params });
  }
  public filteredRatingData(userId, password, businessId, name, number, rating, date) {
    let params = new HttpParams({
      fromObject: {
        user_id: userId,
        password: password,
        business_id: businessId,
        parent_business_id: name,
        mobile_number: number,
        rating: rating,
        month: date
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/rating/reviews";
    return this.http.post(url, params, httpOptions)
  }
  public customerReview(userId, password, businessId, parentBusinessId, number) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        businessId: businessId,
        parentBusinessId: parentBusinessId,
        mobileNo: number
      }
    })
    return this.http.get('https://crm.uengage.in/segments/getCustomerOrder', { params: params })
  }
  public Sales(userId, password, businessId, month, year, page_count) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        businessId: businessId,
        month: month,
        year: year,
        page_count: page_count
      }
    })
    let url = "https://api.uengage.in/addoapi/salesAdmin";
    return this.http.get(url, { params: params })
  }
  public customerDetails(data, flag) {
    return [
      this.requestData = data,
      this.flag = flag
    ]
  }
  public siteLeave(flag) {
    return [
      this.leave = flag
    ]
  }
  public pFlag(flag) {
    return [
      this.productFlag = flag
    ]
  }
  public customerFlag(flag) {
    return [
      this.customerflag = flag
    ]
  }
  public ReviewDetailsData(userId, password, businessId, parentBusinessId, orderId, bId) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: bId,
        parent_business_id: parentBusinessId,
        order_id: orderId,
        child_business_id: businessId
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/order/details";
    return this.http.post(url, params, httpOptions)
  }
  public salesAdmin(userId, password, businessId) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        businessId: businessId,
      }
    })
    let url = "https://api.uengage.in/addoapi/salesAdmin_agg";
    return this.http.get(url, { params: params })
  }
  public ReviewDateSet(userId, password, businessId, parentBusinessId, month) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        month: month
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/rating/mis";
    return this.http.post(url, params, httpOptions)
  }
  public addBalanceSet(userId, password, businessId, parentBusinessId, amount, description, invoice) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        amount: amount,
        description: description,
        invoice_generated: invoice,
        child_business_id: businessId
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/add/wallet/balance";
    return this.http.post(url, params, httpOptions)
  }
  public ledgerSet(userId, password, businessId, parentBusinessId, pageCount) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId,
        page_count: pageCount
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/wallet/transaction/history";
    return this.http.post(url, params, httpOptions)
  }


  public walletDetails(userId, password, businessId, parentBusinessId) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        parent_business_id: parentBusinessId
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/wallet/balance";
    return this.http.post(url, params, httpOptions)
  }
  public reportpastCamp(userId, password, businessId, campaign_id) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        campaign_id: campaign_id
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/campaign/details";
    return this.http.post(url, params, httpOptions)
  }
  public reportpastCamp2(userId, password, businessId, campaign_id, mobileNo, page) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        campaign_id: campaign_id,
        mobile_number: mobileNo,
        page_count: page
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/sms/summary";
    return this.http.post(url, params, httpOptions)
  }
  public reportpastCamp3(userId, password, businessId, campaign_id, mobile_number) {
    let params = new HttpParams({
      fromObject: {
        userId: userId,
        password: password,
        business_id: businessId,
        campaign_id: campaign_id,
        mobile_number: mobile_number
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/sms/summary";
    return this.http.post(url, params, httpOptions)
  }

  // public callAsideComponent(){
  //   ("hani")
  //   this.aside.ngOnInit()
  // }


  isFalsy(value) {
    // Check for null, undefined, "0", 0, "", false
    if (value === null ||
      value === undefined ||
      value === "undefined" ||
      value === "null" ||
      value === "0" ||
      value === 0 ||
      value === "" ||
      value === false) {
      return true;
    }

    // Check for empty arrays
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    // Check for empty objects
    if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
      return true;
    }

    // If none of the above conditions are met, return false
    return false;
  }

}


// import { Injectable } from '@angular/core';
// import { HttpClient, HttpParams } from '@angular/common/http';
// import { selectLastCreatedUserId } from './core/auth';
// import emailAddress from 'src/assets/plugins/formvalidation/src/js/validators/emailAddress';

// @Injectable({
//   providedIn: 'root'
// })
// export class ApiService {
//   lost: number;
//   new: number;
//   recent: number;
//   requestData:any=[];
//   flag: any;
//   leave=0;
//   message: any;
//   title: any;
//   image: any;
//   os: any;
//   newUserFlag: number;
//   constructor(private http: HttpClient) { }
//   segmentName:any;
//   campaignValue:any='';
//   text:any;
//   type:any;
//   segmentId:any;
//   prePOP:any;
//   total:any;
//   active:any;
//   indexDetails:any;
//   customerReport:any='';
//   // public getData(value:any) {

//   //   return this.http.get('https://crm.uengage.in/segments/dashboardData/4580');
//   // }
//   public getStoreDetails(selectedBId:any,userId,password,businessId,parentBusinessId,flag,startdate,enddate){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         selectedBId:selectedBId,
//         date_filter:flag,
//         start_date:startdate,
//         end_date:enddate
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/dashboardData',{params:params})
//   }
//   public getSegments(userId,password,businessId,parentBusinessId,selectedBId) {
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         selectedBId:selectedBId
//     }
//   })
//     return this.http.get('https://crm.uengage.in/segments/list',{params:params});
//   }
//   public getCount(userId,password,businessId,parentBusinessId,id: any, value: any,selected:any,type:any) {
//     let params = new HttpParams({
//       fromObject: {
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         segmentId: id,
//         segmentValue: value,
//         countData: "1",
//         selectedBId:selected,
//         cType:type
//       }
//     });
//     return this.http.get('https://crm.uengage.in/segments/getSegmentDataCount', { params: params });
//   }
//   public sendText(userId,password,businessId,parentBusinessId,type: any, id: any, value: any, text: any,target,senderid,uengageid) {
//     let params = new HttpParams({
//       fromObject: {
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         campaignType: type,
//         segmentId: id,
//         segmentValue: value,
//         sms_text: text,
//         target_audience:target,
//         senderId:senderid,
//         uengageId:uengageid
//       }
//     });
//     return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
//   }
//   public submitPush(userId,password,businessId,parentBusinessId,type: any, id: any, value: any,
//     title: any,message:any,url:any,os:any,target, selected:any) {
//     let params = new HttpParams({
//       fromObject: {
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         campaignType: type,
//         segmentId: id,
//         segmentValue: value,
//         title: title,
//         message:message,
//         image_url:url,
//         os:os,
//         selectedBId:selected,
//         target_audience:target
//       }
//     });
//     return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
//   }
//  public schedulePush(userId,password,businessId,parentBusinessId,type: any, id: any, value: any, title: any,
//   message:any,url:any,os:any,scheduled_time:any,scheduled_date:any,target,selected:any){
//   let params = new HttpParams({
//     fromObject: {
//       userId:userId,
//       password:password,
//       businessId:businessId,
//       parentBusinessId:parentBusinessId,
//       campaignType: type,
//       segmentId: id,
//       segmentValue: value,
//       title: title,
//       message:message,
//       image_url:url,
//       os:os,
//       selectedBId:selected,
//       scheduled_time:scheduled_time,
//       scheduled_date:scheduled_date,
//       target_audience:target
//     }
//   });
//   return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
//  }
//   public pastCamp(userId,password,businessId,parentBusinessId,index:any,value:any) {
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         page:index,
//         limit:"20",
//         selectedBId:value
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/getPastCampaigns',{params:params});
//   }
//   public filter(userId,password,businessId,parentBusinessId,mobileno){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId: businessId,
//         parentBusinessId: parentBusinessId,
//         mobileNo:mobileno
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/searchCustomer',{params:params});
//   }
//   public totalUser(){
//       this.total=1;
//   }
//   public activeUser(){
//     this.active=1;
//   }
//   public lostUser(){
//     this.lost=1;
//   }
//   // public recentOrder(){
//   //   this.recent=1;
//   // }
//   public newUser(){
//     this.newUserFlag=1;
//   }
//   public scheduleCamp(userId,password,businessId,parentBusinessId,type: any, id: any, value: any,text:any, dateTime:any,target,senderid,uengageid) {
//     let params = new HttpParams({
//       fromObject: {
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         campaignType: type,
//         segmentId: id,
//         segmentValue: value,
//         sms_text: text,
//         scheduled_at: dateTime,
//         target_audience:target,
//         senderId:senderid,
//         uengageId:uengageid
//       }
//     });
//     return this.http.get('https://crm.uengage.in/segments/addCampaigns', { params: params });
//   }
//   public populateValue(camp:any){
//     return [
//     this.segmentId=camp.segmentId,
//     this.segmentName=camp.segmentName,
//     this.type=camp.campaignType,
//     this.text=camp.sms_text,
//     this.campaignValue=camp.segmentValue,
//     this.message=camp.message,
//     this.title= camp.titile,
//     this.image=camp.image,
//     this.os=camp.os,
//     this.prePOP=1
//     ]
//   }
//   public topCustomer(userId,password,businessId,parentBusinessId,page='0',limit='20'){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId: businessId,
//         parentBusinessId: parentBusinessId,
//         page : page,
//         limit : limit
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/getTopCustomers',{ params: params });
//   }
//  public getIndexDetail(data:any){
//    this.indexDetails=data;
//   }
//   public getReviews(userId,password,businessId,parentBusinessId,value){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         page:value,
//         limit:"25"
//     }
//   })
//      return this.http.get('https://crm.uengage.in/segments/getRatingOrders',{params:params});
//   }
//   public sendMsg(userId,password,businessId,email,message){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         email:email,
//         amount:message
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/contactus',{params:params});
//   }
//   public filteredRatingData(userId,password,businessId,name,number,rating){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:name,
//         mobileNo:number,
//         rating:rating
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/searchRatingOrder',{params:params});
//   }
//   public customerReview(userId,password,businessId,parentBusinessId,number){
//     let params = new HttpParams({
//       fromObject:{
//         userId:userId,
//         password:password,
//         businessId:businessId,
//         parentBusinessId:parentBusinessId,
//         mobileNo:number
//       }
//     })
//     return this.http.get('https://crm.uengage.in/segments/getCustomerOrder',{params:params})
//   }
//   public customerDetails(data,flag){
//     return [
//      this.requestData=data,
//      this.flag=flag
//     ]
//   }
//   public siteLeave(flag){
//     return [
//       this.leave = flag
//     ]
//   }
// }
