<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;">
  <div class="col-6">
    <img src="../../../../../assets/media/logos/logo-sm.png" style="width: 12em;">
  </div>
</nav> -->
<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0" style="height: 5em;" *ngIf="bName!=undefined">
  <div class="col-3">
    <img src="../../../../../assets/media/logos/ue-logonew.jpg" style="width: 12em;">
  </div>
</nav>
<div class="d-flex flex-column-fluid">
  <div class="container">
    <div class="card card-custom gutter-b mt-5 mb-5">
      <div class="card-body">
        <!--begin: Wizard -->
        <div #wizard class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first">
          <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10 d-none d-sm-block"
            style="position: relative;">
            <img src="../../../../../assets/media/misc/UE.png" style="width: 11em;
            position: absolute;
            top: 0;">
            <!--begin: Form Wizard Nav -->
            <div class="wizard-steps"
              style="background-color: #fff; z-index: 1; margin-top: 103px; position: relative;">
              <a class="wizard-step" href="javascript:;" data-wizard-type="step" data-wizard-state="current"
                [ngClass]="{'changeBg':productSelected==1}">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span>
                      <i class="flaticon-squares-4" style="color: #568b17;font-size: 1em;"></i>
                    </span>
                  </div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      Select Product</div>
                    <div class="wizard-desc" *ngIf="productSelected==1">
                      <span *ngIf="selectedProduct==1" class="product">Uengage Edge</span>
                      <span *ngIf="selectedProduct==2" class="product">Uengage Prism</span>
                      <span *ngIf="selectedProduct==3" class="product">Uengage Flash</span>
                    </div>
                  </div>
                </div>
              </a>
              <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step"
                [ngClass]="{'changeBg':industrySelected==1}">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span>
                      <i class="flaticon-buildings" style="color: #568b17;font-size: 1em;"></i>
                    </span>
                  </div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      Industry Type</div>
                    <div class="wizard-desc" *ngIf="industrySelected==1">
                      <span class="industry">{{industryName}}</span>
                    </div>
                  </div>
                </div>
              </a>
              <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step"
                [ngClass]="{'changeBg':bDetails==1}">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span>
                      <i class="flaticon-list" style="color: #568b17;font-size: 1em;"></i>
                    </span>
                  </div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      Business Information</div>
                    <div class="wizard-desc" *ngIf="bDetails==1">
                      <span class="information"></span>
                    </div>
                  </div>
                </div>
              </a>
              <a class="wizard-step mt-2" href="javascript:;" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span>
                      <i class="flaticon-email" style="color: #568b17;font-size: 1em;"></i>
                    </span>
                  </div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      Email Confirmation</div>
                    <!-- <div class="wizard-desc">Address, Street, City, State</div> -->
                  </div>
                </div>
              </a>
              <!-- <a class="wizard-step" href="javascript:;" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span
                            class="svg-icon svg-icon-3x"
                          ></span>
                        </div>
                        <div class="wizard-label">
                          <div class="wizard-title">Make Payment</div>
                          <div class="wizard-desc">Use Credit or Debit Cards</div>
                        </div>
                      </div>
                    </a> -->
              <!-- <a class="wizard-step" href="javascript:;" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span
                            class="svg-icon svg-icon-3x"
                          ></span>
                        </div>
                        <div class="wizard-label">
                          <div class="wizard-title">Completed!</div>
                          <div class="wizard-desc">Review and Submit</div>
                        </div>
                      </div>
                    </a> -->
            </div>
            <!--end: Form Wizard Nav -->
          </div>
          <div class="wizard-body py-8 px-8">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="offset-xxl-2 col-xxl-8">
                <!--begin: Form Wizard Form-->
                <form class="form" id="kt_form">
                  <!--begin: Form Wizard Step 1-->
                  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <h5 class="font-weight-bold text-dark">
                      Step 1:
                    </h5>
                    <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                      What Product Of Services Are You Looking For?
                    </h4>
                    <div class="kt-form__section kt-form__section--first">
                      <div class="row mt-3 p-2" *ngFor="let data of productList;let i=index"
                        style="background-color: white;border-radius: 5px;box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);cursor: pointer;"
                        (click)="selectProduct(data.id)" [ngClass]="{'selectedDiv': selectedProduct==data.id}">
                        <span [ngClass]="{'check-selected': selectedProduct==data.id}" class="check-nonselected">
                          <span [ngClass]="{'check-position-data': selectedProduct==data.id}"><i class="fas fa-check"
                              style="color: #fff;
                            size: 68%;
                            font-size: 100%;
                            padding: 4px;"></i></span>
                        </span>
                        <div class="row m-0">
                          <div class="col-md-2 col-3">
                            <img src="{{data.icon}}" style="width: 5em;">
                          </div>
                          <div class="col-md-10 col-9">
                            <span class="font-weight-bold text-dark" style="font-size: 17px">
                              {{data.title}}
                            </span>
                            <p class="text-dark">
                              {{data.short_description}}
                            </p>
                          </div>
                        </div>
                        <!-- <div class="col-md-2">
                          <img src="{{data.icon}}" style="width: 5em;">
                        </div>
                        <div class="col-md-10">
                          <span class="font-weight-bold text-dark" style="font-size: 17px">
                            {{data.title}}
                          </span>
                          <p class="text-dark">
                            {{data.short_description}}
                          </p>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 1-->

                  <!--begin: Form Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h5 class="font-weight-bold text-dark">
                      Step 2:
                    </h5>
                    <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                      What Is Your Industry Type?
                    </h4>
                    <div class="row mt-3">
                      <div class="col-md-6 mt-5" *ngFor="let data of industryData;let i = index">
                        <div class="p-2 row m-0"
                          style="background-color: white;border-radius: 5px;box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);cursor: pointer;"
                          (click)="selectIndustry(data.category_id,data.industry_name,data.id)"
                          [ngClass]="{'selectedIndustryDiv': industryId==data.id}">
                          <span [ngClass]="{'check-selected1': industryId==data.id}" class="check-nonselected1">
                            <span [ngClass]="{'check-position-data1': industryId==data.id}"><i class="fas fa-check"
                                style="color: #fff;
                              size: 68%;
                              font-size: 100%;
                              padding: 4px;"></i></span>
                          </span>
                          <div class="row m-0 w-100">
                            <div class="col-md-3 col-3">
                              <img src="../../../../../assets/media/logos/brewry-&-cafe.png" style="width: 4em;">
                            </div>
                            <div class="col-md-9 mt-4 col-9">
                              <span class="font-weight-bold text-dark" style="font-size: 17px">
                                {{data.industry_name}}
                              </span>
                            </div>
                          </div>
                          <!-- <div class="col-md-3">
                            <img src="../../../../../assets/media/logos/brewry-&-cafe.png" style="width: 4em;">
                          </div>
                          <div class="col-md-9 mt-4">
                            <span class="font-weight-bold text-dark" style="font-size: 17px">
                              {{data.industry_name}}
                            </span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 m-0" *ngIf="showtextArea==1">
                      <div class="form-group fv-plugins-icon-container w-100">
                        <!-- <label class="font-size-h6 font-weight-bold text-dark control-label"></label> -->
                        <input type="text" class="form-control form-control-solid form-control-lg"
                          placeholder="Industry Type" value="" [(ngModel)]="industryType" name="type">
                        <span class="form-text text-muted">Please enter your Industry Type.</span>
                        <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                      <div *ngIf="a.firstName.errors.required">Field is required</div>
                                  </div> -->
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 2-->

                  <!--begin: Form Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h5 class="font-weight-bold text-dark">
                      Step 3:
                    </h5>
                    <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                      Provide Your Business Information.
                    </h4>
                    <div class="row w-100">
                      <div class="col-xl-6 col-12">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">Business/Brand
                            Name</label>
                          <input type="text" class="form-control form-control-solid form-control-lg"
                            placeholder="Enter business name" value="" [(ngModel)]="bName" name="bName">
                          <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="a.firstName.errors.required">Field is required</div>
                                    </div> -->
                        </div>
                      </div>
                      <div class="col-xl-6 col-12">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">Business Contact
                            Number</label>
                          <input type="text" class="form-control form-control-solid form-control-lg" maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="Enter business number"
                            value="" [(ngModel)]="bNumber" name="bNumber">
                          <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="a.firstName.errors.required">Field is required</div>
                                    </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row w-100 mt-3">
                      <div class="col-xl-6 col-12">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">Merchant Contact
                            Number</label>
                          <input type="text" class="form-control form-control-solid form-control-lg" maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="Enter merchant number"
                            value="" [(ngModel)]="merchantNumber" name="merchantNumber">
                          <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="a.firstName.errors.required">Field is required</div>
                                    </div> -->
                        </div>
                      </div>
                      <div class="col-xl-6 col-12">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">City</label>
                          <select class="form-control form-control-solid form-control-lg" [(ngModel)]="city"
                            (change)="getState()" name="city">
                            <option value="" selected>--Select--</option>
                            <option *ngFor="let data of cityData" value="{{data.cityName}}">{{data.cityName}}</option>
                          </select>
                          <!-- <input type="text" class="form-control form-control-solid form-control-lg"
                            placeholder="Enter city name" value="" [(ngModel)]="city" name="city"> -->
                          <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="a.firstName.errors.required">Field is required</div>
                                    </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row w-100 mt-3">
                      <div class="col-xl-6 col-12">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">State</label>
                          <input type="text" class="form-control form-control-solid form-control-lg"
                            placeholder="Enter state name" value="" [(ngModel)]="state" name="state" readonly>
                          <!-- <div *ngIf="submitted && a.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="a.firstName.errors.required">Field is required</div>
                                    </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 3-->

                  <!--begin: Form Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content" style="padding-bottom: 5.5rem !important;">
                    <h5 class="font-weight-bold text-dark">
                      Step 4:
                    </h5>
                    <h4 class="font-weight-bold mb-5" style="color: #568b17;">
                      Create Your Account
                    </h4>
                    <div class="form-group m-0" *ngIf="showEmailLogin!=1">
                      <div class="row">
                        <div class="col-md-6 step-two-divide">
                          <a class="social-btn facebook-btn" (click)="signInWithFB()" style="cursor: pointer;">
                            <img src="../../../../../assets/media/logos/facebook-icon.png" alt="Facebook Icon">Log in
                            with Facebook
                          </a>
                        </div>
                        <div class="col-md-6 step-two-divide">
                          <a style="cursor: pointer;" class="social-btn" (click)="signInWithGoogle()">
                            <img src="../../../../../assets/media/logos/google-icon.png" alt="Google Icon">Log in with
                            Google
                          </a>
                        </div>
                      </div>
                      <div class="social-or"><span>or</span></div>

                      <a class="login-email mt-5" (click)="emailLogin()" style="cursor: pointer;">Login With Email</a>

                      <p>By signing up you agree to our <a>Terms of Service</a> and <a>Privacy
                          Policy</a></p>
                    </div>
                    <div class="form-group m-0" *ngIf="showEmailLogin==1 && otpCorrect!=1">
                      <form [formGroup]="accountForm" (ngSubmit)="check()">
                        <div class="form-group fv-plugins-icon-container">
                          <label class="font-size-h6 font-weight-bold text-dark control-label">Email
                            Id</label>
                          <input type="text" [readonly]="otpValidate==1" (focusout)="trimValue()"
                            class="form-control form-control-solid form-control-lg" placeholder="Email Id" value=""
                            formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && a.emailId.errors }">
                          <div *ngIf="submitted && a.emailId.errors" class="invalid-feedback">
                            <div *ngIf="a.emailId.errors.required">Field is required</div>
                            <div *ngIf="a.emailId.errors.pattern">Enter valid email id</div>
                          </div>
                        </div>
                        <!-- <div class="form-group fv-plugins-icon-container">
                        <label
                            class="font-size-h6 font-weight-bold text-dark control-label">Password</label>
                        <input type="password"
                            class="form-control form-control-solid form-control-lg"
                            placeholder="Password" value="" formControlName="password"
                            [ngClass]="{ 'is-invalid': submitted && a.password.errors }">
                        <div *ngIf="submitted && a.password.errors" class="invalid-feedback">
                            <div *ngIf="a.password.errors.required">Field is required</div>
                        </div>
                    </div> -->
                        <div style="float: right;" *ngIf="otpValidate!=1">
                          <button type="submit"
                            style="background-color: #568b17 !important;border-color: #568b17 !important;"
                            class="btn btn-primary font-weight-bold font-size-h6 pl-8 pr-8 py-4 my-3">Send OTP
                          </button>
                        </div>
                      </form>
                    </div>
                    <form [formGroup]="otpForm" *ngIf="otpValidate==1 && otpCorrect!=1">
                      <div class="row w-100 m-0 justify-content-end mt-10">
                        <span class="form-text">Didn't Recieve The OTP?
                          <a class="font-weight-bolder" (click)="reqAgain()"
                            style="cursor: pointer;color: #568b17;">Request Again</a></span>
                      </div>
                      <div class="row input-icon input-icon-right w-100 m-0" style="margin-top: 3em;">
                        <input type="text" class="form-control form-control-solid form-control-lg"
                          placeholder="Enter OTP" value="" formControlName="otp"
                          [ngClass]="{ 'is-invalid': submitted1 && o.otp.errors }" />
                        <div *ngIf="submitted1 && o.otp.errors" class="invalid-feedback">
                          <div *ngIf="o.otp.errors.required">Field is required</div>
                        </div>
                      </div>
                      <div class="row w-100 m-0 justify-content-end mt-2">
                        <button
                          style="background-color: #568b17 !important;border-color: #568b17 !important;color: white;"
                          (click)="checkotp()" class="btn">Validate</button>
                      </div>
                    </form>
                    <div *ngIf="otpCorrect==1">
                      <span style="color: #568b17;background-color: #d1efae;"
                        class="label label-lg label-light-danger label-inline font-weight-bold py-5">
                        Congratulations! Your email has been verified successfully.</span><br>
                      <span style="font-size:1em ;">Please provide details to setup your account.</span>
                      <form [formGroup]="companyDetailForm" (ngSubmit)="createAccount()" autocomplete="off">
                        <div class="row w-100">
                          <div class="col-xl-6">
                            <div class="form-group fv-plugins-icon-container">
                              <label class="font-size-h6 font-weight-bold text-dark control-label">First Name</label>
                              <input type="text" class="form-control form-control-solid form-control-lg"
                                placeholder="First Name" value="" formControlName="firstName" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted3 && c.firstName.errors }">
                              <div *ngIf="submitted3 && c.firstName.errors" class="invalid-feedback">
                                <div *ngIf="c.firstName.errors.required">Field is required</div>
                                <!-- <div *ngIf="c.firstName.errors.pattern">Enter valid email id</div> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group fv-plugins-icon-container">
                              <label class="font-size-h6 font-weight-bold text-dark control-label">Last Name</label>
                              <input type="text" class="form-control form-control-solid form-control-lg"
                                placeholder="Last Name" value="" formControlName="lastName" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted3 && c.lastName.errors }">
                              <div *ngIf="submitted3 && c.lastName.errors" class="invalid-feedback">
                                <div *ngIf="c.lastName.errors.required">Field is required</div>
                                <!-- <div *ngIf="c.lastName.errors.pattern">Enter valid email id</div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row w-100">
                          <div class="col-xl-6">
                            <div class="form-group fv-plugins-icon-container">
                              <label class="font-size-h6 font-weight-bold text-dark control-label">Password</label>
                              <input type="password" class="form-control form-control-solid form-control-lg"
                                placeholder="Password" value="" formControlName="accPassword" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted3 && c.accPassword.errors }">
                              <div *ngIf="submitted3 && c.accPassword.errors" class="invalid-feedback">
                                <div *ngIf="c.accPassword.errors.required">Field is required</div>
                                <!-- <div *ngIf="c.firstName.errors.pattern">Enter valid email id</div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="float: right;">
                          <button type="submit"
                            style="background-color: #568b17 !important;border-color: #568b17 !important;"
                            class="btn btn-primary font-weight-bold font-size-h6 pl-8 pr-8 py-4 my-3">Create Account
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 4-->

                  <!--begin: Form Actions -->
                  <div class="d-flex justify-content-between border-top pt-10 mobileBox" *ngIf="showEmailLogin!=1">
                    <div class="mr-2">
                      <button type="button"
                        style="background-color: #c0e495 !important;color: #568b17 !important;border-color: #c0e495 !important;"
                        class="btn btn-info font-weight-bold font-size-h6 pl-6 pr-8 py-4 my-3 mr-3 btn-sm"
                        data-wizard-type="action-prev">
                        <span class="svg-icon svg-icon-md mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <rect fill="#568b17" opacity="0.3"
                                transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                x="14" y="7" width="2" height="10" rx="1"></rect>
                              <path
                                d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                fill="#000000" fill-rule="nonzero"
                                transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)">
                              </path>
                            </g>
                          </svg>
                        </span>Previous</button>
                      <!-- <div
                              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-prev" style="background-color: #c0e495 !important;"
                            >
                              Previous
                            </div> -->
                    </div>
                    <div>
                      <div class="btn btn-success font-weight-bold text-uppercase px-9 py-4 btn-sm"
                        data-wizard-type="action-submit">
                        Submit
                      </div>
                      <!-- <div style="background-color: #568b17 !important;"
                              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-next"
                            >
                              Next Step
                            </div> -->
                      <button type="submit" style="float: right;" data-wizard-type="action-next"
                        style="background-color: #568b17 !important;border-color: #568b17 !important;"
                        (click)="checkStep()"
                        class="btn btn-info font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3 btn-sm">Next Step
                        <span class="svg-icon svg-icon-md ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <rect fill="#000000" opacity="0.3"
                                transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                x="7.5" y="7.5" width="2" height="9" rx="1"></rect>
                              <path
                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                fill="#000000" fill-rule="nonzero"
                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                              </path>
                            </g>
                          </svg>
                        </span></button>
                      <button type="submit" style="float: right;" data-wizard-type="action-next"
                        style="background-color: #568b17 !important;border-color: #568b17 !important;"
                        (click)="checkStep()" *ngIf="showtextArea==1"
                        class="btn btn-info font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3 btn-sm">Next Step
                        <span class="svg-icon svg-icon-md ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <rect fill="#000000" opacity="0.3"
                                transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                x="7.5" y="7.5" width="2" height="9" rx="1"></rect>
                              <path
                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                fill="#000000" fill-rule="nonzero"
                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                              </path>
                            </g>
                          </svg>
                        </span></button>
                    </div>
                  </div>
                  <!--end: Form Actions -->
                  <div *ngIf="showEmailLogin==1">
                    <div class="social-or"><span>or</span></div>
                    <div class="row w-100 m-0 justify-content-center">
                      <a (click)="anotherMethod()" style="cursor: pointer;color: #568b17;">Sign Up With Another
                        Method</a>
                    </div>
                    <!-- <div class="mr-2">
                      <button type="button"
                        style="background-color: #c0e495 !important;color: #568b17 !important;border-color: #c0e495 !important;"
                        class="btn btn-info font-weight-bold font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                        (click)="anotherMethod()">
                        <span class="svg-icon svg-icon-md mr-1">
                        </span>Sign Up With Another Method</button>
                    </div> -->
                  </div>
                </form>
                <!--end: Form Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard -->
      </div>
    </div>
  </div>
</div>

<!--  -->