import { Injectable, Inject } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
// import { Observable } from "rxjs";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.storage.get("selectedModule") != null &&
      this.storage.get("selectedModule").length > 0 &&
      this.storage.get("userId") != null
    ) {
      let product = this.storage.get("products")
      let desc = this.storage.get("desc")
      if(desc =='Online Orders'){
        let path = "Dashboard/" + this.storage.get("selectedModule")[0].page+this.storage.get("selectedBusinessId");
        this.router.navigate([path]);
      } else {
         if(product==2){
          let path = "prism/" + this.storage.get("selectedModule")[0].page
          this.router.navigate([path]);
         } else if(product==3){
          let path = "flash/" + this.storage.get("selectedModule")[0].page
          this.router.navigate([path]);
         } else if(product==4){
          let path = "admin/" + this.storage.get("selectedModule")[0].page
          this.router.navigate([path]);
         } else {
          let path = "edge/" + this.storage.get("selectedModule")[0].page
          this.router.navigate([path]);
         }
      }
      return false;
    } else if (
      this.storage.get("selectedModule") == null &&
      this.storage.get("userId") != null && this.storage.get("productId") == null
    ) {
      ("1")
      this.router.navigate(["businessSelector"]);
      return false;
    } else if (
      (this.storage.get("selectedModule") == undefined ||
        this.storage.get("selectedModule").length == 0) &&
      this.storage.get("userId") != null && this.storage.get("productId") == null
    ) {
      ("2")
      this.router.navigate(["businessSelector"]);
      return false;
    }
    return true;
  }
}
