import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { io, Socket } from "socket.io-client";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket: Socket
  socketId: string;
  baseURL = environment.base_api_crm;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    // console.log("here")
    // this.socket = io('http://localhost:5000', {
    //   withCredentials: true
    // });
    // this.socket.on('connect' , () => {
    //   // console.log("connected")
    //   // console.log(this.socket.id);
    // });
  }
  // connect() {
  //   this.socket = io('http://192.168.1.12:3000', {
  //     withCredentials: true
  //   });
  //   this.socket.on('connect', () => {
  //     console.log("connected")
  //     console.log(this.socket.id);
  //   });
  // }
  connect() {
    this.socket = io(this.baseURL, {
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      // console.log("Connected to WebSocket server");
      // console.log('Socket ID:', this.socket.id);
      this.triggerAgentLogin(this.storage.get("userId"))
    });
  }

  triggerAgentLogin(data: any) {
    if (this.socket) {
      this.socket.emit('agentLogin', data);
      // console.log('Agent login event sent:', data);
    } else {
      // console.error('Socket connection not established.');
    }
  }
  listenEvent(eventName: string, callback: (data: any) => void): void {
    if (this.socket) {
      this.socket.on(eventName, callback);
    } else {
      // console.error('Socket connection not established.');
    }
  }
  disconnect() {
    // console.log('disconnected');
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
