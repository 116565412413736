<nav class="navbar navbar-expand-lg align-items-center navbar-dark bg-white row m-0">
  <div class="col-md-6 col-5  ">
    <img src="../../../../../assets/media/logos/ue-logonew.jpg" style="width: 8em;">
  </div>
  <div class="col-md-6 col-7 p-0 d-flex justify-content-end">
    <a href="javascript:;" style="
    display: flex;
    align-items: center;
  " class="m-nav__link m-dropdown__toggle">
      <span class="m-nav__link-text mr-3">
        <img class="m-topbar__language-selected-img" style="width: 4em;" src="{{ avatar }}" />
      </span>
      <span id="addo-selected-business mr-3">
        <p class="businessDetails" style="font-size: 1rem; font-weight: 600; margin: 0px;">
          {{ businessName }}
        </p>
        <p class="businessDetails" style="font-size: 0.85rem; margin: 0px;">
          {{ locality }}, {{ city }}
        </p>
      </span>
    </a> &nbsp;
    <div ngbDropdown class="d-inline-block dropdown mt-3" style="cursor: pointer;">
      <div class="btn btn-icon pulse pulse-info" id="dropdownBasic1" ngbDropdownToggle>
        <span>
          <i class="far fa-user text" style="font-size: 2em;"></i>
        </span>
        <span class="pulse-ring"></span>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
        background-color: #fff;
        box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
        min-height: 5em;
        margin-top: 1em;
        width: 26em;margin-left:-24em !important
      ">
        <div class="m-dropdown__inner">
          <div class="m-dropdown__header m--align-center" #mProfileDropdown style="height: 3em;">
            <div class="m-card-user m-card-user--skin-dark">
              <div class="m-card-user__details text-center">
                <span class="m-card-user__name m--font-weight-500 ml-2" style="color: black; font-size: 18px;">
                  {{ userName }}
                </span>
                <!-- <span>
                <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 ml-10">
                  <i class="fas fa-power-off"></i>&nbsp;Logout
                </a>
              </span> -->
              </div>
            </div>
          </div>
          <div class="m-dropdown__body mt-2">
            <div class="m-dropdown__content">
              <div class="row w-100 m-0">
                <div class="col-8">
                  <a (click)="routeBack()" class="btn btn-primary btn-sm w-100 m-0">
                    Change Store
                  </a>
                </div>
                <div class="col-4 p-0">
                  <a (click)="logout()" class="btn btn-light-danger btn-sm  text-center">
                    <i class="fas fa-power-off"></i>&nbsp;Logout
                  </a>
                </div>
              </div>
              <!-- <div class="row w-100 justify-content-end">
              <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3">
                <i class="fas fa-power-off"></i>&nbsp;Logout
              </a>
            </div> -->
              <!-- <ul class="m-nav m-nav--skin-light" style="list-style: none;">
              <li class="m-nav__item">
                <button
                  class="btn btn-outline-dark"
                  style="margin-top: 1em;"
                  routerLink="/"
                  (click)="logout()"
                >
                  Logout
                </button>
              </li>
            </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-white" style="border-top: 1px solid #ececec;"
  *ngIf="category_id==4 || businessId==6327">
  <div class="row justify-content-between col-md-6 col-12 mb-2 pl-5 w-100">
    <div class="col-12 pl-0">
      <div class="row">
        <div class="col-sm-3 col-12 mb-sm-0 mb-0">
          <label class="form-control-label form-label">Customer<span class="req">*</span></label>
        </div>
        <div class="col-sm-4 col-12 mb-md-0 mb-3">
          <div class="form-group">
            <!-- <label class="control-label">Customer Name:</label> -->
            <div id="myDropdown" class="dropdown-content">
              <i class="fa fa-times" (click)="refreshContact();"
                style="cursor: pointer; color: #777;position: absolute;margin-top: 8px;z-index: 9;right:1em;display: none;"
                [ngClass]="{'cross':customernumberData.length!=0}" title="Refresh"></i>
              <input placeholder="Enter number" [(ngModel)]="orders.mobileNo" type='text' class="form-control"
                maxlength="10" autocomplete="off" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                (keyup)="searchCustomerByNumberBill($event.target.value)">
              <div id="customerlistnumber1">
                <a *ngFor="let data of customernumberData;let i=index" (click)="selectCustomerNumberBill(data)"
                  style="cursor: pointer;color: black;">
                  {{ data.contactName }} - {{data.mobileNo}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 col-12 mb-md-0 mb-3">
          <div class="form-group">
            <!-- <label class="control-label">Customer Contact:</label> -->
            <div id="myDropdown" class="dropdown-content">
              <i class="fa fa-times" (click)="refreshName();"
                style="cursor: pointer; color: #777;position: absolute;margin-top: 8px;z-index: 9;right:1em;display: none;"
                [ngClass]="{'cross':customerData.length!=0}" title="Refresh"></i>
              <input placeholder="Enter name" [(ngModel)]="orders.customerName" type='text' class="form-control"
                id="myInput" (keyup)="searchCustomerByNameBill($event.target.value)" autocomplete="off">
              <div id="customerlistname1">
                <a *ngFor="let data of customerData;let i=index" (click)="selectCustomerBill(data)"
                  style="cursor: pointer;color: black;">
                  {{ data.contactName }} - {{data.mobileNo}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12 mb-2 pr-0" style="text-align: right !important;">
    <button class="btn btn-light-info font-weight-bold btn-sm" (click)="openAdvancePayment(addAdvancePayment, 'lg')"
      *ngIf="category_id==4 && orders.mobileNo">Add Advance
      Payment</button>&nbsp;
    <button class="btn btn-info font-weight-bold btn-sm" (click)="openModal(newOrders, 'lg')" *ngIf="category_id!=4">Add
      New Order Bill</button>
    <button class="btn btn-light-info btn-sm" (click)="showDraftData(detailDraftModal, 'lg')"
      *ngIf="category_id==4 && draftOrders.length >= 1">Draft
      Orders</button>&nbsp;
    <button class="btn btn-info font-weight-bold btn-sm" (click)="openModal(detailModal, 'lg')"
      *ngIf="category_id==4">Add Bill</button>&nbsp;
    <a href="javascript:void(0)" class="btn mt-md-0 mt-1 btn-light font-weight-bold btn-sm ml-3" (click)="back()">
      <i class="fas fa-arrow-circle-left"></i> <span>Back</span>
    </a>
  </div>
</nav>

<div class="row w-100 m-0 d-flex mt-3" *ngIf="businessId==6327">
  <div class="col-12 col-md-8 col-lg-8">
    <div class="row w-100">
      <div class="col-md-6">
        <div class="input-group input-group-solid" style="background-color:white">
          <div class="input-group-prepend"><span class="input-group-text"></span>
          </div>
          <input type="text" class="form-control" placeholder="Search Menu Items" [(ngModel)]="itemsFilter.itemName"
            *ngIf="category_id!=4" />
          <input type="text" class="form-control" placeholder="Search Services" [(ngModel)]="itemsFilter.itemName"
            *ngIf="category_id==4" />
        </div>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn btn-success font-weight-bold" (click)="openModal(newOrders, 'lg')"
          *ngIf="onCall != 1">Add New Order</button>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn font-weight-bold" (click)="openModal(existingOrderModal, 'lg')"
          *ngIf="onCall != 1" style="background-color: black;color: white;padding: 7px;height: 38px;">{{
          existingOrderArray.length }}-Existing Orders</button>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn font-weight-bold btn-info" (click)="openModal(discountModal, 'md')"
          *ngIf="category_id!=4" [disabled]="orders.totalAmt == 0">Discount</button>
      </div>
    </div>
    <div class="pos-start mt-5">
      <div class="sellables w-100">
        <nav *ngIf="parentBusinessId==0">
          <div class="nav nav-tabs categories" id="nav-tab" role="tablist">
            <a *ngFor="let data of brandsData" class="nav-link category" id="{{data.businessId}}"
              (click)="showSection(data)" role="tab" [ngClass]="{'changeStyle': clicked==1&&data.businessId==id}"
              style="cursor: pointer;">{{data.name}}</a>
          </div>
        </nav>
        <div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-boys" role="tabpanel" aria-labelledby="nav-boys-tab">
              <div class="row m-0">
                <div class="col-md-4 item-leftt item-group-wrapper" [ngClass]="{'changeHeight':parentBusinessId!=0}">
                  <nav class="navigation" id="mainNav">
                    <h4>Sections</h4>
                    <a class="navigation__link" *ngFor="let section of menu | filterBy: sectionFilter"
                      style="cursor: pointer;" [ngClass]="{ activeSection: selectedItem == section }"
                      (click)="listClick(section);scrollToSection(section.sectionId)" id="{{ section.sectionId }}">
                      <div class="row w-100">
                        <div class="col-md-10">
                          {{section.sectionName}}
                        </div>
                        <div class="col-md-2" style="text-align: end;">
                          <span>&#62;</span>
                        </div>
                      </div>
                    </a>
                  </nav>
                </div>

                <div class="col-md-8 item-rightt item-group-wrapper" [ngClass]="{'changeHeight':parentBusinessId!=0}">
                  <div *ngFor="let iitem of menu" [attr.id]="'section' + iitem.sectionId">
                    <h4>{{ iitem.sectionName }}</h4>
                    <div class="row">
                      <div *ngFor="let items of iitem.items | filterBy: itemsFilter" class="page-section ml-3"
                        [ngClass]="{'page-section1':items.variant_count > 0}">
                        <div class="row w-100 m-0" *ngIf="items.variant_count > 0">
                          <div class="col-md-4 col-lg-4 item-iner"
                            *ngFor="let item of items.variants | filterBy: itemsFilter">
                            <a class="item">
                              <h5 style="color: #585757;
                                  font-size: 18px;
                                  font-weight: 500;">{{ item.itemName }}</h5>
                              <p style="    font-size: 17px;
                                  color: #676767;
                                  margin-bottom: 10px;">Cost: <span style="color: #8950FC;
      font-weight: 500;
      font-size: 19px;">&#x20b9;{{ item.sp }}</span></p>
                            </a>
                            <a (click)="checkTemplateExist(item, templateModal)" class="add-btn-item"
                              style="cursor: pointer;">Add</a>
                          </div>
                        </div>

                        <!-- <div > -->
                        <div class="w-100" *ngIf="items.variant_count == 0">
                          <div class="item-iner">
                            <a class="item">
                              <h5 style="color: #585757;
                                  font-size: 18px;
                                  font-weight: 500;">{{ items.itemName }}</h5>
                              <p style="    font-size: 17px;
                                  color: #676767;
                                  margin-bottom: 10px;">Cost: <span style="color: #8950FC;
          font-weight: 500;
          font-size: 19px;">&#x20b9;{{ items.sp }}</span></p>
                            </a>
                            <a (click)="checkTemplateExist(items, templateModal)" class="add-btn-item"
                              style="cursor: pointer;">Add</a>
                          </div>
                        </div>

                        <!-- </div> -->
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4 col-lg-4">
    <div class="register-wrapper-start">
      <div class="order-main-div">
        <div class="top-order-main-dv">
          <p class="order-outer-time">Order Time: <span class="date">{{
              orders.invoicedAt | date: "dd/MMM/yyyy
              h:mm:ss a" }}</span></p>
          <p class="order-name">Order Number: <span class="date">{{
              orders.orderId }}</span></p>
        </div>
      </div>
      <div class="lower-outer">
        <div class="resulttable-pos">
          <table class="table right-table">
            <tr class="d-flex align-items-center justify-content-between">
              <th class="border-0 font-size-h5 mb-0 font-size-bold">
                Billing Summary
              </th>
            </tr>
            <tr class="d-flex align-items-center justify-content-between">
              <td class="border-0 font-size-h5 mb-0">
                Sub Total
              </td>
              <td class="border-0 justify-content-end d-flex text-dark font-size-base">&#x20b9;{{
                getSubtotal(orders.subtotal) }}</td>
            </tr>
            <tr class="d-flex align-items-center justify-content-between">
              <td class="border-0 font-size-h5 mb-0">
                Total Tax
              </td>
              <td class="border-0 justify-content-end d-flex text-dark font-size-base">&#x20b9;{{
                orders.tax }}</td>
            </tr>
            <tr class="d-flex align-items-center justify-content-between">
              <td class="border-0 font-size-h5 mb-0">
                Discount
              </td>
              <td class="border-0 justify-content-end d-flex text-dark font-size-base">&#x20b9;{{orders.discount
                }}</td>
            </tr>
            <tr class="d-flex align-items-center justify-content-between item-price">
              <th class="border-0 font-size-h5 mb-0 font-size-bold">
                Grand Total: <span class="text-muted" *ngIf="orders.totalAmt<getSubtotal(orders.subtotal)"
                  style="font-size:0.9em"><del>&#x20b9;{{
                    getSubtotal(orders.subtotal) }}</del></span>
                &#x20b9;{{ orders.totalAmt }}
              </th>
              <td class="border-0 justify-content-end d-flex font-size-base" style="font-weight: 700;">
                <button *ngIf="onCall != 1" [disabled]="orders.totalAmt == 0 && category_id != 4"
                  (click)="closeOrderModal(completeOrder)" class="btn btn-danger btn-md font-weight-bold">
                  <span>Checkout</span>
                </button>
                <button *ngIf="onCall == 1" [disabled]="orders.totalAmt == 0 && category_id != 4" (click)="placeOrder()"
                  class="btn btn-danger btn-md font-weight-bold">
                  Checkout
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="table-datapos">
          <div class="row w-100 p-2" *ngFor="let item of cartList; let i = index"
            style="border-bottom: 1px dotted black;">
            <div class="col-md-1">{{i+1}}</div>
            <div class="col-md-3 text-dark-75 font-weight-bold">{{ item.itemName
              }}</div>
            <div class="col-md-5">
              {{ item.costPerItem }} x <input type="number" min="1" (change)="qtyChange(item, i)"
                [(ngModel)]="item.quantity" style="width: 30%;" />
              = &#x20b9;{{ item.cost }}
            </div>
            <div class="col-md-3" style="text-align: end;">
              <i class="fa fa-trash" (click)="removeItem(i)" style="color: red !important;"></i>
              <!-- <i class="fa fa-percent" (click)="openModal(addOnModal,md)" style="color: blue !important;"></i> -->
            </div>
            <div class="row w-100 ml-5 mt-3" *ngIf="category_id==4">
              <div class="col-md-6">
                <label
                  class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-4 px-5">Person
                  giving service:</label>
              </div>
              <div class="col-md-6">
                <select class="form-control" [(ngModel)]="item.workerName" (change)="stylistChange(item.workerName,i)">
                  <option value disabled>--SELECT--</option>
                  <option *ngFor="let data of stylistData" value="{{data.id}}">{{data.staff_name}}</option>
                </select>
              </div>
            </div>
            <div class="row w-100 ml-5 mt-2 justify-content-end" *ngIf="category_id==4">
              <div class="col-md-6">
                <label
                  class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-4 px-5">Discount(If
                  Applicable):</label>
              </div>
              <div class="col-md-6">
                <input type="number" class="form-control" placeholder="Enter discount" [(ngModel)]="item.discount"
                  (focusout)="addDiscount(item.discount,i)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="category_id!=4 && businessId!=6327"
  style="background-color: white;height: 100vh;border-top: 1px solid #ececec;" class="row w-100 m-0 p-5">
  <div class="col-md-2 pl-0">
    <div class="style-1">
      <div *ngFor="let data of menu;let i=index" class="p-5 mt-5" (click)="listClick(data,i)"
        [ngClass]="{'activeFoodSection': sectionIndex == i}"
        style="border-radius: 10px;background-color: #ececec;color: black;text-align: center;width: 16em;cursor: pointer;">
        <span>{{data.sectionName}}</span>
      </div>
    </div>
  </div>
  <div class="col-md-10 p-5" style="background-color: #ececec;border-radius: 10px;">
    <div class="row w-100">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style="margin-top: 1em;">
        <div class="form-group">
          <label>Customer Name</label>
          <div id="myDropdown" class="dropdown-content">
            <input [(ngModel)]="customerName" placeholder="Enter name" type='text' class="form-control" id="myInput"
              (keyup)="searchCustomerByName($event.target.value)" autocomplete="off">
            <div id="customerlistname">
              <div class="row w-100 justify-content-end mt-2" *ngIf="customerData.length!=0">
                <i class="fas fa-times" (click)="closeName()" style="cursor: pointer;"></i>
              </div>
              <a *ngFor="let data of customerData;let i=index" (click)="selectCustomerPos(data)"
                style="cursor: pointer;color: black;">
                {{ data.contactName }} - {{data.mobileNo}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style="margin-top: 1em;">
        <div class="form-group">
          <label>Customer Contact</label>
          <div id="myDropdown" class="dropdown-content">
            <input [(ngModel)]="customerNumber" placeholder="Enter number" type='text' class="form-control"
              maxlength="10" autocomplete="off" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              (keyup)="searchCustomerByNumber($event.target.value)">
            <div id="customerlistnumber">
              <div class="row w-100 justify-content-end mt-2" *ngIf="customernumberData.length!=0">
                <i class="fas fa-times" (click)="closeNumber()" style="cursor: pointer;"></i>
              </div>
              <a *ngFor="let data of customernumberData;let i=index" (click)="selectCustomerNumberPos(data)"
                style="cursor: pointer;color: black;">
                {{ data.contactName }} - {{data.mobileNo}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 mt-5">
      <div class="col-md-8">
        <div class="row w-100 style-1">
          <div class="col-md-4 mt-5" *ngFor="let item of selectedItem;let i=index">
            <div style="background-color: white;max-height: 5em;cursor: pointer;" class="p-3 hoverClass"
              (click)="checkItem(item, customisationModal)">
              <span class="text-dark-75" style="font-weight: 500;">
                <img *ngIf="item.vegNonvegBoth=='veg'" src="../../../../../assets/media/misc/Veg-Icon.png"
                  style="width: 1em;">
                <img *ngIf="item.vegNonvegBoth=='non-veg'" src="../../../../../assets/media/misc/Non-Veg-Icon.png"
                  style="width: 1em;">&nbsp;{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 pr-0">
        <div style="border-radius:5px;background:white"
          *ngIf="cartList.length==0 || cartList==undefined || cartList==null">
          <div class="row w-100 m-0 justify-content-center" style="padding-top:8em">
            <i class="fas fa-concierge-bell text-dark" style="font-size: 5em;"></i>
          </div>
          <div class="row w-100 m-0 justify-content-center">
            <label>No items added</label>
          </div>
          <div class="row w-100 ml-3" style="margin-top:14.5em !important">
            <label style="font-size: 1.3em;">Payment Method</label>
          </div>
          <div class="paymentOption">
            <div>
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_1" style="display: none;" disabled />
                    <span style="cursor: not-allowed;"></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Cash
                    </span>
                  </span>
                </span>
              </label>
            </div>
            <div>
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_1" style="display: none;" disabled />
                    <span style="cursor: not-allowed;"></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Card
                    </span>
                  </span>
                </span>
              </label>
            </div>
            <div>
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_1" style="display: none;" disabled />
                    <span style="cursor: not-allowed;"></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Cheque
                    </span>
                  </span>
                </span>
              </label>
            </div>
            <div>
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_1" style="display: none;" disabled />
                    <span style="cursor: not-allowed;"></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      UPI
                    </span>
                  </span>
                </span>
              </label>
            </div>
            <div>
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_1" style="display: none;" disabled />
                    <span style="cursor: not-allowed;"></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Split
                    </span>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <!-- <div class="row w-100 p-3 m-0">
            <button class="btn btn-dark w-100" style="border-radius: 10px;cursor: not-allowed;"
              disabled>Checkout</button>
          </div> -->
        </div>
        <div class="style-5" *ngIf="cartList.length!=0 && cartList!=undefined && cartList!=null">
          <div class="row p-2 m-0 mt-2" *ngFor="let data of cartList;let i=index"
            style="border-radius: 5px;background:white">
            <div class="col-md-1 mt-3">
              <i class="fas fa-trash text-danger" style="cursor:pointer;" (click)="delItem(i)"></i>
            </div>
            <div class="col-md-7 mt-2">
              <span>{{data.itemName}}</span>
              <fieldset>
                <input type="button" value="-" class="decrease" (click)="updateItemQty(data,'dec',i)"
                  [disabled]="data.quantity==0" [ngClass]="{'changeBtn':data.quantity==0}" style="background-color: transparent;
              color: black;
              cursor: pointer;
              width: 24px;
              border-radius: 5px;
              border: 1px solid black;
              height: 24px;" />
                <input type="text" id="six-max" [(ngModel)]="data.quantity" class="ml-2 mr-2"
                  (focusout)="setQty(data,i)" style=" border: none;
              height: 22px;
              width: 14px;
              background: transparent;" />
                <input type="button" value="+" class="increase" (click)="updateItemQty(data,'inc',i)" style="background-color: transparent;
              color: black;
              cursor: pointer;
              width: 24px;
              border-radius: 5px;
              border: 1px solid black;
              height: 24px;" />
              </fieldset>
            </div>
            <div class="col-md-4 mt-3" style="text-align: end;">
              {{data.costPerItem| customcurrency}}
            </div>
            <div class="extra-main-dv w-100" style="padding-left: 12.5px;" *ngIf="data.templateCartDetails.length!=0">
              <p class="extra-main-p mb-0" (click)="toggleCustomizations(i)" style="cursor: pointer;">Customizations
                <span class="angle-icon-down" [style.display]="!showCustomizations[i] ? 'inline-block' : 'none'"><i
                    class="flaticon2-down text-dark" style="font-size: 0.9em;"></i></span>
                <span class="angle-icon-up" [style.display]="showCustomizations[i] ? 'inline-block' : 'none'"><i
                    class="flaticon2-up text-dark" style="font-size: 0.9em;"></i></span>
              </p>
              <div class="extrdp-outer" [style.display]="showCustomizations[i] ? 'inline-block' : 'none'">
                <div class="extrdp w-100" *ngFor="let item of data.templateCartDetails">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-8 pl-0"><span style="font-size:0.9em">+
                      {{item.itemName}}</span></div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-4 text-right p-0"
                    style="font-weight: 400 !important;color: #006630 !important;">{{item.cost
                    | customcurrency}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="border-radius:5px;background:white"
          *ngIf="cartList.length!=0 && cartList!=undefined && cartList!=null" class="mt-3">
          <div class="cashsection">
            <!-- <h3 class="cash-title" style="color: black;text-align: left;
  line-height: 28px;
  font-size: 20px;
  margin: 0px 0 10px 0;
  padding: 0 15px;">Billing Summary</h3> -->
            <div class="bill-cash">
              <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;">
                  Total Items:
                </div>
                <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
                  {{totalQty}}
                </div>
              </div>
              <div class="fl-row" style="display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;">
                  Sub Total:
                </div>
                <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
                  {{orders.subtotal |customcurrency}}
                </div>
              </div>
              <div class="fl-row" style="display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;">
                  Discount:
                </div>
                <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
                  - {{orders.discountedAmount |customcurrency}}
                </div>
              </div>
              <div class="row w-100 m-0 ml-4">
                <a (click)="opendiscountModalpos(posDiscountModal)"
                  style="color: #8CC42A;cursor: pointer;text-decoration: underline;">Add
                  Discount</a>
              </div>
              <div class="fl-row" style="display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;">
                  Taxes:
                </div>
                <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;">
                  {{orders.tax |customcurrency}}
                </div>
              </div>
              <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;border-top: 1px dashed black;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;font-size: 1.2em;">
                  Payable Amount:
                </div>
                <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;font-size: 1.2em;">
                  {{orders.roundedTotalAmt|customcurrency}}
                </div>
              </div>
              <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
                <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 8px 14px;">
                  <label class="checkbox checkbox-outline checkbox-info">
                    <input type="checkbox" name="Checkboxesgst" style="display: none;" value="gstDetail"
                      [(ngModel)]="gstFlag" (click)="gstDetail(gstModal,$event.target.checked)" />
                    <span></span>
                    <label style="padding-left: 0.3em;margin-top: 0.5rem;">Enter
                      GST Details</label>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="splitArea==1">
            <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 17px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Split Payment Details</h3>
            <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;" *ngFor="let data of paymentList">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 8px 14px;"
                *ngIf="data.added==1">
                {{data.mode}}:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;" *ngIf="data.added==1">
                &#8377; {{data.amount | number:"1.2-2"}}
              </div>
            </div>
          </div>
          <!-- <div class="row w-100 p-3 m-0">
            <button class="btn btn-dark w-100" style="border-radius: 10px;" (click)="closePOSOrder()">Checkout</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div id="taskButton" *ngIf="cartList.length!=0">
    <div class="col-md-8">
      <div class="row w-100 ml-3">
        <label style="font-size: 1.3em;font-weight: bold;">Payment
          Methods:</label>
      </div>
      <div class="paymentOption">
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" style="display: none;" value="cash" [(ngModel)]='paymentType'
                  style="display: none;" (click)="getPayment('cash')" [checked]="paymentType==cash" />
                <span></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Cash
                </span>
              </span>
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" style="display: none;" value="card" [(ngModel)]='paymentType'
                  style="display: none;" (click)="getPayment('card')" [checked]="paymentType==card" />
                <span></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Card
                </span>
              </span>
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" style="display: none;" value="bank" [(ngModel)]='paymentType'
                  style="display: none;" (click)="getPayment('bank')" [checked]="paymentType==bank" />
                <span></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Cheque
                </span>
              </span>
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" style="display: none;" value="split" [(ngModel)]='paymentType'
                  style="display: none;" (click)="splitPayment(splitModal)" [checked]="paymentType==split" />
                <span></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Split
                </span>
              </span>
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" style="display: none;" value="upi" [(ngModel)]='paymentType'
                  style="display: none;" (click)="getPayment('upi')" [checked]="paymentType==upi" />
                <span></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  UPI
                </span>
              </span>
            </span>
          </label>
        </div>

      </div>
    </div>
    <div class="col-md-4 pl-0 mt-5">
      <button class="feedback btn w-100" type="button" (click)="closePOSOrder()"
        style="color: white;background-color: #8CC42A;">Checkout
      </button>
    </div>
  </div>
</div>

<div *ngIf="category_id==4" style="background-color: white;border-top: 1px solid #ececec;
min-height: 72vh;">
  <div class="row w-100  m-0" style="height: 100%;" *ngIf="designType!=1">
    <div class="col-md-8 col-12 col-lg-9 col-xl-9 my-md-0 my-0 pt-3">
      <div class="d-flex align-items-center justify-content-between flex-wrap"
        *ngIf="customerId != undefined && customerId != null && customerId != ''">
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-calendar display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75" *ngIf="lastorder!=''">
            <span class="font-weight-bolder font-size-sm">Last Visit</span>
            <span class="font-size-h5">{{lastorder | date:
              'dd MMM,yyyy'}}</span>
          </div>
          <div class="d-flex flex-column text-dark-75" *ngIf="lastorder==''">
            <span class="font-weight-bolder font-size-sm">Last Visit</span>
            <span class="font-size-h5">First Visit</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">Total Billing</span>
            <span class="font-size-h5">
              {{ltv | customcurrency}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">Total Visits</span>
            <span class="font-size-h5">{{lto}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="text-dark-75">
            <span class="font-weight-bolder font-size-sm">Personalised
              Notes</span><br>
            <a class="text-info font-weight-bolder" style="cursor: pointer;"
              (click)="openNotesModel(notesModal)">View</a>&nbsp;
            <a class="text-dark font-weight-bolder" style="cursor: pointer;" (click)="addNotesModel(addnotesModal)">Add
              note</a>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <button type="button" class="btn btn-light-info btn-sm" (click)="viewProfile()"
            *ngIf="customerId!=undefined">View Full Profile</button>
        </div>
      </div>
      <hr
        style="background-color:#8950fc;height: 3px;margin-top: 0px !important;margin-bottom: 0px !important;margin-left: -0.5em;"
        *ngIf="customerId != undefined && customerId != null && customerId != ''">
      <div class="billtypebg row pt-8 m-0 heightManage">
        <div class="bil-hide-mob row d-md-flex d-none">
          <div class="col-md-4 service-width pl-0" style="text-align: center;">
            <label for class="form-label semibold">Service/Product <span class="req">*</span></label>
          </div>
          <div class="col-md-2 staff-width pl-0" style="text-align: center;">
            <label for class="form-label semibold">Staff(s)<span class="req">*</span></label>
          </div>
          <!-- <div class="col-md-1 qty-width pl-0">
            <label for="" class="form-label semibold">Qty.</label>
          </div> -->
          <div class="col-md-2 price-width pl-0" style="text-align: center;">
            <label for class="form-label semibold">Price X Qty.</label>
          </div>
          <div class="col-md-1 disc-width pl-0" style="text-align: end;">
            <label for class="form-label semibold">Disc.</label>
          </div>
          <!-- <div class="col-md-2 disc-width pl-0">
            <label for="" class="form-label semibold">Disc. Remark</label>
          </div> -->
          <div class="col-md-1 total-width pl-0" style="text-align: end;">
            <label for class="form-label semibold">Total</label>
          </div>
          <div class="col-md-2 action-width pl-0">
            <!-- <i class="fas fa-trash" style="color: red;"></i> -->
          </div>
        </div>
        <div class="timeline timeline-3 w-100">
          <div class="timeline-items">
            <div class="timeline-item" *ngFor="let data of cartList;let i=index">
              <div class="timeline-media">
                <span class="text-info font-weight-bold">{{i+1}}.</span>
              </div>
              <div class="timeline-content">
                <div class="ser-bill row sale-row ng-scope">
                  <div class="col-md-4 service-width long-drop pl-0" id="serviceList">
                    <label for class="d-md-none d-block">Service/Product</label>
                    <ejs-dropdownlist [attr.id]="'checkbox'+i" [dataSource]='menuData' [fields]='fields'
                      [placeholder]='text' [allowFiltering]='true' [filterBarPlaceholder]="filterPlaceholder"
                      (filtering)="onFiltering($event)" (change)="addService($event,i)" [value]="data.itemId"
                      popupWidth='450px'>
                    </ejs-dropdownlist>
                  </div>
                  <div class="col-md-2 stafffav-out staff-width pl-0" id="staffList">
                    <label for class="d-md-none d-block">Staff</label>
                    <ejs-multiselect id='country' [dataSource]='staffdata' [filterBarPlaceholder]="filterPlaceholder"
                      [allowFiltering]='true' [placeholder]='watermark' [ignoreAccent]='true' [value]="data.workerId"
                      (change)="addStylist(i,$event)" [fields]='stylistfield' autocomplete="off"></ejs-multiselect>
                  </div>
                  <!-- <div class="col-md-1 qty-width pl-0" style="width: 8%;float:left">f
                        <label class="form-label showbil-mob" style="padding: 5px 0 8px 0;
                          font-family: 'Segoe UI Semibold' !important;
                          font-size: 14px;
                          border-radius: 0 !important;display: none;">Qty.</label>
                        <input type="text" class="form-control no-radius ng-pristine ng-valid" id="quantity0"
                          [(ngModel)]="data.quantity" (change)="qtyChange(data, i)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                      </div> -->
                  <div class="col-md-2 price-width pl-0">
                    <label for class="d-md-none d-block">Price X QTY</label>
                    <div style="display: flex;">

                      <label class="form-label showbil-mob">Price ( <small class="fal USD"
                          ng-class="currencyCode"></small>
                        )</label>
                      <span>
                        <!-- {{data.costPerItem}} -->
                        <input type="text" class="form-control no-radius" value="0.00"
                          (keyup)="updateServicePrice(i,data.costPerItem,data)"
                          (focusout)="updateServicePrice(i,data.costPerItem,data)" [(ngModel)]="data.costPerItem">
                      </span>
                      <span class="font-weight-bold" style="margin-top: 7px;margin-left: 7px;">X</span>
                      <!-- <span> -->
                      <ejs-dropdownlist #ddlelement [attr.id]="'qty'+i" [dataSource]='qtyData' [placeholder]='textQty'
                        style="width: 60%;margin-left: 12px;" (change)="qtyChange1(data,$event,i)"
                        [value]="data.quantity" [readonly]="data.packingMappingId" [fields]='fieldsDis'
                        [ngClass]="{'checkoutbutton':data.packingMappingId}" popupWidth='80px'></ejs-dropdownlist>
                      <!-- </span> -->
                    </div>
                  </div>
                  <div class="col-md-1 disc-width pl-0">
                    <label for class="d-md-none d-block">Disc.</label>
                    <div class="form-group m-b-0 bil-discount">
                      <label for class="form-label showbil-mob">Disc. ( <small class="fal fa-percent"></small>
                        )</label>
                      <div class="b-a l-h-b-div b-b ng-binding"
                        style="color: #343434;line-height: 27px;height: 31px;border-radius: 0;border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;border: none;">
                        {{data.discount | number:'1.2-2'}} <i class="fas fa-plus text-primary"
                          style="font-size: 1em;margin-left: auto;cursor: pointer;"
                          *ngIf="!data.packingMappingId && (totalDiscount==0 ||  orders.discountedAmount <  0)"
                          (click)="openDiscountModal(salonDiscountModal,i)" title="Apply Discount"></i>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-2 pl-0" id="discRemark">
                        <ejs-dropdownlist id='discount' [dataSource]='discData' [filterBarPlaceholder]="filterPlaceholder"
                          [allowFiltering]='true' [placeholder]='disctext' [ignoreAccent]='true' [value]="data.discRemark"
                          (change)="addRemark(i,$event)" [fields]='discfield'></ejs-dropdownlist>
                      </div> -->
                  <div class="col-md-1 total-width pl-0">
                    <label for class="d-md-none d-block">Total</label>
                    <label class="form-label showbil-mob">Total ( <small class="fal USD"
                        ng-class="currencyCode"></small>
                      )</label>
                    <div class="b-a l-h-b-div b-b ng-binding"
                      style="color: #343434;line-height: 27px;height: 31px;border-radius: 0;border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;border: none;">
                      {{data.cost | number:'1.2-2'}}
                    </div>
                  </div>
                  <div class="col-md-2 serv-del action-width bil-hide-mob mt-4 pl-0">
                    <!-- <label class="form-label showbil-mob">&nbsp;</label> -->
                    <!-- <span class="del-height m-b-0"> -->
                    <i aria-hidden="true" class="fas fa-trash cursor-pointer text-danger" (click)="removeItem(i)"
                      title="Delete" style="padding-right: 15px;"></i>
                    <a *ngIf="data.itemId!=''" style="cursor: pointer;font-weight: 500;font-size:1em;"
                      (click)="customSelectOpen(addOnModal,i)">Add
                      On<sup>+</sup></a>
                    <!-- <i aria-hidden="true" class="fas fa-percentage cursor-pointer text-primary"
                            (click)="openModal(addOnModal,'lg')"
                            title="Add On"></i> -->
                    <!-- </span> -->
                  </div>
                  <!-- <div class="row m-0"> -->
                  <!-- </div> -->
                </div>
                <div *ngIf="data.subItems.length!=0" class="font-weight-bold">
                  <span>+</span>&nbsp;<span>{{data.subItems[0].name}}&nbsp;&nbsp;(&#8377;{{data.subItems[0].sp}})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end m-0">
          <button class="btn btn-sm btn-light-info" (click)="addNewItem()">Add Service/Product<sup>+</sup></button>
        </div>
        <!-- <div class="row">
          <div class="col-lg-12 pl-0">
            <a style="cursor: pointer;color: #8950fc;font-weight: 500;font-size: 1.1em;" (click)="opentotalDiscModal(totalDiscModal)">Apply Discount On Payable Amount</a>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-4 col-12 heightManage  col-lg-3 col-xl-3 p-md-0 p-5"
      style="border-left: 1px solid #ccc;padding-top: 20px !important;">
      <div style="min-height: 299px;">
        <div class="cashsection">
          <h6 style="color: red;margin: 0px 0 10px 0;padding: 0 15px;font-size: 1em;"
            *ngIf="pendingPayoutAmount!=0 && pendingPayoutAmount!=undefined && pendingPayoutAmount!='' && pendingPayoutAmount!=null">
            Customer has a pending payout of
            {{pendingPayoutAmount|customcurrency}}</h6>
          <h3 class="cash-title" style="color: black;text-align: left;
          line-height: 28px;
          font-size: 20px;
          margin: 0px 0 10px 0;
          padding: 0 15px;">Billing Summary</h3>
          <div class="bill-cash">
            <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Sub Total:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{orders.subtotal | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.discountedAmount!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Discount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.discountedAmount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.memPerDiscount>0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership % Discount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.memPerDiscount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.promoDiscount!=0 && orders.promoDiscount!=undefined">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Promo Code ({{orders.promoCode}}):
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.promoDiscount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Net Amount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{( orders.netAmt ? orders.netAmt : '0')
                | number:'1.2-2'}}
              </div>

            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="orders.walletPoints!=0 && orders.walletPoints!=null && orders.walletPoints!=undefined">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Wallet Points Used:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.walletPoints | number:'1.2-2'}}
              </div>
            </div>


            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Taxes:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="taxFlag">
                + &#8377;{{orders.tax | number:'1.2-2'}}
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="!taxFlag">
                - &#8377;{{orders.tax | number:'1.2-2'}}
              </div>
            </div>

            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openTotalDiscountModal(salonTotalDiscountModal)" class="text-info">Apply Discount</label>
              </div>
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="removeTotalDisc()" class="text-danger"
                  *ngIf="totalDiscount==1 || orders.discountedAmount > 0">Remove</label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;" value="taxApplicable"
                    [(ngModel)]="taxFlag" (click)="checkTax($event.target.checked)" [disabled]="isIncluseTax==true" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Tax
                    Applicable</label>
                </label>
              </div>
            </div>
            <!-- <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold"
                style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;"
                    value="taxApplicable"
                    [(ngModel)]="itemTaxFlag"
                    (click)="checkItemTax($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Item Price Excluding Tax
                    </label>
                </label>
              </div>
            </div> -->
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;" [(ngModel)]="isIncluseTax"
                    (click)="addInclusiveTax($event.target.checked)" [disabled]="memUsed==true || taxFlag == false" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Sub
                    Total Inclusive of GST
                  </label>
                </label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;" *ngIf="showMem==1 && category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">

                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="membership"
                    [(ngModel)]='memUsed'
                    [disabled]="membershipData[0]==undefined || membershipData[0].membershipAmount==0 || (membershipOtpRedemtion == 1 && !isOtpValid)"
                    (click)="completeExistingOrder('MS',completeOrder,$event)" />

                  <span
                    [ngClass]="{'payment':membershipData[0]==undefined || membershipData[0].membershipAmount==0}"></span>
                  <label class="mb-0" style="padding-left: 0.3em;margin-top: 0.5rem;">Membership Wallet <br>
                    <small class="text-danger">Valid Till :
                      {{membershipData[0].valid_till | date:"dd MMM,yyyy"}}</small></label>
                </label>
                <span class="text-info ml-5 pointer" style="font-size: 11px; text-decoration:underline;"
                  *ngIf="membershipOtpRedemtion === 1 && !isOtpValid"
                  (click)="sendOtp(); openModal(getOtpModal, 'md')">Send OTP to redeem</span>
                <span style="font-size: 11px; color: green;" *ngIf="membershipOtpRedemtion === 1 && isOtpValid">OTP
                  validated</span>
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;"
                *ngIf="membershipData[0]!=undefined">
                &#8377;{{membershipData[0].membershipAmount}}
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 0px 0px 0px 14px;"
                *ngIf="membershipData[0]==undefined">
                &#8377;0
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="category_id==4 && orders.customerName!=null && orders.customerName!=undefined && orders.customerName!=''">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="partail"
                    [(ngModel)]="paymentFlag" (click)="partailPayment(amountModal,$event.target.checked)"
                    [disabled]="disableMode==1" [ngClass]="{'payment':disableMode==1}" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Partial
                    Payment</label>
                </label>
                <small style="color: red;"
                  *ngIf="pendingAmountLeft!=undefined && pendingAmountLeft!='' && pendingAmountLeft!=null">Pending
                  Amount Left: {{pendingAmountLeft|customcurrency}}</small>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;"
              *ngIf="category_id==4 && orders.customerName!=null && orders.customerName!=undefined && orders.customerName!=''">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 8px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxesgst" style="display: none;" value="gstDetail"
                    [(ngModel)]="gstFlag" (click)="gstDetail(gstModal,$event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Enter
                    GST Details</label>
                </label>
              </div>
            </div>
            <!-- <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="advancePaymentDetails">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 8px 14px;">
                <label style="padding-left: 0.3em;margin-top: 0.5rem;color: green;">Advance Wallet</label>
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 8px 14px;color: green;">
                {{ advancePaymentDetails | customcurrency }}
              </div>
            </div> -->
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="loyaltyRules && !isObjectEmpty(loyaltyRules) && category_id==4 && loyaltyRules.available_point!=undefined && loyaltyRules.available_point!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxespoints" style="display: none;" value="pointss"
                    (click)="applyPoints($event.target.checked)" [(ngModel)]="pointFlag" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;"><i class="fas fa-wallet text-dark"
                      style="font-size: 1.1em;"></i>&nbsp;Wallet
                    Points</label>
                </label>
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{loyaltyRules.available_point}}
              </div>
            </div>
            <div class="row w-100 m-0" style="padding: 0px 22px 8px 14px;"
              *ngIf="loyaltyRules && !isObjectEmpty(loyaltyRules) && category_id==4 && loyaltyRules.available_point!=0 && loyaltyRules.available_point!=undefined">
              <small>{{loyaltyRules.discount_percentage}}% of the Sub Total can
                be paid upto
                {{loyaltyRules.max_discount_amount | customcurrency}} using
                points. (Min. Bill Amount:
                {{loyaltyRules.minimum_order_value | customcurrency}})</small>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="loyaltyCard.length!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <span style="color: green;font-size:1.1em">Membership %
                  Available</span><br>
                <span style="font-size:1em">{{loyaltyCard[0].cardName}},
                  {{loyaltyCard[0].discountValue}}% discount on subtotal</span>
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="partail"
                    [(ngModel)]="loyaltyFlag" (click)="loyaltyDiscount($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Apply</label>
                </label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;" *ngIf="plength!=0">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openPackageModal(packageModal, 'lg')" class="text-info">Check Package Details</label>
              </div>
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openPromoModal(promoModal)" class="text-info">Apply An Offer</label>
              </div>
            </div>
          </div>
        </div>
        <div class="pay-title" style=" padding: 0 15px;
      position: relative; margin-top: 1em;
      background: rgba(0, 0, 0, 0.04);
      border-bottom: 1px solid rgba(204, 204, 204, 0.4);">
          <div class="fl-row flex-column" style="  display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;">
            <!-- <div class="fl-cell fl-cell-lbl pb-0 w-100"
              style=" font-size: 20px;
            padding: 12px 0px;
            font-weight: 500;color: black;
            line-height: 30px;">
              Net Amount
              <span class="ng-binding" style="padding-left: 9.5rem !important;">
                <small> &#8377;</small> {{( orders.netAmt ? orders.netAmt : '0')
                | number:'1.2-2'}}
              </span>

            </div> -->
            <div class="fl-cell fl-cell-lbl w-100" style=" font-size: 20px;
            padding: 12px 0px;
            font-weight: 500;color: black;
            line-height: 30px;">
              Payable Amount
              <br>
              <span style="font-size: 13px !important;">(Round Off
                <span>{{orders.roundDiff ? orders.roundDiff : '0.00'}}</span>
                <!-- <span
                  *ngIf="orders.totalAmt>orders.roundedTotalAmt">-{{orders.roundDiff
                  | number:'1.2-2'}}</span>
                <span *ngIf="orders.totalAmt<orders.roundedTotalAmt">+{{orders.roundDiff
                  |
                  number:'1.2-2'}}</span> -->
                )
              </span>

              <span class="ng-binding" style="padding-left: 9.5rem !important;">
                <small> &#8377;</small> {{orders.roundedTotalAmt |
                number:'1.2-2'}}
              </span>
            </div>
          </div>
        </div>
        <div class="thirdParty" *ngIf="showThirdParty==1">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 15px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Select Third Party Company</h3>
          <div class="paymentOption">
            <div *ngFor="let data of thirdPartyData;let i=index">
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_2" value="{{data.name}}" [(ngModel)]='thirdPartyType'
                      style="display: none;" />
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      <img src="{{data.icon}}" style="width:2em">&nbsp;{{data.name}}
                    </span>
                  </span>
                  <!-- <span class="option-body">
                    {{segment.desc}}
                  </span> -->
                </span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="splitArea==1">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 17px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Split Payment Details</h3>
          <div class="fl-row" style="display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;" *ngFor="let data of paymentList">
            <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
              {{data.mode}}:
            </div>
            <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="data.amount!=''">
              &#8377; {{data.amount | number:"1.2-2"}}
            </div>
            <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="data.amount==''">
              &#8377; 0
            </div>
          </div>
        </div>
        <!-- <div class="payment-options">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 17px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Payment Options</h3>
          <div class="paymentOption">
            <div class="row w-100 m-0">
              <div class="input-group" style="display: contents;">
                <div class="input-group-prepend"><span class="input-group-text">₹</span></div>
                <input class="form-control" placeholder="Adjust Payment" [(ngModel)]="adjustPayment" type="number" min="0" style="width: auto; padding: 0; border-bottom: none !important;border: 1px solid #ced4da !important;border-radius: 2 !important;padding: 4px !important;">
               </div>
            </div>
              <div class="mt-5" *ngFor="let data of paymentOption ; let i=index">
                <label class="option">
                  <span class="option-control">
                    <span class="checkbox">
                      <input type="checkbox" id="{{data.id}}" name="{{data.id}}" value="{{data.name}}" style="display: none;"  (click)="completeExistingOrder($event.target.value,i,$event.target.checked)" [disabled]="data.name=='Membership' && data.memAmount==''"/>
                      <span [ngClass]="{'payment':data.name=='Membership' && data.memAmount==''}"></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        {{data.name}} <span *ngIf="data.memAmount!=''">( &#8377; {{data.memAmount}} )</span>
                      </span>
                    </span>
                    <span class="option-body" *ngIf="data.amount!=''">
                      &#8377; {{data.amount}}
                    </span>
                  </span>
                </label>
              </div>
          </div>
        </div> -->
        <div class="membership-details" *ngIf="sectionShow==1">
          <div class="bill-cash">
            <div class="fl-row" style="display: flex;
              flex-direction: row;padding: 0 5px;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership Balance Used:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377; {{membershipUsed}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
              flex-direction: row;padding: 0 5px;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership Balance Left:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377; {{walletLeft}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row w-100  m-0" *ngIf="designType==1">
    <div class="col-md-12 col-lg-12 col-xl-9" style="padding: 20px;padding-top: 1em;">
      <div class="d-flex align-items-center justify-content-between flex-wrap"
        *ngIf="customerId != undefined && customerId != null && customerId != ''">
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-calendar display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75" *ngIf="lastorder!=''">
            <span class="font-weight-bolder font-size-sm">Last Visit</span>
            <span class="font-size-h5">{{lastorder | date:
              'dd MMM,yyyy'}}</span>
          </div>
          <div class="d-flex flex-column text-dark-75" *ngIf="lastorder==''">
            <span class="font-weight-bolder font-size-sm">Last Visit</span>
            <span class="font-size-h5">First Visit</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">Total Billing</span>
            <span class="font-size-h5">
              {{ltv | customcurrency}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">Total Visits</span>
            <span class="font-size-h5">{{lto}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <span class="mr-4">
            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="text-dark-75">
            <span class="font-weight-bolder font-size-sm">Personalised
              Notes</span><br>
            <a class="text-info font-weight-bolder" style="cursor: pointer;"
              (click)="openNotesModel(notesModal)">View</a>&nbsp;
            <a class="text-dark font-weight-bolder" style="cursor: pointer;" (click)="addNotesModel(addnotesModal)">Add
              note</a>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mb-3">
          <button type="button" class="btn btn-light-info btn-sm" (click)="viewProfile()"
            *ngIf="customerId!=undefined">View Full Profile</button>
        </div>
      </div>
      <hr
        style="background-color:#8950fc;height: 3px;margin-top: 0px !important;margin-bottom: 0px !important;margin-left: -0.5em;"
        *ngIf="customerId != undefined && customerId != null && customerId != ''">
      <div style="background-color: white;max-height: 55vh;border-top: 1px solid #ececec; overflow-y: auto; "
        class="row w-100 scroll-style m-0">
        <div class="col-md-4 col-12 col-sm-5">
          <div class="style-2 px-5">
            <div *ngFor="let data of menu;let i=index" class="p-5 w-100 mt-5" (click)="listClick2(data,i)"
              [ngClass]="{'activeFoodSection': sectionIndex == i}"
              style="border-radius: 10px;background-color: #ececec;color: black;text-align: center;width: 16em;cursor: pointer;">
              <span>{{data.sectionName}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-12 col-sm-7 p-5 mt-5" style="background-color: #ececec;border-radius: 10px;">
          <div class="input-group">
            <div class="d-flex">
              <div class="custom-cros">
                <input type="text" class="form-control" placeholder="Search by name" [(ngModel)]="searchText"
                  style="background-color: white;width:100%; height: 38px; padding: 5px 10px;" />
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="row style-1 style-3">
                <div class="col-md-4 mt-2" *ngFor="let item of selectedItem | filter: searchText">
                  <div style="background-color: white;min-height: 5em; cursor: pointer;" class="p-3 hoverClass"
                    (click)="addService3(item, addStaff);">
                    <span class="text-dark-75" style="font-weight: 500;">
                      <span style="font-weight: bold;">{{item.name}}</span><br />
                      {{item.price |customcurrency}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="taskButton" *ngIf="cartList.length!=0" style="display: none;">
          <div class="col-md-8">
            <div class="row w-100 ml-3">
              <label style="font-size: 1.3em;font-weight: bold;">Payment
                Methods:</label>
            </div>
            <div class="paymentOption">
              <div>
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio" name="m_option_2" style="display: none;" value="cash"
                        [(ngModel)]='paymentType' style="display: none;" (click)="getPayment('cash')"
                        [checked]="paymentType==cash" />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        Cash
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div>
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio" name="m_option_2" style="display: none;" value="card"
                        [(ngModel)]='paymentType' style="display: none;" (click)="getPayment('card')"
                        [checked]="paymentType==card" />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        Card
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div>
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio" name="m_option_2" style="display: none;" value="bank"
                        [(ngModel)]='paymentType' style="display: none;" (click)="getPayment('bank')"
                        [checked]="paymentType==bank" />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        Cheque
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div>
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio" name="m_option_2" style="display: none;" value="split"
                        [(ngModel)]='paymentType' style="display: none;" (click)="splitPayment(splitModal)"
                        [checked]="paymentType==split" />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        Split
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div>
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio" name="m_option_2" style="display: none;" value="upi" [(ngModel)]='paymentType'
                        style="display: none;" (click)="getPayment('upi')" [checked]="paymentType==upi" />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        UPI
                      </span>
                    </span>
                  </span>
                </label>
              </div>

            </div>
          </div>
          <div class="col-md-4 pl-0 mt-5">
            <button class="feedback btn w-100" type="button" (click)="closePOSOrder()"
              style="color: white;background-color: #8CC42A;">Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 heightManage  p-xl-0 px-4 col-lg-12  col-xl-3"
      style="border-left: 1px solid #ccc;padding-top: 20px !important;">
      <div class="row m-1 py-xl-0 py-5 justify-content-between align-item-center" style="min-height: 250px;">
        <div class="style-5 col-xl-12 col-md-6 col-12 m-xl-1 m-0 bg-light p-3 rounded-lg"
          *ngIf="cartList.length!=0 && cartList!=undefined && cartList!=null">
          <div class="row p-2 m-0 mt-2" *ngFor="let data of cartList;let i=index"
            style="border-radius: 5px;background:white;box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);border-bottom: 1px dashed black;">
            <div class="col-md-1 mt-3">
              <i class="fas fa-trash text-danger" style="cursor:pointer;" (click)="delItem(i)"></i>
            </div>
            <div class="col-md-8 mt-2" style="word-break: break-word;">
              <p class="cartlistItemName mb-0">{{data.itemName}}</p>
              <small>(Staff - {{data.workerName}})</small>
            </div>
            <div class="col-md-3 mt-3" style="text-align: end;">
              <!-- {{data.costPerItem | customcurrency}} -->
              <input type="text" class="form-control no-radius" value="0.00"
                (keyup)="updateServicePrice(i,data.costPerItem,data)"
                (focusout)="updateServicePrice(i,data.costPerItem,data)" [(ngModel)]="data.costPerItem">
            </div>
            <div class="extra-main-dv w-100" style="padding-left: 12.5px;" *ngIf="data.templateCartDetails.length!=0">
              <p class="extra-main-p mb-0" (click)="toggleCustomizations(i)" style="cursor: pointer;">Customizations
                <span class="angle-icon-down" [style.display]="!showCustomizations[i] ? 'inline-block' : 'none'"><i
                    class="flaticon2-down text-dark" style="font-size: 0.9em;"></i></span>
                <span class="angle-icon-up" [style.display]="showCustomizations[i] ? 'inline-block' : 'none'"><i
                    class="flaticon2-up text-dark" style="font-size: 0.9em;"></i></span>
              </p>
              <div class="extrdp-outer" [style.display]="showCustomizations[i] ? 'inline-block' : 'none'">
                <div class="extrdp w-100" *ngFor="let item of data.templateCartDetails">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-8 pl-0"><span style="font-size:0.9em">+
                      {{item.itemName}}</span></div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-4 text-right p-0"
                    style="font-weight: 400 !important;color: #006630 !important;">{{item.cost
                    | customcurrency}}</div>

                </div>
              </div>
            </div>
            <div class="row w-100 ml-3 mr-3 mt-3" style="justify-content: space-between;">
              <fieldset>
                <input type="button" value="-" class="decrease" (click)="updateItemQty2(data,'dec',i)"
                  [disabled]="data.quantity==0" [ngClass]="{'changeBtn':data.quantity==0}" style="background-color: transparent;
        color: black;
        cursor: pointer;
        width: 24px;
        border-radius: 5px;
        border: 1px solid black;
        height: 24px;" />
                <input type="text" id="six-max" [(ngModel)]="data.quantity" class="ml-2 mr-2"
                  (focusout)="setQty(data,i)" style=" border: none;
        height: 22px;
        width: 20px;
        background: transparent;" />
                <input type="button" value="+" class="increase" (click)="updateItemQty2(data,'inc',i)" style="background-color: transparent;
        color: black;
        cursor: pointer;
        width: 24px;
        border-radius: 5px;
        border: 1px solid black;
        height: 24px;" />
              </fieldset>
              <button class="btn btn-light-info font-weight-bold btn-sm mb-3" style="padding: 5px 5px;"
                (click)="staffOpen(addStaff,i)">Map Staff</button>
              <button class="btn btn-light-info font-weight-bold btn-sm mb-3" style="padding: 5px 5px;"
                (click)="openDiscountModal(salonDiscountModal,i)">Add
                discount</button>
              <button class="btn btn-light-info font-weight-bold btn-sm mb-3" style="padding: 5px 5px;"
                (click)="customSelectOpen(addOnModal,i)">Add
                On<sup>+</sup></button>
            </div>
          </div>
        </div>
        <div class="cashsection  col-xl-12 col-md-6 col-12">
          <h6 class="mt-3" style="color: red;margin: 0px 0 10px 0;padding: 0 15px;font-size: 1em;"
            *ngIf="pendingPayoutAmount!=0 && pendingPayoutAmount!=undefined && pendingPayoutAmount!='' && pendingPayoutAmount!=null">
            Customer has a pending payout of
            {{pendingPayoutAmount|customcurrency}}</h6>
          <h3 class="cash-title" style="color: black;text-align: left;
          line-height: 28px;
          font-size: 20px;
          margin: 10px 0 0px 0;
          padding: 0 15px; ">Billing Summary</h3>
          <div class="bill-cash">
            <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Sub Total:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{orders.subtotal | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.discountedAmount!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Discount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.discountedAmount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.memPerDiscount>0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership % Discount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.memPerDiscount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="orders.promoDiscount!=0 && orders.promoDiscount!=undefined">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Promo Code ({{orders.promoCode}}):
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.promoDiscount | number:'1.2-2'}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Net Amount:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{orders.netAmt? orders.netAmt : 0 | number:'1.2-2'}}
              </div>

            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="orders.walletPoints!=0 && orders.walletPoints!=null && orders.walletPoints!=undefined">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Wallet Points Used:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                - &#8377;{{orders.walletPoints | number:'1.2-2'}}
              </div>
            </div>

            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Taxes:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="taxFlag">
                + &#8377;{{orders.tax? orders.tax : 0 | number:'1.2-2'}}
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="!taxFlag">
                - &#8377;{{orders.tax? orders.tax : 0 | number:'1.2-2'}}
              </div>
            </div>


            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openTotalDiscountModal(salonTotalDiscountModal)" class="text-info">Apply Discount</label>
              </div>
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="removeTotalDisc()" class="text-danger"
                  *ngIf="totalDiscount==1 || orders.discountedAmount > 0">Remove</label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;" value="taxApplicable"
                    [(ngModel)]="taxFlag" (click)="checkTax($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Tax
                    Applicable</label>
                </label>
              </div>
            </div>
            <!-- <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold"
                style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;"
                    value="taxApplicable"
                    [(ngModel)]="itemTaxFlag"
                    (click)="checkItemTax($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Item Price Excluding Tax
                    </label>
                </label>
              </div>
            </div> -->
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkbox" style="display: none;"
                    [disabled]="memUsed==true || taxFlag == false" [(ngModel)]="isIncluseTax"
                    (click)="addInclusiveTax($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Sub
                    Total Inclusive of GST
                  </label>
                </label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="showMem==1 && category_id==4">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="membership"
                    [(ngModel)]='memUsed'
                    [disabled]="membershipData[0]==undefined || membershipData[0].membershipAmount==0"
                    (click)="completeExistingOrder('MS',completeOrder,$event.target.checked)" />
                  <span
                    [ngClass]="{'payment':membershipData[0]==undefined || membershipData[0].membershipAmount==0}"></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Membership
                    Wallet <br>
                    <small class="text-danger">Valid Till :
                      {{membershipData[0].valid_till | date:"dd
                      MMM,yyyy"}}</small></label>
                </label>
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;"
                *ngIf="membershipData[0]!=undefined">
                &#8377;{{membershipData[0].membershipAmount}}

              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 0px 0px 0px 14px;"
                *ngIf="membershipData[0]==undefined">
                &#8377;0
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="category_id==4 && orders.customerName!=null && orders.customerName!=undefined && orders.customerName!=''">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="partail"
                    [(ngModel)]="paymentFlag" (click)="partailPayment(amountModal,$event.target.checked)"
                    [disabled]="disableMode==1" [ngClass]="{'payment':disableMode==1}" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Partial
                    Payment</label>
                </label>
                <small style="color: red;"
                  *ngIf="pendingAmountLeft!=undefined && pendingAmountLeft!='' && pendingAmountLeft!=null">Pending
                  Amount Left: {{pendingAmountLeft|customcurrency}}</small>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;"
              *ngIf="category_id==4 && orders.customerName!=null && orders.customerName!=undefined && orders.customerName!=''">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 8px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxesgst" style="display: none;" value="gstDetail"
                    [(ngModel)]="gstFlag" (click)="gstDetail(gstModal,$event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Enter
                    GST Details</label>
                </label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;"
              *ngIf="loyaltyRules && !isObjectEmpty(loyaltyRules) && category_id==4 && loyaltyRules.available_point!=undefined && loyaltyRules.available_point!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 0px 22px 0px 14px;">
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxespoints" style="display: none;" value="pointss"
                    (click)="applyPoints($event.target.checked)" [(ngModel)]="pointFlag" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;"><i class="fas fa-wallet text-dark"
                      style="font-size: 1.1em;"></i>&nbsp;Wallet
                    Points</label>
                </label>
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377;{{loyaltyRules.available_point}}
              </div>
            </div>
            <div class="row w-100 m-0" style="padding: 0px 22px 8px 14px;"
              *ngIf="loyaltyRules && !isObjectEmpty(loyaltyRules) && category_id==4 && loyaltyRules.available_point!=0 && loyaltyRules.available_point!=undefined">
              <small>{{loyaltyRules.discount_percentage}}% of the Sub Total can
                be paid upto
                {{loyaltyRules.max_discount_amount | customcurrency}} using
                points. (Min. Bill Amount:
                {{loyaltyRules.minimum_order_value | customcurrency}})</small>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;" *ngIf="loyaltyCard.length!=0">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <span style="color: green;font-size:1.1em">Membership %
                  Available</span><br>
                <span style="font-size:1em">{{loyaltyCard[0].cardName}},
                  {{loyaltyCard[0].discountValue}}% discount on total
                  bill</span>
                <label class="checkbox checkbox-outline checkbox-info">
                  <input type="checkbox" name="Checkboxes15" style="display: none;" value="partail"
                    [(ngModel)]="loyaltyFlag" (click)="loyaltyDiscount($event.target.checked)" />
                  <span></span>
                  <label style="padding-left: 0.3em;margin-top: 0.5rem;">Apply</label>
                </label>
              </div>
            </div>
            <div class="fl-row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;" *ngIf="plength!=0">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openPackageModal(packageModal, 'lg')" class="text-info">Check Package Details</label>
              </div>
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                <label style="margin-top: 0.5rem;cursor: pointer;border-bottom: 1px dashed #c3c3c3;"
                  (click)="openPromoModal(promoModal)" class="text-info">Apply
                  An Offer</label>
              </div>
            </div>
          </div>
        </div>

        <div class="thirdParty col-xl-12 col-md-6 col-12" *ngIf="showThirdParty==1">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 15px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Select Third Party Company</h3>
          <div class="paymentOption">
            <div *ngFor="let data of thirdPartyData;let i=index">
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="m_option_2" value="{{data.name}}" [(ngModel)]='thirdPartyType'
                      style="display: none;" />
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      <img src="{{data.icon}}" style="width:2em">&nbsp;{{data.name}}
                    </span>
                  </span>
                  <!-- <span class="option-body">
                    {{segment.desc}}
                  </span> -->
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-md-6 col-12" *ngIf="splitArea==1">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 17px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Split Payment Details</h3>
          <div class="fl-row" style="display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;" *ngFor="let data of paymentList">
            <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
              {{data.mode}}:
            </div>
            <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="data.amount!=''">
              &#8377; {{data.amount | number:"1.2-2"}}
            </div>
            <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;" *ngIf="data.amount==''">
              &#8377; 0
            </div>
          </div>
        </div>
        <!-- <div class="payment-options">
          <h3 style="color: black;text-align: left;
          line-height: 28px;
          font-size: 17px;
          margin: 15px 0 10px 0;
          padding: 0 15px;">Payment Options</h3>
          <div class="paymentOption">
            <div class="row w-100 m-0">
              <div class="input-group" style="display: contents;">
                <div class="input-group-prepend"><span class="input-group-text">₹</span></div>
                <input class="form-control" placeholder="Adjust Payment" [(ngModel)]="adjustPayment" type="number" min="0" style="width: auto; padding: 0; border-bottom: none !important;border: 1px solid #ced4da !important;border-radius: 2 !important;padding: 4px !important;">
               </div>
            </div>
              <div class="mt-5" *ngFor="let data of paymentOption ; let i=index">
                <label class="option">
                  <span class="option-control">
                    <span class="checkbox">
                      <input type="checkbox" id="{{data.id}}" name="{{data.id}}" value="{{data.name}}" style="display: none;"  (click)="completeExistingOrder($event.target.value,i,$event.target.checked)" [disabled]="data.name=='Membership' && data.memAmount==''"/>
                      <span [ngClass]="{'payment':data.name=='Membership' && data.memAmount==''}"></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">
                        {{data.name}} <span *ngIf="data.memAmount!=''">( &#8377; {{data.memAmount}} )</span>
                      </span>
                    </span>
                    <span class="option-body" *ngIf="data.amount!=''">
                      &#8377; {{data.amount}}
                    </span>
                  </span>
                </label>
              </div>
          </div>
        </div> -->
        <div class="membership-details col-xl-12 col-md-6 col-12" *ngIf="sectionShow==1">
          <div class="bill-cash">
            <div class="fl-row" style="display: flex;
              flex-direction: row;padding: 0 5px;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership Balance Used:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377; {{membershipUsed}}
              </div>
            </div>
            <div class="fl-row" style="display: flex;
              flex-direction: row;padding: 0 5px;
              justify-content: space-between;
              align-items: center;">
              <div class="fl-cell fl-cell-lbl font-weight-bold" style="padding: 8px 22px 0px 14px;">
                Membership Balance Left:
              </div>
              <div class="fl-cell text-right ng-binding" style="padding: 8px 22px 0px 14px;">
                &#8377; {{walletLeft}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div [ngClass]="designType!=1 ? 'taskButton1' : 'taskButton2'" class=" flex-md-row flex-column ">
    <div class="col-md-8 col-12">
      <div class="row w-100 ml-3">
        <label style="font-size: 1.3em;font-weight: bold;" class="tabAmount" *ngIf="designType==1">Payable
          Amount (<small> &#8377;</small> {{orders.roundedTotalAmt}})</label>
        <label style="font-size: 1.3em;font-weight: bold;" class="nrmlAmount" *ngIf="designType!=1">Payment
          Methods:</label>
      </div>
      <div class="paymentOption">
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_1" value="cash" [(ngModel)]='paymentType' style="display: none;"
                  [disabled]="disableMode==1" (click)="showCompany($event.target.value)"
                  [checked]="paymentType==cash" />
                <span [ngClass]="{'payment':disableMode==1}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Cash
                </span>
              </span>
              <!-- <span class="option-body">
                {{segment.desc}}
              </span> -->
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_1" value="card" [(ngModel)]='paymentType' style="display: none;"
                  [disabled]="disableMode==1" (click)="showCompany($event.target.value)"
                  [checked]="paymentType==card" />
                <span [ngClass]="{'payment':disableMode==1}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Card
                </span>
              </span>
              <!-- <span class="option-body">
                {{segment.desc}}
              </span> -->
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio"
                  [disabled]="(orders.roundedTotalAmt > (+advancePaymentDetails)) || disableMode==1 || advancePaymentDetails==0"
                  name="m_option_2" style="display: none;" value="advance_payment" [(ngModel)]='paymentType'
                  style="display: none;" (click)="getPayment('advance_payment')"
                  [checked]="paymentType==advance_payment" />
                <span
                  [ngClass]="{'payment': (orders.roundedTotalAmt > (+advancePaymentDetails)) || disableMode==1 || advancePaymentDetails==0 }"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Advance Payment <span *ngIf="advancePaymentDetails">({{advancePaymentDetails |
                    customcurrency}})</span>
                </span>
              </span>
            </span>
          </label>
        </div>
        <div>
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_1" value="upi" [(ngModel)]='paymentType' style="display: none;"
                  [disabled]="disableMode==1" (click)="showCompany($event.target.value)" [checked]="paymentType==upi" />
                <span [ngClass]="{'payment':disableMode==1}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  UPI
                </span>
              </span>
              <!-- <span class="option-body">
                {{segment.desc}}
              </span> -->
            </span>
          </label>
        </div>
        <div *ngIf="category_id==4">
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_1" value="third_party" [(ngModel)]='paymentType'
                  style="display: none;" [disabled]="disableMode==1" (click)="showCompany($event.target.value)"
                  [checked]="paymentType==third_party" />
                <span [ngClass]="{'payment':disableMode==1}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Third Party
                </span>
              </span>
              <!-- <span class="option-body">
                {{segment.desc}}
              </span> -->
            </span>
          </label>
        </div>
        <div *ngIf="category_id==4">
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_1" value="split" [(ngModel)]='paymentType' style="display: none;"
                  [disabled]="disableMode==1 || orders.roundedTotalAmt==0" (click)="splitPayment(splitModal)"
                  [checked]="paymentType==split" />
                <span [ngClass]="{'payment':disableMode==1 || orders.roundedTotalAmt==0}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">
                  Split Payment
                </span>
              </span>
              <!-- <span class="option-body">
                {{segment.desc}}
              </span> -->
            </span>
          </label>
        </div>
        <!-- <div *ngIf="showMem==1 && category_id==4">
          <label class="option">
            <span class="option-control">
              <span class="radio">
                <input type="radio" name="m_option_2" value="membership" [(ngModel)]='memUsed'
                  style="display: none;" [disabled]="membershipData[0]==undefined"
                  (click)="completeExistingOrder('MS',completeOrder)" />
                <span [ngClass]="{'payment':membershipData[0]==undefined}"></span>
              </span>
            </span>
            <span class="option-label">
              <span class="option-head">
                <span class="option-title" *ngIf="membershipData[0]!=undefined">
                  Membership( &#8377; {{membershipData[0].membershipAmount}} )
                </span>
                <span class="option-title" *ngIf="membershipData[0]==undefined">
                  Membership( &#8377; 0 )
                </span>
              </span>
            </span>
          </label>
        </div> -->
      </div>
    </div>
    <div class="col-md-4 col-12 row pl-0 justify-content-end align-items-center mt-5" style="text-align: end;">
      <button class="btn col-md-6 col-4  btn-light-info" (click)="getJobCard()">Print Job
        Card</button>&nbsp;&nbsp;
      <button class="btn col-md-5 col-4 btn-danger" type="button" (click)="openProductMapping(productModal)">Checkout
      </button>
    </div>
  </div>
</div>

<ng-template #promoModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Select Promo</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="d('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <select class="form-control" [(ngModel)]="promoId" (change)="getPromoDetail()">
        <option value>--Select Promo--</option>
        <option *ngFor="let data of promoData" value="{{data.promoCodeId}}">{{data.code}}</option>
      </select>
    </div>
    <div class="row w-100 m-0 mt-5" *ngIf="promoDetails.length!=0" style="display: block;">
      <p class="mb-0" style="font-size: 1.1em;font-weight: 500;">Promo
        Details:</p>
      <span>{{promoDetails.description}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="applyPromo()">Apply</button>
  </div>
</ng-template>

<ng-template #customisationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Customisations</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="d('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="(addedItem.viewType==null || addedItem.viewType==1) && addedItem.templates.length!=0">
      <div class="row w-100 m-0 mt-5" *ngFor="let data of itemTemplates;let i=index"
        style="border-bottom: 1px dashed black;">
        <span style="font-weight: 500;font-size: 1.3em;">{{data.templateName}}</span>
        <span class="text-muted" *ngIf="data.maxQty==data.minQty">&nbsp;(Please
          select any {{data.maxQty}}
          option)</span>
        <span class="text-muted" *ngIf="data.maxQty!=data.minQty">&nbsp;(Choose
          upto {{data.maxQty}} option(s))</span>
        <div class="row w-100 mb-5">
          <div class="col-md-4 mt-5" *ngFor="let item of data.templates;let j=index">
            <input id="{{item.id}}" type="checkbox" name="{{item.id}}" (click)="saveTemplate(item)"
              [checked]="item.selected==1" />
            <label for="{{item.id}}" class="box">
              <img *ngIf="item.vegNonvegBoth=='veg'" src="../../../../../assets/media/misc/Veg-Icon.png"
                style="width: 1em;">
              <img *ngIf="item.vegNonvegBoth=='non-veg'" src="../../../../../assets/media/misc/Non-Veg-Icon.png"
                style="width: 1em;">&nbsp;{{item.itemName}}
              <small>&nbsp;&nbsp;({{item.sp | customcurrency}})</small>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="addedItem.viewType==null && addedItem.variant!=undefined">
      <span style="font-weight: 500;font-size: 1.3em;">Variants</span>
      <div class="row w-100 mb-5 pb-5" style="border-bottom: 1px dashed black;">
        <!-- <ng-container > -->
        <div class="col-md-4 mt-5" *ngFor="let data of itemTemplates;let i=index">
          <div
            style="background-color: white;max-height: 5em;cursor: pointer;border: 1px solid black;position: relative;"
            class="p-3 hoverClass" (click)="selectTemplate(data.variants,i)"
            [ngClass]="{'selectedDiv': variantIndex==i}">
            <div class="rightCircle" *ngIf="variantIndex==i">
              <i class="fas fa-check" style="color: #8CC42A;"></i>
            </div>
            <span class="text-dark-75" style="font-weight: 500;">
              <img *ngIf="data.variants.vegNonvegBoth=='veg'" src="../../../../../assets/media/misc/Veg-Icon.png"
                style="width: 1em;">
              <img *ngIf="data.variants.vegNonvegBoth=='non-veg'"
                src="../../../../../assets/media/misc/Non-Veg-Icon.png"
                style="width: 1em;">&nbsp;{{data.variants.itemName}}</span><br>
            <small>{{data.variants.sp | customcurrency}}</small>
          </div>
        </div>
        <!-- </ng-container> -->
      </div>
      <div class="row w-100 m-0 mt-5" *ngFor="let data of variantTemplates;let i=index"
        style="border-bottom: 1px dashed black;">
        <span style="font-weight: 500;font-size: 1.3em;">{{data.templateName}}</span>
        <span class="text-muted">&nbsp;(Choose upto {{data.maxQty}}
          option(s))</span>
        <div class="row w-100 mb-5">
          <div class="col-md-4 mt-5" *ngFor="let item of data.templates;let j=index">
            <input id="{{item.id}}" type="checkbox" name="{{item.id}}" (click)="saveTemplate(item)"
              [checked]="item.selected==1" />
            <label for="{{item.id}}" class="box">
              <img *ngIf="item.vegNonvegBoth=='veg'" src="../../../../../assets/media/misc/Veg-Icon.png"
                style="width: 1em;">
              <img *ngIf="item.vegNonvegBoth=='non-veg'" src="../../../../../assets/media/misc/Non-Veg-Icon.png"
                style="width: 1em;">&nbsp;{{item.itemName}}
              <small>&nbsp;&nbsp;({{item.sp | customcurrency}})</small>
            </label>
          </div>
          <!-- <div class="col-md-4 mt-5" *ngFor="let item of data.templates;let j=index">
            <div style="background-color: white;max-height: 5em;cursor: pointer;border: 1px solid black;"
              class="p-3 hoverClass" (click)="selectTemplate(item)">
              <span class="text-dark-75" style="font-weight: 500;">
                <img *ngIf="item.vegNonvegBoth=='veg'" src="../../../../../assets/media/misc/Veg-Icon.png"
                  style="width: 1em;">
                <img *ngIf="item.vegNonvegBoth=='non-veg'" src="../../../../../assets/media/misc/Non-Veg-Icon.png"
                  style="width: 1em;">&nbsp;{{item.itemName}}</span><br>
              <small>{{item.sp | customcurrency}}</small>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="addItemToCart()" style="background-color: #8cc42a;color: white;">Add
      Item</button>
  </div>
</ng-template>
<ng-template #addOnModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Select Add On</h5>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0 mt-2">
      <div class="col-lg-6">
        <label class="control-label">Add On:</label>
        <ejs-dropdownlist [dataSource]='customData' [fields]='customFields' [placeholder]='customtext'
          (change)="customSelect($event)" [value]="customList">
        </ejs-dropdownlist>
      </div>
      <div class="col-lg-6">
        <label class="control-label">Amount:</label>
        <input type="number" class="form-control no-radius" [(ngModel)]="customAmount" min="1">
        <div class="text-danger">{{tipErrorMsg}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    <button type="button" class="btn btn-info" (click)="addCustomisation()">Save</button>
  </div>
</ng-template>
<ng-template #splitModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Payment Split</h5>
  </div>
  <div class="modal-body">
    <div class="row p-2">
      <div class="col-lg-6">
        <span style="color: green;">Total Order Amount:
          &#8377;{{orders.roundedTotalAmt}}</span>
      </div>
      <div class="col-lg-6">
        <span style="color: red;" *ngIf="sameAmount!=1">Amount Pending:
          &#8377;{{splitAmountLeft}}</span>
        <span style="color: red;" *ngIf="sameAmount==1">Amount Pending:
          &#8377;{{orders.roundedTotalAmt}}</span>
      </div>
    </div>
    <div>
      <div class="row w-100 m-0 mt-3">
        <div class="col-md col-6" style="cursor: pointer;" *ngFor="let data of paymentList;let i=index"
          (click)="changePaymentStatus(i)">
          <div style="border-radius: 5px;border:1px solid black;text-align: center;" class="p-3"
            [ngClass]="{'changeBackground':data.added==1}">
            <span class="font-weight-bold" style="color: black;" [ngClass]="{'changeIcon':data.added==1}">{{data.mode}}
              <span *ngIf="data.mode=='Advance Payment' && advancePaymentDetails">{{advancePaymentDetails
                |
                customcurrency}}</span></span>
          </div>
        </div>
      </div>
      <div *ngFor="let data of paymentList;let i=index">
        <ng-container *ngIf="data.added==1">
          <div class="row w-100 m-0 mt-3">
            <label class="font-weight-bold">Enter {{data.mode}} amount:</label>
            <input type="text" class="form-control no-radius" (change)="addPayment(i)" [(ngModel)]="data.amount">
          </div>
        </ng-container>
      </div>
    </div>
    <!-- <div class="row w-100 m-0 mt-2" *ngFor="let data of paymentList;let i=index">
      <div class="col-lg-5">
        <ejs-dropdownlist [dataSource]='paymentData' [fields]='paymentFields' [placeholder]='paymenttext'
          (change)="paymentMethod($event,i)" popupWidth='50%' [value]="data.mode" style="width: 250px;">
        </ejs-dropdownlist>
      </div>
      <div class="col-lg-5">
        <input type="text" class="form-control no-radius" (change)="addPayment(i)" [(ngModel)]="data.amount">
      </div>
      <div class="col-lg-2">
      </div>
    </div>
    <div class="row w-100 m-0 mt-2">
      <a style="cursor: pointer;color: #8950fc;font-weight: 500;font-size: 1.1em;" (click)="addPaymentSplit()">Add
        Payment Method<sup>+</sup></a>
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click');closePayment()">Close</button>
    <button type="button" class="btn" (click)="savePaymentSplit()"
      style="background-color: #8CC42A;color: white;">Split</button>
  </div>
</ng-template>
<ng-template #productModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Product Consumption</h5>
  </div>
  <div class="modal-body">
    <div class="col-xl-12 p-3 bg-light col-md-12 col-sm-12 mt-3 " style="border-radius: 5px;">
      <!-- <label>
         Include GST Tax
      </label>
      <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-info">
         <input type="checkbox" name="Checkboxes16" [(ngModel)]="variantDetail.includeGST" [checked]="variantDetail.includeGST" style="display: none;"/>
         <span></span>
       </label> -->
      <label class="checkbox checkbox-outline checkbox-info">
        <input type="checkbox" name="Checkboxes16" [(ngModel)]="isPrint" [checked]="variantDetail.includeGST"
          style="display: none;" />
        <span></span>&nbsp;
        <strong class="ml-1" style="font-size: 1.1rem;">
          Do you want invoice to be print on bill creation ?
        </strong>
      </label>
    </div>
    <div class="row mt-3 p-2" *ngFor="let data of itemarr[0].itemData;let i = index"
      [ngStyle]="{'border-bottom': i !== itemarr[0].itemData.length - 1 ? '1px solid #ccc' : 'none'}">
      <div class="col-md-4">
        <span class="font-weight-bold text-dark">{{data.itemName}}</span>
      </div>
      <div class="col-md-4">
        <span>Product Used:</span><br>
        <select class="form-control" [(ngModel)]="data.productUsed" (change)="mapProduct($event.target.value,i)">
          <option value>--SELECT--</option>
          <option *ngFor="let data of productData" value="{{data.id}}">{{data.itemName}}</option>
        </select>
      </div>
      <div class="col-md-4">
        <span>Quantity Used:</span><br>
        <div class="form-group">
          <!-- <label>Right Icon Input</label> -->
          <div class="input-icon input-icon-right">
            <input type="number" class="form-control" placeholder="Enter Usage Quantity" [(ngModel)]="data.productQty"
              (change)="mapQuantity(data.productQty,i)" min="0" />
            <span>{{data.unit}}</span>
          </div>
          <!-- <span class="form-text text-muted">Some help content goes here</span> -->
        </div>
      </div>

      <!-- <div class="input-group">
          <input type="number" class="form-control" placeholder="Enter Usage Quantity" [(ngModel)]="data.productQty"
            (change)="mapQuantity(data.productQty,i)" min="0" />
          <span class="text-muted">{{data.unit}}</span>
          <div class="input-group-append"><span class="input-group-text">{{unit}}</span></div>
        </div> -->
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    <button type="button" [ngClass]="{'btn-sm spinner spinner-white spinner-right': isloading}" class="btn btn-info "
      (click)="closeSalonOrder()" [disabled]="isloading">Generate Bill</button>
  </div>
</ng-template>
<ng-template #completeOrder let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Complete Order</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="d('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="errorMessage.length > 0" class="errorAlert">
      <span>{{ errorMessage }}</span>
    </div>
    <div class="row">
      <div class="col-xl-3 col-md-3 col-sm-3">
        <span *ngIf="category_id!=4"><b> Total Items: </b></span>
        <span *ngIf="category_id==4"><b> Total Services: </b></span>
        <label>{{ orders.itemCount }}</label>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-3">
        <span><b> Total Amount: </b></span>
        <label>&#8377; {{ orders.totalAmt }}</label>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-3" *ngIf="category_id!=4">
        <span><b> Order Source: </b></span>
        <label>{{ orders.source }}</label>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-3" *ngIf="category_id!=4">
        <span><b> Order Type: </b></span>
        <label *ngIf="orders.orderType == '1'">Delivery</label>
        <label *ngIf="orders.orderType == '2'">Take Away</label>
        <label *ngIf="orders.orderType == '3'">Dine In</label>
        <label *ngIf="orders.orderType == '4'">Walk-In</label>
      </div>
    </div>
    <!-- <div class="row w-100 mt-3">
      <span
        class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-6 px-5">Customer
        Details</span>
    </div> -->

    <div class="row" style="padding: 1%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Customer Name:</span>
        <input type="text" [(ngModel)]="orders.customerName" class="form-control" />
      </div>
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Customer Mobile Number:</span>
        <input type="text" [(ngModel)]="orders.mobileNo" class="form-control" *ngIf="category_id!=4" maxlength="10"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
        <input [(ngModel)]="orders.mobileNo" class="form-control" *ngIf="category_id==4" (keyup)="checkMembership()"
          type="text" maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
        <label class="radio mt-2" *ngIf="category_id==4 && showMem==1" style="display: inline-flex;">
          <input type="radio" name="radios2" style="display: none;" [(ngModel)]="memUsed" value="membership"
            [disabled]="membershipData[0]==undefined" (click)="completeExistingOrder('MS',completeOrder)" />
          <span [ngClass]="{'payment':membershipData[0]==undefined}"></span>&nbsp;
          Membership<label *ngIf="membershipData[0]!=undefined">(&#8377;
            {{membershipData[0].membershipAmount}})</label>
          <label *ngIf="membershipData[0]==undefined">(&#8377; 0)</label>
        </label>
      </div>
    </div>
    <div class="row" style="padding: 1%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label>Payment Type:</label>
          <div class="radio-inline">
            <label class="radio">
              <input type="radio" name="radios1" [(ngModel)]="paymentType" value="cash" style="display: none;"
                [disabled]="disableMode==1" />
              <span [ngClass]="{'payment':disableMode==1}"></span>
              Cash
            </label>
            <label class="radio">
              <input type="radio" name="radios1" [(ngModel)]="paymentType" value="card" style="display: none;"
                [disabled]="disableMode==1" />
              <span [ngClass]="{'payment':disableMode==1}"></span>
              Card
            </label>
            <label class="radio">
              <input type="radio" name="radios1" [(ngModel)]="paymentType" value="upi" style="display: none;"
                [disabled]="disableMode==1" />
              <span [ngClass]="{'payment':disableMode==1}"></span>
              UPI
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 p-0" *ngIf="sectionShow==1">
      <div class="col-md-6">
        <div class="card">
          <li class="list-group-item text-center font-weight-bolder" style="background-color: #ffffff">Membership
            Details</li>
          <ul class="list-group list-group-flush style-1">
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 font-weight-bolder">
                  Payable Amount:
                </div>
                <div class="col-md-6">
                  &#8377;{{orders.totalAmt}}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 font-weight-bolder">
                  Wallet Balance Used:
                </div>
                <div class="col-md-6">
                  &#8377;{{membershipUsed}}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 font-weight-bolder">
                  Membership Balance Left:
                </div>
                <div class="col-md-6">
                  <label
                    class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-4 px-5">&#8377;{{walletLeft}}</label>
                </div>
              </div>
            </li>
            <!-- <li class="list-group-item" *ngIf="disableMode==0">
              <div class="row">
                <div class="col-md-6 font-weight-bolder">
                  Pending Amount:
                </div>
                <div class="col-md-6">
                  <label
                    class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-4 px-5">&#8377;{{amountLeft}}</label>
                </div>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="row" *ngIf="orders.orderType == '1' && orders.sourceId == '2'">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Address Line1</span>
        <input type="text" [(ngModel)]="orders.addressLine1" class="form-control" />
      </div>
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Address Line2</span>
        <input type="text" [(ngModel)]="orders.addressLine2" class="form-control" />
      </div>
    </div>
    <div class="row" style="padding: 2%;" *ngIf="orders.orderType == '1' && orders.sourceId != '2'">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Linked Order Id</span>
        <input type="text" [(ngModel)]="orders.linkedOrderId" class="form-control" />
      </div>
    </div>
    <div *ngIf="ifPackingChargesApplicable == 1" class="row divLabel">
      <div class="col">
        <i class="fa fa-bars" aria-hidden="true"></i>
        <span align="center">Miscellaneous</span>
      </div>
    </div>
    <div *ngIf="ifPackingChargesApplicable == 1" class="row" style="padding: 2%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Packing Charges</span>
        <input type="text" [(ngModel)]="orders.packingCharges" on-focusout="packingCharges()" />
      </div>
    </div> -->
    <!-- <div class="row w-100 mt-3">
      <span
        class="label label-lg label-light-primary label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-6 px-5">Payment
        Mode</span>
    </div> -->
    <!-- <div class="row">
      <div class="col middle" style="text-align: left;">
        <label style="margin: 1%; border: 1px solid;">
          <input type="radio" name="radio" />
          <div class="box" (click)="completeExistingOrder('C')" style="width: 10em;">
            <span><i style="font-size: 2em; padding-top: 0.8em;" class="fa fa-rupee-sign"></i></span><br />
            <label>CASH</label>
          </div>
        </label>
        <label style="margin: 1%; border: 1px solid;">
          <input type="radio" name="radio" />
          <div class="box" (click)="completeExistingOrder('CC')" style="width: 10em;">
            <span><i style="font-size: 2em; padding-top: 0.8em;" class="fa fa-credit-card"
                aria-hidden="true"></i></span><br />
            <label>CARD</label>
          </div>
        </label>
        <label style="margin: 1%; border: 1px solid;">
          <input type="radio" name="radio" />
          <div class="box" (click)="completeExistingOrder('W')" style="width: 10em;">
            <span>
              <img style="height: 5em;" src="../../assets/media/misc/paytm.png" aria-hidden="true" /></span><br />
            <label>PAYTM</label>
          </div>
        </label>
        <label style="margin: 1%; border: 1px solid;">
          <input type="radio" name="radio" />
          <div class="box" (click)="completeExistingOrder('OL')" style="width: 10em;">
            <span><i style="font-size: 2em; padding-top: 0.8em;" class="fa fa-credit-card custom"></i></span><br />
            <label>PREPAID</label>
          </div>
        </label>
        <label style="margin: 1%; border: 1px solid;" *ngIf="category_id == 4">
          <input type="radio" name="radio" />
          <div class="box" (click)="completeExistingOrder('MS')" style="width: 10em;">
            <span><i style="font-size: 2em; padding-top: 0.8em;" class="fa fa-credit-card custom"></i></span><br />
            <label>MEMBERSHIP</label>
          </div>
        </label>
      </div>
    </div> -->
    <!-- <div class="row" style="padding: 1%;">
      <div class="col-md-6 col-lg-6 col-xxl-6">
        <span>Remarks</span>
        <input type="text" [(ngModel)]="orders.remarks" class="form-control"/>
      </div>
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Back
    </button>
    <button type="button" class="btn btn-danger" (click)="close();">
      Close Order
    </button>
    <!-- <a
      type="button"
      class="btn btn-primary"
      target="_blank"
      routerLink="/printOnlineOrder/{{ orders.orderId }}"
      >Print</a
    > -->
    <!-- <button type="button" class="btn btn-primary" (click)="printKOT(orders.orderId)">
      KOT
    </button> -->
    <button type="button" class="btn btn-primary" (click)="closeOrder(paymentModel)" *ngIf="category_id!=4">
      Initiate Payment
    </button>
    <!-- <button type="button" class="btn btn-primary" (click)="print()" *ngIf="showPrint==1">
      Print
    </button> -->
    <!-- <button type="button" class="btn btn-primary" (click)="printkot()">
      Print KOT
    </button> -->
    <!-- <button type="button" class="btn btn-primary" (click)="closeOrder(2)">
      Print & Close Order
    </button> -->
  </div>
</ng-template>
<ng-template #existingOrderModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="row mt-3" *ngFor="let ord of existingOrderArray; let i = index"
      style="padding: 1%;border: 1px solid #ececec;">
      <div class="col-xl-2 col-md-2 col-sm-2">
        <div>
          <span class="font-weight-bold text-dark">Order Id</span>
        </div>
        <div>
          <span>{{ ord.orderId }}</span>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-3">
        <div>
          <span class="font-weight-bold text-dark">Customer Name</span>
        </div>
        <div>
          <span *ngIf="ord.customerName!=''">{{ ord.customerName }}</span>
          <span *ngIf="ord.customerName==''">N/A</span>
        </div>
      </div>
      <div class="col-xl-2 col-md-2 col-sm-2">
        <div>
          <span class="font-weight-bold text-dark">Mobile Number</span>
        </div>
        <div>
          <span *ngIf="ord.mobileNo!=''">{{ ord.mobileNo }}</span>
          <span *ngIf="ord.mobileNo==''">N/A</span>
        </div>
      </div>
      <div class="col-xl-2 col-md-2 col-sm-2" *ngIf="category_id!=4">
        <div>
          <span class="font-weight-bold text-dark">Order Type</span>
        </div>
        <div>
          <span *ngIf="ord.orderType==''">N/A</span>
          <span *ngIf="ord.orderType == '1'">Delivery</span>
          <span *ngIf="ord.orderType == '2'">Take Away</span>
          <span *ngIf="ord.orderType == '3'">Dine In</span>
          <span *ngIf="ord.orderType == '4'">Walk-In</span>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-3">
        <!-- <button class="btn btn-primary" (click)="openOrder(ord.orderId);">Open</button> -->
        <button class="btn btn-danger" mwlConfirmationPopover [popoverTitle]="popoverTitle"
          [popoverMessage]="popoverMessage" placement="left" (confirm)="deleteOrder(i)" (cancel)="cancelClicked = true">
          Delete
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" #closeVendorModal (click)="c('Close click')" style="padding: 8px;">
      Close
    </button>
  </div>
</ng-template>
<ng-template #discountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="discountAlertMessage.length > 0" class="errorAlert">
      <span>{{ discountAlertMessage }}</span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>Discount Type</span>
        <select class="form-control" [(ngModel)]="orders.discountType">
          <option value="1">Fix Amount</option>
          <option value="2">Percentage</option>
        </select>
      </div>
      <div class="col-md-6">
        <span>Discount Applicable On</span>
        <select class="form-control" [(ngModel)]="discountApplicableOn">
          <option value="1">Food</option>
          <option value="2">Drinks</option>
          <option value="3">All</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>Discount</span>
        <input type="number" [(ngModel)]="orders.discount" class="form-control" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="discountCart()">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #itemdiscountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <span>Discount</span>
        <input type="number" [(ngModel)]="item.discount" class="form-control" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="applyDiscount()">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #newOrders let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Create New Order</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="errorAlert" *ngIf="alertMessage.length > 0">
      <span>{{ alertMessage }}</span>
    </div>
    <!-- <div class="row divLabel">
      <div class="col">
        <span align="center">Customer Details</span>
      </div>
    </div> -->
    <div class="row" style="padding: 1%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Customer Name</span>
        <input type="text" [(ngModel)]="newOrder.customerName" class="form-control" />
      </div>
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Customer Mobile Number</span>
        <input type="text" [(ngModel)]="newOrder.mobileNo" (focusout)="getDeliveryAddress()" class="form-control"
          maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
      </div>
    </div>
    <div *ngIf="channels.length > 1" class="row divLabel">
      <div class="col">
        <span align="center">Order Source</span>
      </div>
    </div>
    <div class="row mt-3" *ngIf="channels.length > 1">
      <div class="col-md-2 middle boxSource" *ngFor="let channel of channels">
        <label>
          <input type="radio" name="radio" />
          <div class="col-md-12 box" (click)="sourceNavigation(channel, 1)">
            <img style="width: 5em;" src="{{ channel.icon }}" />
            <p style="color: #000;">{{ channel.channel_name }}</p>
          </div>
        </label>
      </div>
    </div>
    <div *ngIf="showOrderType == 'true'" class="row divLabel">
      <div class="col">
        <span align="center">Order Type</span>
      </div>
    </div>

    <div *ngIf="showOrderType == 'true'" class="row" id="channelSource">
      <a *ngIf="showDineIn == '1'" class="btn-lg btn-light" (click)="sourceNavigation(1, 2)"><i
          class="la la-cutlery"></i>Dine In</a>
      <a *ngIf="showTakeAway == '1'" class="btn-lg btn-light" (click)="sourceNavigation(2, 2)"><i class="fa fa-male"
          aria-hidden="true"></i> Take Away</a>
      <a *ngIf="showDelivery == '1'" class="btn-lg btn-light" (click)="sourceNavigation(3, 2)"><i
          class="la la-car"></i>Delivery</a>
    </div>
    <div *ngIf="newOrder.orderType == '1'" class="row divLabel">
      <div class="col">
        <span align="center">Address</span>
      </div>
    </div>
    <div *ngIf="newOrder.orderType == '1' && newOrder.sourceId == 2" class="row">
      <table width="100%" class="item-table">
        <thead>
          <tr>
            <th style="width: 10%;">#</th>
            <th style="width: 40%;">Address Line1</th>
            <th style="width: 10%;">Address Line2</th>
            <th style="width: 20%;">City</th>
            <th style="width: 20%;">State</th>
            <th style="width: 20%;">Pincode</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let delivery of deliveryAddArray; let i = index">
            <tr>
              <td>
                <input type="radio" name="deliveryId" (click)="updateAddress(delivery)" />
              </td>
              <td>
                <span>{{ delivery.addressLine1 }}</span>
              </td>
              <td>{{ delivery.addressLine2 }}</td>
              <td>{{ delivery.city }}</td>
              <td>{{ delivery.state }}</td>
              <td>{{ delivery.pincode }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="newOrder.orderType == '1' && newOrder.sourceId != 2" class="row">
      <div class="col-xl-6 col-md-6">
        <span>Linked Order Id</span>
        <input type="text" class="form-control" placeholder="Linked OrderId" [(ngModel)]="newOrder.linkedOrderId" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Close</button>
    <button type="button" class="btn btn-info" (click)="createNewOrders(1)">
      Create Order
    </button>
  </div>
</ng-template>
<ng-template #addQty let-c="close" let-d="dismiss">
  <div class="modal-body" style="padding: 0%;">
    <div class="col-xl-12" style="border-bottom: 1px solid #ececec; padding: 4%;">
      <input type="text" class="form-control" [(ngModel)]="quantityModel" id="qty" placeholder="Quantity" />
    </div>
    <div class="buttons">
      <div (click)="updateText('1')" class="button">1</div>
      <div (click)="updateText('2')" class="button">2</div>
      <div (click)="updateText('3')" class="button">3</div>
      <div (click)="updateText('4')" class="button">4</div>
      <div (click)="updateText('5')" class="button">5</div>
      <div (click)="updateText('6')" class="button">6</div>
      <div (click)="updateText('7')" class="button">7</div>
      <div (click)="updateText('8')" class="button">8</div>
      <div (click)="updateText('9')" class="button">9</div>
      <div (click)="updateText('0')" class="button">0</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" #closeVendorModal (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="updateTriggerModal()">
      Save
    </button>
  </div>
</ng-template>
<ng-template #openItem let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Add Open Item</h5>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 0%;">
    <div class="row" style="padding: 1%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Item Name</span>
        <input type="text" [(ngModel)]="openItems.itemName" class="form-control" />
      </div>
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Item Price</span>
        <input type="text" [(ngModel)]="openItems.sp" class="form-control" />
      </div>
    </div>
    <div class="row" style="padding: 1%;">
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Tax</span>
        <input type="text" [(ngModel)]="openItems.tax" class="form-control" />
      </div>
      <div class="col-xl-6 col-md-6 col-sm-6">
        <span>Food Category</span>
        <select class="form-control" [(ngModel)]="openItems.foodCategory">
          <option value="1">Food</option>
          <option value="2">Drinks</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" #closeVendorModal (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="addOpenItems()">
      Add Items
    </button>
  </div>
</ng-template>
<ng-template #templateModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="row">
      <div style="width: 100%;" *ngFor="let items of templateItemParentDetailArray">
        <div *ngIf="items.variantDetails?.length > 0">
          <div class="col-xl-12 col-md-12 col-xl-sm col-xs-12 p-3"
            style="background-color: #333; color: #fff; font-weight: 600;">
            <span>{{ items.itemName }}</span>
          </div>
          <div *ngFor="let item of items.variantDetails" class="col-xl-12 col-md-12 col-xl-sm col-xs-12 itemDisplay">
            <div>
              <div class="card-body">
                <div class="col-xl-12" style="display: flex;">
                  <span class="col-xl-8" style="text-align: left;">{{
                    item.itemName
                    }}</span>
                  <span class="col-xl-3" style="text-align: left;"><span>&#x20B9; {{ item.sp
                      }}</span></span>
                  <span (click)="addToCart(item)" style="
                      background-color: #51ab6d;
                      border-radius: 5px;
                      color: white;
                      font-size: 1em;
                      font-weight: bold;
                      height: 1.5em;
                      text-align-last: center;
                    " class="col-xl-1">Add</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary p-2" #closeVendorModal (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="discountCart()">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #complimentaryModel let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Mark Complimentary</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <span>Items to be Marked Complimentary</span>
        </div>
        <div>
          <input class="form-control" type="number" [(ngModel)]="noOfOrderMarkComplimentary" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" #closeVendorModal (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="markItemComplimentary()">
      Save
    </button>
  </div>
</ng-template>
<ng-template #salonDiscountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <div class="alert alert-custom alert-light-danger fade show mb-5 w-100" role="alert" style="height: 4em;">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Maximum Discount Limit Is
          {{maxDiscountAllowed}}%.</div>
      </div>
    </div>
    <div class="row w-100 m-0 mt-5">
      <div class="col-xl-4 col-lg-4">
        <label>Discount Type:</label>
        <select class="form-control" [(ngModel)]="selectedItemCart.disType">
          <option value>--Discount Type--</option>
          <option value="percentage">Percentage (%)</option>
          <option value="value">Value (&#8377;)</option>
        </select>
      </div>
      <div class="col-xl-4 col-lg-4">
        <label>Value:</label>
        <input type="text" class="form-control no-radius" maxlength="5"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          [(ngModel)]="selectedItemCart.disValue">
      </div>
      <div class="col-xl-4 col-lg-4">
        <label>Discount Remark:</label>
        <select class="form-control" [(ngModel)]="selectedItemCart.discRemark">
          <option value>--Remark--</option>
          <option value="Manager">Manager</option>
          <option value="Owner">Owner</option>
          <option value="Shoutlo" *ngIf="category_id==4">Shoutlo</option>
          <option value="Make My Trip" *ngIf="category_id==4">Make My
            Trip</option>
        </select>
      </div>
    </div>
    <!-- <div class="row w-100 m-0 mt-3">
      <a (click)="sendReq(approvalModal)" style="color: blue;cursor: pointer;">Request To Increase Discount Limit</a>
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="addItemDiscount()">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #salonTotalDiscountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <div class="alert alert-custom alert-light-danger fade show mb-5 w-100" role="alert" style="height: 4em;">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Maximum Discount Limit Is {{maxDiscountAllowed}}%.</div>
      </div>
    </div>
    <div class="row w-100 m-0 mt-5">
      <div class="col-xl-6 col-lg-6">
        <label>Discount Type:</label>
        <select class="form-control" [(ngModel)]="orders.discountType">
          <option value>--Discount Type--</option>
          <option value="1">Percentage (%)</option>
          <option value="2">Value (&#8377;)</option>
        </select>
      </div>
      <div class="col-xl-6 col-lg-6">
        <label>Value:</label>
        <input type="text" class="form-control no-radius" maxlength="5"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          [(ngModel)]="orders.discount">
      </div>
    </div>
    <!-- <div class="row w-100 m-0 mt-3">
      <a (click)="sendReq(approvalModal)" style="color: blue;cursor: pointer;">Request To Increase Discount Limit</a>
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
    <button type="button" [disabled]="maxDiscountAllowed <=0 " class="btn btn-primary" (click)="addTotalDiscount()">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #packageModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Package Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="row" *ngFor="let data of packageDetailsArr;let i=index" style="padding: 0.5em;margin-bottom: 0.5em;">
      <div class="checkbox-list">
        <label class="checkbox checkbox-outline checkbox-info checkbox-lg">
          <input type="checkbox" name="Checkboxes15" (click)="addItem(data.itemId)" [disabled]="data.redeemed!=0" [ngClass]="{'cursorStyle':data.redeemed!=0}"/>
          <span></span>
          Package Name: {{data.packageName}}\<br>
          Item Name: {{data.itemName}}
        </label>
      </div>
    </div> -->
    <div class="d-flex align-items-center mb-9 bg-light-info rounded p-5"
      *ngFor="let data of packageDetailsArr;let i=index">
      <span class="mr-5">
        <i class="fas fa-spa fa-2x pr-3" aria-hidden="true" style="color: #8950fc!important;"></i>
      </span>
      <div class="d-flex flex-column flex-grow-1 mr-2">
        <label class="font-weight-bold text-dark-75  font-size-lg mb-1">Package:
          {{data.pName}}</label>
        <div class="row w-100 mt-3" *ngFor="let item of data.items;let j=index">
          <div class="col-xl-8">
            <label class="font-weight-bold text-dark-75  font-size-lg mb-1">Item:
              {{item.itemName}}</label>
          </div>
          <div class="col-xl-4" style="text-align: right;">
            <button class="btn btn-info btn-sm font-weight-bold" *ngIf="item.redeemed==0"
              (click)="addItem(item.itemId,i,j)"
              style="border: #8950fc!important; background-color: #8950fc!important;">Add
              To Cart</button>
            <button class="btn btn-light-info btn-sm font-weight-bold" *ngIf="item.redeemed!=0" disabled
              style="cursor: not-allowed;">Added To Cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
  </div>
</ng-template>
<ng-template #approvalModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
        <i class="fas fa-times"></i>
      </button>
    </h4>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <label style="font-size: 14px;font-weight:400">Enter Discount
        Limit:</label>
      <input class="form-control" placeholder="Enter discount limit" [(ngModel)]="discLimit">
    </div>
    <!-- <div class="row w-100 m-0 justify-content-center p-2">
      <img src="../../../../../assets/media/misc/payment.png" style="width: 7em;">
    </div>
    <div class="row w-100 m-0 justify-content-center p-2">
      <label class="font-weight-bold text-dark-75" style="font-size: 25px;">Checking approval status...</label>
      <label class="text-muted" style="font-size: 15px;">Please wait while we check your approval status.</label>
    </div>
    <div class="row w-100 m-0 justify-content-center p-2">
      <img src="../../../../../assets/media/misc/paymentLoader.gif" style="width: 17em;">
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" (click)="sendDiscReq(otpModal)" class="btn btn-info">
      Send Request
    </button>
  </div>
</ng-template>
<ng-template #otpModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
        <i class="fas fa-times"></i>
      </button>
    </h4>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <label style="font-size: 14px;font-weight:400">Enter OTP:</label>
      <input class="form-control" placeholder="Enter otp" [(ngModel)]="otp">
    </div>
    <!-- <div class="row w-100 m-0 justify-content-center p-2">
      <img src="../../../../../assets/media/misc/payment.png" style="width: 7em;">
    </div>
    <div class="row w-100 m-0 justify-content-center p-2">
      <label class="font-weight-bold text-dark-75" style="font-size: 25px;">Checking approval status...</label>
      <label class="text-muted" style="font-size: 15px;">Please wait while we check your approval status.</label>
    </div>
    <div class="row w-100 m-0 justify-content-center p-2">
      <img src="../../../../../assets/media/misc/paymentLoader.gif" style="width: 17em;">
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" (click)="verifyOtp()" class="btn btn-info">
      Verify
    </button>
  </div>
</ng-template>
<ng-template #amountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Partail Amount</h4>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0 justify-content-center p-2">
      <input type="number" min="0" [(ngModel)]="partialAmount" class="form-control" placeholder="Enter amount">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-secondary">
      Close
    </button>
    <button type="button" (click)="addPartialPayment()" class="btn btn-info">
      Update Amount
    </button>
  </div>
</ng-template>
<ng-template #serviceModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Past Services</h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
        <thead>
          <th>SNo.</th>
          <th>Service Taken</th>
          <th>Date</th>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="c.close()" class="btn btn-secondary">
      Close
    </button>
  </div>
</ng-template>
<!-- <ng-template #totalDiscModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0">
      <div class="alert alert-custom alert-light-danger fade show mb-5 w-100" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Maximum Discount Limit Is {{maxDiscountAllowed}}%.</div>
    </div>
    </div>
    <div class="row w-100 m-0 mt-5">
      <div class="col-xl-4">
        <label>Discount Type:</label>
        <select class="form-control" [(ngModel)]="selectedItemCart.disType">
          <option value="">--Discount Type--</option>
          <option value="percentage">Percentage (%)</option>
          <option value="value">Value (&#8377;)</option>
        </select>
      </div>
      <div class="col-xl-4">
        <label>Value:</label>
        <input type="text" class="form-control no-radius" maxlength="5"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          [(ngModel)]="selectedItemCart.disValue">
      </div>
      <div class="col-xl-4">
        <label>Discount Remark:</label>
        <select class="form-control" [(ngModel)]="selectedItemCart.discRemark">
          <option value="">--Remark--</option>
          <option value="Manager">Manager</option>
          <option value="Owner">Owner</option>
          <option value="Shoutlo">Shoutlo</option>
          <option value="Make My Trip">Make My Trip</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="addItemDiscount()">
      Save changes
    </button>
  </div>
</ng-template> -->
<ng-template #PastOrderModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Past Services</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="row">
        <div class="col-md-6">
          <label >Service Name:</label>&nbsp;&nbsp;
         <span class="label label-inline label-light-primary font-weight-bolder py-5">Root Touch Up</span>
        </div>
        <div class="col-md-6">
          <label>Date:</label>&nbsp;&nbsp;
          <span class="label label-inline label-light-primary font-weight-bolder py-5">20-1-2022</span>
          </div>
      </div> -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="table-light">
            <th>Order Id</th>
            <th>Item Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody *ngIf="pastOrderData.length!=0">
          <tr *ngFor="let data of pastOrderData">
            <td>{{data.orderId}}</td>
            <td>{{data.itemName}}</td>
            <td>{{data.invoicedAt|date:"dd MMM,yyyy hh:mm a"}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="pastOrderData.length==0">
          <tr>
            No Past Services.
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #detailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Punch New Bill</h4>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="detailForm" (ngSubmit)="addNewOrder()">
        <!-- <div class="row">
          <div class="col-md-6" style="color:#fff">
            <label *ngIf="count=='1' && (getNumber!='' || getName!='')" style="font-size: 16px;" class="text-success">
              Returning Customer</label>
            <label *ngIf="count2=='2'&& (getNumber!='' || getName!='')" style="font-size: 16px;" class="text-info">New
              Customer</label>
            <label *ngIf="getName=='' && getNumber==''"></label>
          </div>
        </div> -->
        <div class="row w-100">
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3" style="margin-top: 1em;">
            <label>Bill Date</label>
            <input formControlName="billDate" placeholder="Enter invoice date" class="form-control"
              type="datetime-local" max="{{invoiceMax}}">
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12" style="margin-top: 1em;">
            <div class="form-group">

              <label class="control-label">Customer Name</label>
              <!-- <ejs-dropdownlist [dataSource]='customerData' [fields]='fields' [placeholder]='text'
              [allowFiltering]='true' [filterBarPlaceholder]="filterPlaceholder" (filtering)="onFiltering($event)"
             (change)="customerSelect($event)"></ejs-dropdownlist> -->
              <div id="myDropdown" class="dropdown-content">
                <input formControlName="name" placeholder="Enter name" type='text' class="form-control" id="myInput"
                  [ngClass]="{ 'is-invalid': submitted1 && c.name.errors }"
                  (keyup)="searchCustomerByName($event.target.value)" autocomplete="off" (focus)="blankNumber()">
                <div *ngIf="submitted1 && c.name.errors" class="invalid-feedback">
                  <div *ngIf="c.name.errors.required">Field is required</div>
                </div>
                <div id="customerlistname">
                  <a *ngFor="let data of customerData;let i=index" (click)="selectCustomer(data)"
                    style="cursor: pointer;color: black;">
                    {{ data.contactName }} - {{data.mobileNo}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12" style="margin-top: 1em;">
            <div class="form-group">
              <label class="control-label">Customer Mobile Number</label>
              <div id="myDropdown" class="dropdown-content">
                <input formControlName="number" placeholder="Enter number" type='text' class="form-control"
                  [ngClass]="{ 'is-invalid': submitted1 && c.number.errors }" maxlength="10" autocomplete="off"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  (keyup)="searchCustomerByNumber($event.target.value)" (focus)="blankName()">
                <div *ngIf="submitted1 && c.number.errors" class="invalid-feedback">
                  <div *ngIf="c.number.errors.required">Field is required</div>
                </div>
                <div id="customerlistnumber">
                  <a *ngFor="let data of customernumberData;let i=index" (click)="selectCustomerNumber(data)"
                    style="cursor: pointer;color: black;">
                    {{ data.contactName }} - {{data.mobileNo}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 mt-5" *ngIf="count=='1' && (getNumber!='' || getName!='')">
            <br />
            <input type="button" style="text-align: right;" class="btn btn-link-info font-weight-bold"
              value="View Profile" (click)="viewProfile()">
          </div> -->
        </div>
        <div class="row w-100 mt-5">
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12" style="margin-top: 1em;">
            <label>DOB</label>
            <input formControlName="dateofbirth" placeholder="Enter DOB" class="form-control" type="date">
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12" style="margin-top: 1em;">
            <label>Anniversary</label>
            <input formControlName="anniversary" placeholder="Enter Anniversary" class="form-control" type="date">
          </div>
          <div class="col-md-4 col-lg-4  col-sm-12 col-xs-12" style="margin-top: 1em;">
            <label class="control-label">Gender</label>
            <div class="form-group">
              <div>
                <div class="radio-inline">
                  <label class="radio radio-info">
                    <input type="radio" style="display: none;" value='male' formControlName="gender"
                      [ngClass]="{ 'is-invalid': submitted1 && c.gender.errors }" />
                    <span></span>
                    Male
                  </label>
                  <label class="radio radio-info">
                    <input type="radio" style="display: none;" value='female' formControlName="gender"
                      [ngClass]="{ 'is-invalid': submitted1 && c.gender.errors }" />
                    <span></span>
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="submitted1 && c.gender.errors" class="invalid-feedback">
              <div *ngIf="c.gender.errors.required">Field is required</div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-10">
          <button type="button" class="btn btn-secondary" (click)="modal.close()">Close</button>
          <button type="submit" class="btn btn-info">
            Add Order
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #detailDraftModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Draft Orders</h4>
    <button type="button" class="btn font-weight-bold btn-sm ml-auto" aria-label="Close" (click)="customCloseNodel()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0" style="border-radius: 5px;" *ngIf="draftOrders.length!=0">
      <div class="col-md-4 col-lg-4 col-xl-4 mt-5 col-12" *ngFor="let data of draftOrders; let i=index">
        <div style="border-radius: 10px;border: 1px solid #E6E6E6;background-color: white;">
          <div class="row w-100 p-3 m-0" style="border-bottom: 1px solid #E6E6E6;">
            <div class="col-md-12 col-12">
              <span class="font-weight-bold" style="font-size: 18px;">#{{data.orderId}}</span>
            </div>
          </div>
          <div class="row w-100 m-0 pl-3 pr-3 pt-2 pb-2" style="border-bottom: 1px solid #E6E6E6;">
            <div class="col-md-12 col-6" style="text-align: start;">
              <span style="font-size: 16px;">Customer</span>
              <br>
              <span style="font-size: 16px;" class="font-weight-bold"
                *ngIf="data.customerName!='' && data.customerName!=undefined && data.customerName!=null">
                {{ data.customerName }} <br><a href="tel:{{ data.mobileNo }}">{{data.mobileNo}}</a>
              </span>
              <span style="font-size: 16px;" class="font-weight-bold"
                *ngIf="data.customerName=='' || data.customerName==undefined || data.customerName==null">
                N/A
              </span>
            </div>
          </div>
          <div class="row w-100 m-0 pl-7 pr-7 pt-2 pb-2" style="border-bottom: 1px solid #E6E6E6;display: block;">
            <span style="font-size: 16px;">Created At</span><br>
            <div style="font-size: 16px;word-break: break-all;" class="font-weight-bold">
              {{data.invoicedAt | date:'dd MMM,yyyy hh:mm a'}}
            </div>
          </div>
          <div class="card-footer row m-0 p-1">
            <div class="col-12 p-1">
              <button type="button" class="btn w-100 btn-light-dark" style="padding: 8px 0px"
                (click)="openPosOrder(data.orderId)">
                Edit Bill
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div style="
width: 3267mm;
padding: 1%;
margin: 0%;
font-weight: 500;
display: none;
" id="printJobCard">
  <div style="text-align: center;">
    <!-- <img  class="m-widget3__img" style="width: 5em;" src="https://api.uengage.in/images/addo/logos/{{selectedBusiness.logo}}" alt=""/> -->
    <span style="font-weight: bold;">{{ businessName }}
      {{locality}},{{city}}</span>
  </div>
  <div>
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <span>
      Client Name:
      <span>{{ orders.customerName }}</span>
      <!-- <span *ngIf="order.customerName == null">Guest</span> -->
      <br />
      Phone No: {{ orders.mobileNo }}<br>
      <span> Date Of Visit: {{ orders.invoicedAt | date: "dd MMM,yyyy hh:mm a"}}
      </span><br>
      <span> Order Id: {{ orders.orderId }} </span>
    </span>
  </div>
  <div>
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
  </div>
  <div>
    <table style="width: 100%;border: 1px solid black;
    border-collapse: collapse;">
      <thead>
        <th style="border: 1px solid black;
        border-collapse: collapse;">SNo.</th>
        <th style="border: 1px solid black;
        border-collapse: collapse;">SERVICE(S)</th>
        <th style="border: 1px solid black;
        border-collapse: collapse;">STAFF</th>
      </thead>
      <tbody>
        <tr *ngFor="let data of jobCard;let i=index">
          <td style="border: 1px solid black;
          border-collapse: collapse;">{{i+1}}.</td>
          <td style="border: 1px solid black;
          border-collapse: collapse;">{{data.service}}</td>
          <td style="border: 1px solid black;
          border-collapse: collapse;">{{data.staff}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<button style="
background: #000;
text-align: center;
color: #fff;
font-size: 1.2em;
border:0px !important;
width: auto;
margin: 1%;
float: left;
padding: 1%;
border-radius: 5px;display: none;
" id="print_div" printSectionId="printInvoice" useExistingCss="true" ngxPrint>
  <i class="fas fa-file-alt" style="
color: #fff;"></i> Print Invoice
</button>

<div style="
width: 3267mm;
padding: 1%;
margin: 0%;
font-weight: 500;
display: none;
" *ngFor="let order of orderData" id="printInvoice">
  <div style="width: 72mm; text-align: center;">
    <img class="m-widget3__img mb-2" style="width: 4em;" src="{{avatar}}" alt /><br>
    <span style="font-weight: bold;">{{ order.orderDetails[0].businessName
      }}</span>
  </div>
  <div style="font-size: 1.2em;text-align: center;width: 72mm;" *ngIf="category_id!=4 && parentBusinessId!=6915">
    <span style="font-weight: bold;">
      KOT No. {{order.orderDetails[0].KOTNo}}
    </span>
  </div>
  <div style="width: 72mm; text-align: center;">
    <span>{{ selectedBusiness[0].address }}</span>
    <div>
      <span>Phone: </span>
      <span> {{ order.orderDetails[0].phone }}</span>
    </div>
    <div
      *ngIf="order.orderDetails[0].gstin!=null && order.orderDetails[0].gstin!='' && order.orderDetails[0].gstin!=undefined">
      <span>GST No: </span>
      <span> {{ order.orderDetails[0].gstin }}</span>
    </div>
    <div *ngIf="category_id!=4">
      <span>FSSAI No: </span>
      <span
        *ngIf="order.orderDetails[0].fssai_no!=null && order.orderDetails[0].fssai_no!='null' && order.orderDetails[0].fssai_no!=''">
        {{ order.fssai_no }}</span>
      <span
        *ngIf="order.orderDetails[0].fssai_no==null || order.orderDetails[0].fssai_no=='null' || order.orderDetails[0].fssai_no==''">N/A</span>
    </div>
    <div>
      <span>Legal Entity Name:</span>
      <span> {{ order.orderDetails[0].legalEntityName }}</span>
    </div>
  </div>

  <div style="width: 72mm;" *ngIf="order.orderDetails[0].mobileNo!=9999999999">
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <span>
      Name:
      <span *ngIf="order.orderDetails[0].customerName != null">{{
        order.orderDetails[0].customerName }}</span>
      <span *ngIf="order.orderDetails[0].customerName == null">Guest</span>
      <br />
      Phone No: {{ order.orderDetails[0].mobileNo }}
      <br />
      <span *ngIf="order.orderDetails[0].gst_number ">GST No.: {{
        order.orderDetails[0].gst_number }}</span>

    </span>
  </div>
  <div style="width: 72mm;">
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <div class="row">
      <div class="col-12">
        <span> Date: {{ order.orderDetails[0].invoiced_date}} </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <span> Bill No: {{ order.orderDetails[0].orderId }} </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <span>
          <span *ngIf="order.orderDetails[0].paymentMode == 'COD'">Payment Mode
            : Cash</span>
          <span *ngIf="order.orderDetails[0].paymentMode != 'COD'"> Payment Mode
            :{{order.orderDetails[0].paymentMode |
            uppercase}}</span>
        </span>
      </div>
      <div class="col-6" *ngIf="order.orderDetails[0].invoiceId != null">
        <span>
          Invoice Id:
          <span>{{ order.orderDetails[0].invoiceId }}</span>
        </span>
      </div>
    </div>
  </div>
  <div style="width: 72mm;">
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <table style="width: 100%;" style="margin-bottom: 0;">
      <thead>
        <tr>
          <th align="left" style="width: 60%;" *ngIf="category_id!=4 && parentBusinessId!=6915">Item</th>
          <th align="left" style="width: 60%;" *ngIf="category_id!=4 && parentBusinessId==6915">Ticket</th>
          <th align="left" style="width: 60%;" *ngIf="category_id==4">Description</th>
          <th align="left">Qty</th>
          <th align="left">Price</th>
          <th align="left">Discount</th>
          <th align="right">Amt</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let item of order.orderItemDetails; let i = index" style="border: 1px solid #ccc;">
          <tr>
            <td align="left" style="width: 60%;">
              <!-- {{ item.sectionName }}
              <br /> -->
              <!-- <span *ngIf="item.parentName != null">
                {{ item.parentName }} ({{ item.itemName }})
              </span> -->
              <span style="font-weight: bold;">
                {{ item.itemName }}
              </span> <br>
              <span>
                Staff : {{ getStaff(item.itemId) }}
              </span>
            </td>
            <td align="center" *ngIf="item.weight!='0' && item.weight!=null">

              {{ item.weight }}
              <!-- <input style="width:50%" type="text" #quantity value="{{item.quantity}}" (change)="updateCart(quantity.value,item,i,item.taxPer);"> -->
            </td>
            <td align="center" *ngIf="item.weight=='0' || item.weight==null">

              {{ item.qty }}
              <!-- <input style="width:50%" type="text" #quantity value="{{item.quantity}}" (change)="updateCart(quantity.value,item,i,item.taxPer);"> -->
            </td>
            <td align="center">{{ item.itemPrice }}</td>
            <td align="center">{{ item.discount }}</td>
            <td align="right">{{ ((item.itemPrice * item.qty) -
              item.discount)}}</td>
            <!-- <td (click)="removeItem(i);"><i class="fa fa-trash" aria-hidden="true"></i></td> -->
          </tr>
          <tr *ngFor="let temp of item.subItems; let j = index">
            <td align="left" style="
            width: 60%;
            font-size: 0.9em;
            border-top: 0px;
            padding-top: 0em !important;
          ">
              <span *ngIf="temp.parentName != null">
                + <b>{{ temp.parentName }}:</b> {{ temp.itemName }}
              </span>
              <span *ngIf="temp.parentName == null">
                + <b>{{ temp.sectionName }}:</b> {{ temp.itemName }}
              </span>
            </td>
            <td align="center" style="border-top: 0px; padding-top: 0em !important;" colspan="2">
              {{ temp.qty }} x {{ temp.subTotal }}
            </td>
            <!-- <td align="left" >{{temp.itemPrice}}</td> -->
            <td align="right" style="border-top: 0px; padding-top: 0em !important;">
              {{ temp.subTotal }}
            </td>
            <!-- <td (click)="removeTemplateItem(i,j);"><i class="fa fa-trash" aria-hidden="true"></i></td> -->
          </tr>
        </ng-container>
      </tbody>
    </table>
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
    margin-top: 0;
  " />
    <div style="text-align: left;" *ngIf="category_id!=4 && parentBusinessId!=6915">
      <span>
        <label *ngIf="
      order.orderDetails[0].comments=='' || order.orderDetails[0].comments== 'null' || order.orderDetails[0].comments== null
      ">
          Special Instruction: N/A</label>
        <label *ngIf="
      order.orderDetails[0].comments!='' && order.orderDetails[0].comments!= 'null' && order.orderDetails[0].comments!= null
      ">
          Special Instruction: {{ order.orderDetails[0].comments }}</label>
      </span>

      <hr style="
      display: block;
      border: 0;
      border-top: 1px solid black;
      padding: 0;
      width: 100%;
    " />
    </div>
  </div>

  <div style="width: 72mm;">
    <div>
      <span>Total Services: {{ order.orderItemDetails.length | number: "1.2-2"
        }} </span>
      <span style="float: right;">Sub Total: {{ order.orderDetails[0].subTotal |
        number: "1.2-2" }}</span>
    </div>

    <div style="text-align: right;" *ngIf="order.orderDetails[0].discount >0">
      <span>Discount: {{ order.orderDetails[0].discount | number: "1.2-2" }}
      </span>
    </div>
    <div style="text-align: right;" *ngIf="order.orderDetails[0].netAmt >0">
      <span>Net Amount: {{ order.orderDetails[0].netAmt | number: "1.2-2" }}
      </span>
    </div>
    <div *ngIf="order.orderDetails[0].deliveryCharges != 0 && category_id!=4 && parentBusinessId!=6915"
      style="text-align: right;">
      <span>Delivery Charges: {{ order.orderDetails[0].deliveryCharges | number:
        "1.2-2" }}
      </span>
    </div>
    <div *ngIf="order.orderDetails[0].packagingCharges != 0 && category_id!=4 && parentBusinessId!=6915"
      style="text-align: right;">
      <span>Packaging Charges: {{ order.orderDetails[0].packagingCharges |
        number: "1.2-2" }}
      </span>
    </div>
    <div *ngIf="order.orderDetails[0].taxes > 0" style="text-align: right;">
      <span>Total Tax: {{ order.orderDetails[0].taxes | number: "1.2-2" }}
      </span><br>
      <span style="font-size: 0.9em;">SGST: {{ (order.orderDetails[0].taxes/2) |
        number: "1.2-2" }} </span><br>
      <span style="font-size: 0.9em;">CGST: {{ (order.orderDetails[0].taxes/2) |
        number: "1.2-2" }} </span>
    </div>
    <div *ngIf="order.orderDetails[0].serviceCharges > 0" style="text-align: right;">
      <span>Services Charges: {{ order.orderDetails[0].serviceCharges | number:
        "1.2-2" }} </span>
    </div>
    <div *ngIf="order.orderDetails[0].wallet == 1 && parentBusinessId!=6915" style="text-align: right;">
      <span>Wallet Used: {{ order.orderDetails[0].wallet_amount | number:
        "1.2-2" }} </span>
    </div>
    <div *ngIf="order.orderDetails[0].membership_used > 0" style="text-align: right;">
      <span>Membership Used: {{ order.orderDetails[0].membership_used | number:
        "1.2-2" }} </span>
    </div>
    <div *ngIf="order.orderDetails[0].pending_amount > 0" style="text-align: right;">
      <span>Pending Amount: {{ order.orderDetails[0].pending_amount | number:
        "1.2-2" }} </span>
    </div>
  </div>
  <div style="width: 72mm;">
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <span style="float: right;"><b>Grand Total: {{
        (order.orderDetails[0].totalAmt==0 ?
        order.orderDetails[0].membership_used : order.orderDetails[0].totalAmt |
        number: "1.2-2") }}</b></span>
    <span style="float: left;" *ngIf="order.orderDetails[0].paymentMode == 'membership'"><b>Pending
        Membership Amount:
        {{ walletLeft | number: "1.2-2" }}</b></span>
    <span style="float: left;"
      *ngIf="order.orderDetails[0].paymentMode != 'membership' && membershipData[0]!=undefined && membershipData[0].membershipAmount!=0"><b>Pending
        Membership Amount: {{ membershipData[0].membershipAmount | number:
        "1.2-2" }}</b></span>
    <span style="float: left;" *ngIf="membershipData[0]!=undefined && membershipData[0].membershipAmount!=0"><b>Valid
        Till: {{membershipData[0].valid_till | date:"dd MMM,yyyy"}}</b></span>
  </div>
  <div style="width: 72mm;" *ngIf="parentBusinessId==6915">
    <hr style="
      display: block;
      border: 0;
      border-top: 1px solid black;
      padding: 0;
      width: 100%;
    " />
    <qr-code [value]="qrString" size="200"></qr-code>
    <!-- <div class="qrcodeImage">
      <qrcode [qrdata]="qrString" [width]="200" [errorCorrectionLevel]="'M'" [cssClass]="'center'"
        [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'img'"></qrcode>
    </div> -->
  </div>
  <div style="width: 72mm; text-align: center;">
    <hr style="
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
    width: 100%;
  " />
    <span style="padding: 3%; font-weight: 600; font-size: 1.2em;">
      ****THANK YOU****</span><br>
    <span style="padding: 3%; font-weight: 500; font-size: 1em;">We Look Forward
      To Your Next Visit</span>
  </div>
</div>

<ng-template #posDiscountModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apply Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="row w-100 m-0">
      <div class="alert alert-custom alert-light-danger fade show mb-5 w-100" role="alert" style="height: 4em;">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Maximum Discount Limit Is {{maxDiscountAllowed}}%.</div>
      </div>
    </div> -->
    <div class="row w-100 m-0 mt-5">
      <div class="col-xl-6">
        <label>Discount Type:</label>
        <select class="form-control" [(ngModel)]="orders.posDisType">
          <option value>--Discount Type--</option>
          <option value="percentage">Percentage (%)</option>
          <option value="value">Value (&#8377;)</option>
        </select>
      </div>
      <div class="col-xl-6">
        <label>Value:</label>
        <input type="text" class="form-control no-radius" maxlength="5"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          [(ngModel)]="orders.posDisValue">
      </div>
    </div>
    <!-- <div class="row w-100 m-0 mt-3">
      <a (click)="sendReq(approvalModal)" style="color: blue;cursor: pointer;">Request To Increase Discount Limit</a>
    </div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="addPosDiscount()" style="background-color: #8CC42A;color: white;">
      Save changes
    </button>
  </div>
</ng-template>
<ng-template #gstModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Enter GST Details</h4>
  </div>
  <div class="modal-body">
    <!-- <div class="row w-100 m-0 p-2">
      <label>Bill To:</label>
      <input type="text" [(ngModel)]="billTo" class="form-control" placeholder="Enter bill to name">
    </div>
    <div class="row w-100 m-0 p-2">
      <label>Mobile Number:</label>
      <input type="text" [(ngModel)]="gstMobile" class="form-control" placeholder="Enter mobile" maxlength="10"
        oninput="this.value=this.value.replace(/[^0-9]/g,'');">
    </div> -->
    <div class="row w-100 m-0 p-2">
      <label>GST Number:</label>
      <input type="text" [(ngModel)]="gstNum" class="form-control" placeholder="Enter gst number">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="cancelGst()" class="btn btn-secondary">
      Close
    </button>
    <button type="button" (click)="saveGst()" class="btn btn-info">
      Add
    </button>
  </div>
</ng-template>
<ng-template #notesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Personalised Notes</h4>
  </div>
  <div class="modal-body">
    <table class="table table-bordered">
      <thead>
        <th>Sno.</th>
        <th>Notes</th>
      </thead>
      <tbody *ngIf="notes.length!=0">
        <tr *ngFor="let data of notes;let i=index">
          <td>{{i+1}}.</td>
          <td style="word-break: break-all;">{{data}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="notes.length==0">
        <span style="color: red;">No Data Available</span>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="modal.close()" class="btn btn-secondary">
      Close</button>
  </div>
</ng-template>
<ng-template #addStaff let-modal>
  <div class="modal-body" style="height: auto;">
    <div class="row w-100 m-0">
      <div class="col-lg-6 col-9">
        <!-- <span style="font-size: 13px;font-weight:500;display: flex;">
            <span class="svg-icon svg-icon-info svg-icon-2x mr-2">
              <i class="text-info fas fa-qrcode"></i>
            </span>Download QR Codes:</span> -->
      </div>
      <div class="col-lg-6 col-3" style="text-align: end;">
        <i class="fas fa-times" (click)="modal.close()" style="cursor: pointer;"></i>
      </div>
    </div>
    <div class="row w-100 m-0 mt-2">
      <div class="col-lg-12">
        <label for class="form-label semibold">Map Staff(s)<span class="req">*</span></label>
        <div class="col-md-12 stafffav-out staff-width pl-0">
          <ejs-multiselect id='country1' [dataSource]='staffdata' [filterBarPlaceholder]="filterPlaceholder"
            [allowFiltering]='true' [placeholder]='watermark' [ignoreAccent]='true' [(value)]="selectedStaff"
            (change)="addStylist3($event)" [fields]='stylistfield' popupWidth='350px'></ejs-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" [disabled]="!addStaffShow" (click)="closeAddStaff()">Add Staff</button>
  </div>
</ng-template>

<!-- <button id="pending_payout" class="invisible" (click)="pendingPayout(payoutModal)"></button>

<ng-template #payoutModal let-modal>
  <div class="modal-body" style="height: auto;">
    <div class="row w-100 m-0">
      <div class="col-lg-6 col-9">
      </div>
      <div class="col-lg-6 col-3" style="text-align: end;">
        <i class="fas fa-times" (click)="modal.close()" style="cursor: pointer;"></i>
      </div>
    </div>
    <div class="row w-100 m-0 mt-2 p-5" style="border-radius: 10px;border: 2px solid red;">
      <span style="font-size: 1.1em; font-weight: 500;">Customer has a pending
        payout of {{pendingPayoutAmount|
        customcurrency}}</span>
    </div>
  </div>
</ng-template> -->

<ng-template #addnotesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Note</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="modal.dismiss()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <textarea class="form-control" placeholder="Enter note" [(ngModel)]="note"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-md btn-rounded" (click)="saveNotes()">
      Add
    </button>
  </div>
</ng-template>

<ng-template #addAdvancePayment let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Add Advance Payment</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="c('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body" style="padding: 0%;">
    <div>
      <form [formGroup]="advancePaymentForm" (ngSubmit)="submitPayment()">
        <div class=" px-0 " style="padding: 0%;">

          <div class="row  p-5 justify-content-between align-item-center">

            <!-- <div class="col-md-6 col-12 mb-3">
                      <label style="font-weight: 500;">Name:</label>

                      <input type="text" class="form-control"
                          formControlName="item_name"
                          placeholder="Enter Name"
                          style="height: 33px;border-radius:5px;" />

                  </div> -->
            <div class="col-md-6 col-12 mb-3">
              <label class="control-label">Customer Name</label>
              <!-- <ejs-dropdownlist [dataSource]='customerData' [fields]='fields' [placeholder]='text'
              [allowFiltering]='true' [filterBarPlaceholder]="filterPlaceholder" (filtering)="onFiltering($event)"
             (change)="customerSelect($event)"></ejs-dropdownlist> -->
              <div id="myDropdown" class="dropdown-content">
                <input formControlName="customerName" disabled placeholder="Enter Customer Name" type='text'
                  class="form-control" id="myInput" [ngClass]="{ 'is-invalid': submitted1 && c.name.errors }"
                  (keyup)="searchCustomerByName($event.target.value)" autocomplete="off" (focus)="blankNumber()">
                <div *ngIf="submitted1 && c.name.errors" class="invalid-feedback">
                  <div *ngIf="c.name.errors.required">Field is required</div>
                </div>
                <div id="customerlistname">
                  <a *ngFor="let data of customerData;let i=index" (click)="selectCustomer(data)"
                    style="cursor: pointer;color: black;">
                    {{ data.contactName }} - {{data.mobileNo}}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <label style="font-weight: 500;">Mobile Number:</label>
              <input type="text" class="form-control" formControlName="mobile_no" placeholder="Enter Mobile No"
                style="height: 33px; " disabled />
              <!-- <span class="text-danger"
                          *ngIf="!advancePaymentForm.get('mobile_no').valid && advancePaymentForm.get('mobile_no').touched">
                          Required
                      </span> -->
              <span
                *ngIf="!advancePaymentForm.get('mobile_no')?.valid && (advancePaymentForm.get('mobile_no')?.touched || advancePaymentForm.get('mobile_no')?.dirty)">
                <span style="color: red;" *ngIf=" advancePaymentForm.get('mobile_no').errors['required']">
                  Required</span>
                <span style="color: red;" *ngIf="  advancePaymentForm.get('mobile_no').errors['pattern']">
                  Invalid Number
                </span>

              </span>

            </div>
            <div class="col-md-6 col-12 mb-3">
              <label style="font-weight: 500;">Amount:</label>
              <input type="number" class="form-control" formControlName="amountReceived" placeholder="Enter Amount"
                style="height: 33px;" />
              <span class="text-danger"
                *ngIf="!advancePaymentForm.get('amountReceived').valid && advancePaymentForm.get('amountReceived').touched">
                Required
              </span>

            </div>
            <!-- <div class="col-md-6 col-12 mb-3">
                      <label style="font-weight: 500;">Status:</label>
                      <div class="radio-inline">
                          <label class="radio radio-info"
                              style="display: inline-flex;">
                              <input type="radio" name="status"
                                  formControlName="status" [value]="1"
                                  style="display: none;"
                                  checked />
                              <span></span>
                              Active
                          </label>
                          <label class="radio radio-info"
                              style="display: inline-flex;">
                              <input type="radio" name="status"
                                  formControlName="status" [value]="0"
                                  style="display: none;" />
                              <span></span>
                              Inactive
                          </label>

                      </div>
                      <span class="text-danger"
                          *ngIf="!advancePaymentForm.get('status').valid && advancePaymentForm.get('status').touched">
                          Required
                      </span>
                  </div> -->
            <div class="col-md-6 col-12 mb-3">
              <label style="font-weight: 500;">Mode Of
                Payments:</label>
              <ng-multiselect-dropdown formControlName="modeArr" [data]="paymentTypeList"
                [settings]="dropdownSingleSettings"
                (onSelect)="selectOne($event,'modeArr','mode','id',advancePaymentForm)"
                [placeholder]="'Select Mode of Payments'"
                (onDeSelect)="deSelect($event,'modeArr','mode','id',advancePaymentForm)"
                (onSelectAll)="onSelectAll($event,'modeArr','mode','id',advancePaymentForm)"
                (onDeSelectAll)="onDeSelectAll($event,'modeArr','mode',advancePaymentForm)" name="days">
              </ng-multiselect-dropdown>
              <span class="text-danger"
                *ngIf="!advancePaymentForm.get('modeArr').valid && advancePaymentForm.get('modeArr').touched">
                Required
              </span>

            </div>
            <div class="col-md-12 col-12 mb-3">
              <label style="font-weight: 500;">Remarks:</label>
              <textarea type="text" class="form-control" formControlName="remarks" placeholder="Enter Remarks"
                rows="5"></textarea>
              <span class="text-danger"
                *ngIf="!advancePaymentForm.get('remarks').valid && advancePaymentForm.get('remarkss').touched">
                Required
              </span>

            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn" style="background-color: #80BB18;color: white;">
            Add Payment
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>

<ng-template #getOtpModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Enter OTP</h5>
  </div>
  <div class="modal-body">
    <div class="row w-100 m-0 mt-2">
      <div class="col-lg-12">
        <label class="control-label">OTP:</label>
        <input type="text" [(ngModel)]="memOtp" class="form-control no-radius">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
    <button [disabled]="!memOtp" type="button" (click)="validateOpt()" class="btn btn-info">Submit</button>
  </div>
</ng-template>


<button id="pending_payout2" class="invisible" (click)="pendingPayout(settleModal)"></button>

<ng-template #settleModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Settle Amount</h5>
    <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close" (click)="c('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body" style="padding: 0%;">
    <div class="row w-100 m-0 p-5">
      <div class="col-lg-6 col-12">
        <label class="control-label">Amount:</label>
        <input type="number" [(ngModel)]="settleAmount" class="form-control" placeholder="Enter amount"
          [max]="pendingAmount">
      </div>
      <div class="col-lg-6 col-12">
        <label class="control-label">Payment Via:</label>
        <select class="form-control" [(ngModel)]="settleVia">
          <option value>--Select--</option>
          <option value="cod">Cash</option>
          <option value="card">Card</option>
          <option value="upi">UPI</option>
        </select>
        <!-- <input type="number" [(ngModel)]="settleAmount" class="form-control" placeholder="Enter amount"> -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" style="background-color: #8CC327; color: #fff;" (click)="settleMemAmount()">
      Settle Amount
    </button>
  </div>
</ng-template>