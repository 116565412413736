import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss']
})
export class CreateBusinessComponent implements OnInit {
  @ViewChild("wizard", { static: true }) el: ElementRef;
  detailForm:FormGroup
  submitted2=false;
  wizard: any;

  constructor(private fb:FormBuilder, @Inject(LOCAL_STORAGE) private storage: WebStorageService,public httpClient: HttpClient,
  private cd: ChangeDetectorRef,private router: Router) { }

  ngOnInit(): void {
    this.createDetailForm()
  }
  ngAfterViewInit(): void {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
    });
    // Validation before going to next page
    // this.wizard.on("beforeNext", (wizardObj) => {
    //   if (wizardObj.currentStep == 1) {

    //   }
    //   if (wizardObj.currentStep == 2) {
       
    //   }
    //   if (wizardObj.currentStep == 3) {
  
    //   }
    // });
    // Change event
    this.wizard.on("change", () => {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
  createDetailForm(){
    this.detailForm=this.fb.group({
      businessName:['',[Validators.required]],
      // companyType:['',[Validators.required]],
      legalEntityName:['',[Validators.required]],
      address:['',[Validators.required]],
      mobile:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      state:['',[Validators.required]],
      city:['',[Validators.required]],
      locality:['',[Validators.required]]
    })
  }
  get d() { return this.detailForm.controls; }
  
  saveDetails(){

  }
}
