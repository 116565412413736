// import { Component, OnInit } from '@angular/core';import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { control } from 'leaflet';
import { SetStorageServiceService } from 'src/app/core/_base/layout/services/set-storage-service.service';
import { SocketService } from 'src/app/socket.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var L
@Component({
  selector: '[kt-live-rider-main]',
  templateUrl: './live-rider-main.component.html',
  styleUrls: ['./live-rider-main.component.scss'],
  animations: [
    trigger('toggleAnimation', [
      state('show', style({ transform: 'translateX(0) translateY(0)' })),
      state('hide', style({ transform: 'translateX(100%) translateY(100%)' })),
      transition('hide => show', animate('300ms ease-in-out')),
      transition('show => hide', animate('300ms ease-in-out'))
    ])
  ]
})
export class LiveRiderMainComponent implements OnInit {
  @Input('kt-live-rider-main') dataType: any;
  @ViewChild('detail', { static: true }) menuRef: ElementRef | null = null;
  map1: any
  storeLat: any;
  storeLong: any;
  logo: string;
  summary: any;
  interval: any;
  time: Date;
  markerArr: any[];
  zones: any[] = [];
  showLive = 0;
  showOnline = 0;
  showIdle = 0;
  showDelivery = 0;
  activeTask: any;
  selfPage = 0;
  totalTask: any;
  pageReq: number;
  isDivVisible = false
  orderData = []
  type: any;
  riderLogs: any;
  taskId: any;
  activeRiderData: any;
  partialRiderData: any;
  outForDeliverydata: any;
  constructor(private router: Router,
    private appComp: AppComponent,
    public httpClient: HttpClient,
    private cd: ChangeDetectorRef,
    private _storageService: SetStorageServiceService, private socketService: SocketService,
    private modalRef: NgbActiveModal, public toaster: ToastrService,
    private toastr: ToastrService, private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService) { }
  // Listen for the scroll event
  @HostListener('scroll', ['$event'])
  onScroll(event: any): void {
    // Check if the user has reached the bottom of the scrollable element
    const tolerance = 1; // Adjust the tolerance as needed
    const atBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + tolerance;
    // console.log(atBottom)
    // If at the bottom, load more data
    if (atBottom) {
      // this.loadData();
      this.selfPage += 1
      // console.log(this.selfPage)
      if (this.selfPage <= this.pageReq) {
        this.getPaginatedData(this.selfPage)
      }
    }
  }
  @HostListener('document:mousedown', ['$event'])
  onDocumentMouseDown(event: MouseEvent) {
    if (!this.menuRef.nativeElement.contains(event.target)) {
      document.body.classList.remove('body-no-scroll');
      this.closeDiv()
    }
  }
  closeDiv() {
    this.isDivVisible = false;
    document.body.classList.remove('body-no-scroll');
  }
  ngOnInit(): void {
    // this.socketService.connect()
    // console.log(this.dataType)
    this.type = this.dataType.type
    let selectedModules = this.storage
      .get("modules")
      .filter((e: { page: string; }) => this.router.url.includes(e.page));
    this._storageService.localItem = selectedModules;
    let selectedBusiness = this.storage
      .get("AssignedBusiness")
      .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
    this.storeLat = selectedBusiness[0]["lat"]
    this.storeLong = selectedBusiness[0]["long"]
    if (selectedBusiness[0].logo != null && selectedBusiness[0].logo != undefined && selectedBusiness[0].logo != '') {
      this.logo = "https://api.uengage.in/images/addo/logos/" + selectedBusiness[0].logo;
    } else {
      this.logo = ''
    }
    this.startRefreshTimer();
    this.getDetails()
    this.createMap()
    this.getTasks()
  }
  startRefreshTimer() {
    this.interval = setInterval(() => {
      this.createMap()
      this.getDetails()
      this.getTasks()
    }, 60000); // Set the interval to one minute (60000 milliseconds)
  }
  ngOnDestroy(): void {
    // this.socketService.disconnect()
    clearInterval(this.interval);
    document.body.classList.remove('body-no-scroll');
  }
  @HostListener('window:resize')
  onWindowResize() {
    // Call the method to handle map resize when the window is resized
    this.handleMapResize();
  }
  handleMapResize() {
    if (this.map1) {
      setTimeout(() => {
        this.map1.invalidateSize();
      }, 0);
    }
  }
  getDetails() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get(
        "https://riderapi.uengage.in/flash_admin/getRiderSummary/getRiderDetails?" +
        params,
        {}
      )
      .subscribe((res: any) => {
        if (res.status == 1) {
          if (res.riderSummary.length != 0) {
            this.summary = res.riderSummary[0]
            this.cd.detectChanges()
          }
        }
      });
  }
  viewRider(flag) {
    this.showLive = 0
    this.showOnline = 0
    this.showIdle = 0
    this.showDelivery = 0
    if (flag == 1) {
      this.showOnline = 1
    } else if (flag == -1) {
      this.showIdle = 1
    } else if (flag == 2) {
      this.showDelivery = 1
    }
    this.createMap()
  }
  createMap() {
    this.activeRiderData = []
    this.partialRiderData = []
    this.outForDeliverydata = []
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get(
        "https://riderapi.uengage.in/addoapi/riderAllocation/getRiderDetails_web?" +
        params,
        {}
      )
      .subscribe((res: any) => {
        if (res.status == 1) {
          for (let i = 0; i < res.riderDetails.length; i++) {
            if (res.riderDetails[i].online == 1 && res.riderDetails[i].status == 1) {
              this.activeRiderData.push(res.riderDetails[i])
            } else if (res.riderDetails[i].online == -1 && res.riderDetails[i].status == 1) {
              this.partialRiderData.push(res.riderDetails[i])
            } else if (res.riderDetails[i].out_for_delivery == 1) {
              this.outForDeliverydata.push(res.riderDetails[i])
            }
          }
          this.cd.detectChanges()
          // console.log(this.outForDeliverydata)
          this.time = new Date()
          var mapOptions = {
            center: [this.storeLat, this.storeLong],
            // zoom: '12',
            attribution: ''
          }
          if (this.map1 != undefined) {
            this.map1.remove()
          }
          // Creating a map object
          this.map1 = new L.map('map1', mapOptions);

          // Creating a Layer object
          var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

          // Adding layer to the map
          this.map1.addLayer(layer);
          if (this.logo != '') {
            const imgElement = document.createElement('img');
            imgElement.src = this.logo;
            imgElement.style.width = '5em';
            imgElement.style.position = 'relative';

            const aElement = document.createElement('a');
            aElement.className = 'floatingButton';
            aElement.style.cursor = 'pointer';
            aElement.appendChild(imgElement);

            const innerDivElement = document.createElement('div');
            innerDivElement.className = 'floatingButtonInner';
            innerDivElement.style.cursor = 'pointer';
            innerDivElement.appendChild(aElement);

            const wrapDivElement = document.createElement('div');
            wrapDivElement.className = 'floatingButtonWrap';
            wrapDivElement.appendChild(innerDivElement);
            const customIcon = L.divIcon({
              className: 'custom-marker',
              iconSize: [30, 30],
              html: wrapDivElement.outerHTML,
            });
            let marker = L.marker([this.storeLat, this.storeLong], { icon: customIcon })
            // this.map1.removeControl(this.map1.zoomControl);
            // control.zoom({ position: 'bottomright' }).addTo(this.map1);
            // this.map1.invalidateSize();
            marker.addTo(this.map1);
          } else {
            var storeicon = L.icon({
              iconUrl: '../../../../../assets/media/misc/Pickup-Location-Icon.png',
              iconSize: [74, 74], // size of the icon
              iconAnchor: [37, 74]
            });
            let marker = L.marker([this.storeLat, this.storeLong], { icon: storeicon })
            // this.map1.removeControl(this.map1.zoomControl);
            // control.zoom({ position: 'bottomright' }).addTo(this.map1);
            // this.map1.invalidateSize();
            marker.addTo(this.map1);
          }

          var ridericon = L.icon({
            iconUrl: '../../../../../assets/media/misc/Rider_logo.png',
            iconSize: [54, 54], // size of the icon
            // iconAnchor: [37, 74]
          });
          let riderDetailArr = res.riderDetails;
        
          this.markerArr = []
          // let center = [this.storeLat, this.storeLong]
          for (let i = 0; i < riderDetailArr.length; i++) {
            if (riderDetailArr[i].online == 1 && riderDetailArr[i].status == 1) {
              let ridermarker
              this.markerArr.push([Number(riderDetailArr[i].latitude), Number(riderDetailArr[i].longitude)])
              const riderContent = `
              <div style="text-align:center">
                ${riderDetailArr[i].firstName} ${riderDetailArr[i].lastName}
                <br>
                <strong>${riderDetailArr[i].mobileNo}</strong>
              </div>
            `;
              ridermarker = L.marker([riderDetailArr[i].latitude, riderDetailArr[i].longitude], { icon: ridericon }).addTo(this.map1).bindPopup(riderContent, { autoClose: false })
              setTimeout(() => {
                ridermarker.openPopup();
              }, 100);
              this.cd.detectChanges();
            }
          }

          // console.log(this.markerArr)
          // Include the center and all markers in the bounding box
          const allMarkers = this.markerArr.map(marker => {
            if (typeof marker.getLatLng === 'function') {
              return marker.getLatLng();
            } else if (Array.isArray(marker)) {
              // If marker is a simple array of coordinates
              return L.latLng(marker);
            } else {
              // Handle other marker types as needed
              // console.error('Unsupported marker type:', marker);

              return null;
            }
          }).filter(position => position !== null);
          allMarkers.push(L.latLng(this.storeLat, this.storeLong)); // Add the center coordinates
          // console.log(allMarkers)
          // Create an empty bounds object
          const bounds = new L.LatLngBounds();

          // Extend the bounds with each marker and the center
          allMarkers.forEach(marker => {
            bounds.extend(marker);
          });

          // Set the map view to fit the bounding box
          this.map1.fitBounds(bounds);

          // Adjust the zoom level to ensure the center point is adequately covered
          // const optimalZoom = this.calculateOptimalZoom(bounds);
          // this.map1.setZoom(optimalZoom);
          // Calculate the initial dynamic zones based on riders
          // this.calculateInitialDynamicZones();

          // Now, you can create zones
          // this.createZones();
        }
      });
  }
  calculateInitialDynamicZones() {
    this.zones = []
    this.markerArr.forEach(rider => {
      // console.log(rider)
      const zone = { center: [rider[0], rider[1]], radius: 500 };
      this.zones.push(zone);
    });
    // console.log(this.zones)
    this.createZones();
  }
  createZones() {
    this.zones.forEach(zone => {
      // Calculate the dynamic center based on the average coordinates of riders in the zone
      const dynamicCenter = this.calculateDynamicCenter(zone.radius, zone);

      const circle = L.circle(dynamicCenter, {
        color: 'blue',
        fillColor: '#4285f4',
        fillOpacity: 0.5,
        radius: zone.radius
      }).addTo(this.map1);

      const ridersInZone = this.getRidersInZone(dynamicCenter, zone.radius);
      const riderCountInZone = ridersInZone.length;

      circle.bindPopup(`<b>Rider Zone</b><br>Rider Count: ${riderCountInZone}`);
    });
  }
  calculateDynamicCenter(radius: number, zone: any): [number, number] {
    // Calculate the average coordinates of riders in the zone
    const ridersInZone = this.getRidersInZone(zone.center, radius);
    const totalRiders = ridersInZone.length;

    if (totalRiders === 0) {
      return zone.center; // Return the original center if no riders in the zone
    }

    const sumLat = ridersInZone.reduce((acc, rider) => acc + rider.lat, 0);
    const sumLng = ridersInZone.reduce((acc, rider) => acc + rider.lng, 0);

    const averageLat = sumLat / totalRiders;
    const averageLng = sumLng / totalRiders;

    return [averageLat, averageLng];
  }
  getRidersInZone(center: [number, number], radius: number): any[] {
    // console.log(center)
    // Filter riders based on their distance from the center within the given radius
    return this.markerArr.filter(rider => this.calculateDistance(center, [rider[0], rider[1]]) <= radius);
  }
  calculateDistance(point1: [number, number], point2: [number, number]): number {
    // Simple distance calculation using Euclidean distance formula
    const [lat1, lng1] = point1;
    const [lat2, lng2] = point2;
    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;
    return Math.sqrt(dLat * dLat + dLng * dLng);
  }
  calculateOptimalZoom(bounds) {
    const padding = [50, 50]; // Adjust as needed
    const mapSize = this.map1.getSize();
    const maxZoom = this.map1.getMaxZoom();

    const zoom = this.map1.getBoundsZoom(bounds.pad(0.5), false, padding);

    return Math.min(zoom, maxZoom);
  }
  fullScreen() {
    this.router.navigateByUrl("/live-rider-view")
  }
  getLiveOrders() {
    this.showLive = 1
    this.showOnline = 0;
    this.showIdle = 0;
    this.showDelivery = 0;
    this.createMap()
  }
  cancelLive() {
    this.showOnline = 0;
    this.showIdle = 0;
    this.showDelivery = 0;
    this.showLive = 0
    this.createMap()
  }
  cancelOnline() {
    this.showOnline = 0;
    this.showIdle = 0;
    this.showDelivery = 0;
    this.showLive = 0
    this.createMap()
  }
  cancelDelivery() {
    this.showOnline = 0;
    this.showIdle = 0;
    this.showDelivery = 0;
    this.showLive = 0
    this.createMap()
  }
  cancelIdle() {
    this.showOnline = 0;
    this.showIdle = 0;
    this.showDelivery = 0;
    this.showLive = 0
    this.createMap()
  }
  getTasks() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&page=" + this.selfPage +
      "&limit=25";
    this.httpClient
      .get("https://riderapi.uengage.in/rider/getActiveTask_new?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          if (this.selfPage == 0) {
            this.activeTask = res.activeTask;
            this.totalTask = res.total_task
            this.pageReq = Math.ceil(res.total_task / 25)
          }
          // console.log(this.pageReq)
          // if (this.selfPage == 0) {
          //   this.active_config = {
          //     itemsPerPage: 25,
          //     currentPage: 0,
          //     totalItems: res.total_task
          //   };
          // }
          this.cd.detectChanges();
        }
      });
  }
  getPaginatedData(page) {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&page=" + page +
      "&limit=25";
    this.httpClient
      .get("https://riderapi.uengage.in/rider/getActiveTask_new?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.activeTask = this.activeTask.concat(res.activeTask);
          this.cd.detectChanges();
        }
      });
  }
  orderDetails(data) {
    this.orderData = data
    this.isDivVisible = true
    document.body.classList.add('body-no-scroll');
    this.cd.detectChanges()
  }
  smallScreen() {
    this.router.navigateByUrl('/flash/live-rider-view')
  }
  openLink(link) {
    window.open(link, "_blank")
  }
  riderlogs(id, modal) {
    this.taskId = id
    this.riderLogs = []
    var params = new HttpParams()
      .set("userId", this.storage.get("userId"))
      .set("password", this.storage.get("password"))
      .set("businessId", this.storage.get("selectedBusinessId"))
      .set("taskId", id)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = 'https://api.uengage.in/third_party/check3PLLogs'
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.status) {
        if (res.list.length > 0) {
          this.isDivVisible = false
          this.riderLogs = res.list
          this.openLarge(modal)
          this.cd.detectChanges()
        } else {
          this.toaster.error('Rider logs not available at the moment', 'Error', {
            positionClass: 'toast-bottom-right'
          })
          // Swal.fire('', 'Rider logs not available at the moment', 'error')
        }
      }
    })
  }
  openLarge(content) {
    this.modalRef = this.modalService.open(content, {
      size: 'lg'
    })
  }
}
