<!-- <div class="map-upper-class" style="margin-top: 4em;">
    <div class="map-inner-input" style="box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);display: flex;">
        <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div class="p-5"
                style="background-color: #EEFFD0;border: 1px solid #8CC42A;border-radius: 5px;width: -webkit-fill-available;">
                <span style="color: black;font-weight: 500;font-size: 1.1em;">Active Riders (5)</span>
            </div>
        </div>
        <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div class="p-5"
                style="background-color: #EEFFD0;border: 1px solid #8CC42A;border-radius: 5px;width: -webkit-fill-available;">
                <span style="color: black;font-weight: 500;font-size: 1.1em;"> Riders (5)</span>
            </div>
        </div>
        <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div class="p-5"
                style="background-color: #EEFFD0;border: 1px solid #8CC42A;border-radius: 5px;width: -webkit-fill-available;">
                <span style="color: black;font-weight: 500;font-size: 1.1em;">Active Riders (5)</span>
            </div>
        </div>
        <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div class="p-5"
                style="background-color: #EEFFD0;border: 1px solid #8CC42A;border-radius: 5px;width: -webkit-fill-available;">
                <span style="color: black;font-weight: 500;font-size: 1.1em;">Active Riders (5)</span>
            </div>
        </div>
    </div>
   
</div> -->


<div class="p-8" style="background-color: white;border-radius: 10px;height: 100vh;">
    <div class="row">
        <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div style="background-color: black;border-radius: 10px;width: -webkit-fill-available;padding: 1rem;">
                <span style="color: white;font-weight: 500;font-size: 1.1em;white-space:nowrap;">Total Riders
                    <span *ngIf="summary">({{summary.totalRiders}})</span>
                </span>
            </div>
        </div>
        <!-- <div class="col-md col-6" style="place-items: center;
        display: flex;">
            <div class="p-5"
                style="background-color: #EEFFD0;border: 1px solid #8CC42A;border-radius: 10px;width: -webkit-fill-available;">
                <span style="color: black;font-weight: 500;font-size: 1.1em;">Active Riders (5)</span>
            </div>
        </div> -->
        <div class="col-md col-6 pl-0" style="place-items: center;
        display: flex;cursor: pointer;" (click)="viewRider(1)">
            <div style="background-color: #F1F0F5;border: 1px solid #F1F0F5;border-radius: 10px;width: -webkit-fill-available;padding: 1rem;"
                class="row w-100 m-0 justify-content-between">
                <span style="color: black;font-weight: 500;font-size: 1.1em;white-space:nowrap;">Online Riders
                    <span *ngIf="summary" style="color: #8CC42A;">({{summary.Online - summary.Idle}})</span>
                </span>
                <span>
                    <i class="flaticon-eye text-dark"></i>
                </span>
            </div>
        </div>
        <div class="col-md col-6 addMargin" style="place-items: center;
        display: flex;cursor: pointer;" (click)="viewRider(-1)">
            <div style="background-color: #F1F0F5;border: 1px solid #F1F0F5;border-radius: 10px;width: -webkit-fill-available;padding: 1rem;"
                class="row w-100 m-0 justify-content-between">
                <span style="color: black;font-weight: 500;font-size: 1.1em;white-space:nowrap;">Idle Riders
                    <span *ngIf="summary" style="color: #FEB019;">({{summary.Idle}})</span>
                </span>
                <span>
                    <i class="flaticon-eye text-dark"></i>
                </span>
            </div>
        </div>
        <div class="col-md col-6 addMargin pl-0" style="place-items: center;
        display: flex;cursor: pointer;" (click)="viewRider(2)">
            <div style="background-color: #F1F0F5;border: 1px solid #F1F0F5;border-radius: 10px;width: -webkit-fill-available;padding: 1rem;"
                class="row w-100 m-0 justify-content-between">
                <span style="color: black;font-weight: 500;font-size: 1.1em;white-space:nowrap;">Out For Delivery
                    <span *ngIf="summary" style="color: #F52A38;">({{summary.riderOutforDelivery}})</span>
                </span>
                <span>
                    <i class="flaticon-eye text-dark"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="row w-100 mt-10 m-0">
        <div class="col-md-6 pl-0 col-6">
            <span style="font-size: 1.2em;font-weight: 600;"
                *ngIf="showLive!=1 && showOnline!=1 && showIdle!=1 && showDelivery!=1">Live Map View</span>
        </div>
        <div class="col-md-6 pr-0 col-6" style="text-align: end;">
            <button class="btn" *ngIf="showLive!=1"
                style="background-color: white;border: 1px solid black;color: black;border-radius: 10px;"
                (click)="getLiveOrders()">Live Orders ({{totalTask}})</button>&nbsp;&nbsp;
            <button class="btn btn-dark mobText" (click)="fullScreen()" style="border-radius: 10px;display: inline;"
                *ngIf="type!='fullscreen'">Full Screen View</button>
            <button class="btn btn-dark mobText" (click)="smallScreen()" style="border-radius: 10px;display: inline;"
                *ngIf="type=='fullscreen'">Exit Full Screen View</button>
        </div>
    </div>
    <div class="row w-100 m-0" *ngIf="showLive==1 || showOnline==1 || showIdle==1 || showDelivery==1">
        <div class="col-md-4 pl-0 mt-3 col-12" *ngIf="showLive==1">
            <div class="p-5" style="background-color: #F5F5F5;border-radius: 10px;">
                <div class="row w-100 m-0 pb-3"
                    style="justify-content: space-between;border-bottom: 2px solid #D5D5D5;">
                    <span style="font-weight: 600;font-size: 1.1em;">Live Orders ({{totalTask}})</span>
                    <img src="../../../../assets/media/prism-images/story-cover-cross.png"
                        style="cursor: pointer;height: 1.5em;" (click)="cancelLive()">
                </div>
                <div class="row w-100 m-0 style-3" (scroll)="onScroll($event)">
                    <div class="card card-custom mt-3" *ngFor="let data of activeTask"
                        style="border-radius: 10px !important;width: -webkit-fill-available;">
                        <div class="card-header p-3" style="min-height: 0px !important;"
                            [ngClass]="{'self': data.partner_name==null, 'thirdParty': data.partner_name!=null}">
                            <div class="row w-100 m-0">
                                <div class="col-md-8 pl-0 col-8" style="place-items: center;
                                display: flex;">
                                    <span style="font-weight: bold;font-size: 0.9em;">ORDER ID: {{data.orderId}}</span>
                                </div>
                                <div class="col-md-4 pr-0 col-4" style="text-align: end;place-items: center;
                                display: flex;">
                                    <span class="p-2"
                                        style="border-radius: 15px;background-color: #E4FFB6;font-size: 0.9em;"
                                        *ngIf="data.partner_name==null">
                                        Self Delivery
                                    </span>
                                    <span class="p-2"
                                        style="border-radius: 15px;background-color: #C2E0FF;font-size: 0.9em;"
                                        *ngIf="data.partner_name!=null">
                                        {{data.partner_name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3">
                            <div class="row m-0 pb-3" style="border-bottom: 1px solid #F1F0F5;">
                                <div class="col-md-6 pl-0 col-6" style="display: grid;">
                                    <span
                                        style="font-size: 0.8em; white-space: break-spaces;font-weight: bolder;margin-left: 0.3em;">Details</span>
                                    <span class="font-weight-bold text-dark ng-star-inserted"
                                        style="font-size: 0.9em; white-space: break-spaces">
                                        Task Id: {{data.taskId}}</span>
                                    <span class="font-weight-bold text-dark ng-star-inserted"
                                        style="font-size: 0.9em; white-space: break-spaces">
                                        {{data.total| customcurrency}} ({{data.paymentMode}})</span>
                                </div>
                                <div class="col-md-6 col-6" style="display: grid;">
                                    <span
                                        style="font-size: 0.8em; white-space: break-spaces;font-weight: bolder;margin-left: 0.3em;">Rider</span>
                                    <span class="font-weight-bold text-dark ng-star-inserted"
                                        style="font-size: 0.9em; white-space: break-spaces">
                                        {{data.runnerName}}</span>
                                    <span class="font-weight-bold text-dark ng-star-inserted"
                                        style="font-size: 0.9em; white-space: break-spaces">
                                        {{data.runnerNo}}</span>
                                </div>
                            </div>
                            <div class="row w-100 mt-3 justify-content-center">
                                <a style="color: #8CC42A;text-decoration: underline;cursor: pointer;"
                                    (click)="orderDetails(data)">View Order Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pl-0 mt-3 col-12" *ngIf="showOnline==1">
            <div class="p-5" style="background-color: #F5F5F5;border-radius: 10px;">
                <div class="row w-100 m-0 pb-3"
                    style="justify-content: space-between;border-bottom: 2px solid #D5D5D5;">
                    <span style="font-weight: 600;font-size: 1.1em;">Online Riders ({{summary.Online -
                        summary.Idle}})</span>
                    <img src="../../../../assets/media/prism-images/story-cover-cross.png"
                        style="cursor: pointer;height: 1.5em;" (click)="cancelOnline()">
                </div>
                <div class="row w-100 m-0 mt-3" *ngIf="activeRiderData.length==0">
                    <span class="label label-lg label-light-danger label-inline font-weight-bold py-4">No Riders
                        Available</span>
                </div>
                <div class="row w-100 m-0 style-3">
                    <div class="card card-custom mt-3" *ngFor="let data of activeRiderData"
                        style="border-radius: 10px !important;width: -webkit-fill-available;">
                        <div class="card-body p-3">
                            <div class="row m-0 pb-3" style="border-bottom: 1px solid #F1F0F5;">
                                <div class="col-md-2 pl-0 col-2" style="display: grid;">
                                    <img src="../../../../assets/media/misc/flash_rider.png" style="width: 4em;">
                                </div>
                                <div class="col-md-10 col-10" style="display: grid;">
                                    <p style=" white-space: break-spaces;font-weight: bolder;" class="mb-0">
                                        {{data.firstName | uppercase}} {{data.lastName | uppercase}}</p>
                                    <span class="font-weight-bold text-dark">
                                        {{data.mobileNo}}</span>
                                </div>
                            </div>
                            <div class="row w-100 mt-3 justify-content-center">
                                <span style="font-weight: bold;font-size: 0.9em;">Last Sync:</span>
                                <span style="font-size: 0.9em;">&nbsp;{{data.riderSyncTime | date: 'dd MMM,yyyy hh:mm
                                    a'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pl-0 mt-3 col-12" *ngIf="showIdle==1">
            <div class="p-5" style="background-color: #F5F5F5;border-radius: 10px;">
                <div class="row w-100 m-0 pb-3"
                    style="justify-content: space-between;border-bottom: 2px solid #D5D5D5;">
                    <span style="font-weight: 600;font-size: 1.1em;">Idle Riders ({{summary.Idle}})</span>
                    <img src="../../../../assets/media/prism-images/story-cover-cross.png"
                        style="cursor: pointer;height: 1.5em;" (click)="cancelIdle()">
                </div>
                <div class="row w-100 m-0 mt-3" *ngIf="partialRiderData.length==0">
                    <span class="label label-lg label-light-danger label-inline font-weight-bold py-4">No Riders
                        Available</span>
                </div>
                <div class="row w-100 m-0 style-3">
                    <div class="card card-custom mt-3" *ngFor="let data of partialRiderData"
                        style="border-radius: 10px !important;width: -webkit-fill-available;">
                        <div class="card-body p-3">
                            <div class="row m-0 pb-3" style="border-bottom: 1px solid #F1F0F5;">
                                <div class="col-md-2 pl-0 col-2" style="display: grid;">
                                    <img src="../../../../assets/media/misc/flash_rider.png" style="width: 4em;">
                                </div>
                                <div class="col-md-10 col-10" style="display: grid;">
                                    <p style=" white-space: break-spaces;font-weight: bolder;" class="mb-0">
                                        {{data.firstName | uppercase}} {{data.lastName | uppercase}}</p>
                                    <span class="font-weight-bold text-dark">
                                        {{data.mobileNo}}</span>
                                </div>
                            </div>
                            <div class="row w-100 mt-3 justify-content-center">
                                <span style="font-weight: bold;font-size: 0.9em;">Last Sync:</span>
                                <span style="font-size: 0.9em;">&nbsp;{{data.riderSyncTime | date: 'dd MMM,yyyy hh:mm
                                    a'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pl-0 mt-3 col-12" *ngIf="showDelivery==1">
            <div class="p-5" style="background-color: #F5F5F5;border-radius: 10px;">
                <div class="row w-100 m-0 pb-3"
                    style="justify-content: space-between;border-bottom: 2px solid #D5D5D5;">
                    <span style="font-weight: 600;font-size: 1.1em;">Out for delivery Riders
                        ({{summary.riderOutforDelivery}})</span>
                    <img src="../../../../assets/media/prism-images/story-cover-cross.png"
                        style="cursor: pointer;height: 1.5em;" (click)="cancelDelivery()">
                </div>
                <div class="row w-100 m-0 mt-3" *ngIf="outForDeliverydata.length==0">
                    <span class="label label-lg label-light-danger label-inline font-weight-bold py-4">No Riders
                        Available</span>
                </div>
                <div class="row w-100 m-0 style-3">
                    <div class="card card-custom mt-3" *ngFor="let data of outForDeliverydata"
                        style="border-radius: 10px !important;width: -webkit-fill-available;">
                        <div class="card-body p-3">
                            <div class="row m-0 pb-3" style="border-bottom: 1px solid #F1F0F5;">
                                <div class="col-md-2 pl-0 col-2" style="display: grid;">
                                    <img src="../../../../assets/media/misc/flash_rider.png" style="width: 4em;">
                                </div>
                                <div class="col-md-10 col-10" style="display: grid;">
                                    <p style=" white-space: break-spaces;font-weight: bolder;" class="mb-0">
                                        {{data.firstName | uppercase}} {{data.lastName | uppercase}}</p>
                                    <span class="font-weight-bold text-dark">
                                        {{data.mobileNo}}</span>
                                </div>
                            </div>
                            <div class="row w-100 mt-3 justify-content-center">
                                <span style="font-weight: bold;font-size: 0.9em;">Last Sync:</span>
                                <span style="font-size: 0.9em;">&nbsp;{{data.riderSyncTime | date: 'dd MMM,yyyy hh:mm
                                    a'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 pr-0 col-12 mapHide" style="display: block;">
            <div id="map-container">
                <div id="overlay-div">
                    <span style="font-weight: 500;font-size: 0.9em;">Last Sync: {{time | date:'dd MMM,yyyy hh:mm
                        a'}}</span>
                </div>
                <div id="map1" style="z-index:0;margin-top: 1em;border-radius: 5px;" class='custom-popup'></div>
            </div>
        </div>
    </div>
    <div id="map-container" *ngIf="showLive!=1 && showOnline!=1 && showIdle!=1 && showDelivery!=1">
        <div id="overlay-div">
            <span style="font-weight: 500;font-size: 0.9em;">Last Sync: {{time | date:'dd MMM,yyyy hh:mm a'}}</span>
        </div>
        <div id="map1" style="z-index:0;margin-top: 1em;border-radius: 5px;" class='custom-popup'></div>
    </div>
</div>

<div [ngClass]="{ backdropVisible: isDivVisible, backdrop: isDivVisible }"></div>
<div class="transition-div p-5" [@toggleAnimation]="isDivVisible ? 'show' : 'hide'" #detail>
    <div *ngIf="orderData">
        <div class="row w-100 mobRow">
            <div class="col-md-11 col-11">
                <span style="font-size: 1.5em;" class="font-weight-bold">Task Details: #{{orderData.taskId}} </span>
                <span *ngIf="orderData.partner_name=='Self Delivery'">(Order source:
                    {{orderData.order_source}})</span>
            </div>
            <div class="col-md-1 col-1">
                <a (click)="closeDiv()" class="btn btn-xs btn-icon btn-light btn-hover-secondary">
                    <i class="flaticon2-cancel-music icon-xs text-muted"></i>
                </a>
            </div>
        </div>
        <div class="row mt-5 m-0 p-5" *ngIf="orderData.pay_per_task==1"
            style="background-color: #E8FFC4;border-radius: 10px;display: block;">
            <img src="../../../../../assets/media/misc/Busiest-Day.png" style="width: 1.5em;">
            <span *ngIf="orderData.flash_package==1" style="font-size: 1.1em;">You have BASIC package activated</span>
            <span *ngIf="orderData.flash_package==2" style="font-size: 1.1em;">You have GOLD package activated</span>
            <span *ngIf="orderData.flash_package==3" style="font-size: 1.1em;">You have PRO package activated</span><br>
            <small *ngIf="orderData.flash_package==1" style="margin-left: 2em;">(Task Creation Without Tracking)</small>
            <small *ngIf="orderData.flash_package==2" style="margin-left: 2em;">(Task Creation With Tracking via
                SMS)</small>
            <small *ngIf="orderData.flash_package==3" style="margin-left: 2em;">(Task Creation With Tracking via
                Whatsapp)</small>
        </div>
        <div class="row p-5 mt-5 m-0" style="border-radius: 10px;border: 1px solid #EFEFEF;background-color: #FAFAFA;">
            <span
                *ngIf="(orderData.partner_name=='Self Delivery' || orderData.partner_name==null) && (orderData.deliveredAt==null || orderData.deliveredAt=='')"
                style="font-size: 1.2em;">Your order will be delivered
                via
                your <span class="font-weight-bolder" style="color: #8CC42A;">OWN RIDER</span></span>
            <span
                *ngIf="orderData.partner_name!='Self Delivery' && orderData.partner_name!=null && (orderData.deliveredAt==null || orderData.deliveredAt=='')"
                style="font-size: 1.2em;">Your order will be delivered
                via
                <span class="font-weight-bolder" style="color: #8CC42A;">{{orderData.partner_name | uppercase}}
                    RIDER</span></span>
            <span
                *ngIf="(orderData.partner_name=='Self Delivery' || orderData.partner_name==null) && orderData.deliveredAt!=null && orderData.deliveredAt!=''"
                style="font-size: 1.2em;">Your order was delivered
                via your <span class="font-weight-bolder" style="color: #8CC42A;">OWN RIDER</span></span>
            <span
                *ngIf="orderData.partner_name!='Self Delivery' && orderData.partner_name!=null && orderData.deliveredAt!=null && orderData.deliveredAt!=''"
                style="font-size: 1.2em;">Your order was delivered
                via <span class="font-weight-bolder" style="color: #8CC42A;">{{orderData.partner_name | uppercase}}
                    RIDER</span></span>
            <div class="row w-100 m-0" *ngIf="orderData.orderStatus!=4 && orderData.partner_name!='Self Delivery'">
                <button class="btn-sm btn" style="background-color: #E8FFC4;color: #8CC42A;"
                    (click)="riderlogs(orderData.partner_orderId,logsModal)">Rider Logs</button>
            </div>
            <div class="row w-100 mt-5 m-0 p-3" style="background-color: white;border-radius: 5px;">
                <div class="col-md-6 col-6">
                    <p class="mb-0" style="font-size: 0.9em;">Rider Name</p>
                    <span style="font-size: 1.1em;font-weight: 600;"
                        *ngIf="orderData.runnerName!=null && orderData.runnerName!='' && orderData.runnerName!=undefined">{{orderData.runnerName}}</span>
                    <span style="font-size: 1.1em;font-weight: 600;color: red;"
                        *ngIf="orderData.runnerName==null || orderData.runnerName=='' || orderData.runnerName==undefined">[Rider
                        not allocated]</span>
                </div>
                <div class="col-md-6 col-6" style="border-left: 1px solid #E9E9E9;">
                    <p class="mb-0" style="font-size: 0.9em;">Rider Contact</p>
                    <span style="font-size: 1.1em;font-weight: 600;"
                        *ngIf="orderData.runnerNo!=null && orderData.runnerNo!='' && orderData.runnerNo!=undefined">{{orderData.runnerNo}}</span>
                    <span style="font-size: 1.1em;font-weight: 600;color: red;"
                        *ngIf="orderData.runnerNo==null || orderData.runnerNo=='' || orderData.runnerNo==undefined">[N/A]</span>
                </div>
            </div>
            <div class="row w-100 mt-5 m-0" *ngIf="orderData.pay_per_task==1 && orderData.channel!=null">
                <span style="color: #FFA800;font-size: 1.2em;">NOTE: Tracking link for this order has been sent to
                    customer via
                    {{orderData.channel | uppercase}} on {{orderData.mobileNo}}</span>
                <button class="btn-sm btn" style="background-color: #E8FFC4;color: #8CC42A;"
                    (click)="openLink(orderData.trackingLink)">Open Link</button>
            </div>
        </div>
        <div class="row w-100 m-0 pt-3 pb-3 pl-1 pr-1 justify-content-center"
            *ngIf="orderData.partner_name=='Self Delivery' || orderData.partner_name==null">
            <div class="order-tracking" [ngClass]="{completed: orderData.pickedAt!=null && orderData.pickedAt!=''}">
                <p>Picked</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.pickedAt!=null && orderData.pickedAt!=''" class="mt-0">{{
                    orderData.pickedAt | date : "MMM dd"}}<br>
                    {{ orderData.pickedAt | date : "hh:mm a"}}
                </p>
            </div>
            <div class="order-tracking" [ngClass]="{completed: orderData.enrouteAt!=null && orderData.enrouteAt!=''}">
                <p>En-Route</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.enrouteAt!=null && orderData.enrouteAt!=''" class="mt-0">{{
                    orderData.enrouteAt | date : "MMM dd"}}<br>
                    {{ orderData.enrouteAt | date : "hh:mm a"}}
                </p>
            </div>
            <div class="order-tracking"
                [ngClass]="{completed: orderData.deliveredAt!=null && orderData.deliveredAt!=''}">
                <p>Delivered</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.deliveredAt!=null && orderData.deliveredAt!=''"
                    class="mt-0">
                    {{orderData.deliveredAt | date : "MMM dd"}}<br>
                    {{orderData.deliveredAt | date : "hh:mm a"}}
                </p>
            </div>
        </div>
        <div class="row w-100 m-0 pt-3 pb-3 pl-1 pr-1 justify-content-center"
            *ngIf="orderData.partner_name!='Self Delivery' && orderData.partner_name!=null">
            <div class="order-tracking"
                [ngClass]="{completed: orderData.requestedAt!=null && orderData.requestedAt!=''}">
                <p>Rider Requested</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.requestedAt!=null && orderData.requestedAt!=''"
                    class="mt-0">
                    {{ orderData.requestedAt | date : "MMM dd"}}<br>
                    {{ orderData.requestedAt | date : "hh:mm a"}}
                </p>
            </div>
            <div class="order-tracking" [ngClass]="{completed: orderData.pickedAt!=null && orderData.pickedAt!=''}">
                <p>Picked</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.pickedAt!=null && orderData.pickedAt!=''" class="mt-0">{{
                    orderData.pickedAt | date : "MMM dd"}}<br>
                    {{ orderData.pickedAt | date : "hh:mm a"}}
                </p>
            </div>
            <div class="order-tracking"
                [ngClass]="{completed: orderData.deliveredAt!=null && orderData.deliveredAt!=''}">
                <p>Delivered</p>
                <span class="is-complete"></span>
                <p style="font-size: 8px;" *ngIf="orderData.deliveredAt!=null && orderData.deliveredAt!=''"
                    class="mt-0">
                    {{orderData.deliveredAt | date : "MMM dd"}}<br>
                    {{orderData.deliveredAt | date : "hh:mm a"}}
                </p>
            </div>
        </div>
        <div style="border: 1px solid #ececec;">
            <div class="row m-0">
                <div class="col-md-6 p-3">
                    <span style="font-size: 0.8em; white-space: break-spaces">Customer
                        Number</span>
                    <br>
                    <span class="font-weight-bold text-dark ng-star-inserted"
                        style="font-size: 0.9em; white-space: break-spaces"><a
                            href="tel:{{orderData.mobileNo}}">{{orderData.mobileNo}}</a></span>
                </div>
                <div class="col-md-6 p-3">
                    <span style="font-size: 0.8em; white-space: break-spaces">Customer
                        Name</span>
                    <br>
                    <span class="font-weight-bold text-dark ng-star-inserted"
                        style="font-size: 0.9em; white-space: break-spaces">{{orderData.customerName}}</span>
                </div>
                <div class="col-md-6 p-3">
                    <span style="font-size: 0.8em; white-space: break-spaces">Address</span><br>
                    <span class="font-weight-bold text-dark ng-star-inserted"
                        style="font-size: 0.9em; white-space: break-spaces">{{orderData.addressLine1}}
                        {{orderData.addressLine2}}</span>
                </div>
                <div class="col-md-6 p-3">
                    <span style="font-size: 0.8em; white-space: break-spaces">Amount</span><br>
                    <span class="font-weight-bold text-dark ng-star-inserted"
                        style="font-size: 0.9em; white-space: break-spaces">{{orderData.total | customcurrency}}</span>
                </div>
                <div class="col-md-6 p-3">
                    <span style="font-size: 0.8em; white-space: break-spaces">Payment Mode</span><br>
                    <span class="font-weight-bold text-dark ng-star-inserted"
                        style="font-size: 0.9em; white-space: break-spaces">{{orderData.paymentMode | uppercase}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
    <button class="feedback btn rotate" id="vert" type="button" [routerLink]="['/flash/flash-task']">Create Task
    </button>
</div>

<ng-template #logsModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Rider logs for task id: {{taskId}}</h5>
        <button type="button" class="btn font-weight-bold btn-sm" aria-label="Close"
            (click)="modal.close('Cross click')">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row w-100 m-0">
            <span class="font-weight-bold text-dakr-75" style="font-size: 1.3em;">Delivery Partner:
                {{riderLogs[0].partnerName}}</span>
        </div>
        <div class="style-3">
            <div class="row mt-3 p-5" *ngFor="let data of riderLogs"
                style="background-color: #ececec; border-radius: 5px;display: block;">
                <span class="font-weight-bold text-dakr-75 mb-3" style="font-size: 1.2em;">Action: {{data.action}} <span
                        *ngIf="data.insertedAt!=undefined">at {{data.insertedAt|
                        date:'dd MMM,yyyy hh:mm a'}}</span></span>
                <ngx-json-viewer [json]="data.payload" [expanded]="false"
                    class="json-viewer-container"></ngx-json-viewer>
            </div>
        </div>
    </div>
</ng-template>