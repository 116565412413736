<div class="d-flex flex-column-fluid">
    <div class="container">
        <div class="card card-custom mt-5 mb-5">
            <div class="card-body p-0">
                <div class="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid" id="kt_wizard"
                    data-wizard-state="first" #wizard>
                    <div
                        class="wizard-aside bg-white d-flex flex-column flex-row-auto w-100 w-lg-300px w-xl-400px w-xxl-500px">
                        <div class="d-flex flex-column-fluid flex-column px-xxl-30 px-10 stepHide">
                            <div class="wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5">
                                <div class="wizard-steps">
                                    <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                        <div class="wizard-wrapper">
                                            <div class="wizard-icon">
                                                <i class="wizard-check ki ki-check"></i>
                                                <span class="wizard-number">1</span>
                                            </div>
                                            <div class="wizard-label">
                                                <h3 class="wizard-title">Add Business</h3>
                                                <div class="wizard-desc">Setup Your Business Details</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="d-flex flex-row-auto bgi-no-repeat bgi-position-y-bottom bgi-position-x-center bgi-size-contain h-400px resizeImage"
                            style="background-image: url(../../../../../assets/media/bg/mascot.png)"></div> -->
                    </div>
                    <div class="wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-15 px-5 px-lg-10">
                        <div class="text-right mb-15 mr-xxl-10">
                            <span class="font-weight-bold text-muted font-size-h5">Having issues?</span>
                            <a href="https://api.uengage.in/contact-uengage"
                                class="font-weight-bolder text-info font-size-h4" id="kt_login_signup"
                                target="_blank">&nbsp;Get Help</a>
                        </div>
                        <div class="d-flex justify-content-center flex-row-fluid">
                            <form class="pb-5 w-100 w-md-450px w-lg-500px fv-plugins-bootstrap fv-plugins-framework"
                                id="kt_form">
                                <form [formGroup]="detailForm" (ngSubmit)="saveDetails()"
                                    data-wizard-type="step-content">
                                    <div class="pb-5">
                                        <div class="pb-10">
                                            <h3 class="font-weight-bold text-dark font-size-h2">Enter Details</h3>
                                        </div>
                                        <div class="form-group fv-plugins-icon-container row w-100">
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">Business
                                                    Name</label>
                                                <input type="text"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="Business Name" value="" formControlName="businessName"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.businessName.errors }">
                                                <div *ngIf="submitted2 && d.businessName.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="d.businessName.errors.required">Field is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">Legal
                                                    Entity Name</label>
                                                <input type="text"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="Legal Entity Name" value=""
                                                    formControlName="legalEntityName"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.legalEntityName.errors }">
                                                <div *ngIf="submitted2 && d.legalEntityName.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="d.legalEntityName.errors.required">Field is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group fv-plugins-icon-container">
                                            <label class="font-size-h6 font-weight-bold text-dark control-label">Company
                                                Type</label>
                                            <select
                                                class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                formControlName="companyType" [ngClass]="{ 'is-invalid': submitted2 && d.companyType.errors }">
                                                <option value="">Select</option>
                                                <option *ngFor="let data of companyData" value="{{data.company_typ_id}}">{{data.company_type_name}}</option>
                                            </select>
                                            <div *ngIf="submitted2 && d.companyType.errors" class="invalid-feedback">
                                                <div *ngIf="d.companyType.errors.required">Field is required</div>
                                            </div>
                                        </div> -->
                                        <div class="form-group fv-plugins-icon-container row w-100">
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">Address</label>
                                                <input type="text"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="Address" value="" formControlName="address"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.address.errors }">
                                                <div *ngIf="submitted2 && d.address.errors" class="invalid-feedback">
                                                    <div *ngIf="d.address.errors.required">Field is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">Business
                                                    Contact Number</label>
                                                <input type="number"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="Mobile No." value="" formControlName="mobile"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.mobile.errors }">
                                                <div *ngIf="submitted2 && d.mobile.errors" class="invalid-feedback">
                                                    <div *ngIf="d.mobile.errors.required">Field is required</div>
                                                    <div *ngIf="d.mobile.errors.pattern">Enter valid mobile number</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group fv-plugins-icon-container row w-100">
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">State</label>
                                                <input type="number"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="State" value="" formControlName="state"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.state.errors }">
                                                <div *ngIf="submitted2 && d.state.errors" class="invalid-feedback">
                                                    <div *ngIf="d.state.errors.required">Field is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">City</label>
                                                <input type="number"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="City" value="" formControlName="city"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.city.errors }">
                                                <div *ngIf="submitted2 && d.city.errors" class="invalid-feedback">
                                                    <div *ngIf="d.city.errors.required">Field is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group fv-plugins-icon-container row w-100">
                                            <div class="col-md-6">
                                                <label
                                                    class="font-size-h6 font-weight-bold text-dark control-label">Locality</label>
                                                <input type="number"
                                                    class="form-control h-auto p-5 border-0 rounded-lg font-size-h6"
                                                    placeholder="Locality" value="" formControlName="locality"
                                                    [ngClass]="{ 'is-invalid': submitted2 && d.locality.errors }">
                                                <div *ngIf="submitted2 && d.locality.errors" class="invalid-feedback">
                                                    <div *ngIf="d.locality.errors.required">Field is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between pt-3" style="float: right;">
                                        <div>
                                            <button type="submit"
                                                class="btn btn-info font-weight-bold font-size-h6 pl-8 pr-4 py-4 my-3">Add
                                                New Store
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>