<nav class="navbar navbar-expand-lg navbar-dark bg-white row m-0 pb-5" style="height: 5em;">
    <div class="col-12">
        <div class="topbar-item" data-placement="bottom" style="display: flex;">
            <div class="w-auto btn-clean d-flex row justify-content-start m-0 mr-2" id="kt_quick_user_toggle">
                <ng-container>
                    <div class="topbar-item" style="width: auto;">
                        <div class="m-business-selector w-100 dropdown">
                            <a (click)="myFunction()" href="javascript:;" style="margin-top: 0.8em;
                        display: flex;
                        text-decoration: none;
                        justify-content: space-around;
                        text-align: left;
                        background-color: #FAFAFA;
                        border-radius: 5px;
                        padding: 5px;height: 55px;
                        border: 1px solid #EBEBEB;
                      " class="btn btn-icon btn-hover-black d-flex align-items-center btn-lg px-md-2 w-md-auto dropdown-toggle">
                                <span class="m-nav__link-text mr-3 p-1" *ngIf="noImage!=1"
                                    style="background-color: white;border-radius: 5px;">
                                    <img class="m-topbar__language-selected-img" style="width: 3em;"
                                        src="{{ avatar }}" />
                                </span>
                                <span class="m-nav__link-text mr-3 p-1" *ngIf="noImage==1"
                                    style="color: #a3a3a3;font-size: 2em;background-color: white;border-radius: 5px;">
                                    {{getChar(businessName) | uppercase}}
                                </span>
                                <span id="addo-selected-business mr-3 p-3" style="width:max-content">
                                    <p class="businessDetails" style="font-size: 1rem; font-weight: 600; margin: 0px;">
                                        {{ businessName }}
                                        <!-- <i class="fas fa-arrow-down ml-10" style="color: black !important;font-size: 1rem !important;"></i> -->
                                    </p>
                                    <p class="businessDetails" style="font-size: 0.85rem; margin: 0px;color: #787878;">
                                        {{ locality }}, {{ city }}
                                    </p>
                                </span>
                            </a>
                            <div id="myDropdownWeb" class="dropdown-content style-3"
                                style="min-width: 25em;z-index: 9999;">

                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- <ng-container>
                <span
                  class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
                  >Hi,</span
                >
              </ng-container> -->
                <!-- <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
              >
                {{ _user.fullname }}
              </span>
              <span class="symbol symbol-35 symbol-light-success">
                <span class="symbol-label font-size-h5 font-weight-bold">
                  {{ _user.fullname | firstLetter }}
                </span>
              </span> -->
                <!-- <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
              >
                {{ userName }}
              </span> -->
                <!-- <span class="symbol symbol-35 symbol-light-success">
                <span class="symbol-label font-size-h5 font-weight-bold">
                  {{ userName | firstLetter }}
                </span>
              </span> -->
            </div>
        </div>
    </div>
</nav>
<div class="p-3">
    <!-- <kt-live-rider-main></kt-live-rider-main> -->
    <div [kt-live-rider-main]="data"></div>
</div>