// Angular
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
// Material
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
// Translate
import { TranslateModule } from "@ngx-translate/core";
// NGRX
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
// CRUD
import { InterceptService } from "../../../core/_base/crud/";
// Module components
import { AuthComponent } from "./auth.component";
import { LoginComponent } from "./login/login.component";
// import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { AuthNoticeComponent } from "./auth-notice/auth-notice.component";
// import { LottiePlayerModule } from '@lottiefiles/lottie-player';
// Auth
import {
  AuthEffects,
  AuthGuard,
  authReducer,
  AuthService,
} from "../../../core/auth";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { AgmCoreModule } from "@agm/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { Prism2OnboardingComponent } from "./prism2-onboarding/prism2-onboarding.component";
// import { NgxOtpInputModule } from "ngx-otp-input";
import { NgOtpInputModule } from "ng-otp-input";
// import { CookieService } from 'ngx-cookie-service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LoadingInterceptor } from "src/app/loading.interceptor";
const routes: Routes = [
  {
    path: "welcome",
    component: WelcomePageComponent
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
        //redirectTo: 'login',
        pathMatch: "full",
      },
      {
        path: "login",
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      // {
      //   path: "register",
      //   component: RegisterComponent,
      // },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        // data: {
        //   title: 'uEngage Dashboard',
        //   // title: 'Task Management-uEngage Dashboard',
        //   // ogTitle: '-uEngage Dashboard',
        // }
      },
      // {
      //   path:"signup",
      //   component: OnboardingComponent,
      //   data: {
      //     title: 'uEngage Dashboard',
      //     // title: 'Task Management-uEngage Dashboard',
      //     ogTitle: 'Signup-uEngage Dashboard',
      //   }
      // },
      {
        path: "signup/flash",
        component: Prism2OnboardingComponent,
        data: {
          title: 'uEngage Dashboard',
          // title: 'Task Management-uEngage Dashboard',
          ogTitle: 'Signup Flash-uEngage Dashboard',
        }
      },
      // {
      //   path:"signup/edge",
      //   component: OnboardingComponent,
      //   data: {
      //     title: 'uEngage Dashboard',
      //     // title: 'Task Management-uEngage Dashboard',
      //     ogTitle: 'Signup Edge-uEngage Dashboard',
      //   }
      // },
      {
        path: "signup/prism",
        component: OnboardingComponent,
        data: {
          title: 'uEngage Dashboard',
          // title: 'Task Management-uEngage Dashboard',
          ogTitle: 'Signup Prism-uEngage Dashboard',
        }
      },
      // {
      //   path: "signup/flash-new",
      //   component: Prism2OnboardingComponent,
      //   data: {
      //     title: 'uEngage Dashboard',
      //     // title: 'Task Management-uEngage Dashboard',
      //     ogTitle: 'Signup Prism-uEngage Dashboard',
      //   }
      // },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxOtpInputModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    CarouselModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forChild(),
    StoreModule.forFeature("auth", authReducer),
    EffectsModule.forFeature([AuthEffects]),
    NgbModule,
    NgOtpInputModule
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyB1dEy_J-XULzpX6zcSirVT2OQ_-WAHjUo",
    // }),
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    // },
  ],
  exports: [AuthComponent, NgbModule],
  declarations: [
    AuthComponent,
    LoginComponent,
    // RegisterComponent,
    ForgotPasswordComponent,
    AuthNoticeComponent,
    OnboardingComponent,
    WelcomePageComponent,
    Prism2OnboardingComponent
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard],
    };
  }
}
export function playerFactory() {
  return import('lottie-web');
}
