// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
// import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request
    // request = request.clone({
    // 	setHeaders: {
    // 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    // 	}
    // });


    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
          }
        },
        error => {
          // http response status code
        }
      )
    );
  }
}
