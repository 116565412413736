import { Component, Inject, Renderer2, OnInit,Injectable,RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GoogleServiceService {
  renderer: Renderer2;
  constructor(@Inject(DOCUMENT) private document: Document,
  rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }
   public loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      script.id='googleScript'
      this.renderer.appendChild(this.document.body, script);
    })
  }
}
