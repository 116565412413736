import { ChangeDetectorRef, Component, OnInit, Inject, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleServiceService } from 'src/app/views/google-service.service';
// import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { AnimationOptions } from 'ngx-lottie';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
// import { AnimationOptions } from 'ngx-lottie';
declare var gtag
declare var L
// import * as  L from 'leaflet'  // import { curve, Curve } from 'leaflet'; // for TypeScript
export interface PhotosApi {
  albumId?: number;
  id?: number;
  title?: string;
  url?: string;
  thumbnailUrl?: string;
}

@Component({
  selector: 'kt-prism2-onboarding',
  templateUrl: './prism2-onboarding.component.html',
  styleUrls: ['./prism2-onboarding.component.scss']
})
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Prism2OnboardingComponent implements OnInit {
  userForm: FormGroup;
  options: AnimationOptions = {
    path: 'https://lottie.host/82cd9c32-1c79-4c31-b9a5-e68fb778cf93/A5zFE9TMkf.json'
  };
  otpDigits: string[] = ['', '', '', '', '', ''];
  otpDigits2: string[] = ['', '', '', '', '', ''];
  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  onBoarding = false
  latitude: any = '';
  longitude: any = '';
  url: string;
  isEmailVerified = false;
  isPhoneVerified = false;
  education_step = false;
  showPasswordFeild: boolean = false
  step = 1;
  isDisabled: boolean = true;
  isDisabled2: boolean = true;
  map: any;
  verfied: boolean = false;
  // otpInputConfig: NgxOtpInputConfig = {
  //   otpLength: 4,
  //   autofocus: false,
  //   classList: {
  //     inputBox: 'my-super-box-class',
  //     input: 'my-super-class',
  //     inputFilled: 'my-super-filled-class',
  //     inputDisabled: 'my-super-disable-class',
  //     inputSuccess: 'my-super-success-class',
  //     inputError: 'my-super-error-class',
  //   },
  // };
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoplayTimeout: 2000,
    autoHeight: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 6,
      },
      600: {
        items: 6,
      },
      1000: {
        items: 6,
      }
    }
  }
  userId: any;
  emailId: any;
  merchantNum: any;
  otpValidated: number;
  cityData: any[];
  fieldTextType: boolean = false;
  password: any;
  googleData: any;
  googleLogin: number;
  fbLogin: number;
  fbData: any;
  constructor(private formBuilder: FormBuilder, private modalRef: NgbActiveModal, private modalService: NgbModal, public google: GoogleServiceService, private router: Router, public httpClient: HttpClient, private cd: ChangeDetectorRef, private toastr: ToastrService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private authService: SocialAuthService) {

  }
  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      windowClass: "custom-modal-class",
      backdrop: 'static',
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
    });
  }
  changePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngOnInit(): void {

    this.userForm = this.formBuilder.group({
      // name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      // password: ['',]
    });
    this.personalDetails = this.formBuilder.group({
      bussinessName: ['', Validators.required],
      contact: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      name: ['', Validators.required],
      email: [''],
      phoneNumber: [''],
      password: ['', Validators.required]
    });

    // this.addressDetails = this.formBuilder.group({
    //   city: ['', Validators.required],
    //   address: ['', Validators.required],
    //   pincode: ['', Validators.required]
    // });

    // this.educationalDetails = this.formBuilder.group({
    //   address: ['', Validators.required],
    //   pinCode: ['', Validators.required],
    //   city: ['', Validators.required],
    //   state: ['', Validators.required]
    // });
  }

  addGoogleMapScript() {
    this.removeGoogleMapScript()
    const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB1dEy_J-XULzpX6zcSirVT2OQ_-WAHjUo&libraries=places&callback=Function.prototype';
    // let element = document.getElementById("googleScript")
    // (element)
    // if(element!=undefined && element!=null){
    this.google.loadScript(url);
  }
  removeGoogleMapScript() {
    // console.debug('removing google script...');
    let keywords = ['maps.googleapis'];

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    let scripts = document.head.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      let scriptSource = scripts[i].getAttribute('src');
      if (scriptSource != null) {
        if (keywords.filter(item => scriptSource.includes(item)).length) {
          scripts[i].remove();
          // scripts[i].parentNode.removeChild(scripts[i]);
        }
      }
    }
  }
  // handeOtpChange(value: string[]): void {
  //   console.log(value[5] != null);
  //   if (value[5] != null && value[5] != '') {
  //     this.isDisabled = false
  //   } else {
  //     this.isDisabled = true
  //   }

  // }

  emailOtp(value: string): void {
    if (value.length == 4) {
      let params =
        "userId=" +
        this.userId +
        "&email_id=" +
        this.emailId +
        "&OTP=" + value;
      this.httpClient.get("https://api.uengage.in/onboarding/validateOTP?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.isEmailVerified = true
          if (this.isEmailVerified && this.isPhoneVerified) {
            setTimeout(() => {
              this.modalRef.close()
              this.isEmailVerified = false
              this.isPhoneVerified = false
              this.otpValidated = 1
              this.personalDetails.controls.email.setValue(this.emailId)
              this.personalDetails.controls.phoneNumber.setValue(this.merchantNum)
              this.personalDetails.controls.contact.setValue('')
              this.personalDetails.controls.password.setValue('')
              this.getCity()
            }, 2000);
          }
          this.personalDetails.controls.contact.setValue('')
          this.personalDetails.controls.password.setValue('')
          // Swal.fire('', 'OTP verified successfully!', 'success')
          // this.submitted1 = false;
          // this.profileShow = data.showProfileScreen
          // if (data.password != undefined && data.password != null) {
          //   this.userPassword = data.password
          //   this.firstName = data.firstName
          //   this.lastName = data.lastName
          // }
          // this.checkBDetails()
          // // this.otpCorrect = 1
          // this.cd.detectChanges()
          // this.router.navigateByUrl("/login");
        } else {
          this.toastr.error(data.msg, 'Error', {
            positionClass: 'toast-bottom-right'
          })
        }
      })
    }
  }
  contactOtp(value: string): void {
    if (value.length == 4) {
      // if (value == '1234') {
      //   this.isPhoneVerified = true
      //   if (this.isEmailVerified && this.isPhoneVerified) {
      //     setTimeout(() => {
      //       this.modalRef.close()
      //       this.isEmailVerified = false
      //       this.isPhoneVerified = false
      //       this.otpValidated = 1
      //       this.personalDetails.controls.email.setValue(this.emailId)
      //       this.personalDetails.controls.phoneNumber.setValue(this.merchantNum)
      //       this.getCity()
      //     }, 2000);
      //   }
      //   this.cd.detectChanges()
      // }
      // return
      let params =
        "userId=" +
        this.userId +
        "&otp=" + value;
      this.httpClient.get("https://api.uengage.in/onboarding/validateMobileNo?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.isPhoneVerified = true
          if (this.isEmailVerified && this.isPhoneVerified) {
            setTimeout(() => {
              this.modalRef.close()
              this.isEmailVerified = false
              this.isPhoneVerified = false
              this.otpValidated = 1
              this.personalDetails.controls.email.setValue(this.emailId)
              this.personalDetails.controls.phoneNumber.setValue(this.merchantNum)
              this.personalDetails.controls.contact.setValue('')
              this.personalDetails.controls.password.setValue('')
              this.getCity()
            }, 2000);
          }
          this.personalDetails.controls.contact.setValue('')
          this.personalDetails.controls.password.setValue('')
          this.cd.detectChanges()
          // Swal.fire('', 'OTP verified successfully!', 'success')
          // this.submitted1 = false;
          // this.profileShow = data.showProfileScreen
          // if (data.password != undefined && data.password != null) {
          //   this.userPassword = data.password
          //   this.firstName = data.firstName
          //   this.lastName = data.lastName
          // }
          // this.checkBDetails()
          // // this.otpCorrect = 1
          // this.cd.detectChanges()
          // this.router.navigateByUrl("/login");
        } else {
          this.toastr.error(data.msg, 'Error', {
            positionClass: 'toast-bottom-right'
          })
        }
      })
    }
  }
  get personal() { return this.personalDetails.controls; }

  get address() { return this.addressDetails.controls; }

  get education() { return this.educationalDetails.controls; }
  opentemplateModel(modal) {
    this.open(modal)
  }
  onDigitInput(index: number) {
    // Move to the next input field when a digit is entered
    if (this.otpDigits[index].length === 1 && index < 5) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude, this.longitude);
        this.map.remove()
        var mapOptions = {
          // center: [this.latitude, this.longitude],
          center: [30.712546585969097, 76.84757766742457],
          zoom: 17,
          attribution: 'uengage'
        }

        this.map = new L.map('map', mapOptions);


        var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');


        this.map.addLayer(layer);

        var storeicon = L.icon({
          iconUrl: '../../../../../assets/media/prism-images/map-marker.png',
          // ,
          iconSize: [54, 54],
          iconAnchor: [27, 54]
        });

        // var marker = L.marker([this.latitude, this.longitude], { icon: storeicon });
        var marker = L.marker([30.712546585969097, 76.84757766742457], { icon: storeicon });
        // this.latitude, this.longitude 30.712546585969097,76.84757766742457,
        marker.addTo(this.map);
      })
    }
  }
  submit() {

    if (this.step == 3) {
      this.education_step = true;
      if (this.educationalDetails.invalid) { return }
      alert("Well done!!")
    }
  }
  onBoardingFlow() {
    // this.onBoarding=true
    this.verfied = true
  }
  verifyPhoneOtp() {
    this.isPhoneVerified = true
  }
  verifyEmailOtp() {
    this.isEmailVerified = true
  }
  continue() {
    this.showPasswordFeild = true
  }
  createUser(modal) {
    if (this.userForm.valid) {
      let data = this.userForm.value
      this.emailId = data.email
      this.merchantNum = data.phoneNumber
      let params =
        "email_id=" +
        data.email +
        "&mobileNo=" + data.phoneNumber;
      this.httpClient.get("https://api.uengage.in/onboarding/createUser?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.userId = data.userId
          this.open(modal)
        }
        else {

        }
      })
    }
  }
  login() {
    this.router.navigateByUrl('/login')
  }
  getCity() {
    this.cityData = []
    this.httpClient.get("https://api.uengage.in/onboarding/getCitiesList").subscribe((data: any) => {
      if (data.status == 1) {
        this.cityData = data.cities
        this.cd.detectChanges()
      }
      else {

      }
    });
  }
  getState() {
    let index = -1
    for (let i = 0; i < this.cityData.length; i++) {
      if (this.cityData[i].cityName == this.personalDetails.controls.city.value) {
        index = i
        break;
      }
    }
    this.personalDetails.controls.state.setValue(this.cityData[index].stateName)
    this.cd.detectChanges()
  }
  createAccount() {
    if (this.personalDetails.valid) {
      let data = this.personalDetails.value
      this.password = data.password
      let name = data.name.split(' ')
      if (this.otpValidated == 1) {
        let params =
          "userId=" +
          this.userId +
          "&email_id=" +
          this.emailId +
          "&firstName=" + name[0] +
          "&lastName=" + name[1] +
          "&password=" + data.password +
          "&bname=" + encodeURIComponent(data.bussinessName) +
          "&city=" + data.city +
          "&state=" + data.state +
          "&rest_contact_no=" + data.contact +
          "&personal_contact_no=" + this.merchantNum +
          "&product_id=3" +
          "&category_id=3" +
          "&login_via=1";
        // if (this.salesId != '' && this.salesId != undefined) {
        //   params += "&sales_ref=" + this.salesId;
        // }
        this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
          if (data.status == 1) {
            Swal.fire({
              icon: "success",
              title: "Account Setup Completed!",
              showConfirmButton: false,
              timer: 2000
            });
            let params =
              "userName=" +
              this.emailId +
              "&password=" +
              this.password;
            this.httpClient.get("https://api.uengage.in/ueapi/login/addo/?" + params, {}).subscribe((res: any) => {
              if (typeof res !== "undefined") {
                if (res.status == 1) {
                  this.storage.set("AssignedBusiness", res.msg);
                  this.storage.set("userId", res.msg[0].userId);
                  this.storage.set("userName", this.emailId);
                  this.storage.set("password", this.password);
                  let data1 = res.onboarding.bDetails
                  // this.authNoticeService.setNotice(res.displayMessage, "success");
                  // (res.onboarding)
                  //this.router.navigate(["businessSelector"]);
                  if (data1 != undefined) {
                    if (data1.onboard_productId == 2) {
                      this.router.navigate(["businessSelector"]);
                    } else if (data1.onboard_productId == 3) {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-flash-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    } else {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-edge-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    }
                  } else {
                    // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                    //   let data = res.onboarding.bDetails
                    //   this.storage.set("productId", data.onboard_productId);
                    //   this.storage.set("businessName", data.name);
                    //   this.router.navigate(["complete-profile"]);
                    // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                    //   this.router.navigate(["approval-pending"]);
                    // } else {
                    this.router.navigate(["businessSelector"]);
                    // }
                  }
                } else {
                  // this.authNoticeService.setNotice(res.displayMessage, "error");
                }
              } else {
                // this.authNoticeService.setNotice(
                //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
                //   "error"
                // );
              }
              // this.loading = false;
              // this.cdr.detectChanges();
            },
              (error) => {
                // this.authNoticeService.setNotice(
                //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
                //   "error"
                // );
              }
            );
          }
          if (data.status == 0) {
            Swal.fire('', data.msg, 'error')
          }
        })
      } else if (this.googleLogin == 1) {
        let params =
          "userId=" +
          this.userId +
          "&email_id=" +
          this.emailId +
          "&firstName=" + name[0] +
          "&lastName=" + name[1] +
          "&password=" + this.googleData.accPassword +
          "&bname=" + encodeURIComponent(data.bussinessName) +
          "&city=" + data.city +
          "&state=" + data.state +
          "&rest_contact_no=" + data.contact +
          "&personal_contact_no=" + data.phoneNumber +
          "&product_id=3" +
          "&category_id=3" +
          "&login_via=3";
        // if (this.salesId != '' && this.salesId != undefined) {
        //   params += "&sales_ref=" + this.salesId;
        // }
        this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
          if (data.status == 1) {
            Swal.fire({
              icon: "success",
              title: "Account Setup Completed!",
              showConfirmButton: false,
              timer: 2000
            });
            let params =
              "email=" +
              this.emailId +
              "&accessToken=" + this.googleData.idToken;
            this.httpClient.get("https://api.uengage.in/ueapi/login_google?" + params, {}).subscribe((res: any) => {
              if (typeof res !== "undefined") {
                if (res.status == 1) {
                  this.storage.set("AssignedBusiness", res.msg);
                  this.storage.set("userId", res.msg[0].userId);
                  this.storage.set("userName", res.msg[0].userName);
                  this.storage.set("password", res.msg[0].misc);
                  let data1 = res.onboarding.bDetails
                  if (data1 != undefined) {
                    if (data1.onboard_productId == 2) {
                      this.router.navigate(["businessSelector"]);
                    } else if (data1.onboard_productId == 3) {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-flash-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    } else {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-edge-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    }
                  } else {
                    this.router.navigate(["businessSelector"]);
                  }
                } else {
                }
              } else {
              }
            });
          }
          if (data.status == 0) {
            Swal.fire('', data.msg, 'error')
          }
        })
      } else {
        let params =
          "userId=" +
          this.userId +
          "&email_id=" +
          this.emailId +
          "&firstName=" + name[0] +
          "&lastName=" + name[1] +
          "&password=" + this.fbData.accPassword +
          "&bname=" + encodeURIComponent(data.bussinessName) +
          "&city=" + data.city +
          "&state=" + data.state +
          "&rest_contact_no=" + data.contact +
          "&personal_contact_no=" + data.phoneNumber +
          "&product_id=3" +
          "&category_id=3" +
          "&login_via=2";
        this.httpClient.get("https://api.uengage.in/onboarding/enter_bDetails?" + params).subscribe((data: any) => {
          if (data.status == 1) {
            Swal.fire({
              icon: "success",
              title: "Account Setup Completed!",
              showConfirmButton: false,
              timer: 2000
            });
            let params =
              "email=" +
              this.emailId +
              "&accessToken=" + this.fbData.authToken;
            this.httpClient.get("https://api.uengage.in/ueapi/login_fb?" + params, {}).subscribe((res: any) => {
              if (typeof res !== "undefined") {
                if (res.status == 1) {
                  this.storage.set("AssignedBusiness", res.msg);
                  this.storage.set("userId", res.msg[0].userId);
                  this.storage.set("userName", res.msg[0].userName);
                  this.storage.set("password", res.msg[0].misc);
                  let data1 = res.onboarding.bDetails
                  if (data1 != undefined) {
                    if (data1.onboard_productId == 2) {
                      this.router.navigate(["businessSelector"]);
                    } else if (data1.onboard_productId == 3) {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-flash-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    } else {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-edge-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    }
                  } else {
                    this.router.navigate(["businessSelector"]);
                  }
                } else {

                }
              } else {
              }
            });
          }
          if (data.status == 0) {
            Swal.fire('', data.msg, 'error')
          }
        })
      }
    }
  }
  changeEmail() {
    this.modalRef.close()
    this.userForm.controls.email.setValue('')
  }
  changeNumber() {
    this.modalRef.close()
    this.userForm.controls.phoneNumber.setValue('')
  }
  signInWithGoogle() {
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.idToken;
          this.httpClient.get("https://api.uengage.in/onboarding/googleLogin?" + params).subscribe((data: any) => {
            if (data.status) {
              this.googleLogin = 1
              this.googleData = data
              this.emailId = userData.email
              this.userId = userData.userId
              this.personalDetails.controls.email.setValue(userData.email)
              this.personalDetails.controls.name.setValue(userData.name)
              this.personalDetails.controls.phoneNumber.setValidators(Validators.required)
              this.personalDetails.get('password').clearValidators();
              this.personalDetails.get('password').updateValueAndValidity();
              this.getCity()
              this.cd.detectChanges()
            }
          })
        }
      })
  }
  signInWithFB(): void {
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        // (userData)
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.authToken;
          this.httpClient.get("https://api.uengage.in/onboarding/facebookLogin?" + params).subscribe((data: any) => {
            if (data.status == 1) {
              this.fbLogin = 1
              this.emailId = userData.email
              this.userId = data.userId
              this.fbData = data
              this.personalDetails.controls.email.setValue(userData.email)
              this.personalDetails.controls.name.setValue(userData.name)
              this.personalDetails.controls.phoneNumber.setValidators(Validators.required)
              this.personalDetails.get('password').clearValidators();
              this.personalDetails.get('password').updateValueAndValidity();
              this.getCity()
              this.cd.detectChanges()
            }
            else {
              Swal.fire('', data.msg, 'error')
            }
          })
        }
        // this.sendToRestApiMethod(userData.token);
      }
    );
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
