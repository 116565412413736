import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import Swal from 'sweetalert2';
import KTImageInput from '../../../../../assets/js/components/image-input.js'
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'kt-edge-creation',
  templateUrl: './edge-creation.component.html',
  styleUrls: ['./edge-creation.component.scss']
})
export class EdgeCreationComponent implements OnInit {
  @ViewChild("wizard", { static: true }) el: ElementRef;
  submitted2 = false
  detailForm: FormGroup
  flashForm: FormGroup
  locationForm: FormGroup
  submitted3 = false
  companyData: any;
  docDetails: any;
  wizard: any;
  bName: any;
  productId: any;
  bDetails: any;
  userId: any;
  bId: any;
  avatar: any;
  docId: any;
  businessDetails: number;
  docList = [];
  docData: any;
  userName: any;
  prefill: number;
  latitude: any = '';
  options: { componentRestrictions: { country: string[]; }; };
  longitude: any = '';
  showMap: number;
  zoom = 15
  wizardStep: number;
  taskSource: any;
  product_icon: any;
  sourceId: any = '';
  logoForm: FormGroup
  submitted4 = false
  fileUpload = '';
  imageChangedEvent: any;
  croppedImage: any;
  croppedImageWidth: any;
  cropppedImageHeight: any;
  imagePosition: any;
  imageUploaded: number = 0;
  brandLogo: any;
  prefillImage: number = 0;
  uploadedDocs = [];
  menuCreation = '';
  menu: number = 0;
  constructor(private fb: FormBuilder, @Inject(LOCAL_STORAGE) private storage: WebStorageService, public httpClient: HttpClient,
    private cd: ChangeDetectorRef, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.userName = this.storage.get("userName");
    let bDetails = []
    if (this.storage.get("onboardBusiness") != undefined && this.storage.get("onboardBusiness") != '' && this.storage.get("onboardBusiness") != null) {
      bDetails = this.storage.get("onboardBusiness")
    } else {
      bDetails = this.storage.get("AssignedBusiness");
    }
    // let bDetails = this.storage.get("AssignedBusiness");
    this.bDetails = bDetails
    this.userId = this.bDetails["userId"]
    this.bId = this.bDetails["businessId"]
    this.detailForm = this.fb.group({
      gstin: ['', [Validators.required]],
      companyType: ['', [Validators.required]],
      legalEntityName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      accHolder: ['', [Validators.required]],
      accNumber: ['', [Validators.required]],
      ifsc: ['', [Validators.required]],
    })
    this.logoForm = this.fb.group({
      logo: ['', []],
    })
    this.getCompanyTypes()
    //  this.getBDetails()
  }
  getBDetails() {
    this.docDetails = []
    this.docList = []
    let params =
      "userId=" + this.userId +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId;
    this.httpClient.get("https://api.uengage.in/onboarding/getUpdatedDetails?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        let bdata = res.bDetails
        this.bName = bdata.name
        this.productId = bdata.onboard_productId
        this.product_icon = bdata.product_icon
        this.cd.detectChanges()
        if (bdata.legalEntityName != '' && bdata.legalEntityName != null) {
          this.prefill = 1
          this.wizardStep = 1
          this.detailForm.controls.legalEntityName.setValue(bdata.legalEntityName)
          this.detailForm.controls.address.setValue(bdata.address)
          this.detailForm.controls.gstin.setValue(bdata.gstin)
          this.detailForm.controls.companyType.setValue(bdata.company_type)
          this.detailForm.controls.bankName.setValue(bdata.bankName)
          this.detailForm.controls.accHolder.setValue(bdata.bankAccountHolder)
          this.detailForm.controls.accNumber.setValue(bdata.bankAccountNumber)
          this.detailForm.controls.ifsc.setValue(bdata.ifsc)
          let index = this.companyData.findIndex(e => e.company_typ_id == bdata.company_type);
          this.docDetails = this.companyData[index].document_list
          this.uploadedDocs = res.doc_uploaded
          // (this.docDetails)
          for (let i = 0; i < this.docDetails.length; i++) {
            this.docList.push({
              id: this.docDetails[i].id,
              name: this.docDetails[i].documentName,
              uploaded: 0,
              file: ''
            })
          }
          // (this.docList)
          if (res.doc_uploaded.length != 0) {
            // (res.doc_uploaded)
            for (let i = 0; i < this.docList.length; i++) {
              let index: any
              index = res.doc_uploaded.findIndex(e => e.document_id == this.docList[i].id)
              // (index)
              if (index > -1) {
                this.docList[index].uploaded = 1
                this.docList[index].file = res.doc_uploaded[index].document_path
              }
            }
          }
          this.docList.sort((a, b) => (a.uploaded < b.uploaded ? -1 : 1));

          //  (this.docList)
          this.businessDetails = 1
          this.cd.detectChanges()
          this.wizard.goNext()
        }
        if (bdata.logo != null) {
          this.wizardStep = 2
          this.imageUploaded = 1
          this.brandLogo = bdata.logo
          this.prefillImage = 1
          this.cd.detectChanges()
          this.wizard.goNext()
        }
      } else {
        this.prefill = 0
        this.businessDetails = 0
      }
    })
  }
  ngAfterViewInit(): void {
    // this.wizardStep = 1
    // Initialize form wizard
    // if(this.productId==3){
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
    });
    // Validation before going to next page
    this.wizard.on("beforeNext", (wizardObj) => {
      if (wizardObj.currentStep == 1) {
        this.saveDetails()
        //  this.wizardStep=2
      }
      if (wizardObj.currentStep == 2) {

      }
      if (wizardObj.currentStep == 3) {

      }
    });
    // Change event
    this.wizard.on("change", (wizardObj) => {
      this.wizardStep = wizardObj.currentStep
      // (this.wizardStep)
      this.cd.detectChanges()
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
  get d() { return this.detailForm.controls; }

  getCompanyTypes() {
    this.httpClient.get("https://api.uengage.in/onboarding/getCompanytypes").subscribe((data: any) => {
      if (data.status == 1) {
        this.companyData = data.list
        this.cd.detectChanges()
        this.getBDetails()
      }
      else {
        Swal.fire('', data.msg, 'error')
        return false;
      }
    });
  }
  saveDetails() {
    this.docDetails = []
    this.docList = []
    this.submitted2 = true
    if (this.detailForm.invalid) {
      this.wizard.stop();
    }
    else {
      let data = this.detailForm.value
      let index = this.companyData.findIndex(e => e.company_typ_id == data.companyType);
      this.docDetails = this.companyData[index].document_list
      for (let i = 0; i < this.docDetails.length; i++) {
        this.docList.push({
          id: this.docDetails[i].id,
          name: this.docDetails[i].documentName,
          uploaded: 0,
          file: ''
        })
      }
      if (this.uploadedDocs.length > 0) {
        for (let i = 0; i < this.docList.length; i++) {
          let index: any
          index = this.uploadedDocs.findIndex(e => e.document_id == this.docList[i].id)
          if (index > -1) {
            this.docList[index].uploaded = 1
            this.docList[index].file = this.uploadedDocs[index].document_path
          }
        }
        // }
        this.docList.sort((a, b) => (a.uploaded < b.uploaded ? -1 : 1));
      }
      // if (this.prefill != 1) {
      // }
      // (this.docList)
      this.cd.detectChanges()
      let params =
        "userId=" + this.userId +
        "&password=" + this.storage.get("password") +
        "&businessId=" + this.bId +
        "&legalEntityName=" + encodeURIComponent(data.legalEntityName) +
        "&gstin=" + data.gstin +
        "&address=" + encodeURIComponent(data.address) +
        "&company_type=" + data.companyType +
        "&bank_name=" + encodeURIComponent(data.bankName) +
        "&bank_account_holder=" + data.accHolder +
        "&ifsc=" + data.ifsc +
        "&bank_account_number=" + data.accNumber +
        "&onboard_productId=" + this.productId;
      this.httpClient.get("https://api.uengage.in/onboarding/updateBusinessDetails?" + params).subscribe((res: any) => {
        if (res.status == 1) {
          // this.wizardStep=-1
          this.businessDetails = 1
          this.wizard.goNext()
          this.cd.detectChanges()
        } else {
          this.businessDetails = 0
          Swal.fire('', res.msg, 'error')
          this.cd.detectChanges()
          this.wizard.stop()
        }
      })
      // (this.docDetails)
      this.cd.detectChanges()

      // this.accountForm.reset();
    }
  }
  previous() {
    this.wizardStep = this.wizardStep - 1
    this.wizard.goPrev()
  }
  change(id) {
    this.avatar = new KTImageInput(id);
    this.docId = id
    // (this.docId)
  }
  onSelectFile(event) {
    // (event.target.files)
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("businessId", this.bId);
    formData.append("userId", this.userId);
    formData.append("password", this.storage.get("password"));
    formData.append("document_id", this.docId);
    this.httpClient
      .post("https://api.uengage.in/onboarding/uploadDocuments", formData)
      .subscribe((res: any) => {
        if (res.status == 1) {
          //  (this.docList)
          let index = this.docList.findIndex(e => e.id == this.docId);
          this.docList[index].uploaded = 1
          this.docList[index].file = res.fileName
          this.cd.detectChanges()
          this.toastr.success(res.msg, 'Success', {
            positionClass: 'toast-bottom-right'
          })
          // Swal.fire({
          //   title: "Document uploaded!",
          //   text: "",
          //   icon: "success"
          // });
        } else {
          let index = this.docList.findIndex(e => e.id == this.docId);
          this.docList[index].uploaded = 0
          this.docList[index].file = ''
          this.cd.detectChanges()
          this.toastr.error(res.error, 'Error', {
            positionClass: 'toast-bottom-right'
          })
          // this.toastr.success(res.msg)
          // Swal.fire({
          //   title: "Failed to upload the document",
          //   text: "",
          //   icon: "error"
          // });
        }
      });
  }
  compSetup() {
    let error = 0
    for (let i = 0; i < this.docList.length; i++) {
      if (this.docList[i].uploaded == 0) {
        error = 1
        break;
      }
    }
    if (error == 1) {
      this.toastr.error('Please upload all documents', 'Error', {
        positionClass: 'toast-bottom-right'
      })
      this.wizard.stop()
    } else {
      let params =
        "userId=" + this.userId +
        "&password=" + this.storage.get("password") +
        "&businessId=" + this.bId;
      this.httpClient.get("https://api.uengage.in/onboarding/completeOnboarding?" + params).subscribe((res: any) => {
        if (res.status == 1) {
          Swal.fire('', res.msg, 'success')
          setTimeout(function () {
            window.location.href = 'http://app.uengage.in/approval-pending';
            // this.router.navigateByUrl("/login");
          }, 2000)
        } else {
          Swal.fire('', res.msg, 'error')
        }
      })
    }
  }
  logout() {
    let isBuzz = this.storage.get("isBuzz");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    let path = "";
    this.router.navigate([path]);
  }
  fileChangeEvent(event: any): void {
    this.brandLogo = ''
    this.prefillImage = 0
    // let imageSize = event.target.files[0].size;
    this.fileUpload = event.target.files[0];
    this.imageChangedEvent = event;
    // if (imageSize > 10000000) {
    //   Swal.fire({
    //     title: "File Should be Less than 60KB",
    //   });
    //   return;
    // } else {

    //   // this.openLarge(modalName);
    // }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageWidth = event.width;
    this.cropppedImageHeight = event.width;
    this.imagePosition = event.imagePosition;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  uploadImageCropped() {
    (this.fileUpload)
    if (this.prefillImage == 0) {
      if (this.fileUpload != '') {
        var formData = new FormData();
        formData.append("file", this.fileUpload);
        formData.append("businessId", this.bId);
        formData.append("userId", this.storage.get("userId"));
        formData.append("password", this.storage.get("password"));
        formData.append("x1", this.imagePosition.x1);
        formData.append("x2", this.imagePosition.x2);
        formData.append("y1", this.imagePosition.y1);
        formData.append("y2", this.imagePosition.y2);
        this.httpClient
          .post("https://api.uengage.in/onboarding/uploadLogo", formData)
          .subscribe((res: any) => {
            if (res.status == 1) {
              Swal.fire('', res.msg, 'success')
              this.imageUploaded = 1
              this.wizard.goNext()
            } else {
              Swal.fire('', res.error, 'error')
              this.imageUploaded = 0
            }
          });
      } else {
        this.imageUploaded = 1
        this.wizard.goNext()
      }
    } else {
      this.wizard.goNext()
    }
  }
  getExtension(file) {
    if (file.includes("pdf")) {
      return true
    } else {
      return false
    }
  }
  saveMenuCreation(value) {
    this.menuCreation = value
    this.cd.detectChanges()
  }
  saveMenu() {
    if (this.menuCreation != '') {
      this.toastr.error('Please enter menu details', 'Error', {
        positionClass: 'toast-bottom-right'
      })
      this.wizard.stop()
    } else {
      this.menu = 1
      this.wizard.goNext()
    }
  }
}
