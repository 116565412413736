<div class="main-onboarding-block-upper-responsive" style="overflow: hidden;">
    <div class="main-onboarding-block">
        <div class="row mobRow m-0" style="height: 100vh;">
            <div class="col-md-8 pl-0 mobHeight" style="height: 100vh;">
                <div class="signup-left-block">
                    <div class="logo" style="display: none;">
                        <img alt="Uengage Logo" class="max-h-60px"
                            src="../../../../../assets/media/logos/uEngage-Logo.png">
                    </div>
                    <div class="row w-100 mobHeading m-0" style="display: none;
                    text-align: center;padding-top: 1rem;padding-bottom: 1rem;">
                        <span class="gradient-text">Efficient Delivery Management System</span><br>
                        <span style="font-size: 1.7em;">For Hyperlocal Businesses</span>
                    </div>
                    <div style="margin-left: 6em;margin-right: 6em;padding-top: 3em;" class="webDiv">
                        <div class="row w-100" style="display: block;
                        text-align: center;padding-top: 2rem;">
                            <span class="gradient-text">Efficient Delivery Management System</span><br>
                            <span style="font-size: 1.7em;">For Hyperlocal Businesses</span>
                        </div>
                        <div class="row m-0 w-100 mt-10">
                            <!-- <ng-lottie [options]="options" style="width: 40%;height: 40%;">
                            </ng-lottie> -->
                            <div class="col-md-4 col-6">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <div class="row w-100 m-0" style="justify-content: space-between;">
                                        <span style="font-weight: bolder;font-size: 2em;color: black;">15,000+</span>
                                        <img src="../../../../../assets/media/prism-images/rocket.png"
                                            style="width: 2em;height: 2em;">
                                    </div>
                                    <div class="row m-0 w-100">
                                        <span style="color: #8CC42A;font-weight: bold;">Business Onboarded</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-6">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <div class="row w-100 m-0" style="justify-content: space-between;">
                                        <span style="font-weight: bolder;font-size: 2em;color: black;">10
                                            Lakh+</span>
                                        <img src="../../../../../assets/media/prism-images/scooter.png"
                                            style="width: 2em;height: 2em;">
                                    </div>
                                    <div class="row m-0 w-100">
                                        <span style="color: #8CC42A;font-weight: bold;">Delivery Tasks
                                            Enabled</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-6">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <div class="row w-100 m-0" style="justify-content: space-between;">
                                        <span style="font-weight: bolder;font-size: 2em;color: black;">95%</span>
                                        <img src="../../../../../assets/media/prism-images/clock.png"
                                            style="width: 2em;height: 2em;">
                                    </div>
                                    <div class="row m-0 w-100">
                                        <span style="color: #8CC42A;font-weight: bold;">On-Time Deliveries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 w-100 mt-3">
                            <div class="col-md-4 text-center col-6" style="margin-left:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <div class="row w-100 m-0" style="justify-content: space-between;">
                                        <span style="font-weight: bolder;font-size: 2em;color: black;">40%</span>
                                        <img src="../../../../../assets/media/prism-images/graph.png"
                                            style="width: 2em;height: 2em;">
                                    </div>
                                    <div class="row m-0 w-100">
                                        <span style="color: #8CC42A;font-weight: bold;">Decrease In Operational
                                            Cost</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center col-6" style="margin-right:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);">
                                    <div class="row w-100 m-0" style="justify-content: space-between;">
                                        <span style="font-weight: bolder;font-size: 2em;color: black;">24*7</span>
                                        <img src="../../../../../assets/media/prism-images/person.png"
                                            style="width: 2em;height: 2em;">
                                    </div>
                                    <div class="row m-0 w-100">
                                        <span style="color: #8CC42A;font-weight: bold;">Dedicated Customer
                                            Support</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="over-all-rating-carousel mt-5">
                            <!-- <div class="trusted-by-responsive">
                                <p style="font-size: 1.2em;">4.9 + Overall Ratings</p>
                                <p><img src="https://www.uengage.in/assets/uen_io/images/star-full.svg" alt="Star Full"
                                        width="16" height="16" style="max-width: 16px;"> <img
                                        src="https://www.uengage.in/assets/uen_io/images/star-full.svg" alt="Star Full"
                                        width="16" height="16" style="max-width: 16px;"> <img
                                        src="https://www.uengage.in/assets/uen_io/images/star-full.svg" alt="Star Full"
                                        width="16" height="16" style="max-width: 16px;"> <img
                                        src="https://www.uengage.in/assets/uen_io/images/star-full.svg" alt="Star Full"
                                        width="16" height="16" style="max-width: 16px;"> <img
                                        src="https://www.uengage.in/assets/uen_io/images/star-half.svg" alt="Star Half"
                                        width="16" height="16" style="max-width: 16px;"></p>
                            </div> -->
                            <div style="margin-top: 3em;" class="mobileMargin">
                                <owl-carousel-o [options]="customOptions">
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img
                                                src="https://api.uengage.in/assets/uen_io/images/the-burger-club-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/the-obroma-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/tossin-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/lapinoz-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/burger-farm-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img
                                                src="https://api.uengage.in/assets/uen_io/images/thewholesale-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/1441-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/chelvies-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/u-campus.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/canadian-pizza.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/qicgo-nw.png" />
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="custom-carousel-images">
                                            <img src="https://api.uengage.in/assets/uen_io/images/biryani-zone.png" />
                                        </div>
                                    </ng-template>
                                </owl-carousel-o>
                                <!-- <div class="trusted-by">
                                    <p style="font-size: 1.5em;">4.9 + Overall Ratings</p>
                                </div> -->
                            </div>
                        </div>
                        <div class="row m-0 w-100 mt-10 position-relative">
                            <div class="col-md-4 text-center pr-0" style="margin-left:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);color: #8CC42A;text-align: center;font-weight: bold">
                                    <span>AI-Powered Algorithm <br>
                                        For Auto Allocation</span>
                                </div>
                            </div>
                            <div class="image-overlay">
                                <img src="../../../../../assets/media/logos/flash.png" alt="Overlay Image">
                            </div>
                            <div class="col-md-4 text-center pr-0" style="margin-right:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);color: #8CC42A;text-align: center;font-weight: bold">
                                    <span>Works With Multiple <br>
                                        POS Integrations</span>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 w-100 mt-2 position-relative">
                            <div class="col-md-4 text-center pr-0" style="margin-left:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);color: #8CC42A;text-align: center;font-weight: bold;">
                                    <span>Plug & Play<br>
                                        3PL Integrations</span>
                                </div>
                            </div>
                            <div class="col-md-4 text-center pr-0" style="margin-right:auto">
                                <div class="p-5"
                                    style="background-color: white;border-radius: 5px; box-shadow: 0px 1px 15px 1px rgb(69 65 78 / 8%);color: #8CC42A;text-align: center;font-weight: bold">
                                    <span>Efficient Real Time <br>
                                        Tracking</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4" style="height: 100vh;">
                <div class="row w-100 mt-5 webLogo">
                    <img alt="Uengage Logo" class="max-h-60px" src="../../../../../assets/media/logos/uEngage-Logo.png">
                </div>
                <div class="signup-form-prism2-upper">
                    <div class="signup-form-prism2">
                        <div class="signup-heading">
                            <p class="comman-paragraph-class hey">Hey<span class="hand-emoji-image"> <img
                                        src="../../../../../assets/media/prism-images/waving-hand.png" /></span>
                            </p>
                            <span style="font-size: 18px;
                                  color: #000000; font-weight: 600;">Let’s take your business to next step</span>
                        </div>
                        <div class="userForm-upper" *ngIf="otpValidated!=1 && googleLogin!=1 && fbLogin!=1">
                            <form [formGroup]="userForm">
                                <div class="form-group userFormParent" [ngClass]="{'custom-color':otpValidated==1}">
                                    <div class="input-tick-image-inner" *ngIf="otpValidated==1">
                                        <img src="../../../../../assets/media/prism-images/verify.png" />
                                    </div>
                                    <label for="email">Email</label>
                                    <input type="email" id="email" formControlName="email" placeholder="Enter email"
                                        class="form-control">
                                    <div class="error"
                                        *ngIf="userForm.get('email').hasError('required') && userForm.get('email').touched">
                                        Email is required.
                                    </div>
                                    <div class="error" *ngIf="userForm.get('email').hasError('email')">
                                        Please enter a valid email address.
                                    </div>
                                </div>
                                <div class="form-group userFormParent" [ngClass]="{'custom-color':otpValidated==1}">
                                    <label for="phoneNumber">Phone Number</label>
                                    <div class="input-tick-image-inner" *ngIf="otpValidated==1">
                                        <img src="../../../../../assets/media/prism-images/verify.png" />
                                    </div>
                                    <input type="tel" id="phoneNumber" formControlName="phoneNumber"
                                        placeholder="Enter mobile number" class="form-control">
                                    <div class="error"
                                        *ngIf="userForm.get('phoneNumber').hasError('required') && userForm.get('phoneNumber').touched">
                                        Phone Number is required.
                                    </div>
                                    <div class="error"
                                        *ngIf="userForm.get('phoneNumber').hasError('pattern') && userForm.get('phoneNumber').touched">
                                        Please enter a valid phone number.
                                    </div>
                                    <div class="error"
                                        *ngIf="userForm.get('phoneNumber').hasError('minlength') || userForm.get('phoneNumber').hasError('maxlength')">
                                        Phone number should be 10 digits long.
                                    </div>
                                </div>
                                <button class="btn signup-button" type="submit" (click)="createUser(dateModal)">Sign
                                    up for free</button>
                            </form>
                            <div class="or">
                                <span>---------- OR ----------</span>
                            </div>
                            <div class="social-media-block">
                                <div class="google-block">
                                    <a class="btn google-button" (click)="signInWithGoogle()"><img alt="Facebook Icon"
                                            src="../../../../../assets/media/misc/Google.png">Google </a>
                                </div>
                                <div class="facebook-block">
                                    <a class="btn google-button" (click)="signInWithFB()">
                                        <img alt="Facebook Icon"
                                            src="../../../../../assets/media/prism-images/Group4399.png">
                                        Facebook </a>
                                </div>
                            </div>
                            <div class="already-have-account">
                                <span style="color: #000000;">Already have an account ? <span
                                        style="color: #8CC42A;cursor: pointer;" (click)="login()">Log
                                        In</span></span>
                            </div>
                        </div>
                        <div class="userForm-upper" *ngIf="otpValidated==1 || googleLogin==1 || fbLogin==1">
                            <form [formGroup]="personalDetails" autocomplete="off">
                                <div class="row w-100">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group userFormParent w-100"
                                            [ngClass]="{'custom-color':otpValidated==1 || googleLogin==1 || fbLogin==1}">
                                            <div class="input-tick-image-inner"
                                                *ngIf="otpValidated==1 || googleLogin==1 || fbLogin==1">
                                                <img src="../../../../../assets/media/prism-images/verify.png" />
                                            </div>
                                            <label for="email">Email</label>
                                            <input type="email" id="email" formControlName="email"
                                                placeholder="Enter email" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12" *ngIf="otpValidated==1">
                                        <div class="form-group userFormParent w-100"
                                            [ngClass]="{'custom-color':otpValidated==1 || googleLogin==1 || fbLogin==1}">
                                            <div class="input-tick-image-inner"
                                                *ngIf="otpValidated==1 || googleLogin==1 || fbLogin==1">
                                                <img src="../../../../../assets/media/prism-images/verify.png" />
                                            </div>
                                            <label for="email">Phone Number</label>
                                            <input type="text" id="phoneNumber" formControlName="phoneNumber"
                                                placeholder="Enter phone number" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="form-group userFormParent w-100">
                                        <label for="name">Contact Name</label>
                                        <input type="text" id="name" formControlName="name" placeholder="Enter name"
                                            class="form-control">
                                        <div class="error"
                                            *ngIf="personalDetails.get('name').hasError('required') && personalDetails.get('name').touched">
                                            Field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="form-group userFormParent w-100">
                                        <label for="business">Business/Brand Name</label>
                                        <input type="text" id="business" formControlName="bussinessName"
                                            placeholder="Enter business name" class="form-control">
                                        <div class="error"
                                            *ngIf="personalDetails.get('bussinessName').hasError('required') && personalDetails.get('bussinessName').touched">
                                            Field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="form-group userFormParent w-100">
                                        <label for="contact">Outlet Contact</label>
                                        <input type="tel" id="contact" formControlName="contact"
                                            placeholder="Enter outlet contact" autocomplete="off" class="form-control">
                                        <div class="error"
                                            *ngIf="personalDetails.get('contact').hasError('required') && personalDetails.get('contact').touched">
                                            Phone Number is required.
                                        </div>
                                        <div class="error"
                                            *ngIf="personalDetails.get('contact').hasError('pattern') && personalDetails.get('contact').touched">
                                            Please enter a valid phone number.
                                        </div>
                                        <div class="error"
                                            *ngIf="personalDetails.get('contact').hasError('minlength') || personalDetails.get('contact').hasError('maxlength')">
                                            Phone number should be 10 digits long.
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="form-group userFormParent w-100" *ngIf="googleLogin==1 || fbLogin==1">
                                        <label for="contact">Merchant Contact</label>
                                        <input type="tel" id="contact" formControlName="phoneNumber"
                                            placeholder="Enter outlet contact" autocomplete="off" class="form-control">
                                        <div class="error"
                                            *ngIf="personalDetails.get('phoneNumber').hasError('required') && personalDetails.get('phoneNumber').touched">
                                            Phone Number is required.
                                        </div>
                                        <div class="error"
                                            *ngIf="personalDetails.get('phoneNumber').hasError('pattern') && personalDetails.get('phoneNumber').touched">
                                            Please enter a valid phone number.
                                        </div>
                                        <div class="error"
                                            *ngIf="personalDetails.get('phoneNumber').hasError('minlength') || personalDetails.get('phoneNumber').hasError('maxlength')">
                                            Phone number should be 10 digits long.
                                        </div>
                                    </div>
                                </div>

                                <div class="row w-100">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group userFormParent w-100">
                                            <label>City</label>
                                            <select formControlName="city" (change)="getState()" class="form-control">
                                                <option value="" selected>--Select--</option>
                                                <option *ngFor="let data of cityData" value="{{data.cityName}}">
                                                    {{data.cityName}}</option>
                                            </select>
                                            <div class="error"
                                                *ngIf="personalDetails.get('city').hasError('required') && personalDetails.get('city').touched">
                                                Field is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group userFormParent w-100">
                                            <label for="state">State</label>
                                            <input type="text" id="state" formControlName="state"
                                                placeholder="Enter state" disabled class="form-control">
                                            <div class="error"
                                                *ngIf="personalDetails.get('state').hasError('required') && personalDetails.get('state').touched">
                                                Field is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="form-group userFormParent w-100" *ngIf="otpValidated==1">
                                        <div class="input-tick-image-inner" (click)="changePasswordVisibility()">
                                            <i class="fa"
                                                [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                                style="color: #8CC42A;"></i>
                                        </div>
                                        <label for="pswrd">Password</label>
                                        <input [type]="fieldTextType ? 'text' : 'password'" id="pswrd"
                                            formControlName="password" placeholder="Enter account password"
                                            autocomplete="off" class="form-control">
                                        <div class="error"
                                            *ngIf="personalDetails.get('password').hasError('required') && personalDetails.get('password').touched">
                                            Field is required.
                                        </div>
                                    </div>
                                </div>

                                <button class="btn signup-button" type="submit" (click)="createAccount()">Create
                                    Account</button>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="privacy-policy">
                            <p>By signing up, You agree to our <span class="privacy-policy-inner">Privacy Policy</span>
                                and <span class="privacy-policy-inner">Terms of Service.</span></p>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<ng-template #dateModal let-modal>
    <div class="modal-body">
        <div class="row w-100 m-0">
            <span class="text-dark-75 font-weight-bolder" style="font-size: 1.5em;">OTP Verification</span>
        </div>
        <div class="mt-5 p-5" style="background-color: #FAFAFA;border-radius: 20px;" *ngIf="!isEmailVerified">
            <div class="row w-100">
                <div class="col-md-6">
                    <span class="text-dark-75" style="font-weight: bold; font-size: 1.1em;">{{emailId}}</span>
                </div>
                <div class="col-md-6" style="text-align: end;">
                    <a style="color: #8CC42A;text-decoration: underline;cursor: pointer; font-size: 0.9em;"
                        (click)="changeEmail()">Change
                        Email</a>
                </div>
            </div>
            <div class="otp-input">
                <ng-otp-input (onInputChange)="emailOtp($event)" [config]="{length:4}"></ng-otp-input>
            </div>
            <!-- <div class="row w-100 justify-content-end">
                <span style="font-size: 0.9em;">Didn't get the OTP?&nbsp;</span><a
                    style="color: #8CC42A;text-decoration: underline;cursor: pointer;font-size: 0.9em;"
                    (click)="resend()">Resend</a>
            </div> -->
        </div>
        <div class="mt-5 p-5" style="background-color: #FAFAFA;border-radius: 20px;" *ngIf="!isPhoneVerified">
            <div class="row w-100">
                <div class="col-md-6">
                    <span class="text-dark-75" style="font-weight: bold; font-size: 1.1em;">+91-{{merchantNum}}</span>
                </div>
                <div class="col-md-6" style="text-align: end;">
                    <a style="color: #8CC42A;text-decoration: underline;cursor: pointer;font-size: 0.9em;"
                        (click)="changeNumber()">Change
                        Number</a>
                </div>
            </div>
            <div class="otp-input">
                <ng-otp-input (onInputChange)="contactOtp($event)" [config]="{length:4}"></ng-otp-input>
            </div>
            <!-- <div class="row w-100 justify-content-end">
                <span style="font-size: 0.9em;">Didn't get the OTP?&nbsp;</span><a
                    style="color: #8CC42A;text-decoration: underline;cursor: pointer;font-size: 0.9em;"
                    (click)="resend()">Resend</a>
            </div> -->
        </div>
        <div class="mt-5 p-5" style="background-color: #FAFAFA;border-radius: 20px;" *ngIf="isEmailVerified">
            <div class="row w-100 m-0 p-5 justify-content-center">
                <img src="../../../../../assets/media/prism-images/verify.png" />
            </div>
            <div class="row w-100 m-0 p-5 justify-content-center">
                <p style="font-size: 1.4em;
                font-weight: bold;">Your Email Address is Verified</p>
            </div>
        </div>
        <div class="mt-5 p-5" style="background-color: #FAFAFA;border-radius: 20px;" *ngIf="isPhoneVerified">
            <div class="row w-100 m-0 p-5 justify-content-center">
                <img src="../../../../../assets/media/prism-images/verify.png" />
            </div>
            <div class="row w-100 m-0 p-5 justify-content-center">
                <p style="font-size: 1.4em;
                font-weight: bold;">Your Mobile Number is Verified</p>
            </div>
        </div>
    </div>
</ng-template>